import { deleteAuthGroup } from '@dmp/qqms/data-access';
import { useErrorToaster, useSuccessToaster } from '@dmp/shared/components';
import { useRecoilCallback } from 'recoil';
import { allAuthGroupList } from './auth-group-list-atoms';

/**
 * Action for creating an auth group
 * - then, update the item in the auth group list.
 */
export const useAuthGroupDelete = (authGroupId: string) => {
    const { handleError } = useErrorToaster();
    const { handleSuccess } = useSuccessToaster();

    const deleteAuthGroupCallback = useRecoilCallback(
        ({ snapshot, set }) =>
            async () => {
                const groupList = await snapshot.getPromise(allAuthGroupList);

                deleteAuthGroup(authGroupId)
                    .then(() => {
                        // update group list
                        const newGroupList = groupList.filter(
                            (group) => group.id !== authGroupId
                        );

                        set(allAuthGroupList, newGroupList);
                        handleSuccess('Auth Group deleted successfully');
                    })
                    .catch(handleError);
            },
        [authGroupId]
    );

    return { deleteAuthGroup: deleteAuthGroupCallback };
};
