import { attachPollResultsKey } from '@dmp/qqms/data-access';
import { generatePollResult } from '@dmp/qqms/survey-generators';
import type { Card, PollResultCard, Survey } from '@dmp/qqms/types';
import type { WithoutID } from '@dmp/shared/types';
import {
    atomFamily,
    DefaultValue,
    selector,
    selectorFamily,
    waitForAll,
} from 'recoil';
import { compareAndSet } from '../../utils/compare-and-set';
import { getVisualCardType } from '../../utils/getVisualCardType';
import { cardConfigSelector } from '../survey-config/survey-config-atoms';
import {
    surveyAboutCopyState,
    surveyCardIdsState,
    surveyQQIDState,
    surveySponsorInfoState,
    surveyTraxexLabelState,
} from '../survey/survey-atoms';
import {
    isDynamicCoreQuestionItem,
    isLinearQuestionItem,
    isPollResultCardItem,
    isQuestionItem,
    isThankYouCardItem,
} from './card-type-guards';
import type {
    CardItemState,
    DynamicCoreQuestionItemState,
    ForkedQuestionItemState,
    LinearQuestionItemState,
    PollResultCardItemState,
    QuestionItemState,
    ThankYouCardItemState,
} from './card-types';
import { without } from '@dmp/shared/helpers';

/****************************************************************
 * Defaults
 ****************************************************************/
export const defaultQuestion: WithoutID<LinearQuestionItemState> = {
    type: 'linearQuestion',
    formType: 'radio',
    body: '',
    answers: [],
    traxexLabel: '',
};

export const defaultCoreQuestion: WithoutID<DynamicCoreQuestionItemState> = {
    type: 'dynamicCoreQuestion',
    questions: [],
};

export const defaultThankYou: WithoutID<ThankYouCardItemState> = {
    type: 'thankYouCard',
    body: 'Thank you for your response.',
    traxexLabel: 'ThankYouCard',
};

export const defaultPollResult: WithoutID<PollResultCardItemState> = {
    type: 'pollResultCard',
    questionId: '',
    traxexLabel: 'PollResultCard',
};

export const cardIdState = atomFamily<CardItemState['id'], CardItemState['id']>(
    {
        key: 'cardIdState',
        default: '',
    }
);

export const cardTypeState = atomFamily<
    CardItemState['type'],
    CardItemState['id']
>({
    key: 'cardTypeState',
    default: 'linearQuestion',
});

export const cardFormTypeState = atomFamily<
    QuestionItemState['formType'],
    CardItemState['id']
>({
    key: 'cardFormTypeState',
    default: 'radio',
});

export const cardBodyState = atomFamily<
    QuestionItemState['body'],
    CardItemState['id']
>({
    key: 'cardBodyState',
    default: '',
});

export const cardAnswerIdsState = atomFamily<
    LinearQuestionItemState['answers'] | ForkedQuestionItemState['answers'],
    CardItemState['id']
>({
    key: 'cardAnswerIdsState',
    default: [],
});

export const cardResultCardState = atomFamily<
    LinearQuestionItemState['resultCard'],
    CardItemState['id']
>({
    key: 'cardResultCardState',
    default: undefined,
});

export const cardTraxexLabelState = atomFamily<
    QuestionItemState['traxexLabel'],
    CardItemState['id']
>({
    key: 'cardTraxexLabelState',
    default: '',
});

export const cardQuestionsState = atomFamily<
    DynamicCoreQuestionItemState['questions'] | undefined,
    CardItemState['id']
>({
    key: 'cardQuestionState',
    default: [],
});

export const cardMaximizeResponsesState = atomFamily<
    DynamicCoreQuestionItemState['maximizeResponses'],
    CardItemState['id']
>({
    key: 'cardMaximizeResponsesState',
    default: undefined,
});

export const cardPollResultQuestionIdState = atomFamily<
    PollResultCardItemState['questionId'],
    CardItemState['id']
>({
    key: 'cardPollResultQuestionIdState',
    default: '',
});

/**
 * Dynamic Card item state by id
 *
 * @param CardId
 */
export const cardItemState = selectorFamily<CardItemState, CardItemState['id']>(
    {
        key: 'cardItemState',
        get:
            (id) =>
            ({ get }) => {
                const cardId = get(cardIdState(id));
                const type = get(cardTypeState(id));
                const formType = get(cardFormTypeState(id));
                const body = get(cardBodyState(id));
                const answers = get(cardAnswerIdsState(id));
                const resultCard = get(cardResultCardState(id));
                const traxexLabel = get(cardTraxexLabelState(id));
                const questions = get(cardQuestionsState(id));
                const maximizeResponses = get(cardMaximizeResponsesState(id));
                const questionId = get(cardPollResultQuestionIdState(id));

                switch (type) {
                    case 'dynamicCoreQuestion':
                        return {
                            id: cardId,
                            type,
                            resultCard,
                            questions: questions || [],
                            maximizeResponses,
                        };

                    case 'forkedQuestion':
                        return {
                            id: cardId,
                            traxexLabel,
                            type,
                            formType,
                            body,
                            answers,
                        };

                    case 'linearQuestion':
                        return {
                            id: cardId,
                            type,
                            traxexLabel,
                            formType,
                            body,
                            answers,
                            resultCard,
                        };

                    case 'thankYouCard':
                        return {
                            id: cardId,
                            type,
                            body,
                            traxexLabel,
                        };

                    case 'pollResultCard':
                        return {
                            id: cardId,
                            type,
                            questionId,
                            traxexLabel,
                        };
                }
            },
        set:
            (id) =>
            ({ get, set, reset }, newValue) => {
                if (newValue instanceof DefaultValue) {
                    reset(cardIdState(id));
                    reset(cardTypeState(id));
                    reset(cardFormTypeState(id));
                    reset(cardBodyState(id));
                    reset(cardAnswerIdsState(id));
                    reset(cardResultCardState(id));
                    reset(cardTraxexLabelState(id));
                    reset(cardQuestionsState(id));
                    reset(cardPollResultQuestionIdState(id));
                    return;
                }

                const _set = compareAndSet(get, set);

                // Apply on all card types
                _set(cardIdState(id), newValue.id);
                _set(cardTypeState(id), newValue.type);

                // Core, Forked, and Linear Questions
                if (isQuestionItem(newValue)) {
                    _set(cardFormTypeState(id), newValue.formType);
                    _set(cardBodyState(id), newValue.body);
                    _set(cardAnswerIdsState(id), newValue.answers);
                    _set(cardTraxexLabelState(id), newValue.traxexLabel);

                    if (isLinearQuestionItem(newValue)) {
                        _set(cardResultCardState(id), newValue.resultCard);
                    }
                }

                // Dynamic Core question
                if (isDynamicCoreQuestionItem(newValue)) {
                    _set(cardResultCardState(id), newValue.resultCard);
                    _set(cardQuestionsState(id), newValue.questions);
                    _set(
                        cardMaximizeResponsesState(id),
                        newValue.maximizeResponses
                    );
                }

                // Thank You Card
                if (isThankYouCardItem(newValue)) {
                    _set(cardBodyState(id), newValue.body);
                    _set(cardTraxexLabelState(id), newValue.traxexLabel);
                }

                // Poll Result Card
                if (isPollResultCardItem(newValue)) {
                    _set(
                        cardPollResultQuestionIdState(id),
                        newValue.questionId
                    );
                    _set(cardTraxexLabelState(id), newValue.traxexLabel);
                }
            },
    }
);

/**
 * Get all card entities in survey
 */
export const surveyCardsSelector = selector({
    key: 'surveyCardsSelector',
    get: ({ get }) => {
        const cardIds = get(surveyCardIdsState);
        const cards = get(waitForAll(cardIds.map((id) => cardItemState(id))));
        return cards;
    },
});

/**
 * Check if survey has a card with defined type
 */
export const hasCardWithTypeSelector = selectorFamily({
    key: 'hasCardWithTypeSelector',
    get:
        (type: CardItemState['type']) =>
        ({ get }) => {
            const cards = get(surveyCardsSelector);
            return cards.some((c) => c.type === type);
        },
});

/**
 * Check if card is the start card: first card (cards[0])
 *
 * @param CardId
 */
export const isTheFirstCardSelector = selectorFamily({
    key: 'isTheFirstCardSelector',
    get:
        (cardId: Card['id']) =>
        ({ get }) =>
            get(surveyCardIdsState)[0] === cardId,
});

/**
 * Get card visual type
 *
 * @param CardId
 */
export const cardVisualTypeSelector = selectorFamily({
    key: 'cardVisualTypeSelector',
    get:
        (cardId: Card['id']) =>
        ({ get }) => {
            const type = get(cardTypeState(cardId));
            const formType = get(cardFormTypeState(cardId));

            return getVisualCardType({
                type,
                formType,
            });
        },
});

/**
 * Get the other card ids than the given card id in survey
 *
 * @param CardId
 */
export const surveyOtherCardIdsSelector = selectorFamily({
    key: 'surveyOtherCardIdsSelector',
    get:
        (cardId: Card['id']) =>
        ({ get }) => {
            const allCardIds = get(surveyCardIdsState);
            return without(allCardIds, [cardId]);
        },
});

/**
 * Get the next index card id
 */
export const nextIndexCardIdSelector = selectorFamily({
    key: 'nextIndexCardIdSelector',
    get:
        (cardId: Card['id']) =>
        ({ get }) => {
            const cardIds = get(surveyCardIdsState);
            const cardIndex = cardIds.findIndex((id) => id === cardId);
            const id = cardIds[cardIndex + 1];
            return id || undefined;
        },
});

/**
 * Check if the Question has Poll Result.
 */
export const questionHasPollResultSelector = selectorFamily({
    key: 'questionHasPollResultSelector',
    get:
        (questionId: Card['id']) =>
        ({ get }) => {
            const cards = get(surveyCardsSelector);
            const pollResultCards = cards.filter(
                (c) => c.type === 'pollResultCard'
            ) as PollResultCard[];

            const pollResultCardsQuestionIds = pollResultCards.map(
                (c) => c.questionId
            );

            return pollResultCardsQuestionIds.includes(questionId);
        },
});

export const cardToSurveyPreviewSelector = selectorFamily({
    key: 'cardToSurveyPreviewSelector',
    get:
        (cardId: Card['id']) =>
        ({ get }): Survey => {
            const card = get(cardConfigSelector(cardId));
            const questionHasPollResult = get(
                questionHasPollResultSelector(card.id)
            );

            let cards = [card];

            if (card.type === 'pollResultCard') {
                // We must include the source Question,
                // so the QQ Renderer can get the Question body.

                const sourceCard = get(cardConfigSelector(card.questionId));
                cards = [card, attachPollResultsKey(sourceCard)];
            } else if (questionHasPollResult) {
                // We must include the Poll Result card, so that the Question
                // knows to show the "See how other readers answered…" messaging.
                cards = [card, generatePollResult({ questionId: card.id })];
            }

            /**
             * The Survey ID is later used as a key for <SurveyPreview />. This ensures
             * unique renders and data fetches when the Card or dependent cards change.
             */
            const surveyId =
                card.type === 'pollResultCard'
                    ? `${card.id}-${card.questionId}`
                    : card.id;

            return {
                id: surveyId,
                shortId: 'mock-survey',
                qqid: get(surveyQQIDState),
                traxexLabel: get(surveyTraxexLabelState),
                aboutCopy: get(surveyAboutCopyState),
                sponsorInfo: get(surveySponsorInfoState),
                cards,
            };
        },
});
