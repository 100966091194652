/**
 * Convert a string to color
 * Src: https://stackoverflow.com/questions/3426404/create-a-hexadecimal-colour-based-on-a-string-with-javascript
 */
export const colorize = (str: string) => {
    const stringUniqueHash = [...str].reduce(
        (acc, char) => char.charCodeAt(0) + ((acc << 5) - acc),
        0
    );
    return `hsl(${stringUniqueHash % 360}, 95%, 35%)`;
};
