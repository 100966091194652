import {
    HStack,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
} from '@chakra-ui/react';
import { memo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { previewSizes } from '../../../config/preview-sizes';
import { cardTypeState } from '../../../state/card/card-atoms';
import type { CardItemState } from '../../../state/card/card-types';
import { previewSizeState } from '../../../state/ui/preview-size-atoms';
import { previewZoomState } from '../../../state/ui/preview-zoom-atoms';
import { PaperCard } from '../../common/paper-card';
import { CardPreview } from './card-preview';
import { PreviewZoom } from './preview-zoom';

interface CardPreviewBoxProps {
    cardId: CardItemState['id'];
}

const CardPreviewBoxCom: React.FC<CardPreviewBoxProps> = ({ cardId }) => {
    const cardType = useRecoilValue(cardTypeState(cardId));
    const {
        currentIndex,
        handleTabChange,
        height,
        previewZoom,
        sizeCollection,
        width,
    } = useCardPreviewBox();

    if (cardType === 'dynamicCoreQuestion') {
        return null;
    }

    return (
        <PaperCard>
            <Tabs isLazy index={currentIndex} onChange={handleTabChange}>
                <TabList>
                    {sizeCollection.map((size) => (
                        <Tab key={size.name} fontSize="sm">
                            {size.name}
                        </Tab>
                    ))}

                    <HStack flex="1" justifyContent="flex-end" px="2">
                        <PreviewZoom />
                    </HStack>
                </TabList>

                <TabPanels>
                    {sizeCollection.map((size) => (
                        <TabPanel key={size.name}>
                            <CardPreview
                                cardId={cardId}
                                width={width}
                                height={height}
                                previewZoom={previewZoom}
                            />
                        </TabPanel>
                    ))}
                </TabPanels>
            </Tabs>
        </PaperCard>
    );
};

export const CardPreviewBox = memo(CardPreviewBoxCom);

const useCardPreviewBox = () => {
    const previewZoom = useRecoilValue(previewZoomState);

    const [sizeName, setSizeName] = useRecoilState(previewSizeState);
    const { width, height } = previewSizes[sizeName];
    const sizeCollection = Object.values(previewSizes);

    const currentIndex = sizeCollection.findIndex(
        (size) => size.name === sizeName
    );

    const handleTabChange = (index: number) =>
        setSizeName(sizeCollection[index].name);

    return {
        currentIndex,
        currentSize: sizeName,
        handleTabChange,
        height,
        previewZoom,
        setSizeName,
        sizeCollection,
        width,
    };
};
