import { atom } from 'recoil';
import { localStorageEffect } from '../../utils/localStorageEffect';

/**
 * Atom state to set preview-zoom
 */
export const previewZoomState = atom<number>({
    key: 'ui_previewZoomState',
    default: 0.75,
    effects_UNSTABLE: [localStorageEffect('preview-zoom')],
});
