import type { AuthGroupResource } from '@dmp/qqms/data-access';
import { replaceItem } from '@dmp/shared/helpers';
import { atom, DefaultValue, selector, selectorFamily } from 'recoil';

/**
 * Store original core auth group list
 */
export const allAuthGroupList = atom<AuthGroupResource[]>({
    key: 'allAuthGroupList',
    default: [],
});

/**
 * Get the list of all auth group ids
 */
export const authGroupIdsSelector = selector<Array<AuthGroupResource['id']>>({
    key: 'authGroupIdsSelector',
    get: ({ get }) => [...get(allAuthGroupList)].map((cq) => cq.id),
});

/**
 * Get/Set auth group config by id
 * - Getter: get auth group config
 * - Setter: set auth group config by id. allAuthGroupList will be automatically updated.
 */
export const authGroupResourceSelector = selectorFamily<
    AuthGroupResource | undefined,
    AuthGroupResource['id']
>({
    key: 'authGroupResourceSelector',
    get:
        (id) =>
        ({ get }) =>
            get(allAuthGroupList).find((cq) => cq.id === id),
    set:
        (id) =>
        ({ get, set }, newValue) => {
            if (newValue instanceof DefaultValue) {
                return;
            }

            if (!newValue) {
                return;
            }

            const allAuthGroups = get(allAuthGroupList);
            const index = allAuthGroups.findIndex((cq) => cq.id === id);

            // exists, update the config
            if (index !== -1) {
                const newCoreQuestionList = replaceItem(
                    allAuthGroups,
                    index,
                    newValue
                );

                set(allAuthGroupList, newCoreQuestionList);
                return;
            }

            // not found, add on top of the list
            set(allAuthGroupList, [newValue].concat(allAuthGroups));
        },
});
