import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import type { Survey } from '@dmp/qqms/types';
import { useSurveyFetch } from '../../state/survey/use-survey-fetch';
import { isSurveyDirtyState } from '../../state/ui/is-survey-dirty-atoms';
import {
    surveyCardIdsState,
    surveyNameState,
} from '../../state/survey/survey-atoms';
import { useSurveyReset } from '../../state/survey/use-survey-reset';
import { useCoreQuestionListReset } from '../../state/core-question/use-core-question-list-reset';
import { useCoreQuestionListFetch } from '../../state/core-question/use-core-question-list-fetch';

export const useSurveyView = (surveyId: Survey['id']) => {
    const surveyName = useRecoilValue(surveyNameState);
    const surveyCardIds = useRecoilValue(surveyCardIdsState);
    const isSurveyDirty = useRecoilValue(isSurveyDirtyState);

    const { fetchSurveyIntoState } = useSurveyFetch(surveyId);
    const { resetSurvey } = useSurveyReset();
    const { fetchCoreQuestionList } = useCoreQuestionListFetch();
    const { resetCoreQuestionList } = useCoreQuestionListReset();

    useEffect(() => {
        fetchSurveyIntoState();
        fetchCoreQuestionList();

        return () => {
            // Prevent Memory Leak - clean up survey, cards, answer atoms after exit
            resetSurvey();
            resetCoreQuestionList();
        };
    }, [
        fetchCoreQuestionList,
        fetchSurveyIntoState,
        resetCoreQuestionList,
        resetSurvey,
        surveyId,
    ]);

    return {
        cardIds: surveyCardIds,
        surveyName,
        isSurveyDirty,
    };
};
