import type { Answer, Question } from '@dmp/qqms/types';
import type { ChangeEventHandler } from 'react';
import { useDispatch } from '../events/hooks';

interface DropDownComponentProps {
    question: Question;
}

export function DropDownComponent({ question }: DropDownComponentProps) {
    const dispatch = useDispatch();

    const answers: Answer[] = question.answers;

    // add default option to the answers collection
    const options = [
        { label: '..select answer', value: '' },
        ...answers.map((a) => ({
            label: a.body,
            value: a.body,
        })),
    ];

    const onChange: ChangeEventHandler<HTMLSelectElement> = (e) => {
        if (e.target) {
            const value = (e.target as HTMLSelectElement).value;
            const answer = answers.find((a) => a.body === value);

            if (answer) {
                dispatch('answersSelected', { answers, question });
            }
        }
    };

    return (
        <select onChange={onChange}>
            {options.map((o) => (
                <option
                    key={`${question.id}-${o.value}`}
                    className="option"
                    value={o.value}
                >
                    {o.label}
                </option>
            ))}
        </select>
    );
}
