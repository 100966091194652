import type {
    AnswerEvent,
    CardEvent,
    ExternalEventContext,
} from '@dmp/qqms/types';
import {
    makeAnswerTrackingUrls,
    makeCardTrackingUrls,
} from '@dmp/qqms/survey-utils';

const mockExternalEventContext: ExternalEventContext = {
    bot: '',
    cb: '%%CACHEBUSTER%%',
    cid: '%%PATTERN:contentid%%',
    ctype: '%%PATTERN:pageType%%',
    g: '%g',
    gam_advertiser: '%eadv!',
    gam_creative: '%ecid!',
    gam_line: '%eaid!',
    gam_order: '%ebuy!',
    gnt_sb: '%%PATTERN:gnt_sb%%',
    gnt_ub: '%%PATTERN:gnt_ub%%',
    gup_id: 'gup_id',
    package_name: '%%PATTERN:PackageName%%',
    sc: '%%PATTERN:property%%',
    site: '%%SITE%%',
    ss: '%%PATTERN:ss%%',
    ssts_s: '%%PATTERN:ssts_section%%',
    ssts_ss: '%%PATTERN:ssts_subsection%%',
    ssts_t: '%%PATTERN:ssts_topic%%',
    user_type: 'Anonymous',
    variant: '%%PATTERN:variant%%',
};

const mockTraxexApi = 'https://traxex.gannettdigital.com';

export const getAnswerTrackingUrls = (
    answerEvent: Omit<AnswerEvent, 'eventContext'>
): string[] => {
    const survey = {
        ...answerEvent.survey,
        traxexApi: mockTraxexApi,
    };

    return makeAnswerTrackingUrls({
        ...answerEvent,
        eventContext: mockExternalEventContext,
        survey,
    });
};

export const getCardEnterTrackingUrls = (
    cardEvent: Omit<CardEvent, 'eventContext'>
): string[] => {
    const survey = {
        ...cardEvent.survey,
        traxexApi: mockTraxexApi,
    };

    return makeCardTrackingUrls({
        ...cardEvent,
        eventContext: mockExternalEventContext,
        survey,
    });
};
