import { memo } from 'react';
import type { IconProps } from '@chakra-ui/react';
import { Icon } from '@chakra-ui/react';

const IconThankYouCom = (props: IconProps) => {
    return (
        <Icon viewBox="0 0 80 60" {...props}>
            <path
                fill="currentColor"
                d="M49.4 50.3H29.3c-1.5 0-2.7-1.4-2.7-3.1 0-1.7 1.2-3.1 2.7-3.1h20.1c1.5 0 2.7 1.4 2.7 3.1 0 1.7-1.2 3.1-2.7 3.1zM56.5 18.9l-7.1-2.6-2.9-6.4-7.1 2.6-7.1-2.6-2.9 6.4-7.1 2.6 2.9 6.4-2.9 6.4 7.1 2.6 2.9 6.4 7.1-2.6 7.1 2.6 2.9-6.4 7.1-2.6-2.9-6.4 2.9-6.4zM37.2 32.2l-5.3-4.8 2.4-2.2 2.9 2.6 8.4-7.6 2.4 2.2-10.8 9.8z"
            />
        </Icon>
    );
};

export const IconThankYou = memo(IconThankYouCom);
