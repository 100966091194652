import type {
    AnswerItemState,
    ForkedAnswerItemState,
    LinearAnswerItemState,
} from './answer-types';

export const isForkedAnswerItem = (
    answer: AnswerItemState
): answer is ForkedAnswerItemState => answer.type === 'forkedAnswer';

export const isLinearAnswerItem = (
    answer: AnswerItemState
): answer is LinearAnswerItemState => answer.type === 'linearAnswer';
