import { useSurveyState } from '../state/use-survey-state';
import { CardComponent } from './card-component';

export function CardsComponent() {
    const surveyState = useSurveyState();

    const currentCardState =
        surveyState.type === 'idle'
            ? 'idle'
            : surveyState.direction === 'forwards'
            ? 'removed'
            : 'removed-backward';

    const nextCardState =
        surveyState.type === 'idle'
            ? 'idle'
            : surveyState.direction === 'forwards'
            ? 'added'
            : 'added-backward';

    return (
        <div id="cards">
            <CardComponent
                key={surveyState.currentCard.id}
                card={surveyState.currentCard}
                animState={currentCardState}
            />

            {surveyState.type === 'transitioning' && (
                <CardComponent
                    key={surveyState.nextCard.id}
                    card={surveyState.nextCard}
                    animState={nextCardState}
                />
            )}
        </div>
    );
}
