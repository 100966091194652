import { Button } from '@chakra-ui/react';
import { VscFiles as IconCopy, VscCheck as IconConfirm } from 'react-icons/vsc';
import * as React from 'react';

import { copyText } from '@dmp/shared/client-utils';

interface CopyTextProps {
    text: string;
    disabled?: boolean;
}

const CopyTextCom: React.FC<CopyTextProps> = ({ text, disabled }) => {
    const [confirmed, setConfirmed] = React.useState<boolean>(false);

    const copy = () => {
        copyText(text);

        setConfirmed(true);
        setTimeout(() => setConfirmed(false), 2000);
    };

    const otherProps = confirmed ? { colorScheme: 'teal' } : {};

    return (
        <Button
            isDisabled={disabled}
            size="sm"
            leftIcon={confirmed ? <IconConfirm /> : <IconCopy />}
            onClick={copy}
            {...otherProps}
        >
            {confirmed ? 'Copied' : 'Copy'}
        </Button>
    );
};

export const CopyText = React.memo(CopyTextCom);
