import {
    atomFamily,
    DefaultValue,
    selector,
    selectorFamily,
    waitForAll,
} from 'recoil';

import type {
    AnswerItemState,
    ForkedAnswerItemState,
    LinearAnswerItemState,
} from './answer-types';
import {
    cardAnswerIdsState,
    cardTypeState,
    surveyCardsSelector,
} from '../card/card-atoms';
import { isQuestionCardType, isQuestionItem } from '../card/card-type-guards';
import type { CardItemState } from '../card/card-types';
import { compareAndSet } from '../../utils/compare-and-set';
import { isForkedAnswerItem } from './answer-type-guards';

export const answerIdState = atomFamily<
    AnswerItemState['id'],
    AnswerItemState['id']
>({
    key: 'answerIdState',
    default: '',
});

export const answerTypeState = atomFamily<
    AnswerItemState['type'],
    AnswerItemState['id']
>({
    key: 'answerTypeState',
    default: 'linearAnswer',
});

export const answerBodyState = atomFamily<
    AnswerItemState['body'],
    AnswerItemState['id']
>({
    key: 'answerBodyState',
    default: '',
});

export const answerResultCardState = atomFamily<
    ForkedAnswerItemState['resultCard'],
    AnswerItemState['id']
>({
    key: 'answerResultCardState',
    default: undefined,
});

export const answerTraxexLabelState = atomFamily<
    ForkedAnswerItemState['traxexLabel'],
    AnswerItemState['id']
>({
    key: 'answerTraxexLabelState',
    default: '',
});

export const answerIsFreeFormState = atomFamily<
    LinearAnswerItemState['isFreeForm'],
    AnswerItemState['id']
>({
    key: 'answerFreeFormState',
    default: undefined,
});

/**
 * Dynamic Answer item state by id
 *
 * @param AnswerId
 */
export const answerItemState = selectorFamily<
    AnswerItemState,
    AnswerItemState['id']
>({
    key: 'answerItemState',
    get:
        (id) =>
        ({ get }) => {
            const answerId = get(answerIdState(id));
            const type = get(answerTypeState(id));
            const body = get(answerBodyState(id));
            const traxexLabel = get(answerTraxexLabelState(id));

            const baseAnswer = {
                id: answerId,
                type,
                body,
                traxexLabel,
            };

            // forked answer
            if (type === 'forkedAnswer') {
                return {
                    ...baseAnswer,
                    resultCard: get(answerResultCardState(id)),
                };
            } else {
                // Linear Answer
                const isFreeForm = get(answerIsFreeFormState(id));

                return isFreeForm === true
                    ? { ...baseAnswer, isFreeForm }
                    : baseAnswer;
            }
        },
    set:
        (id) =>
        ({ get, set, reset }, newValue) => {
            if (newValue instanceof DefaultValue) {
                reset(answerIdState(id));
                reset(answerTypeState(id));
                reset(answerBodyState(id));
                reset(answerTraxexLabelState(id));
                reset(answerResultCardState(id));
                reset(answerIsFreeFormState(id));

                return;
            }

            const _set = compareAndSet(get, set);

            _set(answerIdState(id), newValue.id);
            _set(answerTypeState(id), newValue.type);
            _set(answerBodyState(id), newValue.body);
            _set(answerTraxexLabelState(id), newValue.traxexLabel);

            if (isForkedAnswerItem(newValue)) {
                _set(answerResultCardState(id), newValue.resultCard);
            } else {
                _set(answerIsFreeFormState(id), newValue.isFreeForm);
            }
        },
});

/**
 * Get full answers in card
 *
 * @param CardId
 */
export const cardAnswersSelector = selectorFamily({
    key: 'cardAnswers',
    get:
        (cardId: CardItemState['id']) =>
        ({ get }): AnswerItemState[] => {
            const cardType = get(cardTypeState(cardId));
            const cardAnswerIds = get(cardAnswerIdsState(cardId));

            // non-question items
            if (!isQuestionCardType(cardType)) {
                return [];
            }

            const answers = get(
                waitForAll(
                    cardAnswerIds.map((answerId) => answerItemState(answerId))
                )
            );

            return answers;
        },
});

/**
 * Get ALL Answer entities in survey
 *
 * @param CardId
 */
export const surveyAllAnswersSelector = selector({
    key: 'surveyAllAnswersSelector',
    get: ({ get }): AnswerItemState[] => {
        const allCards = get(surveyCardsSelector);

        const allAnswerIds = allCards
            .map((card) => (isQuestionItem(card) ? card.answers : []))
            .flat();

        const allAnswers = get(waitForAll(allAnswerIds.map(answerItemState)));

        return allAnswers;
    },
});
