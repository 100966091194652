import type { FormControlProps } from '@chakra-ui/react';
import { Center, FormErrorMessage } from '@chakra-ui/react';
import { useColorMode } from '@chakra-ui/color-mode';
import { FormControl, FormLabel } from '@chakra-ui/form-control';
import { normalizeError } from '@dmp/shared/client-utils';
import { uploadImage } from '@dmp/qqms/data-access';
import type { UploadDropzoneProps } from '@dmp/shared/components';
import { UploadDropzone } from '@dmp/shared/components';
import { memo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { surveyIdState } from '../../../state/survey/survey-atoms';
import { trackingFieldStyles } from '../../../theme/common-styles/tracking-field-styles';
import { truthy } from '@dmp/shared/helpers';

interface SurveySponsoredLogoProps extends Omit<FormControlProps, 'onChange'> {
    value: string;
    onChange: (logo: string) => void;
}

type SurveySponsoredLogoState =
    | { kind: 'idle' }
    | { kind: 'busy' }
    | { kind: 'error'; error: string };

export const SurveySponsoredLogo = memo(
    ({ value, onChange }: SurveySponsoredLogoProps) => {
        const surveyId = useRecoilValue(surveyIdState);
        const [state, setState] = useState<SurveySponsoredLogoState>({
            kind: 'idle',
        });

        const { colorMode } = useColorMode();
        const styles = trackingFieldStyles(colorMode);

        const handleUpload: UploadDropzoneProps['onChange'] = async (file) => {
            setState({ kind: 'busy' });

            if (!file) {
                setState({ kind: 'idle' });
                onChange('');
                return;
            }

            try {
                const response = await uploadImage(surveyId, file);
                setState({ kind: 'idle' });
                onChange(response.url);
            } catch (e) {
                const errorMessage = normalizeError(e)
                    .map((e) => e.detail)
                    .filter(truthy)
                    .join('');

                setState({ kind: 'error', error: errorMessage });
            }
        };

        return (
            <FormControl
                id="survey-sponsored-logo"
                isInvalid={state.kind === 'error'}
            >
                <FormLabel {...styles.label}>Sponsored Logo</FormLabel>
                <Center {...styles.upload}>
                    <UploadDropzone
                        value={value}
                        onChange={handleUpload}
                        dropzoneText="Drop/Upload Image File"
                        isInvalid={state.kind === 'error'}
                    />
                </Center>
                {state.kind === 'error' && (
                    <FormErrorMessage>{state.error}</FormErrorMessage>
                )}
            </FormControl>
        );
    }
);
