import { fetchCoreQuestionList } from '@dmp/qqms/data-access';
import { useErrorToaster } from '@dmp/shared/components';
import { useQuery } from '@tanstack/react-query';
import { useSetRecoilState } from 'recoil';
import { CORE_QUESTIONS } from '../../config/query-keys';
import { allCoreQuestionList } from './core-question-list-atoms';

/**
 * Fetch and process response to atom states
 */
export const useCoreQuestionListFetch = () => {
    const { handleError } = useErrorToaster();
    const setCoreQuestionList = useSetRecoilState(allCoreQuestionList);

    const query = useQuery([CORE_QUESTIONS], fetchCoreQuestionList, {
        enabled: false, // Needs to be manually triggered.
        onSuccess: (resp) => setCoreQuestionList(resp.data),
        onError: (err) => handleError(err),
    });

    return {
        fetchCoreQuestionList: query.refetch,
    };
};
