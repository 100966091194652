import { Center, Text } from '@chakra-ui/layout';
import { Group } from '@visx/group';
import type { HierarchyPointNode } from 'd3-hierarchy';
import { useColorMode } from '@chakra-ui/color-mode';
import * as React from 'react';

import { colorize } from '@dmp/shared/client-utils';
import type { TreeInfo } from '../../../utils/convert-cards-to-tree';

interface TreeNodeProps {
    node: HierarchyPointNode<TreeInfo>;
}

const TreeNodeCom = ({ node }: TreeNodeProps) => {
    const { colorMode } = useColorMode();

    const width = 100;
    const height = 60;
    const x = -width / 2;
    const y = -height / 2;
    const name = node.data.name;
    const cardId = node.data.data?.id;
    const uniqueColor = colorize(cardId + name);

    const pointToCard = () => {
        const cardContainerEl = document.querySelector('.card-forms');
        const cardFormEl = cardContainerEl?.querySelector(
            `#card-${cardId}`
        ) as HTMLDivElement;
        if (cardContainerEl && cardFormEl) {
            cardContainerEl.scrollTo({
                top: cardFormEl.offsetTop - 60,
                behavior: 'smooth',
            });
        }
    };

    const isDark = colorMode === 'dark';

    return (
        <Group
            cursor="pointer"
            top={node.x}
            left={node.y}
            onClick={pointToCard}
        >
            <rect
                width={width}
                height={height}
                x={x}
                y={y}
                fill={!name ? 'orangered' : isDark ? '#272b4d' : 'white'}
                stroke={uniqueColor}
                strokeOpacity="0.5"
                strokeWidth={1}
                rx={10}
            />

            <foreignObject x={x} y={y} width={width} height={height}>
                <Center
                    w="100%"
                    h="100%"
                    p="10px"
                    rounded="10px"
                    title={name}
                    _hover={{
                        background: isDark ? 'whiteAlpha.100' : 'blackAlpha.50',
                    }}
                >
                    <Text
                        w="100%"
                        h="100%"
                        title={name}
                        userSelect="none"
                        fontSize="xs"
                        lineHeight="1.2"
                        overflow="hidden"
                    >
                        {name}
                        {name === '' && 'ERROR: No Name'}
                    </Text>
                </Center>
            </foreignObject>
        </Group>
    );
};

export const TreeNode = React.memo(TreeNodeCom);
