import type { DraftDecorator, EditorState, EntityInstance } from 'draft-js';
import { RichUtils } from 'draft-js';

/**
 * Remove draft entity in the current selection
 */
export const removeEntity = (editorState: EditorState) => {
    const selection = editorState.getSelection();
    const newState = !selection.isCollapsed()
        ? RichUtils.toggleLink(editorState, selection, null)
        : editorState;
    return newState;
};

/**
 * Get entity key at the current selection
 */
export const getEntityKey = (editorState: EditorState): string | undefined => {
    const contentState = editorState.getCurrentContent();
    const startKey = editorState.getSelection().getStartKey();
    const startOffset = editorState.getSelection().getStartOffset();
    const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
    const entityKey = blockWithLinkAtBeginning.getEntityAt(startOffset);
    return entityKey;
};

/**
 * Get entity instance at the current selection
 */
const getEntityInstance = (
    editorState: EditorState
): EntityInstance | undefined => {
    const contentState = editorState.getCurrentContent();
    const entityKey = getEntityKey(editorState);
    return entityKey ? contentState.getEntity(entityKey) : undefined;
};

/**
 * Get entity instance by type at the current selection
 */
export const getInstanceType =
    (instanceType: string) =>
    (editorState: EditorState): EntityInstance | undefined => {
        const instance = getEntityInstance(editorState);
        return instance && instance.getType() === instanceType
            ? instance
            : undefined;
    };

/**
 * Generate strategy
 */
export const generateStrategy =
    (entityType: string): DraftDecorator['strategy'] =>
    (contentBlock, callback, contentState) => {
        contentBlock.findEntityRanges((character) => {
            const entityKey = character.getEntity();
            return (
                entityKey !== null &&
                contentState.getEntity(entityKey).getType() === entityType
            );
        }, callback);
    };
