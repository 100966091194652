import { Divider, HStack, Select, useColorMode } from '@chakra-ui/react';
import type { DraftInlineStyleType, EditorState } from 'draft-js';
import { RichUtils } from 'draft-js';
import React from 'react';
import { ButtonCommandButton } from './button/button-command-button';
import { ColorCommandButton } from './color/color-command-button';
import { CommandButton } from './command-button';
import {
    BLOCKQUOTE,
    BOLD,
    H1,
    H2,
    H3,
    ITALIC,
    OL,
    STRIKETHROUGH,
    UL,
    UNDERLINE,
} from './config';
import { FontSizeCommandButton } from './font-size/font-size-command-button';
import { LinkCommandButton } from './link/link-command-button';

interface GroupProps {
    children: React.ReactNode;
}

const Group = ({ children }: GroupProps) => {
    const { colorMode } = useColorMode();
    const isDark = colorMode === 'dark';

    return (
        <HStack
            spacing="1"
            p="1"
            bg={isDark ? 'whiteAlpha.100' : 'whiteAlpha.700'}
            rounded="md"
            border="1px solid"
            borderColor={isDark ? 'blackAlpha.600' : 'gray.300'}
        >
            {children}
        </HStack>
    );
};

interface CommandBarProps {
    editorState: EditorState;
    setEditorState: (value: EditorState) => void;
}

const DividerCom = <Divider orientation="vertical" h="4" />;

export const CommandBar: React.FC<CommandBarProps> = ({
    editorState,
    setEditorState,
}) => {
    const { colorMode } = useColorMode();
    const isDark = colorMode === 'dark';

    // for inline styles
    const currentStyle = editorState.getCurrentInlineStyle();

    // for block type
    const blockType = editorState
        .getCurrentContent()
        .getBlockForKey(editorState.getSelection().getStartKey())
        .getType();

    const toggleInlineStyle = (style: DraftInlineStyleType) =>
        setEditorState(RichUtils.toggleInlineStyle(editorState, style));

    const toggleBlockType = (type: string) =>
        setEditorState(RichUtils.toggleBlockType(editorState, type));

    return (
        <HStack
            p="1"
            bg={isDark ? 'blackAlpha.200' : 'blackAlpha.50'}
            borderBottom="1px solid"
            borderColor={isDark ? 'blackAlpha.400' : 'blackAlpha.100'}
        >
            <Group>
                {[BOLD, ITALIC, STRIKETHROUGH, UNDERLINE].map((type) => (
                    <CommandButton
                        key={type.style}
                        {...type}
                        isActive={currentStyle.has(type.style)}
                        onToggle={(style) =>
                            toggleInlineStyle(style as DraftInlineStyleType)
                        }
                    />
                ))}

                {DividerCom}

                <FontSizeCommandButton
                    editorState={editorState}
                    setEditorState={setEditorState}
                />

                {DividerCom}

                <ColorCommandButton
                    editorState={editorState}
                    setEditorState={setEditorState}
                />
            </Group>

            <Group>
                {[UL, OL].map((type) => (
                    <CommandButton
                        key={type.style}
                        {...type}
                        isActive={blockType === type.style}
                        onToggle={toggleBlockType}
                    />
                ))}

                {DividerCom}

                <Select
                    size="xs"
                    w="85px"
                    border="0"
                    rounded="md"
                    fontWeight="bold"
                    color={isDark ? 'brand.200' : 'brand.100'}
                    value={blockType}
                    onChange={(e) => {
                        toggleBlockType(e.target.value);
                    }}
                >
                    {['ordered-list-item', 'unordered-list-item'].includes(
                        blockType
                    ) && <option>(List)</option>}

                    <option value="unstyled">Normal</option>

                    {[H1, H2, H3, BLOCKQUOTE].map((type) => (
                        <option key={type.style} value={type.style}>
                            {type.label}
                        </option>
                    ))}
                </Select>
            </Group>

            <Group>
                <LinkCommandButton
                    editorState={editorState}
                    setEditorState={setEditorState}
                />
            </Group>

            <Group>
                <ButtonCommandButton
                    editorState={editorState}
                    setEditorState={setEditorState}
                />
            </Group>
        </HStack>
    );
};
