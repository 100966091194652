import { useColorMode } from '@chakra-ui/color-mode';
import { FormControl, FormLabel, Textarea } from '@chakra-ui/react';
import { useRecoilState } from 'recoil';

import { surveyAboutCopyState } from '../../../state/survey/survey-atoms';
import { trackingFieldStyles } from '../../../theme/common-styles/tracking-field-styles';
import PermissionGate from '../../permission-gate/permission-gate';

export const SurveyAboutCopy = () => {
    const [aboutCopy, setAboutCopy] = useRecoilState(surveyAboutCopyState);
    const { colorMode } = useColorMode();
    const { label, input } = trackingFieldStyles(colorMode);

    return (
        <PermissionGate silent permission="aboutCopy.customize">
            <FormControl id="about-this-survey">
                <FormLabel {...label}>About This Survey Copy</FormLabel>
                <Textarea
                    {...input}
                    value={aboutCopy}
                    onChange={(e) => setAboutCopy(e.target.value)}
                />
            </FormControl>
        </PermissionGate>
    );
};

export default SurveyAboutCopy;
