import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import type { Card } from '@dmp/qqms/types';
import { isTheFirstCardSelector } from '../../../state/card/card-atoms';
import { surveyCardIdsState } from '../../../state/survey/survey-atoms';
import { useCardDelete } from '../../../state/card/use-card-delete';
import { useCardSort } from '../../../state/card/use-card-sort';

export const useCardActionBar = (cardId: Card['id']) => {
    const surveyCardIds = useRecoilValue(surveyCardIdsState);
    const isTheFirstCard = useRecoilValue(isTheFirstCardSelector(cardId));

    const { deleteCard } = useCardDelete(cardId);
    const { sortCard } = useCardSort(cardId);

    const currentIndex = useMemo(
        () => surveyCardIds.findIndex((id) => id === cardId),
        [surveyCardIds, cardId]
    );

    const moveUp = () => {
        sortCard(currentIndex - 1);
    };

    const moveDown = () => {
        sortCard(currentIndex + 1);
    };

    return {
        canDelete: surveyCardIds.length > 1,
        canMoveDown: currentIndex < surveyCardIds.length - 1,
        canMoveUp: currentIndex !== 0,
        isTheFirstCard,
        moveDown,
        moveUp,
        remove: deleteCard,
    };
};
