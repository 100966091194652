import { DefaultValue, selector } from 'recoil';

import type { CoreQuestionResource } from '@dmp/qqms/data-access';
import { coreQuestionResourceListToState } from '../../transformers/core-question-list-resource-to-state';
import { answerItemState } from '../answer/answer-atoms';
import { cardItemState, cardTypeState } from '../card/card-atoms';
import {
    editingCoreQuestionIdState,
    formCoreQuestionIsActiveState,
} from '../core-question/core-question-edit-form-atoms';
import { isCoreQuestionFormDirtyState } from './core-question-edit-form-atoms';
import { coreQuestionResourceSelector } from './core-question-list-atoms';

/**
 * SETTER ONLY - Action for switching editing core question for the editing form
 * - Copy and convert the core question config to card and answer atoms.
 * - Passing THE SAME ID will reset the current question's form to its original config.
 */
export const switchEditingCoreQuestionSelector = selector<
    CoreQuestionResource['id']
>({
    key: 'currentEditingCoreQuestionSelector',
    get: () => '',
    set: ({ get, set }, nextId) => {
        if (nextId instanceof DefaultValue) {
            return;
        }

        const coreQuestionResource = get(coreQuestionResourceSelector(nextId));

        if (!coreQuestionResource) {
            throw new Error(
                `Unable to pull Core Question Resource ${nextId} from state.`
            );
        }

        // update the new current editing core question id
        set(editingCoreQuestionIdState, nextId);

        // reset dirty form state
        set(isCoreQuestionFormDirtyState, false);

        // We'll treat Core Question editing like Linear Question.
        set(cardTypeState(nextId), 'linearQuestion');

        // Set form isActive state from the resource
        set(
            formCoreQuestionIsActiveState,
            coreQuestionResource.attributes.isActive
        );

        const { questions, answers } = coreQuestionResourceListToState([
            coreQuestionResource,
        ]);

        // create individual answer atom
        answers.forEach((answer) => set(answerItemState(answer.id), answer));

        // create individual card atom
        questions.forEach((card) => set(cardItemState(card.id), card));
    },
});
