import { useToast } from '@chakra-ui/react';
import type { CoreQuestionCreateAttributes } from '@dmp/qqms/data-access';
import { postCoreQuestion } from '@dmp/qqms/data-access';
import { useErrorToaster, useSuccessToaster } from '@dmp/shared/components';
import { useRecoilCallback, waitForAll } from 'recoil';
import { answerItemState } from '../answer/answer-atoms';
import { cardItemState } from '../card/card-atoms';
import { isLinearQuestionItem } from '../card/card-type-guards';
import { convertToCoreQuestionConfig } from '../survey-config/convert-to-card-config';
import { validateCardConfigSelector } from '../survey-config/survey-config-atoms';
import {
    formCoreQuestionIsActiveState,
    isCoreQuestionFormDirtyState,
    newCoreQuestionIdState,
} from './core-question-edit-form-atoms';
import { coreQuestionResourceSelector } from './core-question-list-atoms';

/**
 * Action for saving the editing core question for the editing form
 * - convert the current form data to core question config.
 * - update the core question list.
 */
export const useCoreQuestionPost = () => {
    const { handleError } = useErrorToaster();
    const { handleSuccess } = useSuccessToaster();
    const toast = useToast();

    const postCoreQuestionCallback = useRecoilCallback(
        ({ snapshot, set }) =>
            async (withActivation: boolean | undefined = undefined) => {
                const newQuestionId = await snapshot.getPromise(
                    newCoreQuestionIdState
                );

                const isFormDirty = await snapshot.getPromise(
                    isCoreQuestionFormDirtyState
                );

                const card = await snapshot.getPromise(
                    cardItemState(newQuestionId)
                );

                const isActive = await snapshot.getPromise(
                    formCoreQuestionIsActiveState
                );

                const errors = await snapshot.getPromise(
                    validateCardConfigSelector(newQuestionId)
                );

                if (errors.length > 0) {
                    toast({
                        title: 'Error',
                        status: 'error',
                        duration: 4000,
                        isClosable: true,
                        description:
                            'Core Question is invalid. Please check the errors in card validation box.',
                    });
                    return;
                }

                if (!isFormDirty) {
                    return;
                }

                if (!isLinearQuestionItem(card)) {
                    throw new Error(
                        'Only Linear Questions can be Core Questions'
                    );
                }

                const answerCards = await snapshot.getPromise(
                    waitForAll(
                        card.answers.map((answerId) =>
                            answerItemState(answerId)
                        )
                    )
                );

                const config = convertToCoreQuestionConfig(card, answerCards);

                const payload: CoreQuestionCreateAttributes = {
                    name: config.body,
                    config,
                    isActive:
                        withActivation === undefined
                            ? isActive
                            : withActivation,
                };

                // After successfully submit
                // - Add the new core question into the collection list
                // - (the new cord question is automatically selected as it has the same id with the new form)
                // - Reset/empty the new form in newCoreQuestionIdState
                // - Set pristine
                postCoreQuestion(payload)
                    .then((resp) => {
                        // update core question config in the list
                        set(
                            coreQuestionResourceSelector(resp.data.id),
                            resp.data
                        );

                        // reset new form id
                        set(newCoreQuestionIdState, '');

                        // set dirty state to pristine
                        set(isCoreQuestionFormDirtyState, false);

                        handleSuccess('Core Question created successfully');
                    })
                    .catch(handleError);
            },
        []
    );

    return {
        postCoreQuestion: postCoreQuestionCallback,
    };
};
