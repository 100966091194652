import {
    Box,
    Heading,
    HStack,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    VStack,
} from '@chakra-ui/react';
import { SurveyProvider } from '@dmp/qqms-renderer';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import {
    surveyConfigSelector,
    validateSurveyConfigSelector,
} from '../../../state/survey-config/survey-config-atoms';
import { SurveyPreview } from '../../common/survey-preview/survey-preview';
import { useSurveyPreview } from '../../survey-proof/use-survey-preview';
import { SurveyPreviewControls } from './survey-preview-controls';
import { SurveyTrackingUrls } from './survey-tracking-urls';
import { SurveyWarning } from './survey-warning';

const SurveyPreviewWrapperCom = () => {
    const survey = useRecoilValue(surveyConfigSelector);
    const errorLoadable = useRecoilValueLoadable(validateSurveyConfigSelector);

    const { currentIndex, handleTabChange, previewSizes, previewSurvey } =
        useSurveyPreview(survey);

    if (errorLoadable.state !== 'hasValue') {
        return null;
    }

    const errors = errorLoadable.contents;
    if (errors.length > 0) {
        return (
            <VStack p="4">
                <Heading as="h1" fontSize="md">
                    Error detected. Cannot preview survey at the moment.
                </Heading>
                <SurveyWarning />
            </VStack>
        );
    }

    return (
        <SurveyProvider survey={previewSurvey}>
            <Tabs
                isLazy
                index={currentIndex}
                onChange={handleTabChange}
                h="100%"
                w="100%"
                p="4"
            >
                <TabList>
                    {previewSizes.map((size) => (
                        <Tab key={size.name}>{size.name}</Tab>
                    ))}
                </TabList>
                <TabPanels>
                    {previewSizes.map((size) => (
                        <TabPanel key={size.name}>
                            <HStack spacing="6" align="start">
                                <HStack align="start" justifyContent="center">
                                    <Box
                                        border="1px solid rgba(0,0,0,0.1)"
                                        boxSizing="content-box"
                                        w={size.width}
                                        h={size.height}
                                    >
                                        <SurveyPreview />
                                    </Box>
                                    <SurveyPreviewControls />
                                </HStack>

                                <Box flex="1" maxH="550px" overflowY="auto">
                                    <SurveyTrackingUrls />
                                </Box>
                            </HStack>
                        </TabPanel>
                    ))}
                </TabPanels>
            </Tabs>
        </SurveyProvider>
    );
};

export const SurveyPreviewWrapper = SurveyPreviewWrapperCom;
