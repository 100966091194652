import { Center, VStack } from '@chakra-ui/react';
import { useRecoilValue } from 'recoil';
import { memo } from 'react';

import { CardItem } from '../card-item';
import { DirtyStateObserver } from '../common/dirty-state-observer';
import {
    editingCoreQuestionIdState,
    isCoreQuestionFormDirtyState,
} from '../../state/core-question/core-question-edit-form-atoms';
import { CoreQuestionActionBar } from './core-question-action-bar';

const CoreQuestionEditFormCom = () => {
    const currentEditingId = useRecoilValue(editingCoreQuestionIdState);

    if (!currentEditingId) {
        return <Center p="10">Select a core question to edit.</Center>;
    }

    return (
        <VStack align="stretch" overflow="hidden" spacing="0">
            <CoreQuestionActionBar />
            <VStack
                flex="1"
                align="start"
                overflow="auto"
                p="6"
                className="scrollbar"
            >
                <CardItem cardId={currentEditingId} />
            </VStack>

            <DirtyStateObserver atomState={isCoreQuestionFormDirtyState} />
        </VStack>
    );
};

export const CoreQuestionEditForm = memo(CoreQuestionEditFormCom);
