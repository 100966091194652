import * as React from 'react';
import { Box } from '@chakra-ui/react';

interface SwatchColorProps {
    name: string;
    value: string;
    isActive: boolean;
    onChange: (value: string) => void;
}

const SwatchColorCom: React.FC<SwatchColorProps> = ({
    name,
    value,
    isActive,
    onChange,
}) => {
    return (
        <Box
            key={value}
            position="relative"
            backgroundImage={`data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAAAXNSR0IArs4c6QAAAB9JREFUKFNjZGBg8GVABZuRuYz0UYDmBFQ3gdxAYwUAbksFPf/BpLAAAAAASUVORK5CYII=`}
            title={`${value} - ${name}`}
            w="22px"
            h="20px"
            onClick={() => onChange(value)}
        >
            <Box
                background={value}
                position="absolute"
                top="0"
                left="0"
                right="0"
                bottom="0"
                border="1px solid"
                borderColor={isActive ? 'white' : 'whiteAlpha.100'}
                _hover={{ borderColor: 'white' }}
            />
        </Box>
    );
};

export const SwatchColor = React.memo(
    SwatchColorCom,
    (prev, next) =>
        prev.name === next.name &&
        prev.value === next.value &&
        prev.isActive === next.isActive
);
