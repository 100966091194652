import * as React from 'react';
import type { ContentState, DraftDecorator } from 'draft-js';
import { EditorState, AtomicBlockUtils } from 'draft-js';
import { generateStrategy, getInstanceType, getEntityKey } from '../rte-utils';

const ENTITY_TYPE = 'BUTTON';

/* *********************************
 * BUTTON STYLES
 * *********************************/
const buildButtonStyles = (data: ButtonData): React.CSSProperties => {
    const { backgroundColor } = data;

    return {
        borderRadius: '25px',
        backgroundColor: backgroundColor || '#1665cf',
    };
};

export interface ButtonData {
    url: string;
    backgroundColor: string;
}

interface OutputButtonProps extends ButtonData {
    children: React.ReactNode;
}

/* *********************************
 * HTML OUTPUT: how it renders in output HTML
 * *********************************/
export const OutputButton = ({
    children,
    url,
    backgroundColor,
}: OutputButtonProps) => {
    const style = buildButtonStyles({ url, backgroundColor });

    return (
        <a
            className="button"
            target="blank"
            rel="noopener noreferrer"
            href={url}
            title={url}
        >
            <button style={style}>{children}</button>
        </a>
    );
};

/* *********************************
 * COMPONENT: Displayed inside the editor
 * *********************************/
interface InEditorRendererProps {
    children: React.ReactNode;
    contentState: ContentState;
    entityKey: string;
}

const InEditorRenderer = ({
    contentState,
    entityKey,
    children,
}: InEditorRendererProps) => {
    const { url, backgroundColor } = contentState
        .getEntity(entityKey)
        .getData() as ButtonData;

    const style = buildButtonStyles({ url, backgroundColor });

    // Use `span` styled as button to easier select texts with cursor
    return (
        <span className="button" title={url} style={style}>
            {children}
        </span>
    );
};

/* *********************************
 * DECORATOR
 * *********************************/
export const buttonDecorator: DraftDecorator = {
    strategy: generateStrategy(ENTITY_TYPE),
    component: InEditorRenderer,
};

/* *********************************
 * ACTIONS
 * *********************************/
export const applyButton =
    (editorState: EditorState) =>
    (url: string, backgroundColor: string, text?: string) => {
        const buttonInstance = getButtonInstance(editorState);
        const contentState = editorState.getCurrentContent();
        const newProps = { url, backgroundColor };

        // no button instance detected. Create a new button
        if (!buttonInstance) {
            const contentWithEntity = contentState.createEntity(
                ENTITY_TYPE,
                'MUTABLE',
                newProps
            );
            const newButtonKey = contentWithEntity.getLastCreatedEntityKey();
            const newEditorState = EditorState.set(editorState, {
                currentContent: contentWithEntity,
            });

            const txt = `  ${text || 'Button'}  `;
            return AtomicBlockUtils.insertAtomicBlock(
                newEditorState,
                newButtonKey,
                txt
            );
        }

        const buttonKey = getEntityKey(editorState);
        if (!buttonKey) {
            return editorState;
        }

        // button instance detected. Merge its values
        const contentWithUpdatedLink = contentState.mergeEntityData(
            buttonKey,
            newProps
        );

        const updatedEditorState = EditorState.set(editorState, {
            currentContent: contentWithUpdatedLink,
        });

        // Hack to have the editor update the new data
        // https://github.com/facebook/draft-js/issues/1702#issuecomment-660512373
        const selection = editorState.getSelection();
        return EditorState.forceSelection(updatedEditorState, selection);
    };

export const getButtonInstance = getInstanceType(ENTITY_TYPE);
