import { HStack, Icon, Text } from '@chakra-ui/react';
import {
    VscCircle as IconDot,
    VscArrowRight as IconArrowRight,
} from 'react-icons/vsc';
import * as React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import type { AnswerItemState } from '../../../state/answer/answer-types';
import type { CardItemState } from '../../../state/card/card-types';
import { ConnectionSelect } from './connection-select';
import {
    answerBodyState,
    answerResultCardState,
} from '../../../state/answer/answer-atoms';

interface CardConnectionForkedAnswerProps {
    answerId: AnswerItemState['id'];
    cardId: CardItemState['id'];
}

const CardConnectionForkedAnswerCom: React.FC<
    CardConnectionForkedAnswerProps
> = ({ answerId, cardId }) => {
    const answerBody = useRecoilValue(answerBodyState(answerId));
    const [answerResultCard, setAnswerResultCard] = useRecoilState(
        answerResultCardState(answerId)
    );

    return (
        <HStack w="100%">
            <Icon as={IconDot} opacity="0.5" />

            <Text w="200px" fontSize="sm" fontWeight="bold" noOfLines={1}>
                {answerBody}
            </Text>

            <Icon as={IconArrowRight} opacity="0.5" />

            <ConnectionSelect
                currentCardId={cardId}
                resultCard={answerResultCard}
                onSelect={setAnswerResultCard}
            />
        </HStack>
    );
};

export const CardConnectionForkedAnswer = React.memo(
    CardConnectionForkedAnswerCom
);
