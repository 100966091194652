import { HStack, VStack } from '@chakra-ui/react';
import { PageTitle } from '@dmp/shared/components';
import { memo } from 'react';
import { useParams } from 'react-router-dom';
import { usePollResultInterceptor } from '../../hooks/use-poll-result-interceptor';
import AuthGate from '../auth-gate/auth-gate';
import { CardItem } from '../card-item';
import { DirtyStateObserver } from '../common/dirty-state-observer';
import { LogoBar } from '../common/logo-bar';
import { ShortcutListeners } from '../common/shortcut-listeners';
import { StateObserver } from '../common/state-observer';
import { VisualTree } from '../common/visual-tree';
import { SurveyFields } from './survey-fields';
import { SurveyForm } from './survey-form/survey-form';
import { SurveyPreviewButton } from './survey-preview-button';
import { SurveyLiveProofButton } from './survey-preview-button/survey-live-proof-button';
import { SurveyPublishButton } from './survey-publish-button';
import { SurveySaveButton } from './survey-save-button';
import { usePrompt } from './use-prompt';
import { useSurveyView } from './use-survey-view';

const UNSAVED_PROMPT =
    'You have unsaved changes. Are you sure you want to leave without saving?';

const SurveyViewCom = () => {
    const { surveyId } = useParams() as { surveyId: string };
    const { cardIds, isSurveyDirty, surveyName } = useSurveyView(surveyId);

    usePrompt(UNSAVED_PROMPT, isSurveyDirty);
    usePollResultInterceptor();

    return (
        <AuthGate>
            <HStack align="stretch" h="100%" spacing="4">
                <VStack flex="1" spacing="0" align="stretch" overflow="hidden">
                    <LogoBar>
                        <HStack flex="1" spacing="4">
                            <HStack flex="1">
                                <SurveyFields />
                            </HStack>

                            <HStack>
                                <SurveyPreviewButton />
                                <SurveyLiveProofButton />
                                <SurveySaveButton />
                                <SurveyPublishButton />
                            </HStack>
                        </HStack>
                    </LogoBar>

                    <VStack
                        p="20"
                        flex="1"
                        overflow="auto"
                        className="scrollbar card-forms"
                    >
                        <SurveyForm />

                        {cardIds.map((id) => (
                            <CardItem key={id} cardId={id} />
                        ))}
                    </VStack>

                    <VisualTree />
                </VStack>

                <ShortcutListeners />
                <StateObserver />
                <DirtyStateObserver />
                <PageTitle
                    title={isSurveyDirty ? `*${surveyName}` : surveyName}
                />
            </HStack>
        </AuthGate>
    );
};

export const SurveyView = memo(SurveyViewCom);
