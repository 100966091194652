import type { Card } from '@dmp/qqms/types';
import { useRecoilCallback } from 'recoil';
import { omit } from 'remeda';
import {
    answerItemState,
    surveyAllAnswersSelector,
} from '../answer/answer-atoms';
import { isForkedAnswerItem } from '../answer/answer-type-guards';
import type { AnswerItemState } from '../answer/answer-types';
import { surveyState } from '../survey/survey-atoms';
import type { SurveyState } from '../survey/survey-types';
import { cardItemState, surveyCardsSelector } from './card-atoms';
import {
    isDynamicCoreQuestionItem,
    isLinearQuestionItem,
    isQuestionItem,
} from './card-type-guards';
import type { CardItemState } from './card-types';

export const useCardDelete = (cardId: Card['id']) => {
    const deleteCard = useRecoilCallback(
        ({ set, snapshot, reset }) =>
            async () => {
                const survey = await snapshot.getPromise(surveyState);
                const cardsInSurvey = await snapshot.getPromise(
                    surveyCardsSelector
                );
                const answersInSurvey = await snapshot.getPromise(
                    surveyAllAnswersSelector
                );
                const card = await snapshot.getPromise(cardItemState(cardId));

                const deleteData = deleteCardUtil(
                    survey,
                    cardsInSurvey,
                    answersInSurvey
                )(cardId);

                if (!deleteData) {
                    return;
                }

                const { newSurvey, newCards, newAnswers } = deleteData;

                // update survey
                set(surveyState, newSurvey);

                // update cards with reconnected cardResults
                newCards.forEach((c) => set(cardItemState(c.id), c));

                // update answers with reconnected cardResults
                newAnswers.forEach((a) => set(answerItemState(a.id), a));

                // delete answers
                if (isQuestionItem(card)) {
                    // Cleanup: reset card atom and answers
                    // https://github.com/facebookexperimental/Recoil/issues/263#issuecomment-639997717
                    [...card.answers].forEach((answerId) => {
                        reset(answerItemState(answerId));
                    });
                }

                // delete card
                reset(cardItemState(cardId));
            },
        [cardId]
    );

    return {
        deleteCard,
    };
};

/**
 * delete card in survey
 */
export const deleteCardUtil =
    (
        survey: SurveyState,
        cardsInSurvey: CardItemState[],
        answersInSurvey: AnswerItemState[]
    ) =>
    (cardId: Card['id']) => {
        // cannot remove the last card
        if (cardsInSurvey.length <= 1) {
            return undefined;
        }

        // reconnect resultCards in the new card collection
        const newCardsInSurvey = cardsInSurvey.filter((c) => c.id !== cardId);

        const { cleanedCards, cleanedAnswers } = cleanResultCardConnections(
            newCardsInSurvey,
            answersInSurvey,
            cardId
        );

        const newSurvey: SurveyState = {
            ...survey,
            cards: newCardsInSurvey.map((c) => c.id),
        };

        return {
            newSurvey,
            newCards: cleanedCards,
            newAnswers: cleanedAnswers,
        };
    };

/**
 * Clean up all `resultCard` that connects to the removed card
 */
const cleanResultCardConnections = (
    cards: CardItemState[],
    answers: AnswerItemState[],
    removedCardId: CardItemState['id']
): { cleanedCards: CardItemState[]; cleanedAnswers: AnswerItemState[] } => {
    const removeResult = <T extends CardItemState>(card: T): T => {
        if (isLinearQuestionItem(card) || isDynamicCoreQuestionItem(card)) {
            if (card.resultCard === removedCardId) {
                return omit(card, ['resultCard']) as T;
            }
        }

        return card;
    };

    const cleanedCards = cards.map(removeResult);

    const cleanedAnswers = answers.map((answer) => {
        if (isForkedAnswerItem(answer)) {
            if (answer.resultCard === removedCardId) {
                return omit(answer, ['resultCard']);
            }
        }

        return answer;
    });

    return {
        cleanedCards,
        cleanedAnswers,
    };
};
