import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Box,
    Button,
    HStack,
    IconButton,
    Text,
    Tooltip,
    useColorModeValue,
    useDisclosure,
} from '@chakra-ui/react';
import type { PermissionGroup } from '@dmp/qqms/permissions';
import { Users } from '@phosphor-icons/react';
import type { ChangeEventHandler } from 'react';
import { memo, useRef } from 'react';
import { VscTrash as IconTrash } from 'react-icons/vsc';
import { useRecoilValue } from 'recoil';
import { authGroupResourceSelector } from '../../state/auth-group/auth-group-list-atoms';
import { useAuthGroupDelete } from '../../state/auth-group/use-auth-group-delete';
import { useAuthGroupPatch } from '../../state/auth-group/use-auth-group-patch';
import { AuthGroupSelect } from './auth-group-select';

interface AuthGroupItemProps {
    authGroupId: string;
}

const AuthGroupItemCom = ({ authGroupId }: AuthGroupItemProps) => {
    const color = useColorModeValue('brand.100', 'brand.200');
    const hoverBg = useColorModeValue('white', 'whiteAlpha.50');

    const authGroup = useRecoilValue(authGroupResourceSelector(authGroupId));
    const { patchAuthGroup } = useAuthGroupPatch();

    if (!authGroup) {
        return null;
    }

    const handleChange: ChangeEventHandler<HTMLSelectElement> = (e) => {
        const permissionGroup = e.currentTarget.value as PermissionGroup;
        patchAuthGroup(authGroupId, permissionGroup);
    };

    const { oktaAuthGroup, permissionGroup } = authGroup.attributes;

    return (
        <HStack
            role="group"
            p="2"
            mx="-2"
            rounded="md"
            _hover={{ bg: hoverBg }}
        >
            <Box px="1">
                <Users opacity="0.6" size="20px" />
            </Box>

            <Text flex="1" _groupHover={{ color }}>
                {oktaAuthGroup}
            </Text>

            <AuthGroupSelect
                value={permissionGroup}
                onChange={handleChange}
                flex="0 0 200px"
            />

            <AuthGroupDeleteButton authGroupId={authGroupId} />
        </HStack>
    );
};

const AuthGroupDeleteButton = ({ authGroupId }: AuthGroupItemProps) => {
    const { deleteAuthGroup } = useAuthGroupDelete(authGroupId);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = useRef<HTMLButtonElement>(null);

    return (
        <>
            <Tooltip label="Remove Auth Group" placement="right">
                <IconButton
                    icon={<IconTrash />}
                    onClick={onOpen}
                    aria-label="Remove Auth Group"
                    variant="ghost"
                    size="sm"
                />
            </Tooltip>

            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Delete Auth Group
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Are you sure? You can't undo this action afterwards.
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                Cancel
                            </Button>
                            <Button
                                colorScheme="red"
                                onClick={deleteAuthGroup}
                                ml={3}
                            >
                                Delete
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    );
};

export const AuthGroupItem = memo(AuthGroupItemCom);
