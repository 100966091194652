import { insert } from '@dmp/shared/helpers';
import type { CardItemState } from '../state/card/card-types';

import type { SurveyState } from '../state/survey/survey-types';

/**
 * Insert new card into collection & reconnect collection
 * - Insert next to the given card id or expand at the end of the collection
 */
export const insertNewCard =
    (survey: SurveyState, cardsInSurvey: CardItemState[]) =>
    <T extends CardItemState>(newCard: T, cardId?: CardItemState['id']) => {
        // Insert new card into the collection
        const newCards = insertNewCardIntoCollection(
            newCard,
            cardsInSurvey,
            cardId
        );

        // add card id into survey's cards
        const newSurvey = {
            ...survey,
            cards: newCards.map((c) => c.id),
        };

        return {
            newSurvey,
            newCards,
        };
    };

/**
 * Insert new card into the current card collection
 * - place the new card after currentCardId if is given, otherwise, expand at the end.
 */
const insertNewCardIntoCollection = (
    newCard: CardItemState,
    currentCards: CardItemState[],
    currentCardId?: CardItemState['id']
) => {
    const newCardIndex = currentCardId
        ? currentCards.findIndex((c) => c.id === currentCardId) + 1
        : currentCards.length;

    return insert(currentCards, newCardIndex, [newCard]);
};
