import { atom } from 'recoil';
import { localStorageEffect } from '../../utils/localStorageEffect';

/**
 * Atom state to show/hide dev-tool
 */
export const devToolState = atom<boolean>({
    key: 'ui_devToolState',
    default: false,
    effects_UNSTABLE: [localStorageEffect('dev-tool')],
});
