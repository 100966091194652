import {
    Button,
    Checkbox,
    FormControl,
    HStack,
    IconButton,
    InputGroup,
    InputLeftAddon,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverFooter,
    PopoverTrigger,
    Portal,
    useColorMode,
    useDisclosure,
} from '@chakra-ui/react';
import { TrimmedInput } from '@dmp/shared/components';
import type { EditorState } from 'draft-js';
import * as React from 'react';
import {
    MdLink as IconLink,
    MdRemoveCircleOutline as IconRemove,
} from 'react-icons/md';
import { removeEntity } from '../rte-utils';
import type { LinkTarget } from './link';
import { applyLink, getLinkInstance } from './link';

interface LinkCommandButtonProps {
    editorState: EditorState;
    setEditorState: (value: EditorState) => void;
}

const LinkCommandButtonCom: React.FC<LinkCommandButtonProps> = ({
    editorState,
    setEditorState,
}) => {
    const { onOpen, onClose, isOpen } = useDisclosure();
    const { colorMode } = useColorMode();
    const isDark = colorMode === 'dark';

    const [localUrl, setLocalUrl] = React.useState<string>('');
    const [localTarget, setLocalTarget] = React.useState<LinkTarget>('_blank');

    const handleUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLocalUrl(e.target.value);
    };

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLocalTarget(e.target.checked ? '_blank' : '_self');
    };

    const apply = () => {
        const newState = applyLink(editorState)(localUrl, localTarget);
        setEditorState(newState);
        onClose();
    };

    const remove = () => {
        const newState = removeEntity(editorState);
        setEditorState(newState);
    };

    const linkInstance = getLinkInstance(editorState);
    const isActive = !!linkInstance;

    // when the popover is open
    // reset value or fill in defined values
    const handleOpen = () => {
        onOpen();

        if (linkInstance) {
            const { url, target } = linkInstance.getData();
            setLocalUrl(url);
            setLocalTarget(target);
            return;
        }

        // default
        setLocalUrl('');
        setLocalTarget('_blank');
    };

    return (
        <Popover isLazy isOpen={isOpen} onOpen={handleOpen} onClose={onClose}>
            <PopoverTrigger>
                <IconButton
                    size="xs"
                    variant="ghost"
                    title="link"
                    aria-label="link"
                    icon={<IconLink size="18px" />}
                    color={isActive ? (isDark ? 'brand.200' : 'brand.100') : ''}
                />
            </PopoverTrigger>
            <Portal>
                <PopoverContent>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverBody py="10" px="5">
                        <form onSubmit={apply}>
                            <FormControl id="link-url">
                                <InputGroup size="sm" rounded="md" pb="2">
                                    <InputLeftAddon rounded="md">
                                        URL
                                    </InputLeftAddon>
                                    <TrimmedInput
                                        id="link-url"
                                        rounded="md"
                                        autoComplete="off"
                                        value={localUrl}
                                        placeholder="https://google.com"
                                        onChange={handleUrlChange}
                                    />
                                </InputGroup>
                            </FormControl>
                            <FormControl id="link-target">
                                <Checkbox
                                    id="link-target"
                                    pl="4"
                                    isChecked={localTarget === '_blank'}
                                    onChange={handleCheckboxChange}
                                >
                                    Open in new window
                                </Checkbox>
                            </FormControl>
                        </form>
                    </PopoverBody>
                    <PopoverFooter>
                        <HStack w="100%" justifyContent="space-between">
                            <Button
                                size="sm"
                                leftIcon={<IconRemove />}
                                onClick={remove}
                            >
                                Remove
                            </Button>
                            <Button
                                size="sm"
                                variant="usatoday_blue_full"
                                onClick={apply}
                            >
                                Apply
                            </Button>
                        </HStack>
                    </PopoverFooter>
                </PopoverContent>
            </Portal>
        </Popover>
    );
};

export const LinkCommandButton = React.memo(LinkCommandButtonCom);
