import type { InputProps, TextareaProps } from '@chakra-ui/react';
import * as React from 'react';

type InputType =
    | React.ChangeEvent<HTMLInputElement>
    | React.ChangeEvent<HTMLTextAreaElement>;

/**
 * Provide a intermediate input value to solve
 * controlled input 'forgets' the cursor position upon re-rendering.
 * - Ref: https://github.com/facebook/react/issues/14904
 */
export const useIntermediateInput = <
    T extends InputProps | TextareaProps = InputProps
>(
    value: T['value'],
    onChange: T['onChange']
): {
    stateValue: T['value'];
    handleChange: T['onChange'];
} => {
    const [stateValue, setStateValue] = React.useState<T['value']>(value);

    // syncing state value and prop value
    React.useEffect(() => {
        setStateValue(value);
    }, [value]);

    const handleChange: T['onChange'] = (e: InputType) => {
        if (!onChange) {
            return;
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore event is filtered based on provided T['onChange']
        onChange(e);
        // also update local value
        setStateValue(e.target.value);
    };

    return {
        stateValue,
        handleChange,
    };
};
