import {
    Button,
    useColorMode,
    Text,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalCloseButton,
    HStack,
    InputGroup,
    Tooltip,
} from '@chakra-ui/react';
import { VscCopy as IconCopy, VscError as IconError } from 'react-icons/vsc';
import { useRef, memo } from 'react';

import { TrimmedInput } from '@dmp/shared/components';
import { useSurveyDuplicateButton } from './use-survey-duplicate-button';
import type { Survey } from '@dmp/qqms/types';

interface SurveyDuplicateProps {
    surveyId: Survey['id'];
}

const SurveyDuplicateButtonCom = ({ surveyId }: SurveyDuplicateProps) => {
    const { colorMode } = useColorMode();
    const initialRef = useRef<HTMLInputElement>(null);

    const {
        error,
        handleChange,
        handleModalClose,
        handleSubmit,
        handleKeyDown,
        isOpen,
        onClose,
        onOpen,
        newSurveyName,
    } = useSurveyDuplicateButton(surveyId);

    return (
        <>
            <Tooltip
                label="Duplicate"
                hasArrow
                display={{ base: 'none', lg: 'flex' }}
            >
                <Button
                    fontWeight="normal"
                    variant="usatoday_outline"
                    size="sm"
                    onClick={onOpen}
                >
                    <HStack spacing="1">
                        <IconCopy />
                        <Text display={{ base: 'flex', lg: 'none' }} size="sm">
                            Duplicate
                        </Text>
                    </HStack>
                </Button>
            </Tooltip>

            <Modal
                isOpen={isOpen}
                onClose={handleModalClose}
                initialFocusRef={initialRef}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Duplicate</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text fontWeight="bold">Survey name</Text>
                        <InputGroup>
                            <TrimmedInput
                                ref={initialRef}
                                placeholder="enter name"
                                onChange={handleChange}
                                onKeyDown={handleKeyDown}
                                value={newSurveyName}
                                borderColor={
                                    colorMode === 'light'
                                        ? 'gray.300'
                                        : 'gray.600'
                                }
                                isInvalid={!!error}
                                errorBorderColor="red.300"
                            />
                        </InputGroup>
                        {error && (
                            <HStack color="red.300" py="1">
                                <IconError />
                                <Text>{error}</Text>
                            </HStack>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button mr="3" onClick={onClose}>
                            Cancel
                        </Button>
                        <Button
                            variant="usatoday_blue_full"
                            onClick={handleSubmit}
                        >
                            Duplicate
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export const SurveyDuplicateButton = memo(SurveyDuplicateButtonCom);
