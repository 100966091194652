import * as React from 'react';
import type { ContentState, DraftDecorator } from 'draft-js';
import { EditorState, RichUtils } from 'draft-js';
import { generateStrategy, getInstanceType } from '../rte-utils';

const ENTITY_TYPE = 'COLOR';

export interface ColorData {
    color: string;
}

interface OutputColorProps extends ColorData {
    children: React.ReactNode;
}

/* *********************************
 * HTML OUTPUT: how it renders in output HTML
 * *********************************/
export const OutputColor = ({ children, color }: OutputColorProps) => {
    return <span style={{ color }}>{children}</span>;
};

/* *********************************
 * COMPONENT: Displayed inside the editor
 * *********************************/
interface InEditorRendererProps {
    children: React.ReactNode;
    contentState: ContentState;
    entityKey: string;
}

const InEditorRenderer: React.FC<InEditorRendererProps> = ({
    contentState,
    entityKey,
    children,
}) => {
    const { color } = contentState.getEntity(entityKey).getData() as ColorData;
    return <OutputColor color={color}>{children}</OutputColor>;
};

/* *********************************
 * DECORATOR
 * *********************************/
export const colorDecorator: DraftDecorator = {
    strategy: generateStrategy(ENTITY_TYPE),
    component: InEditorRenderer,
};

/* *********************************
 * ACTIONS
 * *********************************/
export const applyColor =
    (editorState: EditorState) =>
    (color: string = 'inherit') => {
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity(
            ENTITY_TYPE,
            'MUTABLE',
            { color }
        );
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newEditorState = EditorState.set(editorState, {
            currentContent: contentStateWithEntity,
        });

        const newState = RichUtils.toggleLink(
            newEditorState,
            newEditorState.getSelection(),
            entityKey
        );

        return newState;
    };

export const getColorInstance = getInstanceType(ENTITY_TYPE);
