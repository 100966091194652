import type { FiltersAndPagination } from '@dmp/shared/client-utils';
import {
    ApiUriBuilder,
    queryStringToFiltersAndPagination,
} from '@dmp/shared/client-utils';
import { useLocation, useNavigate } from 'react-router-dom';

export const useQueryParams = () => {
    const location = useLocation();
    const navigate = useNavigate();

    // Values based on params
    const queryParams = queryStringToFiltersAndPagination(location.search);

    const setQueryParams = (
        newPartialQueryParams: Partial<FiltersAndPagination> = {}
    ) => {
        const newQueryParams = updateQueryParams(
            queryParams,
            newPartialQueryParams
        );

        navigate(`/?${newQueryParams}`);
    };

    return [queryParams, setQueryParams] as const;
};

export const updateQueryParams = (
    currentParamsFilters: FiltersAndPagination,
    paramsFiltersPartial: Partial<FiltersAndPagination>
) => {
    const mergedState = { ...currentParamsFilters, ...paramsFiltersPartial };
    const filters = mergedState.filters || {};

    // Filter out filter params with empty values.
    const nonEmptyFilters = Object.keys(filters).reduce((carrier, key) => {
        const value = filters[key];

        if (value === '' || value === undefined) {
            return carrier;
        }

        return { ...carrier, [key]: value };
    }, {});

    return filtersAndPaginationToQueryString({
        ...mergedState,
        filters: nonEmptyFilters,
    });
};

/**
 * Convert filters/pagination to params string
 */
export const filtersAndPaginationToQueryString = (
    filtersAndPagination: FiltersAndPagination
): string => {
    const uriBuilder = new ApiUriBuilder();
    const { page, filters } = filtersAndPagination;

    const queryString = uriBuilder.resolve('dummy', {
        filter: filters,
        page: {
            number: page?.number || 1,
            size: page?.size || 20,
        },
    });

    return queryString.split('?')[1];
};
