import { Button, Tooltip } from '@chakra-ui/react';
import { VscSave as IconSave } from 'react-icons/vsc';
import * as React from 'react';

import { useSurveyPatch } from '../../../state/survey/use-survey-patch';
import { useMainButtonStyles } from '../use-main-button-styles';

const SurveySaveButtonCom: React.FC = () => {
    const { patchSurvey } = useSurveyPatch();
    const { buttonStyles } = useMainButtonStyles();

    return (
        <Tooltip fontSize="xs" label="save survey (Ctrl/Cmd+S)">
            <Button
                {...buttonStyles}
                leftIcon={<IconSave />}
                onClick={patchSurvey}
            >
                <u>S</u>ave
            </Button>
        </Tooltip>
    );
};

export const SurveySaveButton = React.memo(SurveySaveButtonCom);
