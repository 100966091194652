import { atom, atomFamily, selector } from 'recoil';
import type { UserState } from './user-types';

export const currentUserIdState = atom<UserState['id'] | undefined>({
    key: 'currentUserIdState',
    default: undefined,
});

/**
 * User item state by id
 *
 * @param CardId
 */
export const userItemState = atomFamily<UserState | undefined, UserState['id']>(
    {
        key: 'userItemState',
        default: undefined,
    }
);

export const currentUserSelector = selector({
    key: 'currentUser',
    get: ({ get }) => {
        const currentUserId = get(currentUserIdState);

        if (!currentUserId) {
            return undefined;
        }

        return get(userItemState(currentUserId));
    },
});
