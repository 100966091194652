import type { CoreQuestionResource } from '@dmp/qqms/data-access';
import type {
    LinearQuestion,
    ForkedQuestion,
    DynamicCoreQuestion,
    CoreQuestion,
} from '@dmp/qqms/types';

export const defaultLinearQuestion: LinearQuestion = {
    id: '',
    type: 'linearQuestion',
    formType: 'radio',
    body: '',
    traxexLabel: '',
    answers: [],
};

export const defaultForkedQuestion: ForkedQuestion = {
    id: '',
    type: 'forkedQuestion',
    formType: 'radio',
    body: '',
    traxexLabel: '',
    answers: [],
};

export const defaultDynamicCoreQuestion: DynamicCoreQuestion = {
    id: '',
    type: 'dynamicCoreQuestion',
    questions: [
        {
            type: 'coreQuestion',
            id: '123',
            body: 'How now brown cow?',
            formType: 'radio',
            traxexLabel: 'question-tracking-label',
            answers: [
                {
                    type: 'linearAnswer',
                    body: 'Yes',
                    id: 'yes',
                    traxexLabel: 'answer-tracking-label-1',
                },
                {
                    type: 'linearAnswer',
                    body: 'No',
                    id: 'no',
                    traxexLabel: 'answer-tracking-label-2',
                },
            ],
        },
    ],
};

export const defaultCoreQuestion: CoreQuestion = {
    id: '',
    type: 'coreQuestion',
    formType: 'radio',
    body: '',
    traxexLabel: '',
    answers: [],
};

export const defaultCoreQuestionResource: CoreQuestionResource = {
    id: '',
    type: 'coreQuestions',
    attributes: {
        shortId: '',
        isActive: true,
        name: '',
        priority: 0,
        createdAt: '',
        updatedAt: '',
        config: {
            formType: 'radio',
            body: '',
            traxexLabel: '',
            answers: [],
        },
    },
};
