import type { Permission } from '@dmp/qqms/permissions';
import { permissionGroupCan } from '@dmp/qqms/permissions';
import type { ReactElement, ReactNode } from 'react';
import { useCurrentUser } from '../../hooks/use-current-user';
import { AlertBox } from '@dmp/shared/components';

export interface PermissionGateProps {
    permission: Permission;
    silent?: boolean;
    children?: ReactNode;
}

export function PermissionGate({
    children,
    permission,
    silent,
}: PermissionGateProps) {
    const [currentUser] = useCurrentUser();

    const canView =
        currentUser !== undefined &&
        permissionGroupCan(currentUser.permissionGroup, permission);

    if (canView) {
        return children as ReactElement;
    }

    if (silent) {
        return null;
    }

    return (
        <AlertBox status="error">
            You do not have the correct permissions to view this.
        </AlertBox>
    );
}

export default PermissionGate;
