import { Box, HStack, useColorMode } from '@chakra-ui/react';
import * as React from 'react';

import { QQMSLogo } from '../common/qqms-logo';
import { Settings } from '../common/settings';

interface LogoBarProps {
    children: React.ReactNode;
}

export const LogoBar = ({ children }: LogoBarProps) => {
    const { colorMode } = useColorMode();

    const bg = colorMode === 'dark' ? 'gray.900' : 'white';

    return (
        <HStack
            w="100%"
            flexWrap="wrap"
            position="sticky"
            top="0"
            zIndex="sticky"
            bg={bg}
            borderBottom="1px solid"
            borderColor="blackAlpha.100"
            spacing="0"
        >
            <Box w="120px" py="3" px="3" mr="6">
                <QQMSLogo />
            </Box>

            <HStack flex="1">{children}</HStack>
            <Settings />
        </HStack>
    );
};
