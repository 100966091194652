import { patchSurvey } from '@dmp/qqms/data-access';
import type { SurveyConfig } from '@dmp/qqms/types';
import { useErrorToaster, useSuccessToaster } from '@dmp/shared/components';
import { useRecoilCallback } from 'recoil';
import { surveyConfigSelector } from '../survey-config/survey-config-atoms';
import { isSurveyDirtyState } from '../ui/is-survey-dirty-atoms';
import { previousSurveyName } from '../ui/previous-survey-name';
import { surveyState } from './survey-atoms';

/**
 * Patch survey
 */
export const useSurveyPatch = () => {
    const { handleError } = useErrorToaster();
    const { handleSuccess } = useSuccessToaster();

    const patchSurveyCallback = useRecoilCallback(
        ({ snapshot, set }) =>
            async () => {
                const surveyRecoilState = await snapshot.getPromise(
                    surveyState
                );
                const survey = await snapshot.getPromise(surveyConfigSelector);

                const prevSurveyName = await snapshot.getPromise(
                    previousSurveyName
                );

                // find out if survey name needs to be updated
                const surveyName = surveyRecoilState.name;
                const nameObj =
                    prevSurveyName === surveyName ? {} : { name: surveyName };

                const surveyConfig: SurveyConfig = {
                    aboutCopy: survey.aboutCopy,
                    cards: survey.cards,
                    traxexLabel: survey.traxexLabel,
                    sponsorInfo: survey.sponsorInfo,
                };

                const payload = {
                    config: surveyConfig,
                    ...nameObj,
                };

                patchSurvey(survey.id, payload)
                    .then(() => {
                        // update previous survey name to the current
                        set(previousSurveyName, surveyName);

                        // set dirty state to pristine
                        set(isSurveyDirtyState, false);

                        handleSuccess('Survey saved successfully.');
                    })
                    .catch(handleError);
            },
        []
    );

    return {
        patchSurvey: patchSurveyCallback,
    };
};
