import { atom } from 'recoil';

/**
 * Atom state to record previous survey name.
 * This will tell api if it needs to update survey name or not
 */
export const previousSurveyName = atom<string>({
    key: 'ui_previousSurveyName',
    default: '',
});
