import { z } from 'zod';
import { cardValidator } from './card-schema';
import { traxexLabel } from './shared-schema';

export const surveyConfigValidator = z.object({
    cards: z.array(cardValidator).min(2, 'Survey requires at least 2 cards.'),
    aboutCopy: z.string().min(1),
    traxexApi: z.string().url().optional(),
    traxexLabel: traxexLabel(),
    sponsorInfo: z
        .object({
            copy: z.string().min(5),
            logo: z.string().url(),
        })
        .optional(),
});

export const publishedSurveyConfigValidator = surveyConfigValidator.extend({
    id: z.string().min(1),
    shortId: z.string().min(1),
    qqid: z.string().min(1),
    qqmsApi: z.string().url(),
    traxexApi: z.string().url(),
});
