import type { PollResult, PollResultCard } from '@dmp/qqms/types';
import { useEffect, useState } from 'react';
import { useQuestionPollResult } from '../state/use-question-poll-result';
import { classes } from '../utils';

interface PollResultCardComponentProps {
    card: PollResultCard;
}

export function PollResultCardComponent({
    card,
}: PollResultCardComponentProps) {
    const state = useQuestionPollResult(card.questionId);

    switch (state.type) {
        case 'ERROR':
            return (
                <div className="pr-status">
                    We're still tallying up the results, but thank you for
                    answering!
                </div>
            );

        case 'LOADING':
            return <div className="pr-status">Loading results…</div>;

        case 'LOADED': {
            const { title, results } = state.config;

            return (
                <>
                    <h2 className="pr-title">{title}</h2>
                    {results.map((result, index) => (
                        <Result
                            key={result.label}
                            result={result}
                            index={index}
                        />
                    ))}
                </>
            );
        }
    }
}

interface ResultProps {
    result: PollResult;
    index: number;
}

const STAGGERED_DELAY = 100; // milliseconds

function Result({ result, index }: ResultProps) {
    const percent = `${result.percent.toFixed(1)}%`;

    const animClass = useAnimClass(index);
    return (
        <div className="pr-bar-container">
            <div
                className={classes('pr-bar', 'pr-bar-before', animClass)}
                style={{ width: percent }}
            ></div>

            <div className="pr-label">{result.label}</div>

            <div className="pr-percent">{percent}</div>

            <div
                className={classes('pr-bar', 'pr-bar-after', animClass)}
                style={{ width: percent }}
            ></div>

            <div
                className="pr-is-answered"
                title={result.isAnswered ? 'Your answer' : undefined}
            >
                {result.isAnswered && (
                    <svg viewBox="0 0 24 24">
                        <path
                            fill="currentColor"
                            d="M19.7 9v9.9c0 1.6-1.2 2.3-2.6 1.5l-3.4-1.9c-1.4-.8-3.8-2.2-5.2-3l-3.4-1.9c-1.4-.8-1.4-2.2 0-3l3.4-1.9c1.4-.8 3.8-2.2 5.2-3l3.4-1.9c1.4-.8 2.6-.2 2.6 1.5V9z"
                        />
                    </svg>
                )}
            </div>
        </div>
    );
}

/**
 * Manage animation state in order to create a
 * staggered, animated entrance of the bars.
 */
function useAnimClass(index: number) {
    const [hasAnimated, setHasAnimated] = useState(false);

    useEffect(() => {
        setTimeout(() => setHasAnimated(true), index * STAGGERED_DELAY);
    }, [index]);

    return hasAnimated ? 'completed' : undefined;
}
