import {
    Button,
    ButtonGroup,
    IconButton,
    Link,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Tooltip,
} from '@chakra-ui/react';
import { memo } from 'react';
import {
    VscPlay as IconPreview,
    VscLinkExternal as IconLink,
} from 'react-icons/vsc';
import { useParams } from 'react-router';
import { useRecoilState } from 'recoil';
import { routeSurveyPreview } from '../../../config/routeUrls';

import { previewShortcutState } from '../../../state/ui/shortcut-atoms';
import { useMainButtonStyles } from '../use-main-button-styles';
import { SurveyPreviewWrapper } from './survey-preview-wrapper';

const SurveyPreviewButtonCom = () => {
    const { surveyId } = useParams<{ surveyId: string }>() as {
        surveyId: string;
    };

    const [isOpen, setIsOpen] = useRecoilState(previewShortcutState);
    const onOpen = () => setIsOpen(true);
    const onClose = () => setIsOpen(false);

    const { buttonStyles } = useMainButtonStyles();

    return (
        <>
            <ButtonGroup isAttached>
                <Tooltip
                    fontSize="xs"
                    label="preview survey (Ctrl/Cmd+P, Ctrl/Cmd+Enter)"
                >
                    <Button
                        {...buttonStyles}
                        leftIcon={<IconPreview style={{ marginTop: -4 }} />}
                        onClick={onOpen}
                    >
                        <u>P</u>review
                    </Button>
                </Tooltip>

                <Tooltip fontSize="xs" label="to proof page">
                    <IconButton
                        {...buttonStyles}
                        aria-label="to proof page"
                        icon={
                            <Link
                                href={routeSurveyPreview(surveyId)}
                                target="_blank"
                                rel="noreferrer"
                                w="100%"
                                h="100%"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <IconLink size="13" />
                            </Link>
                        }
                    />
                </Tooltip>
            </ButtonGroup>

            {isOpen && (
                <Modal isOpen={isOpen} onClose={onClose} isCentered>
                    <ModalOverlay />
                    <ModalContent w="90%" minW="650px" maxW="90%" h="700px">
                        <ModalHeader>Survey Preview</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody p="0">
                            <SurveyPreviewWrapper />
                        </ModalBody>
                    </ModalContent>
                </Modal>
            )}
        </>
    );
};

export const SurveyPreviewButton = memo(SurveyPreviewButtonCom);
