import { forwardRef } from 'react';
import { Select } from '@chakra-ui/react';
import type { PermissionGroup } from '@dmp/qqms/permissions';
import type { SelectProps } from '@chakra-ui/react';

const permissionGroups: PermissionGroup[] = ['managers', 'users'];

export const AuthGroupSelect = forwardRef<HTMLSelectElement, SelectProps>(
    (props, ref) => {
        return (
            <Select
                variant="filled"
                size="sm"
                rounded="md"
                ref={ref}
                {...props}
            >
                {permissionGroups.map((permission) => (
                    <option key={permission} value={permission}>
                        {permission}
                    </option>
                ))}
            </Select>
        );
    }
);
