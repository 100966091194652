import type { Answer } from '@dmp/qqms/types';
import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { answerItemState } from '../../../state/answer/answer-atoms';
import { StateObserverItem } from './state-observer-item';

interface SOAnswerProps {
    answerId: Answer['id'];
}

export const SOAnswer: React.FC<SOAnswerProps> = React.memo(({ answerId }) => {
    const answer = useRecoilValue(answerItemState(answerId));

    return <StateObserverItem name={`Answer: ${answer.id}`} item={answer} />;
});
