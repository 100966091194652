import { useRecoilValue, useSetRecoilState } from 'recoil';

import type { Card } from '@dmp/qqms/types';
import type { CardItemState } from '../../../state/card/card-types';
import {
    cardFormTypeState,
    cardTypeState,
    cardVisualTypeSelector,
} from '../../../state/card/card-atoms';
import type { VisualCardType } from '../../../utils/getVisualCardType';
import { useQuestionTypeSwitch } from '../../../hooks/use-question-type-switch';

export const useCardVisualItem = (
    cardId: Card['id'],
    visualType: VisualCardType
) => {
    const cardType = useRecoilValue(cardTypeState(cardId));
    const visualCardType = useRecoilValue(cardVisualTypeSelector(cardId));
    const setCardFormType = useSetRecoilState(cardFormTypeState(cardId));

    const { switchQuestionType } = useQuestionTypeSwitch(cardId);

    const handleSelect = () => {
        // no change on already selected item
        if (visualType === visualCardType) {
            return;
        }

        switch (visualType) {
            case 'radio':
                return setCardFormType('radio');
            case 'select':
                return setCardFormType('select');
            case 'multi-select': {
                setCardFormType('multi-select');
                // switch to linearQuestion. only support linear for multi-select.
                // all forked answers will be converted and remap connection to linear question.
                return switchQuestionType('linearQuestion', true);
            }
            default:
                return;
        }
    };

    return {
        handleSelect,
        isActive: visualCardType === visualType,
        isVisible: checkVisible(cardType, visualType),
        visualCardType,
    };
};

/**
 * check if the visual type button should show up based on card type
 */
const checkVisible = (
    cardType: CardItemState['type'],
    visualType: VisualCardType
) => {
    switch (visualType) {
        case 'dynamicCoreQuestion':
            return cardType === 'dynamicCoreQuestion';

        case 'thankYouCard':
            return cardType === 'thankYouCard';

        default:
            return ['forkedQuestion', 'linearQuestion'].includes(cardType);
    }
};
