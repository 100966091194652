import type { SurveyConfig } from '@dmp/qqms/types';
import { SurveyConfigGenerator } from './survey-config-generator';

/**
 * Create a brand new survey config with a linear question + answer
 */
export const newSurveyDefaultConfig = (
    partialSurvey: Partial<SurveyConfig> = {}
): SurveyConfig => {
    const surveyGenerator = new SurveyConfigGenerator(partialSurvey);

    // add 1 linear question & 1 thank you card
    surveyGenerator.addLinearQuestion({ body: 'My First Question' });
    surveyGenerator.addThankYou({});

    // get the question id
    const cards = surveyGenerator.getSurveyConfig().cards;
    const questionId = cards[0].id;

    // add answer to the linear question
    surveyGenerator.addLinearAnswer(questionId, {
        body: 'My 1st Choice',
    });

    surveyGenerator.addLinearAnswer(questionId, {
        body: 'My 2nd Choice',
    });

    return surveyGenerator.getSurveyConfig();
};
