import type { StackProps } from '@chakra-ui/react';
import { Stack, useColorMode } from '@chakra-ui/react';
import * as React from 'react';

export const PaperCard: React.FC<StackProps> = ({
    children,
    ...stackProps
}) => {
    const { colorMode } = useColorMode();
    const isDark = colorMode === 'dark';

    return (
        <Stack {...stackProps} rounded="lg" bg={isDark ? 'gray.700' : 'white'}>
            {children}
        </Stack>
    );
};
