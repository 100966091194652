import type { Card, Question } from '@dmp/qqms/types';

export type IdMap = Record<Question['id'], Question['id'] | undefined>;

/**
 * Given a Card, replace the card IDs to which it points.
 */
export const replaceCardResultIds =
    (idMap: IdMap) =>
    <T extends Card>(card: T): T => {
        switch (card.type) {
            case 'dynamicCoreQuestion':
            case 'linearQuestion': {
                const replacementId = card.resultCard
                    ? idMap[card.resultCard]
                    : undefined;

                return {
                    ...card,
                    resultCard: replacementId || card.resultCard,
                };
            }

            case 'forkedQuestion': {
                return {
                    ...card,
                    answers: card.answers.map((a) => {
                        const replacementId = a.resultCard
                            ? idMap[a.resultCard]
                            : undefined;

                        return {
                            ...a,
                            resultCard: replacementId || a.resultCard,
                        };
                    }),
                };
            }

            case 'pollResultCard': {
                const replacementId = idMap[card.questionId];

                return {
                    ...card,
                    questionId: replacementId || card.questionId,
                };
            }

            default:
                return card;
        }
    };
