import { Box, Heading, Text, VStack } from '@chakra-ui/react';
import { useRecoilState, useRecoilValue } from 'recoil';
import * as React from 'react';

import type { Card } from '@dmp/qqms/types';
import {
    cardFormTypeState,
    cardTraxexLabelState,
    cardTypeState,
} from '../../../state/card/card-atoms';
import { CardAsRadio } from './card-as-radio';
import InputPill from '../../common/input-pill';

interface CardAsRadioComProps {
    cardId: Card['id'];
}

const CardAsMultiSelectCom: React.FC<CardAsRadioComProps> = ({ cardId }) => {
    const cardType = useRecoilValue(cardTypeState(cardId));
    const cardFormType = useRecoilValue(cardFormTypeState(cardId));
    const [cardTraxexLabel, setCardTraxexLabel] = useRecoilState(
        cardTraxexLabelState(cardId)
    );

    if (cardType !== 'linearQuestion' || cardFormType !== 'multi-select') {
        return null;
    }

    const changeTraxex = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCardTraxexLabel(e.target.value);
    };

    return (
        <>
            <CardAsRadio cardId={cardId} />
            <VStack align="start" spacing="2">
                <Heading as="h2" fontSize="sm">
                    Submit Trackings [optional]
                </Heading>
                <Text fontSize="xs" opacity="0.6">
                    These tracking pixels will be triggered when Submit button
                    is clicked.
                </Text>

                <VStack w="100%" align="stretch" spacing="2">
                    <InputPill
                        label={<Box w="90px">Traxex Label</Box>}
                        value={cardTraxexLabel}
                        onChange={changeTraxex}
                    />
                </VStack>
            </VStack>
        </>
    );
};

export const CardAsMultiSelect = React.memo(CardAsMultiSelectCom);
