import type { ComponentSingleStyleConfig } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';
import { usaTodayBlue } from '../foundations/colors';

const themedText: ComponentSingleStyleConfig = {
    variants: {
        gray: (props) => ({
            color: mode('gray.600', 'gray.400')(props),
        }),
        usatoday_text_bold: (props) => ({
            fontWeight: '900',
            color: mode('gray.600', 'white')(props),
        }),
        usatoday_blue: (props) => ({
            color: usaTodayBlue(props.colorMode),
        }),
        usatoday_blue_bold: (props) => ({
            fontWeight: '900',
            color: usaTodayBlue(props.colorMode),
        }),
    },
};

export default themedText;
