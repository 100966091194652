import { memo } from 'react';
import type { IconProps } from '@chakra-ui/react';
import { Icon } from '@chakra-ui/react';

const IconMultiSelectCom = (props: IconProps) => {
    return (
        <Icon viewBox="0 0 80 60" {...props}>
            <path
                fill="currentColor"
                d="M35.4 17.3H14c-1.6 0-2.9 1.5-2.9 3.3v4.8c0 1.8 1.3 3.3 2.9 3.3h21.4c1.6 0 2.9-1.5 2.9-3.3v-4.8c0-1.8-1.3-3.3-2.9-3.3zM35.4 31.1H14c-1.6 0-2.9 1.5-2.9 3.3v4.8c0 1.8 1.3 3.3 2.9 3.3h21.4c1.6 0 2.9-1.5 2.9-3.3v-4.8c0-1.8-1.3-3.3-2.9-3.3zM66 17.3H44.6c-1.6 0-2.9 1.5-2.9 3.3v4.8c0 1.8 1.3 3.3 2.9 3.3H66c1.6 0 2.9-1.5 2.9-3.3v-4.8c-.1-1.8-1.4-3.3-2.9-3.3zM65.4 42.7H45.2c-1.9 0-3.5-1.7-3.5-3.7v-4.3c0-2 1.6-3.7 3.5-3.7h20.1c1.9 0 3.5 1.7 3.5 3.7V39c0 2-1.5 3.7-3.4 3.7zM45.2 32.5c-1 0-1.9 1-1.9 2.2V39c0 1.2.9 2.2 1.9 2.2h20.1c1 0 1.9-1 1.9-2.2v-4.3c0-1.2-.9-2.2-1.9-2.2H45.2z"
            />
        </Icon>
    );
};

export const IconMultiSelect = memo(IconMultiSelectCom);
