import { BsArrowReturnRight as IconConnect } from 'react-icons/bs';
import type { Card } from '@dmp/qqms/types';
import { HStack, Icon } from '@chakra-ui/react';
import { useRecoilValue } from 'recoil';
import * as React from 'react';

import { CardConnectionForked } from './card-connection-forked';
import { cardTypeState } from '../../../state/card/card-atoms';
import { QuestionTypeSelect } from './question-type-select';
import {
    typeIsDynamicCoreQuestion,
    typeIsForkedQuestion,
    typeIsPollResultCard,
    typeIsThankYouCard,
} from '../../../state/card/card-type-check';
import { CardConnectionNonForked } from './card-connection-non-forked';

interface CardConnectionProps {
    cardId: Card['id'];
}

const CardConnectionCom: React.FC<CardConnectionProps> = ({ cardId }) => {
    const cardType = useRecoilValue(cardTypeState(cardId));

    // no connection for thankYou card or poll result card
    if (typeIsThankYouCard(cardType) || typeIsPollResultCard(cardType)) {
        return null;
    }

    const isCoreQuestion = typeIsDynamicCoreQuestion(cardType);

    return (
        <HStack w="100%" align="start" spacing="3">
            <HStack>
                <Icon as={IconConnect} opacity="0.5" />

                {/* Wrapping <div /> required, because Popper won't allow margin on <QuestionTypeSelect /> */}
                {!isCoreQuestion && (
                    <div>
                        <QuestionTypeSelect cardId={cardId} />
                    </div>
                )}
            </HStack>

            {typeIsForkedQuestion(cardType) ? (
                <CardConnectionForked cardId={cardId} />
            ) : (
                <CardConnectionNonForked cardId={cardId} />
            )}
        </HStack>
    );
};

export const CardConnection = React.memo(CardConnectionCom);
