import { useDisclosure } from '@chakra-ui/react';
import type * as React from 'react';
import { useState } from 'react';
import { useSurveyDuplicate } from '../../../state/survey/use-survey-duplicate';

export const useSurveyDuplicateButton = (surveyId: string) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const { duplicateSurvey } = useSurveyDuplicate();

    const [newSurveyName, setSurveyName] = useState<string>('');
    const [error, setError] = useState<string>('');

    const validate = () => {
        const name = newSurveyName.trim();

        if (name.length <= 2) {
            setError('Survey name must be at least 2 characters');
            return false;
        }

        return true;
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSurveyName(e.target.value);
        setError('');
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleSubmit();
        }
    };

    const handleModalClose = () => {
        onClose();
        setSurveyName('');
    };

    const handleSubmit = () => {
        if (validate()) {
            duplicateSurvey(surveyId, newSurveyName);
        }
    };

    return {
        handleChange,
        handleKeyDown,
        handleModalClose,
        handleSubmit,
        isOpen,
        onClose,
        onOpen,
        newSurveyName,
        error,
    };
};
