import { FormControl, FormLabel, useColorMode } from '@chakra-ui/react';
import { useRecoilState } from 'recoil';
import type { ChangeEvent } from 'react';
import { memo } from 'react';

import { answerTraxexLabelState } from '../../state/answer/answer-atoms';
import { trackingFieldStyles } from '../../theme/common-styles/tracking-field-styles';
import { TrimmedInput } from '@dmp/shared/components';

interface AnswerTraxexProps {
    answerId: string;
}

const AnswerTraxexCom = ({ answerId }: AnswerTraxexProps) => {
    const { colorMode } = useColorMode();

    const [traxex, updateTraxex] = useRecoilState(
        answerTraxexLabelState(answerId)
    );

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        updateTraxex(e.target.value);
    };

    const { label, input } = trackingFieldStyles(colorMode);

    return (
        <FormControl id={`answer-${answerId}-tracking-label`}>
            <FormLabel {...label}>
                Tracking Label: User selected this Answer
            </FormLabel>
            <TrimmedInput
                {...input}
                value={traxex || ''}
                onChange={handleChange}
            />
        </FormControl>
    );
};

export const AnswerTraxex = memo(AnswerTraxexCom);
