import { StackDivider, useColorMode, VStack } from '@chakra-ui/react';
import { useEventListener, useSurveyState } from '@dmp/qqms-renderer';
import { useCallback, useState } from 'react';
import { getAnswerTrackingUrls } from '../../common/survey-preview/get-answer-tracking-urls';
import { TrackingUrlList } from '../../common/tracking-url-list';
import { CardWarning } from '../card-warning';

export function CardPreviewStatus({ cardId }: { cardId: string }) {
    const { colorMode } = useColorMode();

    const { survey } = useSurveyState();
    const [urls, setUrls] = useState<string[]>([]);
    const onClear = useCallback(() => setUrls([]), [setUrls]);

    useEventListener('answersSelected', ({ detail }) => {
        const urls = getAnswerTrackingUrls({
            ...detail,
            survey,
        });

        setUrls(urls);
    });

    return (
        <VStack
            w="100%"
            minH="80px"
            maxH="350px"
            p="4"
            spacing="4"
            fontSize="sm"
            bg={colorMode === 'dark' ? 'gray.800' : 'gray.50'}
            rounded="md"
            overflowY="auto"
            className="scrollbar"
            divider={<StackDivider />}
        >
            <CardWarning cardId={cardId} />

            {urls.length > 0 && (
                <TrackingUrlList urls={urls} onClear={onClear} />
            )}
        </VStack>
    );
}
