import { z } from 'zod';
import { traxexLabel } from './shared-schema';

const baseAnswerValidator = z.object({
    id: z.string().min(1),
    body: z.string().min(1, 'Answer text is required.'),
    traxexLabel: traxexLabel(),
});

export const linearAnswerValidator = baseAnswerValidator.extend({
    type: z.literal('linearAnswer'),
    isFreeForm: z.literal(true).optional(),
});

export const forkedAnswerValidator = baseAnswerValidator.extend({
    type: z.literal('forkedAnswer'),
    resultCard: z.string().optional(),
});

export const answerValidator = z.discriminatedUnion('type', [
    linearAnswerValidator,
    forkedAnswerValidator,
]);
