/**
 * @file Utility functions for getting info about an ad placement,
 *     including its size, what platform it is served on, etc.
 */

export type Orientation = 'portrait' | 'landscape';
export type Platform = 'desktop' | 'mobileweb' | 'app';

interface Size {
    width: number;
    height: number;
}

/**
 * Determines the ad orientation based on its current dimensions.
 */
export const getAdOrientation = ({ width, height }: Size): Orientation =>
    height > width ? 'portrait' : 'landscape';

/**
 * Determine the size of the user's device screen.
 * Uses the safeframe API if it is available.
 */
export const getScreenSize = (): Size => ({
    width: window.$sf ? window.$sf.ext.geom().win.w : window.screen.width,
    height: window.$sf ? window.$sf.ext.geom().win.h : window.screen.height,
});

/**
 * Detects platform: Desktop, Mobile Web, or Native App
 */
export const detectPlatform = (): Platform => {
    if (window.mraid) {
        return 'app';
    }

    const screenSize = getScreenSize();

    return screenSize.width < 768 ? 'mobileweb' : 'desktop';
};

/**
 * Get window size: width and height
 */
export const getWindowSize = (): Size => {
    const width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;

    const height =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;

    return { width, height };
};
