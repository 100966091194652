import { useRecoilValueLoadable } from 'recoil';
import * as React from 'react';

import type { CardItemState } from '../../state/card/card-types';
import { randomInArray } from '@dmp/shared/client-utils';
import { validateCardConfigSelector } from '../../state/survey-config/survey-config-atoms';
import { ValidationBox, awesomeText } from '../common/validation-box';

interface CardWarningProps {
    cardId: CardItemState['id'];
}

const CardWarningCom: React.FC<CardWarningProps> = ({ cardId }) => {
    const errorLoadable = useRecoilValueLoadable(
        validateCardConfigSelector(cardId)
    );

    const [validText] = React.useState<string>(randomInArray(awesomeText));

    if (errorLoadable.state !== 'hasValue') {
        return null;
    }

    return (
        <ValidationBox
            errors={errorLoadable.contents}
            successMsg={`This card is ${validText}. Green across the board.`}
        />
    );
};

export const CardWarning = React.memo(CardWarningCom);
