import { useToast } from '@chakra-ui/react';
import type {
    CoreQuestionResource,
    CoreQuestionUpdateAttributes,
} from '@dmp/qqms/data-access';
import { patchCoreQuestion } from '@dmp/qqms/data-access';
import { useErrorToaster, useSuccessToaster } from '@dmp/shared/components';
import { useRecoilCallback, waitForAll } from 'recoil';
import { answerItemState } from '../answer/answer-atoms';
import { cardItemState } from '../card/card-atoms';
import { isLinearQuestionItem } from '../card/card-type-guards';
import { convertToCoreQuestionConfig } from '../survey-config/convert-to-card-config';
import { validateCardConfigSelector } from '../survey-config/survey-config-atoms';
import {
    formCoreQuestionIsActiveState,
    isCoreQuestionFormDirtyState,
} from './core-question-edit-form-atoms';
import { coreQuestionResourceSelector } from './core-question-list-atoms';

/**
 * Action for saving the editing core question for the editing form
 * - convert the current form data to core question config.
 * - update the core question list.
 */
export const useCoreQuestionPatch = () => {
    const { handleError } = useErrorToaster();
    const { handleSuccess } = useSuccessToaster();
    const toast = useToast();

    const patchCoreQuestionCallback = useRecoilCallback(
        ({ snapshot, set }) =>
            async (coreQuestionId: CoreQuestionResource['id']) => {
                const isFormDirty = await snapshot.getPromise(
                    isCoreQuestionFormDirtyState
                );

                const card = await snapshot.getPromise(
                    cardItemState(coreQuestionId)
                );

                const isActive = await snapshot.getPromise(
                    formCoreQuestionIsActiveState
                );

                if (!isFormDirty) {
                    return;
                }

                if (!isLinearQuestionItem(card)) {
                    throw new Error(
                        'Only Linear Questions can be Core Questions'
                    );
                }

                const answerCards = await snapshot.getPromise(
                    waitForAll(
                        card.answers.map((answerId) =>
                            answerItemState(answerId)
                        )
                    )
                );

                const errors = await snapshot.getPromise(
                    validateCardConfigSelector(card.id)
                );

                if (errors.length > 0) {
                    toast({
                        title: 'Error',
                        status: 'error',
                        duration: 4000,
                        isClosable: true,
                        description:
                            'Core Question is invalid. Please check the errors in card validation box.',
                    });
                    return;
                }

                const config = convertToCoreQuestionConfig(card, answerCards);

                const payload: CoreQuestionUpdateAttributes = {
                    config,
                    isActive,
                };

                patchCoreQuestion(coreQuestionId, payload)
                    .then((response) => {
                        set(isCoreQuestionFormDirtyState, false);

                        set(
                            coreQuestionResourceSelector(response.data.id),
                            response.data
                        );

                        handleSuccess('Core Question saved successfully');
                    })
                    .catch(handleError);
            },
        []
    );

    return {
        patchCoreQuestion: patchCoreQuestionCallback,
    };
};
