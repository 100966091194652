import { generateCoreQuestionId } from '@dmp/qqms/survey-generators';
import { useRecoilCallback } from 'recoil';
import { answerItemState } from '../answer/answer-atoms';
import { addAnswerUtil } from '../answer/use-answer-add';
import { cardItemState, defaultQuestion } from '../card/card-atoms';
import type { LinearQuestionItemState } from '../card/card-types';
import {
    editingCoreQuestionIdState,
    formCoreQuestionIsActiveState,
    isCoreQuestionFormDirtyState,
    newCoreQuestionIdState,
} from './core-question-edit-form-atoms';
import { useWarnSwitchingForm } from './use-warn-switching-form';

export const useCoreQuestionAdd = () => {
    const { checkIsOkToSwitch } = useWarnSwitchingForm();

    const addCoreQuestion = useRecoilCallback(
        ({ set }) =>
            async () => {
                const okayToStart = await checkIsOkToSwitch();

                if (!okayToStart) {
                    return;
                }

                const { newCoreQuestion, newAnswer } = addCoreQuestionUtil();

                // create new card atom
                set(cardItemState(newCoreQuestion.id), newCoreQuestion);

                // create new answer atom
                set(answerItemState(newAnswer.id), newAnswer);

                // record new core question id
                set(newCoreQuestionIdState, newCoreQuestion.id);

                // set form isActive state. Let it be false for user to intentionally activate it.
                set(formCoreQuestionIsActiveState, false);

                // update the new current editing core question id
                set(editingCoreQuestionIdState, newCoreQuestion.id);

                // set dirty pristine
                set(isCoreQuestionFormDirtyState, false);
            },
        []
    );

    return {
        addCoreQuestion,
    };
};

/**
 * Create new card and get modified atoms
 */
export const addCoreQuestionUtil = () => {
    const newQuestion: LinearQuestionItemState = {
        ...defaultQuestion,
        type: 'linearQuestion',
        id: generateCoreQuestionId(),
    };

    // add answer
    const { newQuestion: newQuestionWAnswer, newAnswer } =
        addAnswerUtil(newQuestion);

    return {
        newCoreQuestion: newQuestionWAnswer,
        newAnswer,
    };
};
