import {
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    HStack,
    Text,
} from '@chakra-ui/react';
import * as React from 'react';

import type { AnswerItemState } from '../../../state/answer/answer-types';
import type { CardItemState } from '../../../state/card/card-types';
import type { SurveyState } from '../../../state/survey/survey-types';

interface StateObserverItemProps {
    item: SurveyState | CardItemState | AnswerItemState;
    name: string;
}

export const StateObserverItem: React.FC<StateObserverItemProps> = React.memo(
    ({ item, name }) => {
        return (
            <AccordionItem w="100%">
                <AccordionButton bg="gray.800" fontSize="xs">
                    <Box flex="1" textAlign="left" noOfLines={1}>
                        {name}
                    </Box>
                    <AccordionIcon />
                </AccordionButton>
                <AccordionPanel>
                    {Object.entries(item).map(([k, val]) => (
                        <HStack key={k} align="start" spacing="2" fontSize="sx">
                            <Text flex="0 0 60px" noOfLines={1}>
                                {k}
                            </Text>
                            <Text title={JSON.stringify(val, null, 2)}>
                                {['string', 'number'].includes(typeof val)
                                    ? val
                                    : JSON.stringify(val, null, 2)}
                            </Text>
                        </HStack>
                    ))}
                </AccordionPanel>
            </AccordionItem>
        );
    }
);
