import { fetchAuthGroups } from '@dmp/qqms/data-access';
import { useErrorToaster } from '@dmp/shared/components';
import { useQuery } from '@tanstack/react-query';
import { AUTH_GROUPS } from '../../config/query-keys';
import { useSetRecoilState } from 'recoil';
import { allAuthGroupList } from './auth-group-list-atoms';

/**
 * Fetch and process response to atom states
 */
export const useAuthGroupListFetch = () => {
    const { handleError } = useErrorToaster();
    const setAuthGroupList = useSetRecoilState(allAuthGroupList);

    const query = useQuery([AUTH_GROUPS], fetchAuthGroups, {
        enabled: false, // Needs to be manually triggered.
        onSuccess: (resp) => setAuthGroupList(resp.data),
        onError: (err) => handleError(err),
    });

    return {
        fetchAuthGroupList: query.refetch,
    };
};
