/**
 * @file SurveyPreview component. Renders a survey inside of an iframe.
 */
import { SurveyComponent } from '@dmp/qqms-renderer';
import type { HTMLProps } from 'react';
import { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useRecoilValue } from 'recoil';
import { surveyCardIdsState } from '../../../state/survey/survey-atoms';

export const SurveyPreview = ({
    style,
    ...rest
}: HTMLProps<HTMLIFrameElement>) => {
    const surveyCardIds = useRecoilValue(surveyCardIdsState);

    const iFrameRef = useRef<HTMLIFrameElement>(null);

    const [bodyRef, setBodyRef] = useState<HTMLBodyElement>();
    const [headRef, setHeadRef] = useState<HTMLHeadElement>();

    useEffect(() => {
        if (!iFrameRef.current) {
            return;
        }

        const iframe$ = iFrameRef.current;

        // enclose in timeout to fix Firefox render
        setTimeout(() => {
            setBodyRef(iframe$.contentDocument?.body as HTMLBodyElement);
            setHeadRef(iframe$.contentDocument?.head);
        }, 500);
    }, [surveyCardIds]); // NOTE: reset the ref states when cards order changes.

    return (
        <iframe
            title="survey-preview"
            ref={iFrameRef}
            style={{
                backgroundColor: 'white',
                color: 'black',
                width: '100%',
                height: '100%',
                ...style,
            }}
            {...rest}
        >
            {headRef &&
                ReactDOM.createPortal(
                    <style>{findUnitStyle()}</style>,
                    headRef
                )}
            {bodyRef &&
                ReactDOM.createPortal(
                    <div id="qq-survey">
                        <SurveyComponent site="" />
                    </div>,
                    bodyRef
                )}
        </iframe>
    );
};

const findUnitStyle = (): string => {
    // When we import `SurveyComponent`, it imports the styles from
    // that library. We have to re-inject those styles into the iframe.
    const surveyStyle =
        Array.from(document.head.getElementsByTagName('style')).find((s) =>
            s.innerHTML.includes('#survey-root')
        )?.innerHTML || '';

    return `
        html {
            height: 100%;
        }

        body {
            height: 100%;
            overflow: hidden;
        }

        ${surveyStyle}
    `;
};
