import { useRecoilCallback } from 'recoil';

import { generateDynamicCoreQuestionId } from '@dmp/qqms/survey-generators';
import { insertNewCard } from '../../utils/insertNewCard';
import { surveyState } from '../survey/survey-atoms';
import type { SurveyState } from '../survey/survey-types';
import {
    cardItemState,
    defaultCoreQuestion,
    surveyCardsSelector,
} from './card-atoms';
import type { CardItemState, DynamicCoreQuestionItemState } from './card-types';

/**
 * Add a core question card
 * cardId given, new card will be add after that card
 */
export const useCardAddDynamicCoreQuestion = (cardId?: CardItemState['id']) => {
    const addCoreQuestion = useRecoilCallback(
        ({ set, snapshot }) =>
            async () => {
                const survey = await snapshot.getPromise(surveyState);

                const cardsInSurvey = await snapshot.getPromise(
                    surveyCardsSelector
                );
                const { newSurvey, newCards } = addCardDynamicCoreQuestionUtil(
                    survey,
                    cardsInSurvey
                )(cardId);

                // update all cards, & create new card atom
                newCards.forEach((c) => set(cardItemState(c.id), c));

                // add new card id into survey
                set(surveyState, newSurvey);
            },
        [cardId]
    );

    return {
        addCoreQuestion,
    };
};

/**
 * Create new card and get modified atoms
 */
export const addCardDynamicCoreQuestionUtil =
    (survey: SurveyState, cardsInSurvey: CardItemState[]) =>
    (cardId?: CardItemState['id']) => {
        const newQuestion: DynamicCoreQuestionItemState = {
            ...defaultCoreQuestion,
            id: generateDynamicCoreQuestionId(),
            questions: [],
        };

        const { newSurvey, newCards } = insertNewCard(survey, cardsInSurvey)(
            newQuestion,
            cardId
        );

        return {
            newSurvey,
            newCards,
        };
    };
