import { useColorMode } from '@chakra-ui/color-mode';
import { usaTodayBlue } from '../../theme/foundations/colors';

export const useMainButtonStyles = () => {
    const { colorMode } = useColorMode();

    const usatodayColor = usaTodayBlue(colorMode);
    const isDark = colorMode === 'dark';
    const bg = isDark ? 'gray.700' : usatodayColor;
    const color = isDark ? usatodayColor : 'white';

    return {
        buttonStyles: {
            size: 'sm',
            bg,
            color,
            _hover: {
                filter: 'brightness(120%)',
            },
            rounded: 'md',
        },
    };
};
