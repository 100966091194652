import type { CoreQuestionResource } from '@dmp/qqms/data-access';
import { useRecoilCallback } from 'recoil';
import {
    editingCoreQuestionIdState,
    isCoreQuestionFormDirtyState,
} from './core-question-edit-form-atoms';

export const useWarnSwitchingForm = () => {
    const checkIsOkToSwitch = useRecoilCallback(
        ({ snapshot }) =>
            async (coreQuestionId: CoreQuestionResource['id'] = '') => {
                const isFormDirty = await snapshot.getPromise(
                    isCoreQuestionFormDirtyState
                );

                const editingCQId = await snapshot.getPromise(
                    editingCoreQuestionIdState
                );

                const noFormActive = !editingCQId;
                const isSelected = coreQuestionId === editingCQId;

                // no form is active currently, allow to switch
                if (noFormActive) {
                    return true;
                }

                // form is active, no need to switch
                if (isSelected) {
                    return false;
                }

                // form is dirty, needs confirmation from user
                if (isFormDirty) {
                    const msg =
                        'You have unsaved changes. Are you sure you want to proceed without saving?';
                    return window.confirm(msg);
                }

                return true;
            }
    );

    return {
        checkIsOkToSwitch,
    };
};
