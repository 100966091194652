import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormHelperText,
    Heading,
    HStack,
    useColorMode,
    VStack,
} from '@chakra-ui/react';
import type { Card } from '@dmp/qqms/types';
import { SearchBox } from '@dmp/shared/components';
import { closestCenter, DndContext, DragOverlay } from '@dnd-kit/core';
import {
    SortableContext,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { cardTypeState } from '../../../../state/card/card-atoms';
import { DroppableContainer } from './droppable-container';
import { SortableBar, SortableBarShadow } from './sortable-bar';
import { useMaximizeResponses } from './use-maximize-responses';
import { useSortableCoreQuestions } from './use-sortable-core-questions';

interface CardAsDynamicCoreQuestionComProps {
    cardId: Card['id'];
}

const CardAsDynamicCoreQuestionCom: React.FC<
    CardAsDynamicCoreQuestionComProps
> = ({ cardId }) => {
    const { colorMode } = useColorMode();

    const cardType = useRecoilValue(cardTypeState(cardId));

    const { maximizeResponses, setMaximizeResponses } =
        useMaximizeResponses(cardId);

    const {
        draggingIds,
        empty,
        handleDragEnd,
        handleDragOver,
        handleDragStart,
        handleRemove,
        handleSelect,
        keyword,
        libIds,
        questionIds,
        selectedIds,
        sensors,
        setKeyword,
        transferAll,
    } = useSortableCoreQuestions(cardId);

    if (cardType !== 'dynamicCoreQuestion') {
        return null;
    }

    const isDark = colorMode === 'dark';

    return (
        <VStack spacing="10">
            <FormControl>
                <Checkbox
                    id="maximize-respones"
                    isChecked={maximizeResponses}
                    onChange={(e) => {
                        setMaximizeResponses(e.target.checked);
                    }}
                    alignSelf="flex-start"
                >
                    Maximize Core Question Responses
                </Checkbox>

                <FormHelperText>
                    Include all Core Questions but show at most 3 to the user.
                </FormHelperText>
            </FormControl>

            {!maximizeResponses && (
                <>
                    <SearchBox
                        // w="400px"
                        mt="-15px"
                        iconSpace="32px"
                        size="sm"
                        initialValue={keyword}
                        onSubmit={setKeyword}
                        placeholder="search question"
                        isInstant
                    />

                    <DndContext
                        sensors={sensors}
                        collisionDetection={closestCenter}
                        onDragEnd={handleDragEnd}
                        onDragOver={handleDragOver}
                        onDragStart={handleDragStart}
                    >
                        <HStack
                            minW="1000px"
                            maxW="1600px"
                            align="start"
                            spacing="6"
                        >
                            <VStack flex="1" align="stretch">
                                <HStack>
                                    <Heading as="h2" flex="1" fontSize="sm">
                                        Core Questions will be displayed
                                    </Heading>
                                    <Button
                                        size="xs"
                                        onClick={empty}
                                        isDisabled={questionIds.length === 0}
                                    >
                                        Empty
                                    </Button>
                                </HStack>
                                <DroppableContainer id="main">
                                    <VStack
                                        w="100%"
                                        minH="250px"
                                        maxH="800px"
                                        align="start"
                                        spacing="1"
                                        p="3"
                                        border="1px solid"
                                        borderColor="whiteAlpha.300"
                                        bg={
                                            isDark ? 'inherit' : 'blackAlpha.50'
                                        }
                                        rounded="md"
                                        overflowY="auto"
                                    >
                                        <SortableContext
                                            id="main"
                                            items={questionIds}
                                            strategy={
                                                verticalListSortingStrategy
                                            }
                                        >
                                            {questionIds.map((id) => (
                                                <SortableBar
                                                    key={id}
                                                    keyword={keyword}
                                                    listName="main"
                                                    id={id}
                                                    isDragging={draggingIds.includes(
                                                        id
                                                    )}
                                                    onSelect={handleSelect}
                                                    isSelected={selectedIds.includes(
                                                        id
                                                    )}
                                                    onRemove={handleRemove}
                                                />
                                            ))}

                                            {questionIds.length === 0 && (
                                                <Box
                                                    w="100%"
                                                    p="4"
                                                    color="orange.400"
                                                    textAlign="center"
                                                >
                                                    Warning: This card must have
                                                    at least one Core Question
                                                </Box>
                                            )}
                                        </SortableContext>
                                    </VStack>
                                </DroppableContainer>
                            </VStack>

                            <VStack w="40%" align="start">
                                <HStack w="100%">
                                    <Heading flex="1" as="h2" fontSize="sm">
                                        Core Question Library
                                    </Heading>
                                    <Button
                                        size="xs"
                                        onClick={transferAll}
                                        isDisabled={libIds.length === 0}
                                    >
                                        Use all
                                    </Button>
                                </HStack>

                                <DroppableContainer
                                    id="lib"
                                    style={{ width: '100%' }}
                                >
                                    <VStack
                                        w="100%"
                                        minH="250px"
                                        maxH="800px"
                                        align="start"
                                        spacing="1"
                                        p="3"
                                        bg={
                                            isDark
                                                ? 'blackAlpha.400'
                                                : 'blackAlpha.50'
                                        }
                                        border="1px solid"
                                        borderColor="whiteAlpha.300"
                                        rounded="md"
                                        overflowY="auto"
                                    >
                                        <SortableContext
                                            id="lib"
                                            items={libIds}
                                            strategy={
                                                verticalListSortingStrategy
                                            }
                                        >
                                            {libIds.map((id) => (
                                                <SortableBar
                                                    key={id}
                                                    keyword={keyword}
                                                    listName="lib"
                                                    id={id}
                                                    isDragging={draggingIds.includes(
                                                        id
                                                    )}
                                                    isLib
                                                    onSelect={handleSelect}
                                                    isSelected={selectedIds.includes(
                                                        id
                                                    )}
                                                    isDisabled
                                                />
                                            ))}
                                        </SortableContext>
                                    </VStack>
                                </DroppableContainer>
                            </VStack>

                            <DragOverlay>
                                <SortableBarShadow ids={draggingIds} />
                            </DragOverlay>
                        </HStack>
                    </DndContext>
                </>
            )}
        </VStack>
    );
};

export const CardAsDynamicCoreQuestion = React.memo(
    CardAsDynamicCoreQuestionCom
);
