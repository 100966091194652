import {
    Box,
    Button,
    HStack,
    Select,
    StackDivider,
    VStack,
} from '@chakra-ui/react';
import { SurveyProvider } from '@dmp/qqms-renderer';
import type { Survey } from '@dmp/qqms/types';
import * as React from 'react';
import { usePollResultInterceptor } from '../../hooks/use-poll-result-interceptor';
import { SurveyPreview } from '../common/survey-preview/survey-preview';
import { SurveyTrackingUrls } from '../survey-view/survey-preview-button/survey-tracking-urls';
import { SurveyPreviewBoxControls } from './survey-preview-box-controls';
import { useSurveyPreview } from './use-survey-preview';
import { useSurveyPreviewButtonProps } from './use-survey-preview-button-props';

interface SurveyPreviewBoxProps {
    survey: Survey;
}

const SurveyPreviewBoxCom: React.FC<SurveyPreviewBoxProps> = ({ survey }) => {
    const {
        height,
        previewSizes,
        previewSurvey,
        setSizeName,
        setZoom,
        sizeName,
        width,
        zoom,
    } = useSurveyPreview(survey);

    usePollResultInterceptor();

    const buttonProps = useSurveyPreviewButtonProps();

    return (
        <SurveyProvider survey={previewSurvey}>
            <VStack
                divider={<StackDivider />}
                w={['100%', 'unset']}
                spacing="4"
            >
                <HStack p={['2', '0']} mt="6" align="stretch" w="100%">
                    <HStack align="start" flex="1">
                        row
                        {previewSizes.map((ps) => (
                            <Button
                                {...buttonProps}
                                border={
                                    ps.name === sizeName ? '1px solid' : '0'
                                }
                                key={ps.name}
                                onClick={() => {
                                    setSizeName(ps.name);
                                }}
                            >
                                {ps.name}
                            </Button>
                        ))}
                    </HStack>

                    <HStack>
                        <Select
                            {...buttonProps}
                            value={zoom}
                            onChange={(e) => {
                                setZoom(+e.target.value);
                            }}
                        >
                            <option value={1}>100%</option>
                            <option value={0.75}>75%</option>
                            <option value={0.5}>50%</option>
                        </Select>

                        <SurveyPreviewBoxControls />
                    </HStack>
                </HStack>

                <Box
                    w="100%"
                    p={['4', '0']}
                    overflow="auto"
                    className="scrollbar"
                    justifyContent="center"
                    display="flex"
                >
                    <Box
                        w={width * zoom}
                        h={height * zoom}
                        border="1px solid"
                        borderColor="gray.200"
                    >
                        <SurveyPreview />
                    </Box>
                </Box>

                <Box maxW={['100%', width]} p="2">
                    <SurveyTrackingUrls />
                </Box>
            </VStack>
        </SurveyProvider>
    );
};

export const SurveyPreviewBox = React.memo(SurveyPreviewBoxCom);
