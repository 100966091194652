import { useColorMode } from '@chakra-ui/react';

export function useSurveyPreviewButtonProps() {
    const { colorMode } = useColorMode();
    const isDark = colorMode === 'dark';

    return {
        size: 'sm',
        bg: isDark ? 'gray.700' : 'gray.200',
        _hover: {
            background: isDark ? 'gray.600' : 'gray.300',
        },
        rounded: 'md',
        border: '0',
        borderColor: 'blue.400',
    };
}
