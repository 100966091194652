import {
    cardValidator,
    coreQuestionValidator,
    surveyConfigValidator,
} from '@dmp/qqms/survey-validators';
import { selector, selectorFamily } from 'recoil';
import type { ZodIssue } from 'zod';

import {
    convertCardsToTree,
    extractConnectedCards,
} from '../../utils/convert-cards-to-tree';
import { cardAnswersSelector } from '../answer/answer-atoms';
import { cardItemState } from '../card/card-atoms';
import type { CardItemState } from '../card/card-types';
import { editingCoreQuestionIdState } from '../core-question/core-question-edit-form-atoms';
import { allCoreQuestionList } from '../core-question/core-question-list-atoms';
import { collectiveStateSelector } from './collective-state-atoms';
import { convertToCardConfig } from './convert-to-card-config';
import { convertToSurveyConfig } from './convert-to-survey-config';

/****************************************************************
 * Config Getters
 ****************************************************************/

/**
 * Get survey config from current state
 */
export const surveyConfigSelector = selector({
    key: 'surveyConfigSelector',
    get: ({ get }) => {
        const collectiveState = get(collectiveStateSelector);
        return convertToSurveyConfig(collectiveState);
    },
});

/**
 * Get card config from current card state by id
 *
 * @param CardId
 */
export const cardConfigSelector = selectorFamily({
    key: 'expandedCardByIdSelector',
    get:
        (cardId: CardItemState['id']) =>
        ({ get }) => {
            const card = get(cardItemState(cardId));
            const answers = get(cardAnswersSelector(cardId));
            const allCoreQuestions = get(allCoreQuestionList);

            return convertToCardConfig(card, answers, allCoreQuestions);
        },
});

/****************************************************************
 * Config Validators
 ****************************************************************/
/**
 * Validate survey config
 */
export const validateSurveyConfigSelector = selector({
    key: 'validate_SurveyConfigSelector',
    get: ({ get }): string[] => {
        const surveyConfig = get(surveyConfigSelector);
        const result = surveyConfigValidator.safeParse(surveyConfig);

        return result.success ? [] : result.error.errors.map(zodIssueToString);
    },
});

/**
 * Validate card config by card id.
 * - Gather data from card state
 * - Converts to card config then validate
 */
export const validateCardConfigSelector = selectorFamily({
    key: 'validate_CardConfigSelector',
    get:
        (cardItemId: CardItemState['id']) =>
        ({ get }): string[] => {
            const cardConfig = get(cardConfigSelector(cardItemId));
            const isCoreQuestion = get(editingCoreQuestionIdState) !== '';

            const result = isCoreQuestion
                ? coreQuestionValidator.safeParse({
                      ...cardConfig,
                      type: 'coreQuestion',
                  })
                : cardValidator.safeParse(cardConfig);

            return result.success
                ? []
                : result.error.errors.map(zodIssueToString);
        },
});

function zodIssueToString(zodIssue: ZodIssue) {
    const path = zodIssue.path.length > 0 ? zodIssue.path.join('.') : undefined;

    return path ? `${path}: ${zodIssue.message}` : zodIssue.message;
}

/**
 * Collect survey connected card ids
 */
export const surveyConnectedCardsSelector = selector({
    key: 'surveyConnectedCardsSelector',
    get: ({ get }): string[] => {
        const surveyConfig = get(surveyConfigSelector);
        const startCard = surveyConfig.cards[0];
        const treeInfo = convertCardsToTree(startCard, surveyConfig.cards);

        return extractConnectedCards([], treeInfo);
    },
});

/**
 * Check if a card is connected
 * This will be in every card, therefore it should return a primitive value.
 */
export const isCardConnectedSelector = selectorFamily({
    key: 'checkCardIsConnectedSelector',
    get:
        (cardId: string) =>
        ({ get }): boolean => {
            const connectedCards = get(surveyConnectedCardsSelector);
            const isCoreQuestionView = connectedCards.length === 0;
            return isCoreQuestionView ? true : connectedCards.includes(cardId);
        },
});
