import {
    useDispatch,
    useEventListener,
    useSurveyState,
} from '@dmp/qqms-renderer';
import type { Card } from '@dmp/qqms/types';
import { useState } from 'react';

export function useSurveyPreviewControls() {
    const { survey } = useSurveyState();
    const dispatch = useDispatch();

    // record order of cards answered
    const [cardsAnswered, setCardsAnswered] = useState<Array<Card['id']>>([]);

    useEventListener('answersSelected', ({ detail }) => {
        // record card answered
        setCardsAnswered((currentCards) =>
            currentCards.concat(detail.question.id)
        );
    });

    const goToCard = (cardId: Card['id']) => {
        if (!cardId) {
            return;
        }

        dispatch('cardSelected', { cardId });

        // update record, remove the selected card and ones after it.
        const cardIndex = cardsAnswered.findIndex((id) => id === cardId);
        setCardsAnswered([...cardsAnswered.slice(0, cardIndex)]);
    };

    const atStart = cardsAnswered.length === 0;
    const goBack = () => goToCard(cardsAnswered[cardsAnswered.length - 1]);
    const startOver = () => !atStart && survey && goToCard(survey.cards[0].id);

    return { atStart, goBack, startOver };
}
