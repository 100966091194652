import { useContext } from 'react';
import { SurveyStateContext } from '../components/survey-provider';

/**
 * Get the current Survey State.
 */
export function useSurveyState() {
    const context = useContext(SurveyStateContext);

    if (context === undefined) {
        throw new Error(
            `The useSurveyState() hook must be used within a <SurveyStateProvider />`
        );
    }

    return context;
}
