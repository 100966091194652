import * as React from 'react';
import { HStack, Input } from '@chakra-ui/react';
import debounce from 'lodash.debounce';

interface ColorPickerProps {
    value: string;
    onChange: (value: string) => unknown;
}

const ColorPickerCom: React.FC<ColorPickerProps> = ({
    value = '',
    onChange,
}) => {
    const [localValue, setLocalValue] = React.useState<string>(value);

    // sync prop value and local value
    React.useEffect(() => {
        if (value !== localValue) {
            setLocalValue(value);
        }
        // NOTE: only listen to props "value" change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const triggerChange = React.useMemo(
        () => debounce(onChange, 500),
        [onChange]
    );

    const handleColorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setLocalValue(value);
        triggerChange(value);
    };

    return (
        <HStack flex="1" rounded="md" overflow="hidden">
            <Input
                type="color"
                id="color-url"
                border="0"
                p="0"
                value={localValue}
                onChange={handleColorChange}
            />
        </HStack>
    );
};

export const ColorPicker = React.memo(ColorPickerCom);
