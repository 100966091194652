import { HStack, Text } from '@chakra-ui/react';
import { useRecoilState, useRecoilValue } from 'recoil';
import * as React from 'react';

import { InputInline } from '../common/input-inline';
import {
    surveyNameState,
    surveyQQIDState,
} from '../../state/survey/survey-atoms';

const SurveyFieldsCom: React.FC = () => {
    const surveyQQID = useRecoilValue(surveyQQIDState);
    const [surveyName, setSurveyName] = useRecoilState(surveyNameState);

    return (
        <HStack w="100%" spacing="2">
            <Text fontWeight="bold">{surveyQQID}</Text>
            <InputInline
                flex="1"
                pl="1"
                py="1"
                h="auto"
                fontWeight="bold"
                value={surveyName}
                onChange={(e) => setSurveyName(e.target.value)}
            />
        </HStack>
    );
};

export const SurveyFields = React.memo(SurveyFieldsCom);
