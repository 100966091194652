import type { Card } from '@dmp/qqms/types';
import * as React from 'react';
import { useRecoilState } from 'recoil';
import { cardResultCardState } from '../../../state/card/card-atoms';

import { ConnectionSelect } from './connection-select';

interface CardConnectionNonForkedProps {
    cardId: Card['id'];
}

const CardConnectionNonForkedCom: React.FC<CardConnectionNonForkedProps> = ({
    cardId,
}) => {
    const [resultCard, setResultCard] = useRecoilState(
        cardResultCardState(cardId)
    );

    return (
        <ConnectionSelect
            currentCardId={cardId}
            resultCard={resultCard}
            onSelect={setResultCard}
        />
    );
};

export const CardConnectionNonForked = React.memo(CardConnectionNonForkedCom);
