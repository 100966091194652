import type { Card, SurveyConfig } from '@dmp/qqms/types';

/**
 * Concat/Append uniquely for string array
 */
export const concatUnique = (
    stringCollection: string[],
    newStrings: string[]
): string[] => [...new Set(stringCollection.concat(newStrings))];

/**
 * Get all cards by type
 */
export const getCardsByType = <T extends Card['type']>(
    type: T,
    surveyConfig?: SurveyConfig
) =>
    (surveyConfig?.cards.filter((c) => c.type === type) ?? []) as Array<
        Extract<Card, { type: T }>
    >;

/**
 * Check if survey has a card type
 */
export const hasCardByType = (
    type: Card['type'],
    surveyConfig?: SurveyConfig
): boolean => getCardsByType(type, surveyConfig).length > 0;

export const classes = (
    ...args: Array<string | null | undefined | false>
): string => args.filter(Boolean).join(' ');

/**
 * Should this Survey show pagination?
 */
export function isSurveyPaginated(surveyConfig: SurveyConfig): boolean {
    return (
        surveyConfig.cards.length > 1 &&
        // Only show pagination if there are no ForkedQuestions
        !surveyConfig.cards.some((card) => card.type === 'forkedQuestion')
    );
}

/**
 * Does the Question with the given ID have an associated PollResultCard?
 */
export function questionHasPollResult(
    surveyConfig: SurveyConfig,
    questionId: string
): boolean {
    const prCards = getCardsByType('pollResultCard', surveyConfig);
    const questionIds = prCards.map((c) => c.questionId).filter(Boolean);
    return questionIds.includes(questionId);
}
