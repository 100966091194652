import { useRecoilCallback } from 'recoil';

import type { Answer, Card } from '@dmp/qqms/types';
import { answerItemState } from './answer-atoms';
import { cardItemState } from '../card/card-atoms';
import { isQuestionItem } from '../card/card-type-guards';
import type { QuestionItemState } from '../card/card-types';
import { without } from '@dmp/shared/helpers';

export const useAnswerDelete = (cardId: Card['id'], answerId: Answer['id']) => {
    const deleteAnswer = useRecoilCallback(
        ({ set, snapshot, reset }) =>
            async () => {
                const card = await snapshot.getPromise(cardItemState(cardId));

                if (isQuestionItem(card)) {
                    const { newCard } = deleteAnswerUtil(card)(answerId);
                    set(cardItemState(cardId), newCard);

                    // Cleanup: reset answer atom
                    // https://github.com/facebookexperimental/Recoil/issues/263#issuecomment-639997717
                    reset(answerItemState(answerId));
                }
            },
        [cardId, answerId]
    );

    return {
        deleteAnswer,
    };
};

/**
 * delete answer Id in card
 */
export const deleteAnswerUtil =
    (card: QuestionItemState) =>
    (answerId: Answer['id']): { newCard: QuestionItemState } => {
        return {
            newCard: {
                ...card,
                answers: without(card.answers, [answerId]),
            },
        };
    };
