import * as React from 'react';
import type { EditorState } from 'draft-js';
import {
    Box,
    HStack,
    IconButton,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverTrigger,
    Portal,
    Tooltip,
    useDisclosure,
    VStack,
} from '@chakra-ui/react';
import { MdFormatColorReset as IconRemove } from 'react-icons/md';
import { ImTextColor as IconColor } from 'react-icons/im';

import { applyColor, getColorInstance } from './color';
import { removeEntity } from '../rte-utils';
import { Swatches } from './swatches';
import { ColorPicker } from './color-picker';

interface ColorCommandButtonProps {
    editorState: EditorState;
    setEditorState: (value: EditorState) => void;
}

const ColorCommandButtonCom: React.FC<ColorCommandButtonProps> = ({
    editorState,
    setEditorState,
}) => {
    const { onOpen, onClose, isOpen } = useDisclosure();

    const [localColor, setLocalColor] = React.useState<string>('');

    const handleColorChange = (value: string) => {
        setLocalColor(value);
        const newState = applyColor(editorState)(value);
        setEditorState(newState);
    };

    const remove = () => {
        const newState = removeEntity(editorState);
        setEditorState(newState);
    };

    const colorInstance = getColorInstance(editorState);
    const displayColor = colorInstance?.getData().color || '#303030';

    // when the popover is open
    // reset value or fill in defined values
    const handleOpen = () => {
        onOpen();

        if (colorInstance) {
            const { color } = colorInstance.getData();
            setLocalColor(color);
            return;
        }

        // default
        setLocalColor('');
    };

    return (
        <Popover isLazy isOpen={isOpen} onOpen={handleOpen} onClose={onClose}>
            <PopoverTrigger>
                <Box position="relative">
                    <IconButton
                        size="xs"
                        variant="ghost"
                        title="link"
                        aria-label="link"
                        icon={<IconColor size="16px" />}
                        mt="-10px"
                    />
                    <Box
                        position="absolute"
                        bottom="1px"
                        left="2px"
                        w="85%"
                        h="5px"
                        rounded="md"
                        border="1px solid"
                        borderColor="gray.800"
                        style={{ background: displayColor }}
                    />
                </Box>
            </PopoverTrigger>
            <Portal>
                <PopoverContent w="222px">
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverBody p="0">
                        <VStack pb="2" pt="8">
                            <HStack w="100%" justifyContent="flex-start" px="2">
                                <Tooltip label="unformat color">
                                    <IconButton
                                        size="sm"
                                        aria-label="unformat"
                                        icon={<IconRemove size="20px" />}
                                        onClick={remove}
                                    />
                                </Tooltip>
                                <ColorPicker
                                    value={localColor}
                                    onChange={handleColorChange}
                                />
                            </HStack>

                            <Swatches
                                value={localColor}
                                onChange={handleColorChange}
                            />
                        </VStack>
                    </PopoverBody>
                </PopoverContent>
            </Portal>
        </Popover>
    );
};

export const ColorCommandButton = React.memo(ColorCommandButtonCom);
