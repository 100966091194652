import { VStack } from '@chakra-ui/react';
import type { Card } from '@dmp/qqms/types';
import * as React from 'react';
import { useRecoilValue } from 'recoil';
import {
    cardAnswerIdsState,
    cardItemState,
} from '../../../state/card/card-atoms';
import { SOAnswer } from './so-answer';
import { StateObserverItem } from './state-observer-item';

interface SOCardProps {
    cardId: Card['id'];
}

export const SOCard: React.FC<SOCardProps> = React.memo(({ cardId }) => {
    const card = useRecoilValue(cardItemState(cardId));
    const answers = useRecoilValue(cardAnswerIdsState(cardId));

    return (
        <VStack w="100%" align="start" bg="blue.800">
            <StateObserverItem name={`Card: ${card.id}`} item={card} />
            <VStack
                w="100%"
                pl="2"
                align="start"
                bg="blue.700"
                overflow="hidden"
            >
                {answers.map((answerId) => (
                    <SOAnswer key={answerId} answerId={answerId} />
                ))}
            </VStack>
        </VStack>
    );
});
