import { injectCoreQuestions } from '@dmp/qqms-renderer';
import type { Survey } from '@dmp/qqms/types';
import { useMemo, useState } from 'react';
import { previewSizes } from '../../config/preview-sizes';
import { MOCK_QQMS_API } from '../../hooks/use-poll-result-interceptor';
import type { SizeName } from '../../state/ui/preview-size-types';

export const useSurveyPreview = (survey: Survey) => {
    // zoom value
    const [zoom, setZoom] = useState<number>(1);

    // store preview size name
    const [sizeName, setSizeName] = useState<SizeName>('In-Article');

    // dimension
    const { width, height } = previewSizes[sizeName];

    // sizes tab control
    const sizes = Object.values(previewSizes);
    const currentIndex = sizes.findIndex((size) => size.name === sizeName);
    const handleTabChange = (index: number) => setSizeName(sizes[index].name);

    const previewSurvey = useMemo(() => {
        return injectCoreQuestions({
            survey: { ...survey, qqmsApi: MOCK_QQMS_API },
            userTraits: '',
        });
    }, [survey]);

    return {
        currentIndex,
        handleTabChange,
        height,
        previewSizes: sizes,
        previewSurvey,
        setSizeName,
        setZoom,
        sizeName,
        width,
        zoom,
    };
};
