import { memo, useState, useEffect } from 'react';

import { isSurveyDirtyState } from '../../../state/ui/is-survey-dirty-atoms';
import { PublishSettings } from './publish-settings';
import { surveyState } from '../../../state/survey/survey-atoms';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { validateSurveyConfigSelector } from '../../../state/survey-config/survey-config-atoms';

const PublishSettingsWrapperCom = () => {
    const { errors, surveyId, surveyName, surveyQQID, isSurveyDirty } =
        usePublishSettingsWrapper();

    return (
        <PublishSettings
            surveyId={surveyId}
            surveyErrors={errors}
            surveyName={surveyName}
            surveyQQID={surveyQQID}
            saveFirst={isSurveyDirty}
        />
    );
};

export const PublishSettingsWrapper = memo(PublishSettingsWrapperCom);

export interface PublishData {
    Version: string;
    Config: string;
}

const usePublishSettingsWrapper = () => {
    const survey = useRecoilValue(surveyState);
    const isSurveyDirty = useRecoilValue(isSurveyDirtyState);
    const errorLoadable = useRecoilValueLoadable(validateSurveyConfigSelector);

    const [errors, setErrors] = useState<string[]>([]);

    useEffect(() => {
        if (errorLoadable.state !== 'hasValue') {
            return;
        }

        // detect config error in FE first
        if (errorLoadable.contents.length > 0) {
            setErrors(errorLoadable.contents);
            return;
        }
    }, [survey.id, errorLoadable]);

    return {
        surveyId: survey.id,
        surveyName: survey.name,
        surveyQQID: survey.qqid,
        errors,
        isSurveyDirty,
    };
};
