import type { Answer, Question } from '@dmp/qqms/types';
import { useState } from 'react';
import { useDispatch } from '../events/hooks';
import { classes } from '../utils';
import { ButtonComponent } from './button-component';

interface ButtonGroupMultiSelectComponentProps {
    question: Question;
}

const ACTIVE_CLASS = 'selected';

export function ButtonGroupMultiSelectComponent({
    question,
}: ButtonGroupMultiSelectComponentProps) {
    const dispatch = useDispatch();

    const answers: Answer[] = question.answers;

    const [answeredIds, setAnsweredIds] = useState<string[]>([]);

    const onToggleSelection = (answer: Answer) =>
        setAnsweredIds((current) => {
            const answerIndex = current.indexOf(answer.id);

            // exists, remove the answer. otherwise, add answer into array
            return answerIndex !== -1
                ? [
                      ...current.slice(0, answerIndex),
                      ...current.slice(answerIndex + 1),
                  ]
                : [...current, answer.id];
        });

    const buttonClassName = classes(
        'button-group',
        `button-group-${answers.length + 1}`,
        'multi-select'
    );

    return (
        <div className={buttonClassName}>
            {answers.map((a: Answer) => (
                <ButtonComponent
                    key={a.id}
                    className={
                        answeredIds.includes(a.id) ? ACTIVE_CLASS : undefined
                    }
                    id={`id${a.id}`}
                    label={a.body}
                    isMultiSelect
                    onClick={() => onToggleSelection(a)}
                />
            ))}

            <ButtonComponent
                disabled={answeredIds.length === 0}
                label="Submit"
                id="button-submit"
                isMultiSelect
                onClick={() => {
                    if (answeredIds.length === 0) {
                        return;
                    }

                    dispatch('answersSelected', {
                        question,
                        answers: answeredIds
                            .map((id) => answers.find((a) => a.id === id))
                            .filter(Boolean) as Answer[],
                    });
                }}
            />
        </div>
    );
}
