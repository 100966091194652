import { useRecoilCallback } from 'recoil';

import { collectiveStateSelector } from '../survey-config/collective-state-atoms';

/**
 * Reset all survey related atoms
 */
export const useSurveyReset = () => {
    const resetSurvey = useRecoilCallback(
        ({ reset }) =>
            () => {
                reset(collectiveStateSelector);
            },
        []
    );

    return {
        resetSurvey,
    };
};
