import { memo } from 'react';
import type { IconProps } from '@chakra-ui/react';
import { Icon } from '@chakra-ui/react';

const IconLeadInQuestionCom = (props: IconProps) => {
    return (
        <Icon viewBox="0 0 80 60" {...props}>
            <path
                fill="currentColor"
                d="M24.9 42.1h4.4v-4.4h-4.4v4.4M27 15.9c-4.9 0-8.8 4-8.8 8.8h4.4c0-2.4 1.9-4.4 4.4-4.4 2.5 0 4.4 1.9 4.4 4.4 0 4.4-6.5 3.8-6.5 10.9h4.4c0-4.9 6.5-5.4 6.5-10.9 0-4.9-3.9-8.8-8.8-8.8z"
            />
            <path
                fill="currentColor"
                d="M62.5 28.4s0-.1 0 0l-.2-.2-7.1-7.1c-.9-.9-2.4-1-3.3-.1-.9.9-.8 2.3.1 3.3l3.5 3.5H42.7c-1.2 0-2.3 1-2.3 2.2 0 1.2 1 2.3 2.3 2.3h12.8L52 35.7c-.9.9-1 2.4-.1 3.3.9.9 2.4.8 3.3-.1l7.2-7.2c1-1 1-2.4.1-3.3z"
            />
        </Icon>
    );
};

export const IconLeadInQuestion = memo(IconLeadInQuestionCom);
