import { memo } from 'react';
import type { IconProps } from '@chakra-ui/react';
import { Icon } from '@chakra-ui/react';

const IconLinearQuestionCom = (props: IconProps) => {
    return (
        <Icon viewBox="0 0 80 60" {...props}>
            <path
                fill="currentColor"
                d="M66.7 22H49.2c-1.3 0-2.3 1-2.3 2.3v4.6H33.1v-4.6c0-1.3-1-2.3-2.3-2.3H13.3C12 22 11 23 11 24.3v11.4c0 1.3 1 2.3 2.3 2.3h17.5c1.3 0 2.3-1 2.3-2.3v-4.4H47v4.4c0 1.3 1 2.3 2.3 2.3h17.5c1.3 0 2.3-1 2.3-2.3V24.3c0-1.2-1.1-2.3-2.4-2.3z"
            />
        </Icon>
    );
};

export const IconLinearQuestion = memo(IconLinearQuestionCom);
