import { Box, Button, HStack, VStack } from '@chakra-ui/react';
import * as React from 'react';
import { TrackingUrlItem } from './tracking-url-item';

interface TrackingUrlListComProps {
    urls: string[];
    onClear: () => void;
}

const TrackingUrlListCom: React.FC<TrackingUrlListComProps> = ({
    urls,
    onClear,
}) => {
    if (urls.length === 0) {
        return null;
    }

    return (
        <VStack w="100%">
            <HStack w="100%" justifyContent="space-between" spacing="5">
                <Box>Impressions</Box>
                <Button size="xs" onClick={onClear}>
                    Clear
                </Button>
            </HStack>

            {urls.map((url) => (
                <TrackingUrlItem key={url} url={url} />
            ))}
        </VStack>
    );
};

export const TrackingUrlList = React.memo(TrackingUrlListCom);
