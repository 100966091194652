import { Button, ButtonGroup, Icon, useColorMode } from '@chakra-ui/react';
import { AiOutlineZoomIn as IconZoom } from 'react-icons/ai';
import * as React from 'react';
import { useRecoilState } from 'recoil';

import { previewZoomState } from '../../../state/ui/preview-zoom-atoms';
import { usaTodayBlue } from '../../../theme/foundations/colors';

const PreviewZoomCom: React.FC = () => {
    const { colorMode } = useColorMode();

    const [previewZoom, setPreviewZoom] = useRecoilState(previewZoomState);

    const activeColor = usaTodayBlue(colorMode);

    return (
        <>
            <Icon as={IconZoom} opacity="0.5" />
            <ButtonGroup size="xs" isAttached>
                <Button
                    mr="-px"
                    onClick={() => setPreviewZoom(0.5)}
                    color={previewZoom === 0.5 ? activeColor : ''}
                >
                    50%
                </Button>
                <Button
                    mr="-px"
                    onClick={() => setPreviewZoom(0.75)}
                    color={previewZoom === 0.75 ? activeColor : ''}
                >
                    75%
                </Button>
                <Button
                    mr="-px"
                    onClick={() => setPreviewZoom(1)}
                    color={previewZoom === 1 ? activeColor : ''}
                >
                    100%
                </Button>
            </ButtonGroup>
        </>
    );
};

export const PreviewZoom = React.memo(PreviewZoomCom);
