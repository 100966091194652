import { FormControl, FormLabel, useColorMode } from '@chakra-ui/react';
import type { Card } from '@dmp/qqms/types';
import { TrimmedInput } from '@dmp/shared/components';
import * as React from 'react';
import { useRecoilState } from 'recoil';
import { cardTraxexLabelState } from '../../../../state/card/card-atoms';
import { trackingFieldStyles } from '../../../../theme/common-styles/tracking-field-styles';

interface CardTraxexLabelProps {
    cardId: Card['id'];
    label?: string;
}

const CardTraxexLabelCom: React.FC<CardTraxexLabelProps> = ({
    cardId,
    label = 'Question Tracking Label',
}) => {
    const [cardTraxexLabel, setCardTraxexLabel] = useRecoilState(
        cardTraxexLabelState(cardId)
    );

    const { colorMode } = useColorMode();
    const { label: labelStyles, input } = trackingFieldStyles(colorMode);

    return (
        <FormControl id={`card-${cardId}-tracking-label`}>
            <FormLabel {...labelStyles}>{label}</FormLabel>
            <TrimmedInput
                {...input}
                value={cardTraxexLabel || ''}
                onChange={(e) => setCardTraxexLabel(e.target.value)}
            />
        </FormControl>
    );
};

export const CardTraxexLabel = React.memo(CardTraxexLabelCom);
