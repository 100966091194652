import { Code, HStack, Tag, useColorMode, VStack } from '@chakra-ui/react';
import * as React from 'react';

import { getParams } from '../../../utils/extractTrackings';

interface TrackingUrlItemComProps {
    url: string;
}

// Only display highlighted keys
const HIGHLIGHTED_KEYS: string[] = [
    'event_id',
    'event_type',
    'qqd',
    'qqid',
    'type',
    'id',
];

const TrackingUrlItemCom: React.FC<TrackingUrlItemComProps> = ({ url }) => {
    const { colorMode } = useColorMode();

    const paramPairs = Array.from(getParams(url).entries());

    const isDark = colorMode === 'dark';
    const bg = isDark ? 'gray.700' : 'gray.50';

    return (
        <VStack
            w="100%"
            border="1px solid"
            borderColor={isDark ? 'gray.900' : 'gray.200'}
            rounded="md"
            p="4"
            bg={bg}
            spacing="4"
        >
            <Code
                w="100%"
                fontSize="xs"
                fontWeight="bold"
                bg={bg}
                color="gray.500"
                rounded="md"
                maxH="50px"
                className="scrollbar"
                overflowY="auto"
            >
                {url}
            </Code>
            <HStack
                align="start"
                w="100%"
                maxH="120px"
                overflowY="auto"
                flexWrap="wrap"
                spacing="0"
            >
                {paramPairs.map((pair) => {
                    const [key, value] = pair;
                    if (!HIGHLIGHTED_KEYS.includes(key)) {
                        return null;
                    }

                    return (
                        <Tag
                            key={key}
                            mb="1"
                            mr="1"
                            fontWeight="bold"
                            border="1px solid"
                            borderColor={isDark ? 'gray.700' : 'gray.300'}
                            bg={isDark ? 'gray.800' : 'gray.200'}
                        >
                            {key}: {value}
                        </Tag>
                    );
                })}
            </HStack>
        </VStack>
    );
};

export const TrackingUrlItem = React.memo(TrackingUrlItemCom);
