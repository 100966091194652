import { Select } from '@chakra-ui/react';
import * as React from 'react';
import { useRecoilValue } from 'recoil';

import type { CardItemState } from '../../../../state/card/card-types';
import {
    nextIndexCardIdSelector,
    surveyOtherCardIdsSelector,
} from '../../../../state/card/card-atoms';
import { OptionByCardId } from '../../../common/option-by-card-id';

interface ConnectionSelectProps {
    currentCardId: CardItemState['id'];
    resultCard?: CardItemState['id'];
    onSelect: (cardId: CardItemState['id'] | undefined) => void;
}

const ConnectionSelectCom: React.FC<ConnectionSelectProps> = ({
    currentCardId,
    resultCard,
    onSelect,
}) => {
    const nextIndexCardId = useRecoilValue(
        nextIndexCardIdSelector(currentCardId)
    );
    const otherCardIds = useRecoilValue(
        surveyOtherCardIdsSelector(currentCardId)
    );

    // Auto-connect to the next card index when result card is undefined
    const computedResultCard = resultCard || nextIndexCardId || '';

    // If next index card is selected, change `resultCard` to `undefined`
    const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = e.target.value;
        const val = value === nextIndexCardId || !value ? undefined : value;
        onSelect(val);
    };

    return (
        <Select
            size="sm"
            fontWeight="bold"
            flex="1"
            onChange={handleSelect}
            value={computedResultCard}
        >
            {otherCardIds.map((id) => (
                <OptionByCardId
                    key={id}
                    cardId={id}
                    prefix={id === nextIndexCardId ? '(Next Card)' : ''}
                />
            ))}
        </Select>
    );
};

export const ConnectionSelect = React.memo(ConnectionSelectCom);
