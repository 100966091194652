import { useCallback, useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import type { Answer, Card } from '@dmp/qqms/types';
import { useAnswerDelete } from '../../state/answer/use-answer-delete';
import { useAnswerSort } from '../../state/answer/use-answer-sort';
import { cardAnswerIdsState } from '../../state/card/card-atoms';

export const useAnswerActionButtons = (
    cardId: Card['id'],
    answerId: Answer['id']
) => {
    const cardAnswerIds = useRecoilValue(cardAnswerIdsState(cardId));

    const { deleteAnswer } = useAnswerDelete(cardId, answerId);
    const { sortAnswer } = useAnswerSort(cardId, answerId);

    const currentIndex = useMemo(
        () => cardAnswerIds.findIndex((id) => id === answerId),
        [cardAnswerIds, answerId]
    );

    const moveUp = useCallback(() => {
        sortAnswer(currentIndex - 1);
    }, [currentIndex, sortAnswer]);

    const moveDown = useCallback(() => {
        sortAnswer(currentIndex + 1);
    }, [currentIndex, sortAnswer]);

    return {
        canMoveDown: currentIndex < cardAnswerIds.length - 1,
        canMoveUp: currentIndex !== 0,
        moveDown,
        moveUp,
        remove: deleteAnswer,
    };
};
