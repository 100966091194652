import type { BoxProps } from '@chakra-ui/react';
import { Box } from '@chakra-ui/react';
import { useDroppable } from '@dnd-kit/core';
import * as React from 'react';

interface DroppableContainerProps extends BoxProps {
    id: string;
    style?: React.CSSProperties;
}
export const DroppableContainer: React.FC<DroppableContainerProps> = ({
    id,
    ...rest
}) => {
    const { setNodeRef } = useDroppable({ id });
    return <Box ref={setNodeRef} {...rest} />;
};
