import { useRecoilCallback } from 'recoil';

import type { AnswerItemState } from '../answer/answer-types';
import {
    answerItemState,
    surveyAllAnswersSelector,
} from '../answer/answer-atoms';
import type { Card } from '@dmp/qqms/types';
import type { CardItemState } from './card-types';
import { cardItemState, surveyCardsSelector } from '../card/card-atoms';
import { surveyState } from '../survey/survey-atoms';
import type { SurveyState } from '../survey/survey-types';
import { move } from '@dmp/shared/helpers';

export const useCardSort = (cardId: Card['id']) => {
    const sortCard = useRecoilCallback(
        ({ set, snapshot }) =>
            async (newIndex: number) => {
                const survey = await snapshot.getPromise(surveyState);
                const cardsInSurvey = await snapshot.getPromise(
                    surveyCardsSelector
                );
                const answersInSurvey = await snapshot.getPromise(
                    surveyAllAnswersSelector
                );

                const oldIndex = survey.cards.findIndex(
                    (cId) => cId === cardId
                );
                const { newSurvey, newCards, newAnswers } = sortCardUtil(
                    survey,
                    cardsInSurvey,
                    answersInSurvey
                )(oldIndex, newIndex);

                // add new answer id into card
                set(surveyState, newSurvey);

                // update cards with reconnected cardResults
                newCards.forEach((c) => set(cardItemState(c.id), c));

                // update answers with reconnected cardResults
                newAnswers.forEach((a) => set(answerItemState(a.id), a));
            },
        [cardId]
    );

    return {
        sortCard,
    };
};

/**
 * sort cards in survey
 */
export const sortCardUtil =
    (
        survey: SurveyState,
        cardsInSurvey: CardItemState[],
        answersInSurvey: AnswerItemState[]
    ) =>
    (oldIndex: number, newIndex: number) => {
        const cardIds = survey.cards;

        // invalid when index < 0 or > array length
        const invalid =
            oldIndex < 0 ||
            newIndex < 0 ||
            oldIndex > cardIds.length ||
            newIndex > cardIds.length;

        // do nothing when it's invalid
        if (invalid) {
            return {
                newSurvey: survey,
                newCards: cardsInSurvey,
                newAnswers: answersInSurvey,
            };
        }

        const newSurvey = {
            ...survey,
            cards: move(cardIds, oldIndex, newIndex),
        };

        // new cards order in survey has changed.
        const newCardsInSurvey = move(cardsInSurvey, oldIndex, newIndex);

        return {
            newSurvey,
            newCards: newCardsInSurvey,
            newAnswers: answersInSurvey,
        };
    };
