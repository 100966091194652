import {
    HStack,
    IconButton,
    StackDivider,
    Text,
    Tooltip,
} from '@chakra-ui/react';
import {
    VscTrash as IconTrash,
    VscTriangleUp as IconUp,
    VscTriangleDown as IconDown,
} from 'react-icons/vsc';
import * as React from 'react';

import type { Card } from '@dmp/qqms/types';
import { useCardActionBar } from './use-card-action-bar';

interface CardActionBarComProps {
    cardId: Card['id'];
}

const CardActionBarCom: React.FC<CardActionBarComProps> = ({ cardId }) => {
    const {
        canDelete,
        canMoveDown,
        canMoveUp,
        isTheFirstCard,
        moveDown,
        moveUp,
        remove,
    } = useCardActionBar(cardId);

    return (
        <>
            <HStack flex="1" px="3">
                {isTheFirstCard && (
                    <Text variant="usatoday_blue_bold">Start Question</Text>
                )}
            </HStack>

            <HStack
                divider={<StackDivider h="50%" alignSelf="center" />}
                spacing="5"
                opacity="0.4"
                sx={{
                    '.card:hover &': {
                        opacity: 1,
                    },
                }}
            >
                <HStack>
                    <Tooltip label="move card down" placement="top">
                        <IconButton
                            size="sm"
                            icon={<IconDown />}
                            onClick={moveDown}
                            isDisabled={!canMoveDown}
                            variant="ghost"
                            aria-label="move card down"
                        />
                    </Tooltip>

                    <Tooltip label="move card up" placement="top">
                        <IconButton
                            size="sm"
                            icon={<IconUp />}
                            onClick={moveUp}
                            isDisabled={!canMoveUp}
                            variant="ghost"
                            aria-label="move card up"
                        />
                    </Tooltip>
                </HStack>

                <Tooltip label="remove card" placement="top">
                    <IconButton
                        size="sm"
                        icon={<IconTrash />}
                        onClick={remove}
                        isDisabled={!canDelete}
                        variant="ghost"
                        aria-label="remove card"
                    />
                </Tooltip>
            </HStack>
        </>
    );
};

export const CardActionBar = React.memo(CardActionBarCom);
