import { jsonApiQuery, filters } from '@dmp/shared/json-api-request';

export const surveysFilter = filters({
    combined: {
        type: 'combined',
        fields: [
            'qqid',
            'name',
            'createdBy.email',
            'createdBy.firstName',
            'createdBy.lastName',
            'updatedBy.email',
            'updatedBy.firstName',
            'updatedBy.lastName',
        ],
    },
});

export const surveysQueryValidator = jsonApiQuery()
    .filter(surveysFilter)
    .include(['createdBy', 'updatedBy'])
    .sort(['name', 'createdAt', 'updatedAt'])
    .paginate();

export type SurveysQuery = typeof surveysQueryValidator._output;
