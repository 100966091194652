/************************************************
 *
 * Surveys
 *
 ************************************************/

export const SURVEYS = 'SURVEYS';
export const SURVEY = 'FETCH_SURVEY';
export const PUBLISH_SURVEY = 'PUBLISH_SURVEY';

/************************************************
 *
 * Core Questions
 *
 ************************************************/

export const CORE_QUESTIONS = 'CORE_QUESTIONS';

/************************************************
 *
 * Users
 *
 ************************************************/

export const CURRENT_USER = 'CURRENT_USER';

/************************************************
 *
 * Auth Groups
 *
 ************************************************/

export const AUTH_GROUPS = 'AUTH_GROUPS';
