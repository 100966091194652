import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import {
    VscWarning as IconWarning,
    VscCheck as IconValid,
} from 'react-icons/vsc';
import * as React from 'react';

interface ValidationBoxProps {
    errors: string[];
    successMsg?: string;
}

/**
 * Validation Box with debounced render
 */
const ValidationBoxCom: React.FC<ValidationBoxProps> = ({
    errors,
    successMsg = 'No error detected',
}) => {
    const isValid = errors.length === 0;

    return (
        <HStack w="100%" align="start">
            <Box
                p="1"
                color={isValid ? 'green.500' : 'yellow.500'}
                fontSize="md"
            >
                {isValid ? <IconValid /> : <IconWarning />}
            </Box>

            <VStack align="start" flex="1" spacing="1">
                <Text fontWeight="bold">Validation</Text>
                {isValid && <Text>{successMsg}</Text>}

                {!isValid &&
                    errors.map((er, idx) => <Text key={er + idx}>• {er}</Text>)}
            </VStack>
        </HStack>
    );
};

export const ValidationBox = React.memo(ValidationBoxCom);

export const awesomeText = [
    'awesomeeee',
    'incredible',
    'the one',
    'beautiful',
    'fantastic',
    'wonderrfun',
    'magnificent',
    'sparkling',
    'shinyyy',
    'stunning',
    'a-mei-zing',
    'remarkable',
    'egg-cellent',
    'the hero',
    'phenomenal',
    'extraordinary',
    'exceptional',
    'marvelous',
    'purrrfect',
    'legendary',
    'golden',
];
