import {
    defaultLinearAnswer,
    defaultForkedAnswer,
} from './defaultShapes/answer';
import { generateFactory } from './generate-factory';
import { generateAnswerId } from './generate-ids';

export const generateLinearAnswer = generateFactory(defaultLinearAnswer, () =>
    generateAnswerId('linearAnswer')
);

export const generateForkedAnswer = generateFactory(defaultForkedAnswer, () =>
    generateAnswerId('forkedAnswer')
);
