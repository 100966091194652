import { ApiUriBuilder, fatch } from '@dmp/shared/client-utils';
import type {
    AuthGroupCreateAttributes,
    AuthGroupUpdateAttributes,
    CoreQuestionCreateAttributes,
    CoreQuestionUpdateAttributes,
    CoreQuestionsUpdateAttributes,
    SurveyCreateAttributes,
    SurveyDuplicateAttributes,
    SurveyUpdateAttributes,
} from './api-requests';
import type {
    AssetResource,
    AuthGroupListResponse,
    AuthGroupResponse,
    CoreQuestionListResponse,
    CoreQuestionResponse,
    SurveyItemResponse,
    SurveyListResponse,
    SurveyLiveProofResponse,
    SurveyPublishResponse,
    UserItemResponse,
} from './api-responses';
import type { SurveysQuery } from './api-validators';

const apiUriBuilder = new ApiUriBuilder();

// ------------------------------------------------
// Surveys
// ------------------------------------------------

export const fetchSurveys = (
    searchKeyword: string,
    pageNumber: number,
    pageSize: number
) =>
    fatch<SurveyListResponse>(
        apiUriBuilder.resolve<SurveysQuery>('surveys', {
            filter: {
                combined: searchKeyword,
            },
            page: {
                number: pageNumber,
                size: pageSize,
            },
            sort: [
                {
                    field: 'updatedAt',
                    direction: 'DESC',
                },
            ],
        })
    );

export const fetchSurvey = (surveyId: string) =>
    fatch<SurveyItemResponse>(apiUriBuilder.resolve(`surveys/${surveyId}`));

export const createSurvey = (payload: SurveyCreateAttributes) =>
    fatch<SurveyItemResponse>(apiUriBuilder.resolve('surveys'), {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({ ...payload, name: payload.name.trim() }),
    });

export const duplicateSurvey = (
    surveyId: string,
    payload: SurveyDuplicateAttributes
) =>
    fatch<SurveyItemResponse>(
        apiUriBuilder.resolve(`surveys/${surveyId}/duplicate`),
        {
            headers: { 'Content-Type': 'application/json' },
            method: 'POST',
            body: JSON.stringify({
                newSurveyName: payload.newSurveyName.trim(),
            }),
        }
    );

export const patchSurvey = (
    surveyId: string,
    payload: SurveyUpdateAttributes
) =>
    fatch(apiUriBuilder.resolve(`surveys/${surveyId}`), {
        headers: { 'Content-Type': 'application/json' },
        method: 'PATCH',
        body: JSON.stringify(payload),
    });

export const publishSurvey = (surveyId: string) =>
    fatch<SurveyPublishResponse>(
        apiUriBuilder.resolve(`surveys/${surveyId}/publish`)
    );

export const publishSurveyProof = (surveyId: string) =>
    fatch<SurveyLiveProofResponse>(
        apiUriBuilder.resolve(`surveys/${surveyId}/live-proof`)
    );

// ------------------------------------------------
// Users
// ------------------------------------------------

export const fetchCurrentUser = () =>
    fatch<UserItemResponse>(apiUriBuilder.resolve('users/current'));

// ------------------------------------------------
// Core Questions
// ------------------------------------------------

export const fetchCoreQuestionList = () =>
    fatch<CoreQuestionListResponse>(
        apiUriBuilder.resolve(`core-questions`, {
            sort: [{ field: 'updatedAt', direction: 'DESC' }],
        })
    );

export const patchCoreQuestion = (
    coreQuestionId: string,
    payload: CoreQuestionUpdateAttributes
) =>
    fatch<CoreQuestionResponse>(
        apiUriBuilder.resolve(`core-questions/${coreQuestionId}`),
        {
            headers: { 'Content-Type': 'application/json' },
            method: 'PATCH',
            body: JSON.stringify(payload),
        }
    );

/**
 * Update multiple Core Questions.
 */
export const patchCoreQuestions = (payload: CoreQuestionsUpdateAttributes) =>
    fatch<CoreQuestionListResponse>(apiUriBuilder.resolve('core-questions'), {
        headers: { 'Content-Type': 'application/json' },
        method: 'PATCH',
        body: JSON.stringify(payload),
    });

export const postCoreQuestion = (payload: CoreQuestionCreateAttributes) =>
    fatch<CoreQuestionResponse>(apiUriBuilder.resolve('core-questions'), {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify(payload),
    });

// ------------------------------------------------
// Upload file/images
// ------------------------------------------------

export const uploadImage = (surveyId: string, file: File) => {
    const payload = new FormData();
    payload.append('file', file);

    return fatch<AssetResource>(
        apiUriBuilder.resolve(`surveys/${surveyId}/sponsor-logo`),
        {
            method: 'PATCH',
            body: payload,
        }
    );
};

// ------------------------------------------------
// Auth Groups
// ------------------------------------------------

export const fetchAuthGroups = () =>
    fatch<AuthGroupListResponse>(
        apiUriBuilder.resolve('auth-groups', {
            sort: [
                {
                    field: 'oktaAuthGroup',
                    direction: 'ASC',
                },
            ],
        })
    );

export const createAuthGroup = (payload: AuthGroupCreateAttributes) =>
    fatch<AuthGroupResponse>(apiUriBuilder.resolve('auth-groups'), {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify(payload),
    });

export const updateAuthGroup = (
    authGroupId: string,
    payload: AuthGroupUpdateAttributes
) =>
    fatch<AuthGroupResponse>(
        apiUriBuilder.resolve(`auth-groups/${authGroupId}`),
        {
            headers: { 'Content-Type': 'application/json' },
            method: 'PATCH',
            body: JSON.stringify(payload),
        }
    );

export const deleteAuthGroup = (authGroupId: string) =>
    fatch<void>(apiUriBuilder.resolve(`auth-groups/${authGroupId}`), {
        method: 'DELETE',
    });
