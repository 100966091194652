import type { Survey } from '@dmp/qqms/types';
import type { ReactNode } from 'react';
import { createContext, useRef } from 'react';
import { useSurveyState_INTERNAL } from '../state/internal/use-survey-state';
import { usePollResults_INTERNAL } from '../state/internal/use-poll-results';

interface SurveyStateProviderProps {
    children: ReactNode;
    survey: Survey;

    // Will this Survey respond to a User answering a Question?
    // Defaults to `true`. Can be set to `false` to show a mock in the editor.
    isNonInteractive?: boolean;
}

export function SurveyProvider(props: SurveyStateProviderProps) {
    const eventTarget = useRef(Math.random().toString(36).slice(2, 10));

    return (
        <EventTargetContext.Provider value={eventTarget.current}>
            <PollResultProvider survey={props.survey}>
                <SurveyStateProvider {...props} />
            </PollResultProvider>
        </EventTargetContext.Provider>
    );
}

/************************************************
 *
 * Event Target
 *
 ************************************************/

// A simple string identifier to keep events in one Survey separated from another.
export const EventTargetContext = createContext('global');

/************************************************
 *
 * Survey/Card State
 *
 ************************************************/

export const SurveyStateContext = createContext<
    ReturnType<typeof useSurveyState_INTERNAL> | undefined
>(undefined);

function SurveyStateProvider({
    children,
    isNonInteractive,
    survey,
}: SurveyStateProviderProps) {
    const surveyState = useSurveyState_INTERNAL({
        isNonInteractive,
        survey,
    });

    return (
        <SurveyStateContext.Provider value={surveyState}>
            {children}
        </SurveyStateContext.Provider>
    );
}

/************************************************
 *
 * Poll Results
 *
 ************************************************/

interface PollResultProviderProps {
    survey: Survey;
    children: ReactNode;
}

export const PollResultContext = createContext<
    ReturnType<typeof usePollResults_INTERNAL> | undefined
>(undefined);

function PollResultProvider({ children, survey }: PollResultProviderProps) {
    const value = usePollResults_INTERNAL(survey);

    return (
        <PollResultContext.Provider value={value}>
            {children}
        </PollResultContext.Provider>
    );
}
