import type {
    CardItemState,
    DynamicCoreQuestionItemState,
    ForkedQuestionItemState,
    LinearQuestionItemState,
    QuestionItemState,
    ThankYouCardItemState,
    PollResultCardItemState,
} from './card-types';

export const isDynamicCoreQuestionItem = (
    card: CardItemState
): card is DynamicCoreQuestionItemState => card.type === 'dynamicCoreQuestion';

export const isForkedQuestionItem = (
    card: CardItemState
): card is ForkedQuestionItemState => card.type === 'forkedQuestion';

export const isLinearQuestionItem = (
    card: CardItemState
): card is LinearQuestionItemState => card.type === 'linearQuestion';

export const isQuestionItem = (
    card: CardItemState
): card is QuestionItemState =>
    ['forkedQuestion', 'linearQuestion'].includes(card.type);

export const isThankYouCardItem = (
    card: CardItemState
): card is ThankYouCardItemState => card.type === 'thankYouCard';

export const isPollResultCardItem = (
    card: CardItemState
): card is PollResultCardItemState => card.type === 'pollResultCard';

export const isCardWithBody = (
    card: CardItemState
): card is LinearQuestionItemState | ThankYouCardItemState =>
    isQuestionItem(card) || isThankYouCardItem(card);

export const isQuestionCardType = (cardType: CardItemState['type']) =>
    ['coreQuestion', 'forkedQuestion', 'linearQuestion'].includes(cardType);
