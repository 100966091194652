import {
    Container,
    Divider,
    HStack,
    Heading,
    VStack,
    useColorModeValue,
} from '@chakra-ui/react';
import { AdminNav } from '../common/admin-nav';
import { authGroupIdsSelector } from '../../state/auth-group/auth-group-list-atoms';
import { AuthGroupItem } from './auth-group-item';
import { LogoBar } from '../common/logo-bar';
import { memo, useEffect } from 'react';
import { useAuthGroupListFetch } from '../../state/auth-group/use-auth-group-list-fetch';
import { useRecoilValue } from 'recoil';
import AuthGate from '../auth-gate/auth-gate';
import PermissionGate from '../permission-gate/permission-gate';
import { AuthGroupCreate } from './auth-group-create';

const AuthGroupViewCom = () => {
    const { fetchAuthGroupList } = useAuthGroupListFetch();
    const authGroupIds = useRecoilValue(authGroupIdsSelector);

    useEffect(() => {
        fetchAuthGroupList();
        // no dependency for it performs once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const bg = useColorModeValue('whiteAlpha.600', 'whiteAlpha.100');
    const borderColor = useColorModeValue('blackAlpha.100', 'whiteAlpha.200');

    return (
        <AuthGate>
            <PermissionGate permission="coreQuestions.manage">
                <VStack
                    align="stretch"
                    h="100%"
                    spacing="0"
                    divider={<Divider />}
                >
                    <LogoBar>
                        <AdminNav />
                    </LogoBar>
                    <HStack
                        flex="1"
                        align="start"
                        overflow="hidden"
                        spacing="0"
                        py="10"
                        px="2"
                    >
                        <Container
                            maxW="container.md"
                            border="1px solid"
                            rounded="md"
                            p="0"
                            bg={bg}
                            borderColor={borderColor}
                        >
                            <Heading as="h2" fontSize="xl" p="4" px="8">
                                Manage Authentication Groups
                            </Heading>

                            <Divider orientation="horizontal" />

                            <VStack align="stretch" px="8" py="8" spacing="8">
                                <VStack align="stretch">
                                    <Heading as="h3" fontSize="sm">
                                        Create Authentication Group
                                    </Heading>

                                    <AuthGroupCreate />
                                </VStack>

                                <VStack align="stretch" spacing="4">
                                    <Heading as="h3" fontSize="sm">
                                        Group List
                                    </Heading>

                                    <VStack align="stretch" spacing="0">
                                        {authGroupIds.map((authGroupId) => (
                                            <AuthGroupItem
                                                key={authGroupId}
                                                authGroupId={authGroupId}
                                            />
                                        ))}
                                    </VStack>
                                </VStack>
                            </VStack>
                        </Container>
                    </HStack>
                </VStack>
            </PermissionGate>
        </AuthGate>
    );
};

export const AuthGroupView = memo(AuthGroupViewCom);
