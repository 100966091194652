import { Button, Tooltip } from '@chakra-ui/react';
import { memo } from 'react';
import { VscBrowser as IconLiveProof } from 'react-icons/vsc';
import { useParams } from 'react-router';
import { useLiveProof } from '../../../hooks/use-live-proof';
import { useMainButtonStyles } from '../use-main-button-styles';

const SurveyLiveProofButtonCom = () => {
    const { surveyId } = useParams<{ surveyId: string }>();
    const liveProof = useLiveProof(surveyId || 'missing-survey-id');
    const { buttonStyles } = useMainButtonStyles();

    return (
        <Tooltip
            fontSize="xs"
            label="preview survey (Ctrl/Cmd+P, Ctrl/Cmd+Enter)"
        >
            <Button
                {...buttonStyles}
                onClick={liveProof.initiate}
                isLoading={liveProof.isProofing}
                loadingText="Proofing"
                leftIcon={<IconLiveProof />}
            >
                Live Proof
            </Button>
        </Tooltip>
    );
};

export const SurveyLiveProofButton = memo(SurveyLiveProofButtonCom);
