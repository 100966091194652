import {
    HStack,
    Icon,
    Tag,
    TagLabel,
    TagLeftIcon,
    Text,
    useColorMode,
    VStack,
} from '@chakra-ui/react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import * as React from 'react';
import {
    VscEyeClosed as IconDisabled,
    VscGripper as IconDrag,
} from 'react-icons/vsc';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { editingCoreQuestionIdState } from '../../state/core-question/core-question-edit-form-atoms';
import { coreQuestionResourceSelector } from '../../state/core-question/core-question-list-atoms';
import { switchEditingCoreQuestionSelector } from '../../state/core-question/switch-editing-core-question-selector';
import { useWarnSwitchingForm } from '../../state/core-question/use-warn-switching-form';

interface CoreQuestionItemProps {
    coreQuestionId: string;
    filterKeyword?: string;
}

const CoreQuestionItemCom: React.FC<CoreQuestionItemProps> = ({
    coreQuestionId,
    filterKeyword = '',
}) => {
    const { colorMode } = useColorMode();

    const { attributes, listeners, setNodeRef, transform, transition } =
        useSortable({ id: coreQuestionId });

    const { title, switchEditingQuestionId, isActive, isSelected, isVisible } =
        useCoreQuestionItem(coreQuestionId, filterKeyword);

    const isDark = colorMode === 'dark';
    const bgActive = isDark ? 'gray.700' : 'gray.50';
    const bgHover = isDark ? 'gray.800' : 'gray.100';

    return (
        <VStack
            ref={setNodeRef}
            w="100%"
            justifyContent="center"
            h="10"
            p="2"
            rounded="md"
            cursor="pointer"
            transformOrigin="top"
            bg={isSelected ? bgActive : 'transparent'}
            _hover={{ bg: isSelected ? bgActive : bgHover }}
            sx={{
                transform: transform
                    ? CSS.Transform.toString(transform)
                    : isVisible
                    ? ''
                    : 'scaleY(0)',
                height: isVisible ? '' : '0px',
                transition:
                    transition ??
                    'transform 0.2s ease-out, height 0.2s ease-out',
                position: isVisible ? 'relative' : 'absolute',
                bottom: isVisible ? 'unset' : '0',
                cursor: isSelected ? 'default' : 'pointer',
            }}
        >
            <HStack
                align="start"
                w="100%"
                title={title}
                opacity={isActive ? '1' : '0.5'}
            >
                <Icon
                    as={IconDrag}
                    opacity="0.2"
                    _hover={{ opacity: '1' }}
                    cursor="grab"
                    {...attributes}
                    {...listeners}
                />

                <Text
                    flex="1"
                    fontSize="sm"
                    fontWeight="bold"
                    noOfLines={1}
                    onClick={switchEditingQuestionId}
                >
                    {title}
                </Text>

                {!isActive && (
                    <Tag
                        size="sm"
                        bg="transparent"
                        border="1px solid rgba(255,255,255,0.2)"
                    >
                        <TagLeftIcon boxSize="12px" as={IconDisabled} />
                        <TagLabel>Disabled</TagLabel>
                    </Tag>
                )}
            </HStack>
        </VStack>
    );
};

export const CoreQuestionItem = React.memo(CoreQuestionItemCom);

const useCoreQuestionItem = (coreQuestionId: string, filterKeyword: string) => {
    const editingCQId = useRecoilValue(editingCoreQuestionIdState);
    const switchEditingCoreQuestion = useSetRecoilState(
        switchEditingCoreQuestionSelector
    );

    const coreQuestion = useRecoilValue(
        coreQuestionResourceSelector(coreQuestionId)
    );

    const { checkIsOkToSwitch } = useWarnSwitchingForm();

    const isSelected = coreQuestionId === editingCQId;
    const isVisible = checkIsVisible(
        filterKeyword,
        coreQuestion?.attributes.config.body
    );

    const switchEditingQuestionId = async () => {
        if (await checkIsOkToSwitch(coreQuestionId)) {
            switchEditingCoreQuestion(coreQuestionId);
        }
    };

    return {
        isActive: coreQuestion?.attributes.isActive,
        isSelected,
        isVisible,
        switchEditingQuestionId,
        title: coreQuestion?.attributes.config.body || '[no question text]',
    };
};

const checkIsVisible = (keyword: string, questionBody: string = '') => {
    if (keyword === '') {
        return true;
    }

    const re = new RegExp(keyword, 'ig');
    return re.test(questionBody);
};
