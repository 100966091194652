import type { Size, SizeName } from '../state/ui/preview-size-types';

export const previewSizes: Record<SizeName, Size> = {
    'In-Article': {
        name: 'In-Article',
        width: 660,
        height: 371,
    },
    MobileView: {
        name: 'MobileView',
        width: 375,
        height: 450,
    },
};
