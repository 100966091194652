import { Center, Text } from '@chakra-ui/react';
import { fetchCurrentUser } from '@dmp/qqms/data-access';
import { RequestError } from '@dmp/shared/client-utils';
import { useQuery } from '@tanstack/react-query';
import type { ReactElement } from 'react';
import { useEffect, useState } from 'react';
import { CURRENT_USER } from '../../config/query-keys';
import { useCurrentUser } from '../../hooks/use-current-user';

interface AuthGateProps {
    children?: React.ReactNode;

    // Disable querying the API. Used mostly for testing.
    disableFetch?: boolean;

    // How long until we show the "Authenticating" message? Helps prevent
    // the message from showing for a unnecessarily short time.
    loadingMessageDelay?: number;
}

type AuthState = 'authenticated' | 'authenticating' | 'redirecting';

export const AuthGate = ({
    children,
    disableFetch,
    loadingMessageDelay = 2000,
}: AuthGateProps) => {
    const [currentUser, setCurrentUser] = useCurrentUser();

    const [state, setState] = useState<AuthState>(
        currentUser ? 'authenticated' : 'authenticating'
    );

    /**
     * Keep track of whether we have waited before showing user any messaging.
     */
    const [hasWaited, setHasWaited] = useState(
        loadingMessageDelay === 0 ? true : false
    );

    useEffect(() => {
        if (state === 'authenticated' || loadingMessageDelay === 0) {
            return;
        }

        const timeout = setTimeout(
            () => setHasWaited(true),
            loadingMessageDelay
        );

        return () => clearTimeout(timeout);
    }, [loadingMessageDelay, state]);

    /**
     * Redirect if User is not authenticated.
     */
    useEffect(() => {
        if (state === 'redirecting') {
            window.location.assign('/oauth/login');
        }
    }, [state]);

    useQuery([CURRENT_USER], fetchCurrentUser, {
        enabled: state === 'authenticating' && !disableFetch,

        // Retry on Proxy error. Most likely, we are in a
        // dev environment and waiting on the API to start.
        retry: (_retryCount, error) =>
            error instanceof RequestError && error.response.status === 504,
        refetchOnWindowFocus: false,
        onSuccess: ({ data }) => {
            setState('authenticated');
            setCurrentUser({ id: data.id, ...data.attributes });
        },
        onError: () => setState('redirecting'),
    });

    switch (state) {
        case 'authenticated':
            return children as ReactElement;

        case 'redirecting':
        case 'authenticating':
            return hasWaited ? (
                <Center height="100vh">
                    <Text fontSize="3xl">Authenticating</Text>
                </Center>
            ) : null;
    }
};

export default AuthGate;
