import { useRecoilValueLoadable } from 'recoil';
import * as React from 'react';

import { validateSurveyConfigSelector } from '../../../state/survey-config/survey-config-atoms';
import { ValidationBox } from '../../common/validation-box';

const SurveyWarningCom: React.FC = () => {
    const errorLoadable = useRecoilValueLoadable(validateSurveyConfigSelector);

    if (errorLoadable.state !== 'hasValue') {
        return null;
    }

    return <ValidationBox errors={errorLoadable.contents} />;
};

export const SurveyWarning = React.memo(SurveyWarningCom);
