import type { InputGroupProps, ThemingProps } from '@chakra-ui/react';
import { InputGroup, InputLeftAddon, useColorMode } from '@chakra-ui/react';
import * as React from 'react';
import { TrimmedInput } from '@dmp/shared/components';

export interface InputPillProps extends InputGroupProps {
    label: React.ReactNode;
    value?: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    size?: ThemingProps['size'];
    rounded?: ThemingProps['size'];
    placeholder?: string;
}

const InputPillCom: React.FC<InputPillProps> = ({
    label,
    onChange,
    value = '',
    size = 'sm',
    rounded = 'md',
    placeholder = '',
    ...inputGroupProps
}) => {
    const { colorMode } = useColorMode();

    const borderColor = colorMode === 'dark' ? 'gray.600' : 'gray.200';
    const bgColor =
        colorMode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'gray.100';
    const textColor = colorMode === 'dark' ? 'gray.400' : 'gray.600';

    return (
        <InputGroup size={size} {...inputGroupProps}>
            <InputLeftAddon
                rounded={rounded}
                pointerEvents="none"
                px="2"
                fontWeight="bold"
                fontSize="xs"
                letterSpacing="-0.5"
                color={textColor}
                background={bgColor}
                borderColor={borderColor}
                borderWidth="1px"
            >
                {label}
            </InputLeftAddon>
            <TrimmedInput
                fontWeight="bold"
                borderColor={borderColor}
                roundedBottomRight={rounded}
                roundedTopRight={rounded}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
            />
        </InputGroup>
    );
};

export const InputPill = InputPillCom;
export default InputPill;
