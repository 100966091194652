import type { ColorMode } from '@chakra-ui/react';

export default {
    brand: {
        100: '#1665cf', // USAToday dark blue
        200: '#74c5f9', // lighter blue
        300: '#303030', // USAToday text gray
        400: '#049bff', // USAToday standard blue
    },
};

export const usaTodayBlue = (colorMode: ColorMode) =>
    colorMode === 'dark' ? 'brand.200' : 'brand.100';

export const backgroundColor = (colorMode: ColorMode) =>
    colorMode === 'dark' ? 'gray.800' : 'gray.100';
