import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Tooltip,
} from '@chakra-ui/react';
import { memo } from 'react';
import { AiOutlineCloudDownload as IconDownload } from 'react-icons/ai';
import { PublishSettingsWrapper } from './publish-settings-wrapper';
import { useRecoilState } from 'recoil';

import { publishShortcutState } from '../../../state/ui/shortcut-atoms';
import { useMainButtonStyles } from '../use-main-button-styles';

const SurveyPublishButtonCom = () => {
    const [isOpen, setIsOpen] = useRecoilState(publishShortcutState);
    const onOpen = () => setIsOpen(true);
    const onClose = () => setIsOpen(false);

    const { buttonStyles } = useMainButtonStyles();

    return (
        <>
            <Tooltip
                fontSize="xs"
                label="save and generate settings file (Ctrl/Cmd+D)"
            >
                <Button
                    {...buttonStyles}
                    leftIcon={<IconDownload fontSize="lg" size="20px" />}
                    onClick={onOpen}
                >
                    <u>D</u>ownload Settings
                </Button>
            </Tooltip>

            {isOpen && (
                <Modal isOpen={isOpen} onClose={onClose} isCentered>
                    <ModalOverlay />
                    <ModalContent w="90%" minW="650px" maxW="90%">
                        <ModalHeader>Survey Publish</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <PublishSettingsWrapper />
                        </ModalBody>
                    </ModalContent>
                </Modal>
            )}
        </>
    );
};

export const SurveyPublishButton = memo(SurveyPublishButtonCom);
