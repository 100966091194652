import {
    defaultCoreQuestion,
    defaultCoreQuestionResource,
    defaultDynamicCoreQuestion,
    defaultForkedQuestion,
    defaultLinearQuestion,
} from './defaultShapes/question';
import { generateFactory } from './generate-factory';
import { generateCoreQuestionId, generateQuestionId } from './generate-ids';

export const generateLinearQuestion = generateFactory(
    defaultLinearQuestion,
    generateQuestionId
);

export const generateForkedQuestion = generateFactory(
    defaultForkedQuestion,
    generateQuestionId
);

export const generateDynamicCoreQuestion = generateFactory(
    defaultDynamicCoreQuestion,
    generateCoreQuestionId
);

export const generateCoreQuestion = generateFactory(
    defaultCoreQuestion,
    generateCoreQuestionId
);

export const generateCoreQuestionResource = generateFactory(
    defaultCoreQuestionResource
);
