import { atom } from 'recoil';

/****************************************************************
 * Atom states to record ui states (modal/alert box/...) for shortcut keys activation.
 ****************************************************************/

export const previewShortcutState = atom<boolean>({
    key: 'ui_previewShortcutState',
    default: false,
});

export const publishShortcutState = atom<boolean>({
    key: 'ui_publishShortcutState',
    default: false,
});
