import { useEventListener, useSurveyState } from '@dmp/qqms-renderer';
import { useState } from 'react';
import {
    getAnswerTrackingUrls,
    getCardEnterTrackingUrls,
} from '../../common/survey-preview/get-answer-tracking-urls';
import { TrackingUrlList } from '../../common/tracking-url-list';

export function SurveyTrackingUrls() {
    const { survey } = useSurveyState();

    // record fired urls
    const [urls, setUrls] = useState<string[]>([]);

    useEventListener('answersSelected', ({ detail }) => {
        if (survey) {
            const urls = getAnswerTrackingUrls({ ...detail, survey });
            setUrls(urls);
        }
    });

    useEventListener('cardEntered', ({ detail }) => {
        if (survey) {
            const urls = getCardEnterTrackingUrls({
                ...detail,
                survey,
            });

            setUrls(urls);
        }
    });

    return <TrackingUrlList urls={urls} onClear={() => setUrls([])} />;
}
