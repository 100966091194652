import { AboutComponent } from './about-component';
import { PaginationComponent } from './pagination-component';
import { SponsorComponent } from './sponsor-component';

export function FooterComponent() {
    return (
        <div id="footer">
            <SponsorComponent />
            <PaginationComponent />
            <AboutComponent />
        </div>
    );
}
