import { duplicateSurvey } from '@dmp/qqms/data-access';
import { useErrorToaster, useSuccessToaster } from '@dmp/shared/components';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { routeSurvey } from '../../config/routeUrls';

export const useSurveyDuplicate = () => {
    const navigate = useNavigate();

    const { handleError } = useErrorToaster();
    const { handleSuccess } = useSuccessToaster();

    const duplicateSurveyCallback = useCallback(
        (surveyId: string, newSurveyName: string) => {
            duplicateSurvey(surveyId, { newSurveyName })
                .then((resp) => {
                    handleSuccess('Survey duplicated successfully.');

                    navigate(routeSurvey(resp.data.id));
                })
                .catch(handleError);
        },
        [handleError, handleSuccess, navigate]
    );

    return {
        duplicateSurvey: duplicateSurveyCallback,
    };
};
