import type { Survey } from '@dmp/qqms/types';
import { useDebounce } from '@react-hook/debounce';
import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { MOCK_QQMS_API } from '../../../hooks/use-poll-result-interceptor';
import { cardToSurveyPreviewSelector } from '../../../state/card/card-atoms';

export function useCardToSurveyPreview(cardId: string): Survey | undefined {
    const survey = useRecoilValue(cardToSurveyPreviewSelector(cardId));

    const [debouncedSurvey, setDebouncedSurvey] = useDebounce<
        Survey | undefined
    >(undefined, 300, false);

    useEffect(() => {
        setDebouncedSurvey({
            ...survey,
            qqmsApi: MOCK_QQMS_API,
        });
    }, [setDebouncedSurvey, survey]);

    return debouncedSurvey;
}
