/**
 * Basic Textarea with cursor jump prevention
 */
import type { TextareaProps } from '@chakra-ui/react';
import { Textarea as ChakraTextarea } from '@chakra-ui/react';
import * as React from 'react';

import { useIntermediateInput } from '../../hooks/use-immediate-input';

const TextareaCom: React.FC<TextareaProps> = (textareaProps) => {
    const { value, onChange, ...rest } = textareaProps;

    const { stateValue, handleChange } = useIntermediateInput<TextareaProps>(
        value,
        onChange
    );

    return (
        <ChakraTextarea {...rest} value={stateValue} onChange={handleChange} />
    );
};
export const Textarea = React.memo(TextareaCom);
