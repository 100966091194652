import type { CoreQuestionResource } from '@dmp/qqms/data-access';
import type { LinearAnswerItemState } from '../state/answer/answer-types';
import type { LinearQuestionItemState } from '../state/card/card-types';
import { compactToIds } from './survey-resource-to-state';

/****************************************************************
 * Transform/Flatten Core Question Config List to Recoil State
 ****************************************************************/
export const coreQuestionResourceListToState = (
    coreQuestions: CoreQuestionResource[]
) => {
    return {
        questions: transformToCardsState(coreQuestions),
        answers: transformToAnswersState(coreQuestions),
    };
};

/**
 * Collect & transform questions resource to array of questions
 */
export const transformToCardsState = (
    coreQuestions: CoreQuestionResource[]
): Array<LinearQuestionItemState> => {
    return coreQuestions.map((c) => ({
        ...c.attributes.config,
        type: 'linearQuestion',
        id: c.id,
        answers: compactToIds(c.attributes.config.answers),
    }));
};

/**
 * Collect & transform answers resource to array of answers
 */
export const transformToAnswersState = (
    coreQuestions: CoreQuestionResource[]
): Array<LinearAnswerItemState> =>
    coreQuestions.map((c) => c.attributes.config.answers).flat();
