import type { ComponentSingleStyleConfig } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';
import { usaTodayBlue } from '../foundations/colors';

const themedButton: ComponentSingleStyleConfig = {
    variants: {
        usatoday_blue: (props) => ({
            backgroundColor: 'transparent',
            color: usaTodayBlue(props.colorMode),
            _hover: {
                backgroundColor: mode('gray.100', 'gray.700')(props),
            },
        }),
        usatoday_blue_full: (props) => ({
            background: usaTodayBlue(props.colorMode),
            color: mode('white', 'gray.700')(props),
            _hover: {
                background: 'blue.500',
            },
        }),
        usatoday_outline: (props) => ({
            background: 'transparent',
            borderWidth: '1px',
            color: mode('gray.600', 'gray.400')(props),
            borderColor: 'gray.500',
            _hover: {
                background: usaTodayBlue(props.colorMode),
                borderColor: usaTodayBlue(props.colorMode),
                color: mode('gray.100', 'gray.800')(props),
                textDecoration: 'none',
            },
        }),
    },
};

export default themedButton;
