import { z } from 'zod';

// Zod Unions do not currently output the `received` value,
// so lets create a better error message.
const errorMap: z.ZodErrorMap = (issue, ctx) => {
    if (issue.code === z.ZodIssueCode.invalid_union) {
        return { message: `Expected users or managers. Received ${ctx.data}.` };
    }

    return z.defaultErrorMap(issue, ctx);
};

export const permissionGroups = ['users', 'managers'] as const;

export const permissionGroupValidator = z.enum(permissionGroups, { errorMap });

export type PermissionGroup = (typeof permissionGroups)[number];

export type Permission = 'coreQuestions.manage' | 'aboutCopy.customize';

export type PermissionMap = Record<PermissionGroup, Permission[]>;

export const permissionMap: PermissionMap = {
    users: [],
    managers: ['aboutCopy.customize', 'coreQuestions.manage'],
};

export const permissionGroupCan = (
    permissionGroup: PermissionGroup,
    permission: Permission
) => permissionMap[permissionGroup].includes(permission);
