import { useColorMode, Link } from '@chakra-ui/react';
import * as React from 'react';

import colors from '../../theme/foundations/colors';

interface QQMSLogoProps {
    url?: string;
}

const QQMSLogoCom: React.FC<QQMSLogoProps> = ({ url = '/' }) => {
    const { colorMode } = useColorMode();

    const textColor = colorMode === 'dark' ? 'white' : '#404040';
    const brandColor =
        colorMode === 'dark' ? colors.brand[400] : colors.brand[100];

    return (
        <Link href={url}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 455.7 131.3">
                <path
                    d="M118.3 104c7.9-10.8 12.6-24.1 12.6-38.5C130.8 29.3 101.6 0 65.4 0S0 29.3 0 65.4s29.3 65.4 65.4 65.4c9.2 0 17.9-1.9 25.9-5.3 11.8 7.1 27 7.7 39.7.3h.1L118.3 104z"
                    fill={brandColor}
                />
                <path
                    d="M72.8 80.8v-11c7.4-1 12.3-4.8 14.9-7.7 4.2-4.5 6.5-10.4 6.5-16.6 0-6.3-2.1-12.9-7.3-18-4.4-4.3-11.3-7.8-21.5-7.8-11.9 0-18.3 4.6-21.5 8-3.7 3.8-7 10-7.2 18h20.1c-.1-2.6.8-5.7 2.2-7.3 1.2-1.2 3.3-2.5 6.7-2.5 1.8 0 4.3.4 6.3 2.5 1.6 1.8 2.5 4.2 2.5 6.7 0 3.4-1.4 6.7-3.9 9-3.9 3.4-11 4-16.7 2.7v24h18.9zm-20.9 18c0 6.6 5.3 11.9 11.9 11.9 6.6 0 11.9-5.3 11.9-11.9 0-6.6-5.3-11.9-11.9-11.9-6.5-.1-11.8 5.1-11.9 11.6v.3z"
                    fill="#fff"
                />
                <path
                    d="M136.3 111.7c0 3.8.7 8.6 4.7 12.9 5.8 6.3 14.4 6.8 19 6.8 5.7 0 13.1-1 18.3-6.4 4.6-4.8 5.4-10.6 5.4-14.6V79h-18.5v29.7c0 1.3 0 4-1.4 5.4-.7.7-1.8 1.2-3.8 1.2-1.5 0-2.7-.3-3.7-1.4-1.5-1.5-1.6-3.7-1.6-5.2V79h-18.4v32.7zM227 130.2v-14.1h-16.4v-4.9h14.5V97.8h-14.5V93h16V79H192v51.2h35zM270.9 82.3c-6.2-3.8-11.5-4.5-15.6-4.5-8.1 0-13.9 2.6-17.8 6.6-3.4 3.4-5 7.4-5 12.2 0 3.7 1.1 6.8 3.7 9.4 2.8 2.9 6 3.8 8.9 4.4l4.5.8c1.9.4 2.9.6 3.5 1.2.4.5.6 1.1.6 1.8.1.9-.3 1.7-1 2.3-.4.3-1.5.9-4 .9-4.5 0-8.4-2.3-10.4-3.8l-2-1.5-5.6 14.4 1.7.7c4.4 1.8 9.6 4 17.4 4 9.6 0 15.1-3.5 18-6.5 3.5-3.6 4.6-7.7 4.6-12.1 0-3.1-.7-7-3.9-10.1-1.5-1.5-4.2-3.4-8.8-4.4l-5.9-1.3c-2.8-.6-3-1.6-3-2.7 0-.7.3-1.4.9-1.8 1-.8 2.5-.9 3.5-.9 4.1 0 8.2 2.3 10.4 3.8l5.3-12.9zM302.7 130.2v-35l10.5.4V79H274v16.6l10.5-.4v35h18.2zM316.9 79h18.6v51.2h-18.6zM391.3 86c-4.4-4.5-11.5-8.2-21.3-8.2s-16.9 3.8-21.3 8.2c-4 4.1-7.1 10.4-7.1 18.5 0 8.2 3.1 14.5 7.1 18.6 4.4 4.5 11.5 8.2 21.3 8.2s16.9-3.8 21.3-8.2c4-4.1 7.1-10.4 7.1-18.6 0-8.1-3.1-14.4-7.1-18.5zm-14.6 26.2c-1.5 1.5-3.8 2.6-6.7 2.6-2.8 0-5.1-1.1-6.7-2.6-1.8-1.8-2.9-4.7-2.9-7.6 0-2.8 1.1-5.8 2.9-7.7 1.2-1.3 3.4-2.6 6.7-2.6 3.1 0 5.3 1.2 6.7 2.6 1.8 1.8 2.9 4.5 2.9 7.7s-1.2 5.9-2.9 7.6zM422.2 130.2v-17.4c0-2.3-.6-6.7-1.2-9.4h.5c1.1 2.3 2.2 4.5 3.5 6.7l12.7 20.2h17.8V79h-17.8v16.3c0 3.8.8 8.9 1.6 12.1h-.3c-1.7-4-2.9-6-4.8-9.1l-12-19.3h-17.7v51.2h17.7z"
                    fill={brandColor}
                />
                <path
                    fill={textColor}
                    d="M191.9 50.9c1.4-1.8 5.2-6.9 5.2-16.7 0-7.6-2.6-14.8-7.6-20-3.9-4-11-8.8-22.8-8.8-10 0-17.2 3.5-22.3 8.5-4.8 4.7-8.2 11.2-8.2 20.2 0 9.2 3.7 16.3 8.3 20.9 6.6 6.5 14.6 7.9 22.5 7.9 4.8 0 8.7-.6 13.4-3.1l3.9 6.7L200 60l-8.1-9.1zm-15.5-13.7L164 39.5l5 5.4c-3.3.9-6.9 0-9.4-2.4-2.3-2.1-3.3-5.5-3.3-8.5 0-2.9 1.1-5.7 3.1-7.9 2.1-2.2 4.5-3.1 7.3-3.1s5.1.8 7.2 2.9c2.8 2.9 3.7 7.2 2.5 11.3zM201.8 47.7c0 2.8.5 6.5 3.5 9.7 4.4 4.7 10.8 5.1 14.3 5.1 4.3 0 9.8-.8 13.7-4.8 3.5-3.6 4-8 4-11V23.2h-13.9v22.3c0 1 0 3-1 4-.5.5-1.4.9-2.8.9-1.1 0-2-.2-2.8-1-1.1-1.2-1.2-2.8-1.2-3.9V23.2h-13.9v24.5h.1zM243.7 23.2h14v38.5h-14zM292.1 46.6c-1.2 1.3-3.9 3.6-7.8 3.6-2.8 0-4.6-1.1-5.7-2.2-1.5-1.5-2.3-3.5-2.3-5.5s.7-4 2-5.5c1.5-1.5 3.5-2.4 5.6-2.4 3.5 0 6.5 2.2 8.1 3.8l-.4-14.7c-3-1-6.1-1.5-9.2-1.5-7.2 0-11.8 3.1-14.5 5.8-3.5 3.6-5.8 8.7-5.8 14.6 0 5.4 2 10.6 5.6 14.2 3.8 3.8 9.2 5.6 14.2 5.6 1.8 0 6.1-.2 9.7-1.6l.5-14.2zM310 61.6V49.2c0-1.2-.1-2.4-.3-3.6h.3c.5 1.3 1.2 2.5 1.8 3.7l7 12.4H335L321.8 42l12-18.9h-15.1L312 35.6c-.8 1.4-1.5 2.8-2 4.3h-.3c.2-1.6.3-3.2.3-4.8V23.2h-13.4v38.5H310v-.1z"
                />
            </svg>
        </Link>
    );
};

export const QQMSLogo = React.memo(QQMSLogoCom);
