import type { ThemeOverride } from '@chakra-ui/react';
import { extendTheme } from '@chakra-ui/react';

import Button from './components/themed-button';
import colors from './foundations/colors';
import IconButton from './components/themed-icon-button';
import styles from './styles';
import Text from './components/themed-text';

const overrides: ThemeOverride = {
    colors,
    styles,
    components: {
        Button,
        Heading: Text,
        IconButton,
        Text,
    },
};

export default extendTheme(overrides);
