import { memo } from 'react';
import type { IconProps } from '@chakra-ui/react';
import { Icon } from '@chakra-ui/react';

const IconPollResultCom = (props: IconProps) => {
    return (
        <Icon viewBox="0 0 80 60" {...props}>
            <path
                fill="currentColor"
                d="M36.4 43.9H20.3c-1.2 0-2.2-1.2-2.2-2.6 0-1.4 1-2.6 2.2-2.6h16.1c1.2 0 2.2 1.2 2.2 2.6 0 1.5-1 2.6-2.2 2.6zM23.3 36.4h-3c-1.2 0-2.2-1.2-2.2-2.6 0-1.4 1-2.6 2.2-2.6h3c1.2 0 2.2 1.2 2.2 2.6 0 1.4-1 2.6-2.2 2.6zM31.9 28.8H20.3c-1.2 0-2.2-1.2-2.2-2.6 0-1.4 1-2.6 2.2-2.6h11.6c1.2 0 2.2 1.2 2.2 2.6 0 1.4-.9 2.6-2.2 2.6zM40.4 21.2H20.3c-1.2 0-2.2-1.2-2.2-2.6 0-1.4 1-2.6 2.2-2.6h20.1c1.2 0 2.2 1.2 2.2 2.6 0 1.5-.9 2.6-2.2 2.6zM41.8 28.6c0-.7.1-1.4.4-2 .3-.6.6-1.2 1.1-1.6.5-.5 1-.8 1.6-1.1.6-.3 1.3-.4 2-.4s1.4.1 2 .4c.6.3 1.2.6 1.6 1.1.5.5.8 1 1.1 1.6.3.6.4 1.3.4 2s-.1 1.4-.4 2c-.3.6-.6 1.2-1.1 1.6-.5.5-1 .8-1.6 1.1-.6.3-1.3.4-2 .4s-1.4-.1-2-.4c-.6-.3-1.2-.6-1.6-1.1-.5-.5-.8-1-1.1-1.6-.3-.6-.4-1.3-.4-2zm7.6 0c0-.7-.2-1.3-.7-1.8-.5-.5-1.1-.7-1.7-.7-.7 0-1.3.2-1.7.7-.5.5-.7 1.1-.7 1.8s.2 1.3.7 1.8c.5.5 1.1.7 1.7.7.7 0 1.3-.2 1.7-.7.4-.5.7-1.1.7-1.8zm8.2-5.7 2.2 1.2-10.7 18.8-2-1.1 10.5-18.9zm-2.8 14.3c0-.7.1-1.4.4-2 .3-.6.6-1.2 1.1-1.6.5-.5 1-.8 1.6-1.1.6-.3 1.3-.4 2-.4s1.4.1 2 .4c.6.3 1.2.6 1.6 1.1.5.5.8 1 1.1 1.6.3.6.4 1.3.4 2s-.1 1.4-.4 2c-.3.6-.6 1.2-1.1 1.6-.5.5-1 .8-1.6 1.1-.6.3-1.3.4-2 .4s-1.4-.1-2-.4c-.6-.3-1.2-.6-1.6-1.1-.5-.5-.8-1-1.1-1.6-.3-.6-.4-1.3-.4-2zm7.6 0c0-.7-.2-1.3-.7-1.8-.5-.5-1.1-.7-1.7-.7-.7 0-1.3.2-1.7.7-.5.5-.7 1.1-.7 1.8s.2 1.3.7 1.8c.5.5 1.1.7 1.7.7.7 0 1.3-.2 1.7-.7.4-.5.7-1.1.7-1.8z"
            />
        </Icon>
    );
};

export const IconPollResult = memo(IconPollResultCom);
