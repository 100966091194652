/**
 * Basic Input
 * Why: Using `variant` breaks the input style
 */
import type { InputProps } from '@chakra-ui/react';
import { useColorMode } from '@chakra-ui/react';
import { TrimmedInput } from '@dmp/shared/components';
import * as React from 'react';
import { useIntermediateInput } from '../../hooks/use-immediate-input';

const InputInlineCom: React.FC<InputProps> = (inputProps) => {
    const { colorMode } = useColorMode();

    const isDark = colorMode === 'dark';

    const focusedStyle = {
        background: isDark ? 'gray.800' : 'gray.50',
        borderColor: isDark ? 'gray.600' : 'gray.200',
    };

    const { onChange, value, ...rest } = inputProps;

    const { stateValue, handleChange } = useIntermediateInput(value, onChange);

    return (
        <TrimmedInput
            fontSize="inherit"
            fontWeight="inherit"
            background="transparent"
            borderColor="transparent"
            _hover={focusedStyle}
            _focus={focusedStyle}
            {...rest}
            onChange={handleChange}
            value={stateValue}
        />
    );
};
export const InputInline = React.memo(InputInlineCom);
