import type { ThankYouCard } from '@dmp/qqms/types';

interface ThankYouCardComponentProps {
    card: ThankYouCard;
}

export function ThankYouCardComponent({ card }: ThankYouCardComponentProps) {
    return (
        <div
            className="text-block"
            dangerouslySetInnerHTML={{ __html: card.body }}
        />
    );
}
