import * as React from 'react';
import type { ContentState, DraftDecorator } from 'draft-js';
import { EditorState, RichUtils } from 'draft-js';
import { generateStrategy, getInstanceType } from '../rte-utils';

const ENTITY_TYPE = 'LINK';

export type LinkTarget = '_blank' | '_self';
export interface LinkData {
    url: string;
    title?: string;
    target?: LinkTarget;
}

interface OutputLinkProps extends LinkData {
    children: React.ReactNode;
}
/* *********************************
 * HTML OUTPUT: how it renders in output HTML
 * *********************************/
export const OutputLink = ({
    children,
    url,
    title,
    target = '_blank',
}: OutputLinkProps) => {
    return (
        <a
            title={title || url}
            href={url}
            target={target}
            rel="noopener noreferrer"
            style={{ color: '#0073ff' }}
        >
            {children}
        </a>
    );
};

/* *********************************
 * COMPONENT: Displayed inside the editor
 * *********************************/
interface InEditorRendererProps {
    children: React.ReactNode;
    contentState: ContentState;
    entityKey: string;
}

const InEditorRenderer = ({
    contentState,
    entityKey,
    children,
}: InEditorRendererProps) => {
    const linkProps = contentState.getEntity(entityKey).getData() as LinkData;

    return <OutputLink {...linkProps}>{children}</OutputLink>;
};

/* *********************************
 * DECORATOR
 * *********************************/
export const linkDecorator: DraftDecorator = {
    strategy: generateStrategy(ENTITY_TYPE),
    component: InEditorRenderer,
};

/* *********************************
 * ACTIONS
 * *********************************/
export const applyLink =
    (editorState: EditorState) =>
    (url: string, target: LinkTarget, title: string = '') => {
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity(
            ENTITY_TYPE,
            'MUTABLE',
            { title, url, target }
        );
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newEditorState = EditorState.set(editorState, {
            currentContent: contentStateWithEntity,
        });

        const newState = RichUtils.toggleLink(
            newEditorState,
            newEditorState.getSelection(),
            entityKey
        );

        return newState;
    };

export const getLinkInstance = getInstanceType(ENTITY_TYPE);
