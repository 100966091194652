import type { LinearAnswer, ForkedAnswer } from '@dmp/qqms/types';

export const defaultLinearAnswer: LinearAnswer = {
    id: '',
    type: 'linearAnswer',
    body: '',
    traxexLabel: '',
};

export const defaultForkedAnswer: ForkedAnswer = {
    id: '',
    type: 'forkedAnswer',
    body: '',
    traxexLabel: '',
};
