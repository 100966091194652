import { Box } from '@chakra-ui/react';
import * as React from 'react';

interface CardAddItemComProps {
    children: React.ReactNode;
    title: string;
}

const CardAddItemCom = ({ title, children }: CardAddItemComProps) => {
    return (
        <>
            <Box
                w="48px"
                h="36px"
                rounded="xl"
                border="2px"
                borderColor="currentColor"
                mr="20px"
            >
                {children}
            </Box>
            <span>{title}</span>
        </>
    );
};

export const CardAddItem = CardAddItemCom;
