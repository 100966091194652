import type { Question } from '@dmp/qqms/types';
import { useSurveyState } from '../state/use-survey-state';
import { questionHasPollResult } from '../utils';

interface PollResultNoteComponentProps {
    question: Question;
}

export function PollResultNoteComponent({
    question,
}: PollResultNoteComponentProps) {
    const { survey } = useSurveyState();
    const hasPollResult = questionHasPollResult(survey, question.id);

    if (!hasPollResult) {
        return null;
    }

    return (
        <p className="pr-question-note">
            <svg viewBox="0 0 24 24">
                <path
                    fill="currentColor"
                    d="M3,22V8H7V22H3M10,22V2H14V22H10M17,22V14H21V22H17Z"
                />
            </svg>
            See how other readers answered by completing the survey.
        </p>
    );
}
