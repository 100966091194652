import type { CoreQuestionResource } from '@dmp/qqms/data-access';
import type { Card } from '@dmp/qqms/types';
import { countSeq } from '@dmp/shared/client-utils';
import { appendUnique, insert, without } from '@dmp/shared/helpers';
import type { DragEndEvent } from '@dnd-kit/core';
import { PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { useSetQuestionsForDynamicCoreQuestion } from '../../../../hooks/use-set-questions-for-dynamic-core-question';
import { cardQuestionsState } from '../../../../state/card/card-atoms';
import { activeCoreQuestionShortIdsListSelector } from '../../../../state/core-question/core-question-list-atoms';

type CQID = CoreQuestionResource['id'];

export type ListName = 'lib' | 'main';
const LIB: ListName = 'lib';
const MAIN: ListName = 'main';

export const useSortableCoreQuestions = (cardId: Card['id']) => {
    const allActiveCoreQuestionShortIds = useRecoilValue(
        activeCoreQuestionShortIdsListSelector
    );
    const coreQuestions = useRecoilValue(cardQuestionsState(cardId));

    const { setQuestionsForDynamicCoreQuestion } =
        useSetQuestionsForDynamicCoreQuestion(cardId);

    // sensors for dnd-kit
    const sensors = useSensors(useSensor(PointerSensor));

    // filter keywords
    const [keyword, setKeyword] = React.useState<string>('');

    // Store question and library ids list
    // these values will be changed during dragging/sort/dropping actions.
    const [libIds, setLibIds] = React.useState<CQID[]>([]);
    const [questionIds, setQuestionIds] = React.useState<CQID[]>(
        allActiveCoreQuestionShortIds
    );

    // store states to control actions while active dragging
    const [draggingIds, setDraggingIds] = React.useState<CQID[]>([]);

    // Store selected items
    const [selectedIds, setSelectedIds] = React.useState<CQID[]>([]);

    // Update question ids and auto-update the lib ids
    const updateQuestionIdList = React.useCallback(
        (newQuestionIds: string[]) => {
            const newLibIds = without(
                allActiveCoreQuestionShortIds,
                newQuestionIds
            );
            setQuestionIds(newQuestionIds);
            setLibIds(newLibIds);
        },
        [allActiveCoreQuestionShortIds]
    );

    // sync state core question ids with dynamicCoreQuestion's questions
    // and the global core question resources.
    React.useEffect(() => {
        updateQuestionIdList(coreQuestions || []);
    }, [coreQuestions, allActiveCoreQuestionShortIds, updateQuestionIdList]);

    const resetLocalStates = () => {
        setDraggingIds([]);
        setSelectedIds([]);
    };

    /**
     * Start dragging
     * - Collect all selected items, including the dragging item
     */
    const handleDragStart = (event: DragEndEvent) => {
        const sourceListName = getListByItemId(
            event.active.id.toString(),
            questionIds
        );
        const sourceList = sourceListName === MAIN ? questionIds : libIds;
        const newDraggingIds = appendUnique([event.active.id])(selectedIds);
        const sortedDraggingIndexes = newDraggingIds
            .map((id) => sourceList.findIndex((i) => i === id))
            .sort()
            .map((idx) => sourceList[idx]);

        setDraggingIds(sortedDraggingIndexes);
    };

    /**
     * Handle item interaction while dragging over other items
     */
    const handleDragOver = (event: DragEndEvent) => {
        const { active, over } = event;

        if (!over) {
            return;
        }

        const newQuestionIds = processListWhileDragging(
            questionIds,
            draggingIds
        )(active.id.toString(), over.id.toString());

        if (newQuestionIds) {
            updateQuestionIdList(newQuestionIds);
        }
    };

    const handleSelect = (
        event: React.MouseEvent<HTMLElement>,
        listName: ListName,
        id: string
    ) => {
        const shiftPressed = event.shiftKey;
        const ctrlOrCmdPressed = event.ctrlKey || event.metaKey;

        const currentList = listName === MAIN ? questionIds : libIds;
        const newSelectedIds = processSelection(selectedIds, currentList)(
            ctrlOrCmdPressed,
            shiftPressed,
            id
        );

        setSelectedIds(newSelectedIds);
    };

    // remove the current id, PLUS the selected ones
    const handleRemove = (id: string) => {
        const newQuestionIds = without(
            questionIds,
            appendUnique(selectedIds)([id])
        );
        setQuestionsForDynamicCoreQuestion(newQuestionIds);
        resetLocalStates();
    };

    // commit changes when dragging end
    const handleDragEnd = () => {
        setQuestionsForDynamicCoreQuestion(questionIds);
        resetLocalStates();
    };

    // only transfer all `isActive` questions
    const transferAll = () => {
        setQuestionsForDynamicCoreQuestion(allActiveCoreQuestionShortIds);
        resetLocalStates();
    };

    const empty = () => {
        setQuestionsForDynamicCoreQuestion([]);
        resetLocalStates();
    };

    return {
        draggingIds,
        empty,
        handleDragEnd,
        handleDragOver,
        handleDragStart,
        handleRemove,
        handleSelect,
        keyword,
        libIds,
        questionIds,
        selectedIds,
        sensors,
        setKeyword,
        transferAll,
    };
};

/**
 * Process the question list while dragging
 * Combined actions Dragging and Sorting require the state list updated
 * to include/exclude the dragging items.
 */
export const processListWhileDragging =
    (questionIds: CQID[], draggingIds: CQID[]) =>
    (activeId: string, overId: string): CQID[] | undefined => {
        // drag over the same item - do nothing;
        if (activeId === overId) {
            return undefined;
        }

        // NOTE: sourceList and targetList changes based on where active-dragging item is over.
        const sourceList = getListByItemId(activeId, questionIds);
        const targetList = getListByItemId(overId, questionIds);
        const targetIsMain = targetList === MAIN;

        // In the same list - sort the items
        if (sourceList === targetList) {
            // not in MAIN list - no sorting
            if (!targetIsMain) {
                return undefined;
            }

            // get index of the target item
            const targetIndex = questionIds.findIndex((id) => id === overId);
            const listWithoutDraggingItems = without(questionIds, draggingIds);

            return insert(listWithoutDraggingItems, targetIndex, draggingIds);
        }

        // dropping onto a different list
        // Move the selected items from source to the target list
        return targetIsMain
            ? appendUnique(draggingIds)(questionIds)
            : without(questionIds, draggingIds);
    };

/**
 * Process Item selection
 */
export const processSelection =
    (selectedIds: string[], currentList: string[]) =>
    (ctrlOrCmdPressed: boolean, shiftPressed: boolean, id: string) => {
        // check if the current id and previous selected id is in the same list
        const inTheSameList = [id, ...selectedIds].reduce(
            (car, sId) => car && currentList.includes(sId),
            true
        );

        const doSingleClick =
            // no ctrl or shift click
            (!ctrlOrCmdPressed && !shiftPressed) ||
            // not in the same list as the previous selected
            !inTheSameList ||
            // empty selected
            selectedIds.length === 0;

        // normal single selection
        if (doSingleClick) {
            // deselected if it exists and no currently multi-selected
            if (selectedIds.includes(id) && selectedIds.length === 1) {
                return without(selectedIds, [id]);
            }

            return [id];
        }

        // SHIFT pressed - use last selected item as based
        if (shiftPressed) {
            const lastSelectedId = selectedIds[selectedIds.length - 1];
            const baseIdx = currentList.findIndex((i) => i === lastSelectedId);
            const idx = currentList.findIndex((i) => i === id);
            const indexes =
                baseIdx > idx
                    ? countSeq(idx, baseIdx - idx + 1)
                    : countSeq(baseIdx, idx - baseIdx + 1);

            return indexes.map((i) => currentList[i]);
        }

        /**
         * CTRL/CMD Pressed - include the item
         */
        return selectedIds.includes(id)
            ? without(selectedIds, [id])
            : appendUnique([id])(selectedIds);
    };

/**
 * Sort active dragging ids based on its source list
 * - It's preferable to sort the selected items in order as how they seen visually
 */
export const sortDraggingIds =
    (questionIds: CQID[], libIds: CQID[], selectedIds: CQID[]) =>
    (activeId: CQID) => {
        // get source list of the active dragging item
        const sourceListName = getListByItemId(activeId, questionIds);
        const sourceList = sourceListName === MAIN ? questionIds : libIds;

        // combine the dragging item with the selected items
        const newDraggingIds = appendUnique([activeId])(selectedIds);

        // sort the items based on the source list
        return newDraggingIds
            .map((id) => sourceList.findIndex((i) => i === id))
            .sort()
            .map((idx) => sourceList[idx]);
    };

/**
 * Get list by the draggable item id
 * Note: Item id could be a Container
 */
const getListByItemId = (id: string, mainQuestionIds: string[]): ListName => {
    // id is one of the containers
    if (id === MAIN || id === LIB) {
        return id;
    }

    return mainQuestionIds.includes(id) ? MAIN : LIB;
};
