import { atom, DefaultValue, selector } from 'recoil';

import { localStorageEffect } from '../../utils/localStorageEffect';

interface BoardDimension {
    width?: string | number;
    height?: string | number;
    collapsed?: boolean;
}

/**
 * Atom state to record visual tree board dimension
 */
export const visualTreeDimension = atom<BoardDimension>({
    key: 'ui_visualTreeDimension',
    effects_UNSTABLE: [localStorageEffect('visual-tree-dimension')],
    default: { height: 300, collapsed: true },
});

export const isVisualTreeCollapsed = selector<boolean>({
    key: 'ui_isVisualTreeCollapsed',
    get: ({ get }) => !!get(visualTreeDimension).collapsed,
    set: ({ set, get }, newValue) => {
        if (newValue instanceof DefaultValue) {
            return;
        }

        const dimension = get(visualTreeDimension);
        set(visualTreeDimension, {
            ...dimension,
            collapsed: newValue,
        });
    },
});
