import { isDynamicCoreQuestion, isQuestion } from '@dmp/qqms/survey-utils';
import type { SurveyResource } from '@dmp/qqms/data-access';
import type { Answer, Card, Question } from '@dmp/qqms/types';

import type { AnswerItemState } from '../state/answer/answer-types';
import type { CardItemState } from '../state/card/card-types';
import type { SurveyState } from '../state/survey/survey-types';
import type { CollectiveState } from '../state/survey-config/collective-state-atoms';

/****************************************************************
 * Transform/Flatten Survey Resource to Recoil State
 ****************************************************************/
export const surveyResourceToState = (
    surveyResource: SurveyResource
): Omit<CollectiveState, 'allCoreQuestions'> => {
    if (!surveyResource || !surveyResource['id']) {
        throw new Error('Incorrect SurveyResource from API');
    }

    const { id, attributes } = surveyResource;
    const { config, name, qqid, shortId } = attributes;
    const { cards, traxexLabel, aboutCopy, sponsorInfo } = config;

    const survey: SurveyState = {
        id,
        shortId,
        qqid,
        name,
        cards: cards.map((c) => c.id),
        traxexLabel,
        aboutCopy,
        sponsorInfo,
    };

    return {
        survey,
        cards: transformToCardsState(cards),
        answers: transformToAnswersState(cards),
    };
};

/**
 * Collect & transform cards resource to array of cards
 */
export const transformToCardsState = (cards: Card[]): Array<CardItemState> => {
    return cards.map((card) => {
        if (isDynamicCoreQuestion(card)) {
            return {
                ...card,
                questions: compactToIds(card.questions),
            };
        } else if (isQuestion(card)) {
            return {
                ...card,
                answers: compactToIds((card.answers || []) as Answer[]),
            };
        }

        return card;
    });
};

/**
 * Collect & transform answers resource to array of answers
 */
export const transformToAnswersState = (
    cards: Card[]
): Array<AnswerItemState> =>
    cards.map((card) => (card as Question).answers || []).flat();

/**
 * Compact collection of object (with id) to a array of ids
 * [{ id: 'one', attributes: .. }] => ['one]
 */
export const compactToIds = <T extends { id: string }>(col: T[]) =>
    col.map((i) => i.id);
