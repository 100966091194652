import { authGroupResourceSelector } from './auth-group-list-atoms';
import { clone } from 'remeda';
import { updateAuthGroup } from '@dmp/qqms/data-access';
import { useErrorToaster, useSuccessToaster } from '@dmp/shared/components';
import { useRecoilCallback } from 'recoil';
import type { AuthGroupResource } from '@dmp/qqms/data-access';
import type { PermissionGroup } from '@dmp/qqms/permissions';

/**
 * Action for saving the editing auth group for the editing form
 * - then, update the item in the auth group list.
 */
export const useAuthGroupPatch = () => {
    const { handleError } = useErrorToaster();
    const { handleSuccess } = useSuccessToaster();

    const patchAuthGroup = useRecoilCallback(
        ({ snapshot, set }) =>
            async (
                authGroupId: AuthGroupResource['id'],
                permissionGroup: PermissionGroup
            ) => {
                const state = authGroupResourceSelector(authGroupId);
                const authGroup = await snapshot.getPromise(state);
                if (!authGroup) {
                    return;
                }

                const oldAuthGroup = clone(authGroup);
                const newAuthGroup = {
                    ...authGroup,
                    attributes: {
                        ...authGroup.attributes,
                        permissionGroup,
                    },
                };

                // set first before patching
                set(state, newAuthGroup);

                updateAuthGroup(authGroupId, newAuthGroup.attributes)
                    .then((response) => {
                        // ensure the data is the same as the api's response
                        set(state, response.data);
                        handleSuccess('Permission Group updated successfully');
                    })
                    .catch((error) => {
                        // reset to the old value
                        set(state, oldAuthGroup);
                        handleError(error);
                    });
            },
        []
    );

    return { patchAuthGroup };
};
