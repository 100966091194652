/**
 * @file Utilities for generating and decoding Poll Results keys.
 * These are used for fetching the Poll Results of a Question in
 * a way that avoids data scraping.
 */

import { isQuestion } from '@dmp/qqms/survey-utils';
import type { Answer, Card, Question } from '@dmp/qqms/types';

/**
 * Attach a `pollResultsKey` to a Question that can be used to fetch its results.
 */
export function attachPollResultsKey<T extends Card>(card: T): T {
    if (!isQuestion(card)) {
        return card;
    }

    return {
        ...card,
        pollResultsKey: generatePollResultsKey(card),
    };
}

// The amount of characters to shift the pollResultKey for obsfucation.
// THIS SHOULD NOT BE CHANGED, as it will break live Surveys.
const CHAR_SHIFT_SIZE = 4;

/**
 * Generate a `pollResultsKey` for Question.
 *
 * NOTE: This key is fairly obfuscated, and the implementation will be
 * hidden from QQ Unit. However, it should not be considered cryptographically
 * secure, and should not be used for more sensitive use cases.
 */
export function generatePollResultsKey(question: Question) {
    const answerIds = question.answers.map((a) => a.id);
    const stringified = answerIds.join(',');

    return btoa(stringified)
        .split('')
        .map((character) => character.charCodeAt(0))
        .map((charCode) => charCode + CHAR_SHIFT_SIZE)
        .map((charCode) => String.fromCharCode(charCode))
        .join('');
}

/**
 * Decode a `pollResultsKey` into an array of Answer IDs.
 */
export function decodePollResultsKey(key: string): Array<Answer['id']> {
    const unshifted = key
        .split('')
        .map((character) => character.charCodeAt(0))
        .map((charCode) => charCode - CHAR_SHIFT_SIZE)
        .map((charCode) => String.fromCharCode(charCode))
        .join('');

    return atob(unshifted).split(',');
}
