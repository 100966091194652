import { atom } from 'recoil';
import { localStorageEffect } from '../../utils/localStorageEffect';
import type { SizeName } from './preview-size-types';

/**
 * Atom state to set preview size name
 */
export const previewSizeState = atom<SizeName>({
    key: 'ui_previewSizeState',
    default: 'In-Article',
    effects_UNSTABLE: [localStorageEffect('preview-size')],
});
