import type { TextareaProps } from '@chakra-ui/react';
import { useColorMode } from '@chakra-ui/react';
import { useRecoilState } from 'recoil';

import type { Card } from '@dmp/qqms/types';
import { cardBodyState } from '../../../../state/card/card-atoms';
import { questionFieldStyles } from '../../../../theme/common-styles/question-field-styles';
import { RichTextInput } from '@dmp/shared/ui-draft-js';
import { Textarea } from '../../../common/textarea';

import './rich-text-editor-styles.scss';

export interface CardBodyProps extends TextareaProps {
    cardId: Card['id'];
    isRichTextEditor?: boolean;
}

const CardBody = ({
    cardId,
    isRichTextEditor,
    ...textAreaProps
}: CardBodyProps) => {
    const { colorMode } = useColorMode();

    const [cardBody, setCardBody] = useRecoilState(cardBodyState(cardId));

    const { input } = questionFieldStyles(colorMode);

    if (isRichTextEditor) {
        return <RichTextInput value={cardBody} onChange={setCardBody} />;
    }

    return (
        <Textarea
            resize="vertical"
            placeholder="enter question"
            value={cardBody}
            onChange={(e) => setCardBody(e.target.value)}
            fontSize="lg"
            {...input}
            {...textAreaProps}
        />
    );
};

export default CardBody;
