import type {
    Answer,
    Card,
    DynamicCoreQuestion,
    Question,
} from '@dmp/qqms/types';
import { isDynamicCoreQuestion, isLinearQuestion } from './type-guards';

interface SelectNextCardIdPayload {
    answerId: Answer['id'];
    question: Question | DynamicCoreQuestion;
    cards: Card[];
}

/**
 * Determine the next Card to show based on what Answer was chosen.
 */
export function selectNextCardId({
    answerId,
    question,
    cards,
}: SelectNextCardIdPayload): Card['id'] | undefined {
    // find next index card to fallback for undefined `resultCard`
    const idx = cards.findIndex((c) => c.id === question.id);
    const nextIndexCard = cards[idx + 1];
    const nextIndexCardId = nextIndexCard?.id || undefined;

    if (isLinearQuestion(question) || isDynamicCoreQuestion(question)) {
        return question.resultCard || nextIndexCardId;
    }

    const answer = question.answers.find((o) => o.id === answerId);

    if (answer === undefined) {
        throw new Error(`Unable to find Answer ${answerId}.`);
    }

    return answer.resultCard || nextIndexCardId;
}
