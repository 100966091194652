import { generatePollResultCardId } from '@dmp/qqms/survey-generators';
import { useRecoilCallback } from 'recoil';
import { insertNewCard } from '../../utils/insertNewCard';
import { surveyState } from '../survey/survey-atoms';
import type { SurveyState } from '../survey/survey-types';
import {
    cardItemState,
    defaultPollResult,
    surveyCardsSelector,
} from './card-atoms';
import type { CardItemState, PollResultCardItemState } from './card-types';

export const useCardAddPollResult = (cardId?: CardItemState['id']) => {
    const addPollResultCard = useRecoilCallback(
        ({ set, snapshot }) =>
            async () => {
                const survey = await snapshot.getPromise(surveyState);
                const cardsInSurvey = await snapshot.getPromise(
                    surveyCardsSelector
                );

                const { newSurvey, newCards } = addPollResultCardUtil(
                    survey,
                    cardsInSurvey
                )(cardId);

                // update all cards, & create new card atom
                newCards.forEach((c) => set(cardItemState(c.id), c));

                // add new card id into survey
                set(surveyState, newSurvey);
            },
        [cardId]
    );

    return {
        addPollResultCard,
    };
};

/**
 * Create new card and get modified atoms
 */
export const addPollResultCardUtil =
    (survey: SurveyState, cardsInSurvey: CardItemState[]) =>
    (cardId?: CardItemState['id']) => {
        const newPollResult: PollResultCardItemState = {
            ...defaultPollResult,
            id: generatePollResultCardId(),
        };

        // Insert new card and reconnects `resultCard` in the collection
        const { newSurvey, newCards } = insertNewCard(survey, cardsInSurvey)(
            newPollResult,
            cardId
        );

        return {
            newSurvey,
            newCards,
        };
    };
