import { Alert, AlertIcon } from '@chakra-ui/react';
import { memo } from 'react';

const WarnPollCardCom = () => {
    return (
        <Alert status="warning" rounded="md" fontSize="sm">
            <AlertIcon />
            If modifying a live survey with a poll result, please create a new
            survey with a poll result.
        </Alert>
    );
};

export const WarnPollCard = memo(WarnPollCardCom);
