import * as React from 'react';
import type { RecoilState } from 'recoil';
import { useRecoilSnapshot, useRecoilState } from 'recoil';
import { isSurveyDirtyState } from '../../state/ui/is-survey-dirty-atoms';

const EXCLUDES_REGEXP = /ui_|validate_/;

interface DirtyStateObserverProps {
    atomState?: RecoilState<boolean>;
}

export const DirtyStateObserver: React.FC<DirtyStateObserverProps> = ({
    atomState = isSurveyDirtyState,
}) => {
    const snapshot = useRecoilSnapshot();
    const [isDirty, setIsDirty] = useRecoilState(atomState);

    React.useEffect(() => {
        for (const node of snapshot.getNodes_UNSTABLE({ isModified: true })) {
            // ignore `ui_..` keys
            if (node.key.match(EXCLUDES_REGEXP)) {
                return;
            }

            if (!isDirty) {
                setIsDirty(true);
            }
        }
        // do not need to observe others.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [snapshot]);

    return null;
};
