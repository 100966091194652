import { isLinearQuestion } from '@dmp/qqms/survey-utils';
import type { Card, LinearQuestion } from '@dmp/qqms/types';
import { useSurveyState } from '../state/use-survey-state';
import { isSurveyPaginated } from '../utils';

export function PaginationComponent() {
    const { survey, currentCard } = useSurveyState();
    const isPaginated = isSurveyPaginated(survey);

    if (
        !isPaginated ||
        ['pollResultCard', 'thankYouCard'].includes(currentCard.type)
    ) {
        return null;
    }

    const { cards } = survey;
    const { total, cardNumber } = getPaginationInfo(cards, currentCard.id);

    return (
        <div id="pagination">
            {cardNumber} of {total}
        </div>
    );
}

/**
 * Get ordered list of question ids to get the correct position of the current page
 */
const getOrders = (
    cards: LinearQuestion[],
    card: LinearQuestion,
    col: string[]
): string[] => {
    // find current card index to fallback
    const cardIndex = cards.map((c) => c.id).indexOf(card.id);
    // undefined resultCard === it automatically goes to the next index card
    const nextCardId = card.resultCard ?? cards[cardIndex + 1]?.id;
    const nextCard = cards.find((card) => card.id === nextCardId);

    return nextCard ? getOrders(cards, nextCard, col.concat(nextCard.id)) : col;
};

export const getPaginationInfo = (cards: Card[], currentCardId: string) => {
    // Note: DynamicQuestion is already converted to 'linearQuestion' at this point
    const linearCards = cards.filter(isLinearQuestion);
    const startCard = linearCards[0];

    if (!startCard) {
        return {
            total: 0,
            cardNumber: 0,
        };
    }

    const ordered = getOrders(linearCards, startCard, [startCard.id]);

    return {
        total: ordered.length,
        cardNumber: ordered.indexOf(currentCardId) + 1,
    };
};
