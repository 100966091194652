/** REQUIREMENT
 * Add this into `index.html` (`global` is renamed to `globalThis`)
   - <script> const global = globalThis; </script>
**/
import { Box, useColorMode, VStack } from '@chakra-ui/react';
import React, { memo, useEffect, useState } from 'react';
import type { DraftHandleValue } from 'draft-js';
import {
    Editor,
    EditorState,
    RichUtils,
    getDefaultKeyBinding,
    CompositeDecorator,
} from 'draft-js';
import { convertFromHTML, convertToHTML } from 'draft-convert';

import 'draft-js/dist/Draft.css';

import { CommandBar } from './command-bar';
import type { LinkData } from './link/link';
import { linkDecorator, OutputLink } from './link/link';
import type { ColorData } from './color/color';
import { colorDecorator, OutputColor } from './color/color';
import type { ButtonData } from './button/button';
import { buttonDecorator, OutputButton } from './button/button';
import type { FontSize } from './font-size/font-size-command-button';
import { fontSizes, fontStyleMap } from './font-size/font-size-command-button';

// Additional decorators
const decorators: CompositeDecorator = new CompositeDecorator([
    linkDecorator,
    colorDecorator,
    buttonDecorator,
]);

interface RichTextInputProps {
    value?: string;
    onChange?: (html: string) => unknown;
}

const RichTextInputCom: React.FC<RichTextInputProps> = ({
    value = '',
    onChange,
}) => {
    const { colorMode } = useColorMode();
    const isDark = colorMode === 'dark';

    const [localHtml, setLocalHtml] = useState<string>('');

    const [editorState, setEditorState] = useState<EditorState>(() =>
        EditorState.createEmpty(decorators)
    );

    // Sync prop value with local value
    useEffect(() => {
        if (value !== localHtml) {
            setEditorState(htmlToDraftState(value));
        }
        // NOTE: only listen to props "value" change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const handleChange = (newEditorState: EditorState) => {
        setEditorState(newEditorState);

        const newHtml = output(newEditorState);
        setLocalHtml(newHtml);
        onChange && onChange(newHtml);
    };

    return (
        <VStack
            w="100%"
            align="stretch"
            spacing="0"
            rounded="md"
            border="1px solid"
            borderColor={isDark ? 'gray.600' : 'gray.200'}
            bg={isDark ? 'blackAlpha.50' : 'gray.50'}
            _hover={{ bg: isDark ? 'blackAlpha.300' : 'inherit' }}
        >
            <CommandBar
                editorState={editorState}
                setEditorState={handleChange}
            />

            <Box w="100%" p="4">
                <Editor
                    placeholder="Enter some text..."
                    handleKeyCommand={createHandleKeyCommand(handleChange)}
                    editorState={editorState}
                    onChange={handleChange}
                    keyBindingFn={getDefaultKeyBinding}
                    customStyleMap={{
                        ...fontStyleMap,
                    }}
                />
            </Box>
        </VStack>
    );
};

export const RichTextInput = memo(RichTextInputCom);

/**
 * Use default key binding from draft js
 */
const createHandleKeyCommand =
    (setState: (value: EditorState) => void) =>
    (command: string, state: EditorState): DraftHandleValue => {
        const newState = RichUtils.handleKeyCommand(state, command);
        if (newState) {
            setState(newState);
            return 'handled';
        }
        return 'not-handled';
    };

const htmlToDraftState = (html: string) => {
    const contentState = convertFromHTML({
        htmlToStyle: (nodeName, node, currentStyle) => {
            if (nodeName === 'span') {
                const fontClass = fontSizes.find((size) =>
                    node.classList.contains(size)
                );
                if (fontClass) {
                    return currentStyle.add(fontClass);
                }
            }
            return currentStyle;
        },
        htmlToEntity: (nodeName, node, createEntity) => {
            switch (nodeName) {
                case 'a': {
                    // Link Button
                    if (node.classList.contains('button')) {
                        const btnEl = node.getElementsByTagName('button')[0];
                        const backgroundColor = btnEl?.style.backgroundColor;
                        return createEntity('BUTTON', 'MUTABLE', {
                            url: node.href,
                            backgroundColor,
                        });
                    }
                    // regular Link
                    return createEntity('LINK', 'MUTABLE', {
                        url: node.href,
                        target: node.getAttribute('target'),
                    });
                }

                case 'span': {
                    // has color
                    if (node.style.color) {
                        return createEntity('COLOR', 'MUTABLE', {
                            color: node.style.color,
                        });
                    }
                    return;
                }

                default:
                    return;
            }
        },
    })(html);

    return EditorState.createWithContent(contentState, decorators);
};

const output = (editorState: EditorState) => {
    // Do not delete (for easy debug)
    // console.log(convertToRaw(editorState.getCurrentContent()));
    const html = convertToHTML({
        styleToHTML: (style) => {
            if (style === 'STRIKETHROUGH') {
                return <s />;
            }

            if (fontSizes.includes(style as FontSize)) {
                return <span className={style} />;
            }

            return;
        },
        blockToHTML: (block) => {
            if (block.type === 'unstyled') {
                return block.text ? <div /> : <br />;
            }
            return;
        },
        entityToHTML: (entity, children) => {
            switch (entity.type) {
                case 'LINK':
                    return (
                        <OutputLink {...(entity.data as LinkData)}>
                            {children}
                        </OutputLink>
                    );

                case 'COLOR':
                    return (
                        <OutputColor {...(entity.data as ColorData)}>
                            {children}
                        </OutputColor>
                    );

                case 'BUTTON':
                    return (
                        <OutputButton {...(entity.data as ButtonData)}>
                            {children}
                        </OutputButton>
                    );

                default:
                    return;
            }
        },
    })(editorState.getCurrentContent());

    // preserve spaces
    return html.replace(/\s\s/g, '&nbsp;&nbsp;');
};
