import { Box, Heading, Select, VStack } from '@chakra-ui/react';
import * as React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import type { Card } from '@dmp/qqms/types';
import {
    cardPollResultQuestionIdState,
    cardTypeState,
    surveyOtherCardIdsSelector,
} from '../../../../state/card/card-atoms';
import { CardTraxexLabel } from '../fields/card-traxex-label';
import { PollResultOption } from './poll-result-option';
import { WarnPollCard } from './warn-poll-card';

interface CardAsPollResultComProps {
    cardId: Card['id'];
}

const CardAsPollResultCom = ({ cardId }: CardAsPollResultComProps) => {
    const cardType = useRecoilValue(cardTypeState(cardId));
    const otherCardIds = useRecoilValue(surveyOtherCardIdsSelector(cardId));
    const [questionId, setQuestionId] = useRecoilState(
        cardPollResultQuestionIdState(cardId)
    );

    if (cardType !== 'pollResultCard') {
        return null;
    }

    return (
        <VStack align="stretch" maxW="500px" h="100%">
            <VStack align="start">
                <Heading as="h2" fontSize="md">
                    Define question for the poll result
                </Heading>
                <Select
                    flex="1"
                    size="sm"
                    rounded="md"
                    value={questionId ?? ''}
                    onChange={(e) => setQuestionId(e.target.value)}
                >
                    <option value="">..select question</option>
                    {otherCardIds
                        .map((id) => <PollResultOption key={id} cardId={id} />)
                        .filter(Boolean)}
                </Select>

                <Box pt="4" pl="8" w="100%">
                    <CardTraxexLabel
                        label="Tracking Label: User viewed these Poll Results"
                        cardId={cardId}
                    />
                </Box>
            </VStack>

            <VStack flex="1" justify="flex-end">
                <WarnPollCard />
            </VStack>
        </VStack>
    );
};

export const CardAsPollResult = React.memo(CardAsPollResultCom);
