import type {
    CoreQuestionConfig,
    CoreQuestionResource,
} from '@dmp/qqms/data-access';
import { isPollResultCard } from '@dmp/qqms/survey-utils';
import type { Answer, Card, ForkedAnswer, LinearAnswer } from '@dmp/qqms/types';
import { truthy } from '@dmp/shared/helpers';
import { omit } from 'remeda';
import { coreQuestionResourceToCoreQuestion } from '../../transformers/core-question-conversions';
import type { AnswerItemState } from '../answer/answer-types';
import {
    isDynamicCoreQuestionItem,
    isForkedQuestionItem,
    isLinearQuestionItem,
    isThankYouCardItem,
} from '../card/card-type-guards';
import type {
    CardItemState,
    LinearQuestionItemState,
    QuestionItemState,
} from '../card/card-types';

/**
 * Convert Card Item State to Card Config
 */
export const convertToCardConfig = (
    cardState: CardItemState,
    allAnswerStates: AnswerItemState[],
    allCoreQuestions: CoreQuestionResource[]
): Card => {
    // Linear Question
    if (isLinearQuestionItem(cardState)) {
        return {
            ...omit(cardState, ['answers']),
            answers: getCardAnswers<LinearAnswer>(cardState, allAnswerStates),
        };
    }

    // Forked Question
    if (isForkedQuestionItem(cardState)) {
        return {
            ...omit(cardState, ['answers']),
            answers: getCardAnswers<ForkedAnswer>(cardState, allAnswerStates),
        };
    }

    // Dynamic Core Question
    if (isDynamicCoreQuestionItem(cardState)) {
        const questions = cardState.questions
            .map((q) =>
                allCoreQuestions.find((c) => c.attributes.shortId === q)
            )
            .filter(truthy)
            .map(coreQuestionResourceToCoreQuestion);

        return { ...cardState, questions };
    }

    // ThankYou card
    if (isThankYouCardItem(cardState)) {
        return cardState;
    }

    // Poll Result card
    if (isPollResultCard(cardState)) {
        return cardState;
    }

    throw new Error(`Cannot convert Card Item State to Card Config.`);
};

export const convertToCoreQuestionConfig = (
    cardState: LinearQuestionItemState,
    allAnswerStates: AnswerItemState[]
): CoreQuestionConfig => {
    const { body, formType, traxexLabel } = cardState;

    return {
        answers: getCardAnswers<LinearAnswer>(cardState, allAnswerStates),
        body,
        formType,
        traxexLabel,
    };
};

const getCardAnswers = <T extends Answer>(
    cardState: QuestionItemState,
    allAnswerStates: AnswerItemState[]
): T[] =>
    cardState.answers
        .map(
            (aid) =>
                allAnswerStates.find((as) => as.id === aid) as T | undefined
        )
        .filter(truthy);
