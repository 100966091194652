import { v4 as uuidv4 } from 'uuid';

type IDGenerator = () => string;

/**
 * Generate Factory with id object
 */
export const generateFactory =
    <T extends { id: string }>(
        defaultObj: T,
        idGenerator: IDGenerator = uuidv4
    ) =>
    (partialObj: Partial<T> = {}): T => ({
        ...defaultObj,
        id: idGenerator(),
        ...partialObj,
    });
