import {
    detectPlatform,
    getAdOrientation,
    getWindowSize,
} from '@dmp/shared/ad-utils';
import type {
    Answer,
    AnswerEvent,
    FullEventContext,
    TraxexCompatibleCard,
} from '@dmp/qqms/types';
import { isLinearQuestionFromCoreQuestion } from './type-guards';

interface MakeTraxexUrlInput extends Omit<AnswerEvent, 'answers' | 'question'> {
    answer?: Answer;
    card: TraxexCompatibleCard;
}

/**
 * Generate a Traxex tracking pixel.
 */
export const makeTraxexUrl = ({
    answer,
    eventContext,
    card,
    survey,
}: MakeTraxexUrlInput): string | undefined => {
    if (!survey.traxexApi) {
        return;
    }

    const surveyTraxexLabel = isLinearQuestionFromCoreQuestion(card)
        ? 'Default'
        : survey.traxexLabel;

    const traxexPieces =
        answer === undefined
            ? [surveyTraxexLabel, card.traxexLabel]
            : [surveyTraxexLabel, card.traxexLabel, answer.traxexLabel];

    const traxexLabel = traxexPieces.join('|');

    const size = getWindowSize();

    const fullEventContext: FullEventContext = {
        ...eventContext,
        id: answer === undefined ? card.id : answer.id,
        app_name: 'qqms',
        app_id: survey.id,
        type: 'qq',
        qqd: traxexLabel,
        qqid: survey.qqid,
        orientation: getAdOrientation(size),
        platform: detectPlatform(),
        size: `${size.width}x${size.height}`,
    };

    const queryString = Object.keys(fullEventContext)
        .map((key) => {
            return `${key}=${encodeURIComponent(
                fullEventContext[key as keyof FullEventContext]
            )}`;
        })
        .join('&');

    return `${survey.traxexApi}/event?${queryString}`;
};
