import { useRecoilValue } from 'recoil';
import KeyboardJS from 'keyboardjs';
import * as React from 'react';

import { useCoreQuestionPatch } from '../../../state/core-question/use-core-question-patch';
import { editingCoreQuestionIdState } from '../../../state/core-question/core-question-edit-form-atoms';

export const FormShortcutListeners: React.FC = () => {
    const editingCoreQuestionId = useRecoilValue(editingCoreQuestionIdState);
    const { patchCoreQuestion } = useCoreQuestionPatch();

    React.useEffect(() => {
        // save
        KeyboardJS.bind(['ctrl+s', 'command+s'], (e) => {
            e?.preventDefault();
            patchCoreQuestion(editingCoreQuestionId);
        });

        return () => {
            KeyboardJS.reset();
        };
    }, [patchCoreQuestion, editingCoreQuestionId]);

    return null;
};
