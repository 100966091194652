import { z } from 'zod';
import { forkedAnswerValidator, linearAnswerValidator } from './answer-schema';
import { traxexLabel } from './shared-schema';

const baseQuestionValidator = z.object({
    id: z.string().min(1),
    formType: z.union([
        z.literal('select'),
        z.literal('radio'),
        z.literal('multi-select'),
    ]),
    body: z.string().min(1, 'Question does not have content.'),
    traxexLabel: traxexLabel(),
    pollResultsKey: z.string().optional(),
});

export const linearQuestionValidator = baseQuestionValidator.extend({
    type: z.literal('linearQuestion'),
    resultCard: z.string().optional(),
    answers: z
        .array(linearAnswerValidator)
        .min(2, 'Question requires at least 2 answers.'),
});

export const forkedQuestionValidator = baseQuestionValidator.extend({
    type: z.literal('forkedQuestion'),
    answers: z
        .array(forkedAnswerValidator)
        .min(2, 'Question requires at least 2 answers.'),
});

export const thankYouCardValidator = z.object({
    id: z.string().min(1),
    type: z.literal('thankYouCard'),
    body: z
        .string({ required_error: 'Thank you note message is required.' })
        .min(1),
    traxexLabel: traxexLabel(),
});

export const pollResultCardValidator = z.object({
    id: z.string().min(1),
    type: z.literal('pollResultCard'),
    questionId: z.string().min(1),
    traxexLabel: traxexLabel(),
});

export const coreQuestionValidator = baseQuestionValidator.extend({
    type: z.literal('coreQuestion'),
    answers: z
        .array(linearAnswerValidator)
        .min(2, 'Question requires at least 2 answers.'),
});

export const dynamicCoreQuestionValidator = z.object({
    id: z.string().min(1),
    type: z.literal('dynamicCoreQuestion'),
    maximizeResponses: z.boolean().optional(),
    questions: z.array(coreQuestionValidator),
    resultCard: z.string().optional(),
});

export const cardValidator = z.discriminatedUnion('type', [
    linearQuestionValidator,
    forkedQuestionValidator,
    dynamicCoreQuestionValidator,
    thankYouCardValidator,
    pollResultCardValidator,
]);
