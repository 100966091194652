/**
 * Convert RGA to Hex from browser
 * Default computed color in element is either in RGB or RGBA,
 *
 * Solution extracted from:
 * https://stackoverflow.com/questions/1740700/how-to-get-hex-color-value-rather-than-rgb-value
 */
export const rgb2hex = (rgb: string = ''): string => {
    return rgb.match(/^rgb/)
        ? rgb
            ? `#${rgb
                  .match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/)
                  ?.slice(1)
                  .map((n: string) =>
                      parseInt(n, 10).toString(16).padStart(2, '0')
                  )
                  .join('')}`
            : ''
        : rgb;
};

/**
 * Compare 2 either or both HEX and RGB colors
 */
export const isEqualHEXorRGB = (color1: string = '', color2: string = '') =>
    rgb2hex(color1).toUpperCase() === rgb2hex(color2).toUpperCase();
