import { allAuthGroupList } from './auth-group-list-atoms';
import { createAuthGroup } from '@dmp/qqms/data-access';
import { useErrorToaster, useSuccessToaster } from '@dmp/shared/components';
import { useRecoilCallback } from 'recoil';
import type { AuthGroupAttributes } from '@dmp/qqms/data-access';

/**
 * Action for creating an auth group
 * - then, update the item in the auth group list.
 */
export const useAuthGroupCreate = () => {
    const { handleError } = useErrorToaster();
    const { handleSuccess } = useSuccessToaster();

    const createAuthGroupCallback = useRecoilCallback(
        ({ snapshot, set }) =>
            async (authGroupAttributes: AuthGroupAttributes) => {
                const groupList = await snapshot.getPromise(allAuthGroupList);

                createAuthGroup(authGroupAttributes)
                    .then((response) => {
                        // update group list
                        const newGroupList = [response.data].concat(groupList);
                        set(allAuthGroupList, newGroupList);
                        handleSuccess('Auth Group created successfully');
                    })
                    .catch(handleError);
            },
        []
    );

    return {
        createAuthGroup: createAuthGroupCallback,
    };
};
