import { useContext, useEffect, useRef } from 'react';
import { EventTargetContext } from '../components/survey-provider';
import type { SurveyEventMap } from './types';

/**
 * Subscribe to an event on the Document and
 * automatically unsubscribe on unmount.
 */
export function useEventListener<K extends keyof SurveyEventMap>(
    eventName: K,
    listener: (event: SurveyEventMap[K]) => unknown,
    options?: boolean | AddEventListenerOptions
) {
    const eventTargetId = useContext(EventTargetContext);
    const listenerRef = useRef<typeof listener>();

    // Keep the listenerRef up-to-date with the supplied listener.
    // This allows us to always use the most up-to-date listener
    // without triggering re-renders due to dependencies in the
    // next `useEffect()` below.
    useEffect(() => {
        listenerRef.current = listener;
    }, [listener]);

    useEffect(() => {
        const eventListener: typeof listener = (e) => {
            if (
                listenerRef.current === undefined ||
                e.detail.eventTargetId !== eventTargetId
            ) {
                return;
            }

            listenerRef.current(e);
        };

        // We attach to `document` and not `eventTarget`,
        // because the `eventTarget` is not actually inserted
        // into the DOM, so it's listener will never fire.
        document.addEventListener(
            eventName,
            eventListener as EventListener,
            options
        );

        return () =>
            document.removeEventListener(
                eventName,
                eventListener as EventListener
            );
    }, [eventName, eventTargetId, options]);
}

/**
 * Create a function that can used to fire Survey events.
 */
export function useDispatch() {
    const eventTargetId = useContext(EventTargetContext);

    return function dispatchEvent<
        K extends keyof SurveyEventMap,
        Detail extends SurveyEventMap[K]['detail']
    >(eventName: K, detail: Omit<Detail, 'eventTargetId'>): void {
        const event = new CustomEvent(eventName, {
            detail: { ...detail, eventTargetId },
            bubbles: true,
        });

        document.dispatchEvent(event);
    };
}
