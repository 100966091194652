import type { SurveyConfig } from '@dmp/qqms/types';
import { v4 as uuidv4 } from 'uuid';
import { defaultSurvey, defaultSurveyConfig } from './defaultShapes/survey';
import { generateFactory } from './generate-factory';

export const generateSurveyConfig = (
    partialSurveyConfig: Partial<SurveyConfig>
): SurveyConfig => {
    return {
        ...defaultSurveyConfig,
        ...partialSurveyConfig,
    };
};

export const generateSurvey = generateFactory(defaultSurvey, uuidv4);
