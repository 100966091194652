import {
    Alert,
    AlertIcon,
    Box,
    Button,
    Center,
    Code,
    Heading,
    HStack,
    Spinner,
    StackDivider,
    VStack,
} from '@chakra-ui/react';
import { publishSurvey } from '@dmp/qqms/data-access';
import type { Survey } from '@dmp/qqms/types';
import type { RequestError } from '@dmp/shared/client-utils';
import { downloadFile } from '@dmp/shared/client-utils';
import { truthy } from '@dmp/shared/helpers';
import type { ResponseWithErrors } from '@dmp/shared/types';
import { useQuery } from '@tanstack/react-query';
import { memo, useCallback, useMemo } from 'react';
import { AiOutlineCloudDownload as IconDownload } from 'react-icons/ai';
import { VscChevronRight as IconKey } from 'react-icons/vsc';
import { PUBLISH_SURVEY } from '../../../config/query-keys';
import { useSurveyPatch } from '../../../state/survey/use-survey-patch';
import { CopyText } from '../../common/copy-text';

interface PublishSettings {
    surveyId: Survey['id'];
    surveyName: string;
    surveyQQID: string;
    surveyErrors?: string[];
    saveFirst?: boolean;
}

const PublishSettingsCom = (props: PublishSettings) => {
    const { state, download } = usePublishSettings(props);

    if (state.type === 'error') {
        return (
            <VStack h="600px" position="relative" spacing="4">
                <Code
                    w="100%"
                    h="100%"
                    p="4"
                    bg="gray.800"
                    overflowY="auto"
                    color="white"
                    position="relative"
                    rounded="md"
                >
                    <VStack align="start">
                        <Heading as="h6" fontSize="md">
                            Error processing survey config
                        </Heading>
                        {state.errors.map((msg) => (
                            <Alert key={msg} status="error">
                                <AlertIcon /> {msg}
                            </Alert>
                        ))}
                    </VStack>
                </Code>
            </VStack>
        );
    }

    if (state.type === 'fetching') {
        return (
            <Center p="10">
                <Spinner />
            </Center>
        );
    }

    return (
        <VStack position="relative" spacing={4} pb={6}>
            <HStack w="100%" justifyContent="flex-end">
                <Button
                    size="sm"
                    my="2"
                    leftIcon={<IconDownload size="20px" />}
                    variant="usatoday_blue_full"
                    onClick={download}
                >
                    Download Settings TXT
                </Button>
            </HStack>

            <VStack
                w="100%"
                spacing="4"
                divider={<StackDivider opacity="0.2" />}
            >
                {Object.entries(state.data).map(([key, val]) => (
                    <HStack
                        key={key}
                        w="100%"
                        align="start"
                        position="relative"
                        spacing="10"
                    >
                        <HStack w="150px">
                            <IconKey />
                            <Heading as="h2" fontSize="l">
                                {key}
                            </Heading>
                        </HStack>
                        <Code
                            flex="1"
                            minH="48px"
                            maxH="400px"
                            p="4"
                            bg="gray.800"
                            overflowY="auto"
                            color="white"
                            position="relative"
                            rounded="md"
                        >
                            <pre>{val}</pre>
                        </Code>

                        <Box position="absolute" top="0" right="0" p="2">
                            <CopyText text={val} />
                        </Box>
                    </HStack>
                ))}
            </VStack>
        </VStack>
    );
};

export const PublishSettings = memo(PublishSettingsCom);

export interface PublishData {
    Version: string;
    Config: string;
    'Exclusion Segment': string;
}

type PublishSettingsState =
    | { type: 'fetching' }
    | { type: 'error'; errors: string[] }
    | { type: 'success'; data: PublishData };

const usePublishSettings = (props: PublishSettings) => {
    const {
        surveyQQID,
        surveyName,
        surveyId,
        surveyErrors = [],
        saveFirst = true,
    } = props;

    const { patchSurvey } = useSurveyPatch();

    const query = useQuery(
        [PUBLISH_SURVEY],
        async () => {
            // if survey needs to  be saved first
            const preFetch = saveFirst ? patchSurvey : () => Promise.resolve();

            await preFetch();

            // give server time to digest saved data.
            if (saveFirst) {
                await delay(2000);
            }

            // update type when the route is available
            const resp = await publishSurvey(surveyId);
            const { config, version } = resp;

            const firstQuestionId = config.cards[0].id;

            return {
                Version: version || '',
                Config: JSON.stringify(config, null, ' '),
                'Exclusion Segment': `user_traits is none of ${firstQuestionId}`,
            };
        },
        { enabled: surveyErrors.length === 0 }
    );

    const state = useMemo((): PublishSettingsState => {
        if (surveyErrors.length > 0) {
            return {
                type: 'error',
                errors: surveyErrors,
            };
        }

        switch (query.status) {
            case 'success':
                return { type: 'success', data: query.data };

            case 'error': {
                const msgs = (
                    query.error as RequestError<ResponseWithErrors>
                ).data.errors
                    .map((i) => i.title)
                    .filter(truthy);

                return {
                    type: 'error',
                    errors: msgs,
                };
            }

            default:
                return { type: 'fetching' };
        }
    }, [query.data, query.error, query.status, surveyErrors]);

    const download = useCallback(() => {
        if (state.type !== 'success') {
            return;
        }

        const fields = {
            'Survey Name': surveyName,
            'GAM Creative Template': 'Paramount: QQ',
            'Exclusion Segment': state.data['Exclusion Segment'],
            Version: state.data.Version,
            'Hide Ad Label?': 'yes',
            Config: state.data.Config,
        };

        const text = Object.entries(fields)
            .map(
                ([key, value]) =>
                    '----------------------------------------\n' +
                    `${key}\n` +
                    '----------------------------------------\n' +
                    `${value}\n`
            )
            .join('\n')
            // Use Windows newlines, since most of sales is on Windows machines.
            .replace('\n', '\r\n');

        const filename = `QQMS-${surveyQQID}-${surveyName}.txt`;

        downloadFile(new Blob([text]), filename);
    }, [state, surveyName, surveyQQID]);

    return { state, download };
};

// delay in promise
const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
