import type { Card } from '@dmp/qqms/types';
import * as React from 'react';

import type { VisualCardType } from '../../../utils/getVisualCardType';
import { CardVisualItem } from './card-visual-item';

export type VisualCardTypeChange = Record<string, VisualCardType>;

interface CardVisualTypesProps {
    cardId: Card['id'];
}

const CardVisualTypesCom: React.FC<CardVisualTypesProps> = ({ cardId }) => {
    return (
        <>
            <CardVisualItem
                cardId={cardId}
                visualType="radio"
                name="SingleSelect"
            />

            <CardVisualItem
                cardId={cardId}
                visualType="select"
                name="DropDown"
            />

            <CardVisualItem
                cardId={cardId}
                visualType="multi-select"
                name="MultiSelect"
            />

            <CardVisualItem
                cardId={cardId}
                visualType="thankYouCard"
                name="ThankYou"
            />

            <CardVisualItem
                cardId={cardId}
                visualType="dynamicCoreQuestion"
                name="CoreQuestion"
            />
        </>
    );
};

export const CardVisualTypes = React.memo(CardVisualTypesCom);
export default CardVisualTypes;
