import { jsonApiQuery, filters } from '@dmp/shared/json-api-request';

export const authGroupsFilter = filters({
    oktaAuthGroupIn: {
        type: 'in',
        field: 'oktaAuthGroup',
    },
});

export const authGroupsQueryValidator = jsonApiQuery()
    .filter(authGroupsFilter)
    .sort(['oktaAuthGroup', 'permissionGroup']);

export type AuthGroupsQuery = typeof authGroupsQueryValidator._output;
