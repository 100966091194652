import {
    Button,
    FormControl,
    HStack,
    IconButton,
    InputGroup,
    InputLeftAddon,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverFooter,
    PopoverTrigger,
    Portal,
    Text,
    useColorMode,
    useDisclosure,
    VStack,
} from '@chakra-ui/react';
import { TrimmedInput } from '@dmp/shared/components';
import type { EditorState } from 'draft-js';
import * as React from 'react';
import { MdRemoveCircleOutline as IconRemove } from 'react-icons/md';

import { applyButton, getButtonInstance } from './button';
import { IconButtonClick } from './icon-button-click';
import { isEqualHEXorRGB } from '../color/colorUtils';
import { removeEntity } from '../rte-utils';
import { SwatchColor } from '../color/swatch-color';
import { swatchesGannett } from '../color/color-picker-config';

interface ButtonCommandButtonProps {
    editorState: EditorState;
    setEditorState: (value: EditorState) => void;
}

const ButtonCommandButtonCom: React.FC<ButtonCommandButtonProps> = ({
    editorState,
    setEditorState,
}) => {
    const { onOpen, onClose, isOpen } = useDisclosure();
    const { colorMode } = useColorMode();
    const isDark = colorMode === 'dark';

    const [localUrl, setLocalUrl] = React.useState<string>('');
    const [localBgColor, setLocalBgColor] = React.useState<string>('');
    // localText is only for Button Creation
    const [localText, setLocalText] = React.useState<string>('');

    const apply = () => {
        const newState = applyButton(editorState)(
            localUrl,
            localBgColor,
            localText
        );
        setEditorState(newState);
        onClose();
    };

    const remove = () => {
        const newState = removeEntity(editorState);
        setEditorState(newState);
    };

    const buttonInstance = getButtonInstance(editorState);
    const isActive = !!buttonInstance;

    // when the popover is open
    // reset value or fill in defined values
    const handleOpen = () => {
        onOpen();

        if (!buttonInstance) {
            setLocalUrl('');
            setLocalBgColor('');
            setLocalText('');
            return;
        }

        const { url, backgroundColor } = buttonInstance.getData();
        setLocalUrl(url);
        setLocalBgColor(backgroundColor);
    };

    return (
        <Popover isLazy isOpen={isOpen} onOpen={handleOpen} onClose={onClose}>
            <PopoverTrigger>
                <IconButton
                    size="xs"
                    variant="ghost"
                    title="button"
                    aria-label="button"
                    icon={<IconButtonClick w="35px" h="30px" />}
                    color={isActive ? (isDark ? 'brand.200' : 'brand.100') : ''}
                />
            </PopoverTrigger>
            <Portal>
                <PopoverContent>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverBody py="10" px="5">
                        <form onSubmit={apply}>
                            {!isActive && (
                                <FormControl id="button-text">
                                    <InputGroup size="sm" rounded="md" pb="2">
                                        <InputLeftAddon rounded="md">
                                            Text
                                        </InputLeftAddon>
                                        <TrimmedInput
                                            id="button-text"
                                            rounded="md"
                                            autoComplete="off"
                                            placeholder="My button"
                                            value={localText}
                                            onChange={(e) =>
                                                setLocalText(e.target.value)
                                            }
                                        />
                                    </InputGroup>
                                </FormControl>
                            )}

                            <FormControl id="link-url">
                                <InputGroup size="sm" rounded="md" pb="2">
                                    <InputLeftAddon rounded="md">
                                        URL
                                    </InputLeftAddon>
                                    <TrimmedInput
                                        id="link-url"
                                        rounded="md"
                                        autoComplete="off"
                                        value={localUrl}
                                        placeholder="https://google.com"
                                        onChange={(e) =>
                                            setLocalUrl(e.target.value)
                                        }
                                    />
                                </InputGroup>
                            </FormControl>

                            <VStack align="start" pt="4">
                                <Text fontSize="sm">Background Color</Text>
                                <HStack spacing="0" wrap="wrap">
                                    {swatchesGannett.map((swatch) => (
                                        <React.Fragment key={swatch.name}>
                                            {swatch.colors.map((color) => (
                                                <SwatchColor
                                                    key={color}
                                                    name={swatch.name}
                                                    value={color}
                                                    isActive={isEqualHEXorRGB(
                                                        color,
                                                        localBgColor
                                                    )}
                                                    onChange={setLocalBgColor}
                                                />
                                            ))}
                                        </React.Fragment>
                                    ))}
                                </HStack>
                            </VStack>
                        </form>
                    </PopoverBody>
                    <PopoverFooter>
                        <HStack w="100%" justifyContent="space-between">
                            <Button
                                size="sm"
                                leftIcon={<IconRemove />}
                                onClick={remove}
                                disabled={!buttonInstance}
                            >
                                Remove
                            </Button>
                            <Button
                                size="sm"
                                variant="usatoday_blue_full"
                                onClick={apply}
                            >
                                {isActive ? 'Apply' : 'Add'}
                            </Button>
                        </HStack>
                    </PopoverFooter>
                </PopoverContent>
            </Portal>
        </Popover>
    );
};

export const ButtonCommandButton = React.memo(ButtonCommandButtonCom);
