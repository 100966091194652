import type { StackProps } from '@chakra-ui/react';
import { VStack } from '@chakra-ui/react';
import type { SponsorInfo } from '@dmp/qqms/types';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { surveySponsorInfoState } from '../../../state/survey/survey-atoms';
import { SurveySponsoredCopy } from './survey-sponsored-copy';
import { SurveySponsoredLogo } from './survey-sponsored-logo';

const DEFAULT_SPONSOR_INFO: SponsorInfo = {
    logo: '',
    copy: 'In partnership with',
};

export function SurveySponsorInfo(props: StackProps) {
    const { sponsorInfo, onChangeCopy, onChangeLogo } = useSponsorInfo();

    return (
        <VStack spacing="4" {...props}>
            <SurveySponsoredCopy
                value={sponsorInfo?.copy || ''}
                placeholder={DEFAULT_SPONSOR_INFO.copy}
                isDisabled={sponsorInfo === undefined}
                onChange={onChangeCopy}
            />
            <SurveySponsoredLogo
                value={sponsorInfo?.logo || ''}
                onChange={onChangeLogo}
            />
        </VStack>
    );
}

function useSponsorInfo() {
    const [sponsorInfo, setSponsorInfo] = useRecoilState(
        surveySponsorInfoState
    );

    const onChangeCopy = useCallback(
        (copy: string) =>
            setSponsorInfo((currentValue) => ({
                ...(currentValue || DEFAULT_SPONSOR_INFO),
                copy,
            })),
        [setSponsorInfo]
    );

    const onChangeLogo = useCallback(
        (logo: string) => {
            if (logo) {
                setSponsorInfo((currentValue) => ({
                    copy: currentValue?.copy || DEFAULT_SPONSOR_INFO.copy,
                    logo,
                }));
            } else {
                setSponsorInfo(undefined);
            }
        },
        [setSponsorInfo]
    );

    return {
        onChangeCopy,
        onChangeLogo,
        sponsorInfo,
    };
}
