import { memo } from 'react';
import type { IconProps } from '@chakra-ui/react';
import { Icon } from '@chakra-ui/react';

const IconCoreQuestionCom = (props: IconProps) => {
    return (
        <Icon viewBox="0 0 80 60" {...props}>
            <path
                fill="currentColor"
                d="M21.2 31.1h-4.3v-4.2h4.3c.9-8.8 7.9-15.8 16.7-16.7V5.9h4.2v4.3c8.8.9 15.8 7.9 16.7 16.7h4.3v4.2h-4.3c-.9 8.8-7.9 15.8-16.7 16.7v4.3h-4.2v-4.3c-8.8-.9-15.8-7.9-16.7-16.7M40 14.3c-8.1 0-14.7 6.6-14.7 14.7S31.9 43.7 40 43.7 54.7 37.1 54.7 29 48.1 14.3 40 14.3M38.2 40h3.7v-3.7h-3.7V40M40 18c-4.1 0-7.4 3.3-7.4 7.4h3.7c0-2 1.6-3.7 3.7-3.7s3.7 1.6 3.7 3.7c0 3.7-5.5 3.2-5.5 9.2h3.7c0-4.1 5.5-4.6 5.5-9.2 0-4.1-3.3-7.4-7.4-7.4z"
            />
        </Icon>
    );
};

export const IconCoreQuestion = memo(IconCoreQuestionCom);
