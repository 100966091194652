import type { FormEventHandler } from 'react';
import { useState } from 'react';

interface FreeFormComponentProps {
    label: string;
    onAnswer: () => void;
    id?: string;
}

export function FreeFormComponent({
    id,
    label,
    onAnswer,
}: FreeFormComponentProps) {
    const [wasFocused, setWasFocused] = useState(false);

    const onFocus = () => {
        setWasFocused(true);
    };

    const onSubmit: FormEventHandler<HTMLFormElement> = (e) => {
        e.preventDefault();
        onAnswer();
    };

    return (
        <form id={id} autoComplete="off" onSubmit={onSubmit}>
            <label
                htmlFor="free-form"
                className={wasFocused ? 'visually-hidden' : undefined}
            >
                {label}
            </label>

            <input
                name="free-form"
                className={wasFocused ? 'focused' : undefined}
                placeholder="Please enter your answer"
                onFocus={wasFocused ? undefined : onFocus}
            />

            <button
                className={wasFocused ? undefined : 'visually-hidden'}
                type="submit"
            >
                Submit
            </button>
        </form>
    );
}
