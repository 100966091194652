import { useRecoilValue } from 'recoil';
import * as React from 'react';

import type { Card } from '@dmp/qqms/types';
import {
    cardAnswerIdsState,
    cardTypeState,
} from '../../../../state/card/card-atoms';
import { OptionByCardId } from '../../../common/option-by-card-id';

interface PollResultOptionComProps {
    cardId: Card['id'];
}

const PollResultOptionCom = ({ cardId }: PollResultOptionComProps) => {
    const cardType = useRecoilValue(cardTypeState(cardId));
    const cardAnswers = useRecoilValue(cardAnswerIdsState(cardId));

    const validLength = cardAnswers.length >= 2 && cardAnswers.length <= 8;

    // Only allows poll result from linear/forked question
    // + valid answer length
    if (
        (cardType === 'linearQuestion' || cardType !== 'forkedQuestion') &&
        validLength
    ) {
        return <OptionByCardId cardId={cardId} />;
    }

    return null;
};

export const PollResultOption = React.memo(PollResultOptionCom);
