import type { SurveyItemResponse } from '@dmp/qqms/data-access';
import { fetchSurvey } from '@dmp/qqms/data-access';
import { useQuery } from '@tanstack/react-query';
import { useRecoilCallback } from 'recoil';
import { SURVEY } from '../../config/query-keys';
import { surveyResourceToState } from '../../transformers/survey-resource-to-state';
import { answerItemState } from '../answer/answer-atoms';
import { cardItemState } from '../card/card-atoms';
import { surveyState } from '../survey/survey-atoms';
import { isSurveyDirtyState } from '../ui/is-survey-dirty-atoms';
import { previousSurveyName } from '../ui/previous-survey-name';

/**
 * Fetch and process response to atom states
 */
export function useSurveyFetch(surveyId: string) {
    const insertSurveyIntoState = useRecoilCallback(
        ({ set }) =>
            async (surveyResponse: SurveyItemResponse) => {
                const { survey, cards, answers } = surveyResourceToState(
                    surveyResponse.data
                );

                /**
                 * Components starts rendering as soon as it has survey.
                 * Therefore, we create in reverse order: answers -> cards -> and finally survey
                 * to reduce unnecessary re-renderings.
                 */
                // create individual answer atom
                answers.forEach((answer) =>
                    set(answerItemState(answer.id), answer)
                );

                // create individual card atom
                cards.forEach((card) => set(cardItemState(card.id), card));

                // create survey atom
                set(surveyState, survey);

                // record original name of the survey
                // this will tell api if it needs to update survey name or not
                set(previousSurveyName, survey.name);

                // make survey pristine
                setTimeout(() => set(isSurveyDirtyState, false), 500);
            },
        []
    );

    /**
     * Fetch a Survey from the API and insert it into state.
     */
    const query = useQuery([SURVEY, surveyId], () => fetchSurvey(surveyId), {
        enabled: false, // Must be manually triggered.
        onSuccess: insertSurveyIntoState,
    });

    return {
        insertSurveyIntoState,
        fetchSurveyIntoState: query.refetch,
    };
}
