import { Select, useColorMode } from '@chakra-ui/react';
import { EditorState, Modifier, RichUtils } from 'draft-js';
import * as React from 'react';
import { last } from 'remeda';

export const fontStyleMap = {
    FONT_60: { fontSize: '60%' },
    FONT_80: { fontSize: '80%' },
    FONT_100: { fontSize: '100%' },
    FONT_120: { fontSize: '120%' },
    FONT_140: { fontSize: '140%' },
    FONT_160: { fontSize: '160%' },
};

export const fontSizes = Object.keys(fontStyleMap);

export type FontSize = keyof typeof fontStyleMap;

interface FontSizeCommandButtonProps {
    editorState: EditorState;
    setEditorState: (value: EditorState) => void;
}

const FontSizeCommandButtonCom: React.FC<FontSizeCommandButtonProps> = ({
    editorState,
    setEditorState,
}) => {
    const { colorMode } = useColorMode();
    const isDark = colorMode === 'dark';

    // get inline font styles at the cursor
    const currentFontStyles = getCurrentFontStyles(editorState);
    const value = last(currentFontStyles);

    const toggle = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const val = e.target.value as FontSize;
        const newState = toggleFontStyle(editorState)(val);
        setEditorState(newState);
    };

    const color = value ? (isDark ? 'brand.200' : 'brand.100') : 'inherit';

    return (
        <Select
            size="xs"
            w="75px"
            border="0"
            h="24px"
            rounded="md"
            fontWeight="bold"
            color={color}
            value={value ?? 'FONT_100'}
            onChange={toggle}
        >
            {fontSizes.map((name) => {
                const size = name.split('_')[1];
                return (
                    <option key={name} value={name}>
                        {size}%
                    </option>
                );
            })}
        </Select>
    );
};

export const FontSizeCommandButton = React.memo(FontSizeCommandButtonCom);

const clearInlineStyles = (editorState: EditorState) => (styles: string[]) => {
    const newContent = styles.reduce(
        (carrier, style) =>
            Modifier.removeInlineStyle(
                carrier,
                editorState.getSelection(),
                style
            ),
        editorState.getCurrentContent()
    );

    return EditorState.set(editorState, {
        currentContent: newContent,
    });
};

const getCurrentFontStyles = (editorState: EditorState) => {
    return editorState
        .getCurrentInlineStyle()
        .filter((v) => fontSizes.includes(v as FontSize))
        .toArray();
};

/**
 * Toggle font style at the selection
 * - Ensure only 1 font style applied at the selection
 */
const toggleFontStyle = (editorState: EditorState) => (value: FontSize) => {
    const fontStyles = getCurrentFontStyles(editorState);
    const stateWithoutFontSize = clearInlineStyles(editorState)(fontStyles);

    if (value === 'FONT_100') {
        return stateWithoutFontSize;
    }

    return RichUtils.toggleInlineStyle(stateWithoutFontSize, value);
};
