import type { Card, Survey } from '@dmp/qqms/types';
import {
    isForkedQuestion,
    isQuestion,
    isThankYouCard,
    isPollResultCard,
    selectNextCardId,
} from '@dmp/qqms/survey-utils';
import { getCardById } from './getCardById';

export interface TreeInfo {
    name: string;
    children: TreeInfo[];
    data?: Card;
}

const cardName = (card: Card) =>
    isQuestion(card) || isThankYouCard(card) ? card.body : card.type;

/**
 * Convert cards to tree structure
 */
export const convertCardsToTree = (
    card: Card | undefined,
    cards: Survey['cards'],
    ancestorCardIds: Array<Card['id']> = []
): TreeInfo => {
    // ancestors id is repeated => Looped
    if (!card || ancestorCardIds.includes(card.id)) {
        return { name: 'Error: Looped Question', children: [] };
    }

    const ancestorIds = ancestorCardIds.concat(card.id);
    let children: TreeInfo[] = [];

    // is Forked Question
    // - connect via answer's `resultCard` and fallback to the next index card
    if (isForkedQuestion(card)) {
        children = card.answers.map((a) => {
            const connectId = selectNextCardId({
                question: card,
                cards,
                answerId: a.id,
            });
            return convertCardsToTree(
                getCardById(connectId, cards),
                cards,
                ancestorIds
            );
        });
        // is others, NON-ThankYou Card
        // - connect via question's `resultCard` and fallback to the next index card
    } else if (!isThankYouCard(card) && !isPollResultCard(card)) {
        const connectId = selectNextCardId({
            question: card,
            cards,
            answerId: '', // non-relevant
        });
        children = [
            convertCardsToTree(
                getCardById(connectId, cards),
                cards,
                ancestorIds
            ),
        ];
    }

    return {
        name: cardName(card),
        children,
        data: card,
    };
};

/**
 * Extract all connected cards based on the survey treeInfo
 */
export const extractConnectedCards = (
    acc: string[],
    treeInfo?: TreeInfo
): string[] => {
    if (!treeInfo) {
        return acc;
    }

    const newAcc = treeInfo.data?.id ? acc.concat(treeInfo.data.id) : acc;

    return treeInfo.children.reduce(
        (innerAcc, childTreeInfo) =>
            extractConnectedCards(innerAcc, childTreeInfo),
        newAcc
    );
};
