import { ChakraProvider, createLocalStorageManager } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import type { ReactNode } from 'react';
import { StrictMode } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import theme from './theme';

// Create a client
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchInterval: false,
            refetchOnWindowFocus: false,
        },
    },
});

interface AppProviderProps {
    children: ReactNode;
}

const colorModeManager = createLocalStorageManager('qqms-color-mode');

export function AppProvider({ children }: AppProviderProps) {
    return (
        <StrictMode>
            <BrowserRouter>
                <ChakraProvider
                    theme={theme}
                    colorModeManager={colorModeManager}
                >
                    <QueryClientProvider client={queryClient}>
                        <RecoilRoot>{children}</RecoilRoot>
                    </QueryClientProvider>
                </ChakraProvider>
            </BrowserRouter>
        </StrictMode>
    );
}
