import { useColorMode } from '@chakra-ui/color-mode';
import { FormControl, FormLabel } from '@chakra-ui/form-control';
import { TrimmedInput } from '@dmp/shared/components';
import type { FC } from 'react';
import { memo } from 'react';
import { useRecoilState } from 'recoil';
import { surveyTraxexLabelState } from '../../../state/survey/survey-atoms';
import { trackingFieldStyles } from '../../../theme/common-styles/tracking-field-styles';

const SurveyTraxexLabelCom: FC = () => {
    const [surveyTraxexLabel, setSurveyTraxexLabel] = useRecoilState(
        surveyTraxexLabelState
    );

    const { colorMode } = useColorMode();
    const { label, input } = trackingFieldStyles(colorMode);

    return (
        <FormControl id="survey-tracking-label">
            <FormLabel {...label}>Survey Tracking Label</FormLabel>
            <TrimmedInput
                {...input}
                value={surveyTraxexLabel}
                onChange={(e) => setSurveyTraxexLabel(e.target.value)}
            />
        </FormControl>
    );
};

export const SurveyTraxexLabel = memo(SurveyTraxexLabelCom);
