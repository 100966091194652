import { decodePollResultsKey } from '@dmp/qqms/data-access';
import type { PollResponse } from '@dmp/qqms/types';
import { http } from 'msw';
import { setupWorker } from 'msw/browser';
import { useEffect, useRef } from 'react';
import { z } from 'zod';

const protocol = window.location.protocol;
export const MOCK_QQMS_API = `${protocol}//mock-api.com`;

export function usePollResultInterceptor(): void {
    const serviceWorkerJob = useRef<Promise<unknown> | undefined>();

    useEffect(() => {
        const handlers = [
            http.get(
                `${MOCK_QQMS_API}/api/poll-results/:pollResultsKey`,
                async ({ params }) => {
                    try {
                        const pollResultsKey = z
                            .string()
                            .parse(params.pollResultsKey);

                        const answerIds = decodePollResultsKey(pollResultsKey);
                        const pollResults = mockPollResults(answerIds);

                        // Return a Not Found response if we can't find the Question.
                        if (pollResults === undefined) {
                            console.log('could not find question');
                            return new Response(undefined, { status: 404 });
                        }

                        return new Response(JSON.stringify(pollResults), {
                            headers: {
                                'Access-Control-Allow-Origin': '*',
                            },
                            status: 200,
                        });
                    } catch (e) {
                        console.error(e);

                        return new Response(undefined, { status: 404 });
                    }
                }
            ),
        ];

        const worker = setupWorker(...handlers);

        // React 18+'s double `useEffect()` is faster than we can build up and tear down
        // service workers, so we use Promise chains in order to queue this process.
        async function startWorker() {
            const currentJob = serviceWorkerJob.current || Promise.resolve();

            serviceWorkerJob.current = currentJob.then(async () => {
                await worker.start({ onUnhandledRequest: 'bypass' });
            });
        }

        async function stopWorker() {
            const currentJob = serviceWorkerJob.current || Promise.resolve();

            serviceWorkerJob.current = currentJob.then(() => {
                worker.stop();
            });
        }

        startWorker();

        return () => {
            stopWorker();
        };
    }, []);
}

function mockPollResults(
    answerIds: string[],
    total: number = 1e6
): PollResponse {
    const percents = mockPercent(answerIds.length);

    const results = answerIds.map((answerId, index) => ({
        answerId,
        percent: percents[index],
        votes: Math.floor((total * percents[index]) / 100),
        isAnswered: index === 1,
    }));

    return {
        total,
        results,
    };
}

// instead of randomizing, have these predefined numbers instead
function mockPercent(length: number): number[] {
    const data = [
        [],
        [100],
        [40, 60],
        [30, 60, 10],
        [10, 30, 40, 20],
        [25, 10, 25, 20, 20],
        [5, 10, 25, 10, 30, 20],
        [10, 10, 25, 10, 2, 20, 23],
        [10, 10, 25, 10, 5, 20, 5, 15],
    ];

    return data[length];
}
