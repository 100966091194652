import { attachPollResultsKey } from '@dmp/qqms/data-access';
import { isQuestion } from '@dmp/qqms/survey-utils';
import type { Survey } from '@dmp/qqms/types';
import { truthy } from '@dmp/shared/helpers';
import { isPollResultCardItem } from '../card/card-type-guards';
import type { CollectiveState } from '../survey-config/collective-state-atoms';
import { convertToCardConfig } from './convert-to-card-config';

/**
 * Transform survey Recoil state to survey config
 */
export const convertToSurveyConfig = (
    collectiveState: CollectiveState
): Survey => ({
    ...collectiveState.survey,
    cards: transformCards(collectiveState),
});

const transformCards = (collectiveState: CollectiveState): Survey['cards'] =>
    collectiveState.survey.cards
        .map((cardId) => {
            const cardState = collectiveState.cards.find(
                (c) => c.id === cardId
            );

            // This wouldn't occur, just in case there's a zombie card somewhere.
            if (!cardState) {
                return undefined;
            }

            const card = convertToCardConfig(
                cardState,
                collectiveState.answers,
                collectiveState.allCoreQuestions
            );

            const hasPollResults = collectiveState.cards.some(
                (c) => isPollResultCardItem(c) && c.questionId === card.id
            );

            if (isQuestion(card) && hasPollResults) {
                return attachPollResultsKey(card);
            }

            return card;
        })
        .filter(truthy);
