import { memo } from 'react';
import type { IconProps } from '@chakra-ui/react';
import { Icon } from '@chakra-ui/react';

const IconDropDownCom = (props: IconProps) => {
    return (
        <Icon viewBox="0 0 80 60" {...props}>
            <path
                d="M58.3 26.2c-.2-.1-.4-.2-.7-.2h-5.3c-.8 0-1.4.6-1.4 1.4 0 .2.1.5.2.7l2.7 4.6c.4.7 1.2.9 1.9.5.2-.1.4-.3.5-.5l2.7-4.6c.3-.7.1-1.5-.6-1.9z"
                fill="none"
            />
            <path
                fill="currentColor"
                d="M60.5 20h-41c-3 0-5.5 2.5-5.5 5.5v8c0 3 2.5 5.5 5.5 5.5h41c3 0 5.5-2.5 5.5-5.5v-8c0-3-2.5-5.5-5.5-5.5zm-1.7 8.1l-2.7 4.6c-.1.2-.3.4-.5.5-.7.4-1.5.2-1.9-.5L51 28.1c-.1-.2-.2-.4-.2-.7 0-.8.6-1.4 1.4-1.4h5.3c.2 0 .5.1.7.2.8.4 1 1.2.6 1.9z"
            />
        </Icon>
    );
};

export const IconDropDown = memo(IconDropDownCom);
