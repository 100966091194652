export interface Swatch {
    name: string;
    colors: string[];
}

export const swatchesGannett: Swatch[] = [
    { name: 'Gannett Blue', colors: ['#049bff'] },
    { name: 'Gannett Darker Blue', colors: ['#1665cf'] },
    { name: 'Gannett Opinion', colors: ['#4F4BB6'] },
    { name: 'Gannett Tech', colors: ['#D34600'] },
    { name: 'Gannett Sports', colors: ['#AA1E23'] },
    { name: 'Gannett Entertainment & Life', colors: ['#691464'] },
    { name: 'Gannett Finance', colors: ['#00553C'] },
    { name: 'Gannett Travel', colors: ['#005F64'] },
    { name: 'Gannett Text', colors: ['#303030'] },
    { name: 'Gannett Text Gray', colors: ['#626262'] },
];

export const swatches: Swatch[] = [
    {
        name: 'blackAlpha',
        colors: [
            'rgba(0, 0, 0, 0.04)',
            'rgba(0, 0, 0, 0.06)',
            'rgba(0, 0, 0, 0.08)',
            'rgba(0, 0, 0, 0.16)',
            'rgba(0, 0, 0, 0.24)',
            'rgba(0, 0, 0, 0.36)',
            'rgba(0, 0, 0, 0.48)',
            'rgba(0, 0, 0, 0.64)',
            'rgba(0, 0, 0, 0.80)',
            'rgba(0, 0, 0, 0.92)',
        ],
    },
    {
        name: 'gray variants',
        colors: [
            '#F7FAFC',
            '#EDF2F7',
            '#E2E8F0',
            '#CBD5E0',
            '#A0AEC0',
            '#718096',
            '#4A5568',
            '#2D3748',
            '#1A202C',
            '#171923',
        ],
    },
    {
        name: 'red variants',
        colors: [
            '#FFF5F5',
            '#FED7D7',
            '#FEB2B2',
            '#FC8181',
            '#F56565',
            '#E53E3E',
            '#C53030',
            '#9B2C2C',
            '#822727',
            '#63171B',
        ],
    },
    {
        name: 'orange variants',
        colors: [
            '#FFFAF0',
            '#FEEBC8',
            '#FBD38D',
            '#F6AD55',
            '#ED8936',
            '#DD6B20',
            '#C05621',
            '#9C4221',
            '#7B341E',
            '#652B19',
        ],
    },
    {
        name: 'yellow variants',
        colors: [
            '#FFFFF0',
            '#FEFCBF',
            '#FAF089',
            '#F6E05E',
            '#ECC94B',
            '#D69E2E',
            '#B7791F',
            '#975A16',
            '#744210',
            '#5F370E',
        ],
    },
    {
        name: 'green variants',
        colors: [
            '#F0FFF4',
            '#C6F6D5',
            '#9AE6B4',
            '#68D391',
            '#48BB78',
            '#38A169',
            '#2F855A',
            '#276749',
            '#22543D',
            '#1C4532',
        ],
    },
    {
        name: 'teal variants',
        colors: [
            '#E6FFFA',
            '#B2F5EA',
            '#81E6D9',
            '#4FD1C5',
            '#38B2AC',
            '#319795',
            '#2C7A7B',
            '#285E61',
            '#234E52',
            '#1D4044',
        ],
    },
    {
        name: 'blue variants',
        colors: [
            '#ebf8ff',
            '#bee3f8',
            '#90cdf4',
            '#63b3ed',
            '#4299e1',
            '#3182ce',
            '#2b6cb0',
            '#2c5282',
            '#2a4365',
            '#1A365D',
        ],
    },
    {
        name: 'cyan variants',
        colors: [
            '#EDFDFD',
            '#C4F1F9',
            '#9DECF9',
            '#76E4F7',
            '#0BC5EA',
            '#00B5D8',
            '#00A3C4',
            '#0987A0',
            '#086F83',
            '#065666',
        ],
    },
    {
        name: 'purple variants',
        colors: [
            '#FAF5FF',
            '#E9D8FD',
            '#D6BCFA',
            '#B794F4',
            '#9F7AEA',
            '#805AD5',
            '#6B46C1',
            '#553C9A',
            '#44337A',
            '#322659',
        ],
    },
    {
        name: 'pink variants',
        colors: [
            '#FFF5F7',
            '#FED7E2',
            '#FBB6CE',
            '#F687B3',
            '#ED64A6',
            '#D53F8C',
            '#B83280',
            '#97266D',
            '#702459',
            '#521B41',
        ],
    },
];
