import { useRecoilCallback } from 'recoil';

import type { CoreQuestion } from '@dmp/qqms/types';
import type { CardItemState } from '../state/card/card-types';
import { cardQuestionsState } from '../state/card/card-atoms';

/**
 * Set core questions by ids into Dynamic Core Question Card
 * - Retrieve and set core questions from global core question resources
 */
export const useSetQuestionsForDynamicCoreQuestion = (
    cardId: CardItemState['id']
) => {
    const setQuestionsForDynamicCoreQuestion = useRecoilCallback(
        ({ set }) =>
            async (ids: Array<CoreQuestion['id']>) => {
                set(cardQuestionsState(cardId), ids);
            },
        [cardId]
    );

    return { setQuestionsForDynamicCoreQuestion };
};
