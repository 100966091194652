import { Heading, HStack, StackDivider, VStack } from '@chakra-ui/react';
import type { FC } from 'react';
import { memo } from 'react';

import { PaperCard } from '../../common/paper-card';
import { SurveyAboutCopy } from './survey-about-copy';
import { SurveyName } from './survey-name';
import { SurveySponsorInfo } from './survey-sponsor-info';
import { SurveyTraxexLabel } from './survey-traxex-label';

const SurveyFormCom: FC = () => {
    return (
        <PaperCard
            w="100%"
            maxW="1300px"
            mb="8"
            spacing="6"
            shadow="lg"
            direction="row"
            divider={<StackDivider />}
        >
            <Heading as="h1" fontSize="20" w="140px" p="5">
                Survey Properties
            </Heading>

            <HStack p="5" align="top" spacing="8" width="100%">
                <VStack flex="1" align="stretch" spacing="4">
                    <HStack spacing="4">
                        <SurveyName />
                    </HStack>
                    <HStack spacing="4">
                        <SurveyTraxexLabel />
                    </HStack>
                    <SurveyAboutCopy />
                </VStack>
                <SurveySponsorInfo width="25%" />
            </HStack>
        </PaperCard>
    );
};

export const SurveyForm = memo(SurveyFormCom);
