import { useState } from 'react';

interface HeaderComponentProps {
    domain: string;
}

const DEFAULT_LOGO =
    'https://www.gannett-cdn.com/ads/scripts/qqms/logos/usat-network-d-oc.svg';

export function HeaderComponent({ domain }: HeaderComponentProps) {
    const [src, setSrc] = useState(() => {
        const fullDomain =
            !domain || domain.indexOf('www') === 0 ? domain : `www.${domain}`;

        const src = fullDomain
            ? `https://${fullDomain}/sitelogos/d-oc.svg`
            : DEFAULT_LOGO;

        return src;
    });

    const onError =
        src === DEFAULT_LOGO
            ? undefined
            : () => {
                  setSrc(DEFAULT_LOGO);
              };

    return (
        <header>
            <img
                id="publisher-logo"
                alt="Publisher logo"
                src={src}
                onError={onError}
            />
        </header>
    );
}
