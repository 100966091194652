import { Button, Heading, VStack } from '@chakra-ui/react';
import type { Card } from '@dmp/qqms/types';
import * as React from 'react';
import { VscAdd as IconAdd } from 'react-icons/vsc';
import { useRecoilValue } from 'recoil';

import { useAnswerAdd } from '../../../state/answer/use-answer-add';
import {
    cardAnswerIdsState,
    cardTypeState,
} from '../../../state/card/card-atoms';
import { isQuestionCardType } from '../../../state/card/card-type-guards';
import { AnswerItem } from '../../answer-item';
import CardBody from './fields/card-body';
import { CardTraxexLabel } from './fields/card-traxex-label';

interface CardAsRadioComProps {
    cardId: Card['id'];
}

const CardAsRadioCom: React.FC<CardAsRadioComProps> = ({ cardId }) => {
    const cardType = useRecoilValue(cardTypeState(cardId));
    const cardAnswerIds = useRecoilValue(cardAnswerIdsState(cardId));

    const { addAnswer } = useAnswerAdd(cardId);

    if (!isQuestionCardType(cardType)) {
        return null;
    }

    return (
        <>
            <VStack align="stretch" spacing="8">
                <VStack align="stretch">
                    <Heading as="h2" fontSize="sm">
                        Question
                    </Heading>

                    <CardBody cardId={cardId} />

                    <VStack pl="8" spacing="4">
                        <CardTraxexLabel cardId={cardId} />
                    </VStack>
                </VStack>

                <VStack align="stretch">
                    <Heading as="h2" fontSize="sm">
                        Answers
                    </Heading>

                    {cardAnswerIds.map((answerId) => (
                        <AnswerItem
                            key={answerId}
                            cardId={cardId}
                            answerId={answerId}
                        />
                    ))}
                </VStack>
            </VStack>

            <Button variant="ghost" leftIcon={<IconAdd />} onClick={addAnswer}>
                add answer
            </Button>
        </>
    );
};

export const CardAsRadio = React.memo(CardAsRadioCom);
