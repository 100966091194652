import { useRecoilCallback } from 'recoil';
import { omit } from 'remeda';

import {
    answerItemState,
    cardAnswersSelector,
} from '../state/answer/answer-atoms';
import type { AnswerItemState } from '../state/answer/answer-types';
import { cardItemState } from '../state/card/card-atoms';
import { isQuestionItem } from '../state/card/card-type-guards';
import type {
    CardItemState,
    LinearQuestionItemState,
} from '../state/card/card-types';
import { surveyCardIdsState } from '../state/survey/survey-atoms';

export const useQuestionTypeSwitch = (cardId: CardItemState['id']) => {
    const switchQuestionType = useRecoilCallback(
        ({ set, snapshot }) =>
            async (
                type: CardItemState['type'],
                isMultiSelect: boolean = false
            ) => {
                const surveyCardIds = await snapshot.getPromise(
                    surveyCardIdsState
                );

                const card = await snapshot.getPromise(cardItemState(cardId));

                // same type. do nothing
                if (card.type === type) {
                    return;
                }

                const answers = await snapshot.getPromise(
                    cardAnswersSelector(cardId)
                );

                const { newAnswers, newCard } = questionTypeSwitchUtil(
                    card,
                    answers,
                    surveyCardIds
                )(type, isMultiSelect);

                // update card
                set(cardItemState(cardId), newCard);

                // update answers
                newAnswers.forEach((answer) =>
                    set(answerItemState(answer.id), answer)
                );
            },
        []
    );

    return {
        switchQuestionType,
    };
};

/**
 * Merge answer info
 */
export const questionTypeSwitchUtil =
    (
        card: CardItemState,
        answers: AnswerItemState[],
        surveyCardIds: Array<CardItemState['id']>
    ) =>
    (
        type: CardItemState['type'],
        isMultiSelect: boolean = false
    ): { newAnswers: AnswerItemState[]; newCard: CardItemState } => {
        // only switch question items
        if (!isQuestionItem(card)) {
            return { newCard: card, newAnswers: answers };
        }

        // find next card id in the list to connect to
        const nextCardId = getNextCardConnection(card.id, surveyCardIds);

        switch (type) {
            // switch to forkedQuestion
            // - change card type
            // - remove card's `resultCard`
            // - change answer type to 'forkedAnswer'
            // - only Linear Questions can be free-form
            case 'forkedQuestion': {
                if (card.type === 'forkedQuestion') {
                    return { newCard: card, newAnswers: answers };
                }

                return {
                    newCard: {
                        ...omit(card, ['resultCard']),
                        type,
                    },
                    newAnswers: answers.map((a) => {
                        if (a.type === 'forkedAnswer') {
                            return a;
                        }

                        return {
                            ...omit(a, ['isFreeForm']),
                            type: 'forkedAnswer',
                            resultCard: nextCardId,
                        };
                    }),
                };
            }

            // switch to linearQuestion
            // - change card type
            // - add card's `resultCard` link to the next card in survey
            // - change answer type to `linearAnswer`
            // - eliminate `resultCard` key
            case 'linearQuestion': {
                if (card.type === 'linearQuestion') {
                    return { newCard: card, newAnswers: answers };
                }

                const newCard: LinearQuestionItemState = {
                    ...card,
                    type,
                    formType: isMultiSelect ? 'multi-select' : card.formType,
                    resultCard: nextCardId,
                };

                return {
                    newCard,
                    newAnswers: answers.map((answer) => {
                        if (answer.type === 'linearAnswer') {
                            return answer;
                        }

                        return {
                            ...omit(answer, ['resultCard']),
                            type: 'linearAnswer',
                        };
                    }),
                };
            }

            // do nothing for unsupported switch types
            default:
                return {
                    newCard: card,
                    newAnswers: answers,
                };
        }
    };

/**
 * Get the next card id for connection in the survey list
 */
const getNextCardConnection = (
    cardId: CardItemState['id'],
    cardIdList: Array<CardItemState['id']>
) => {
    const cardIndex = cardIdList.findIndex((id) => id === cardId);
    return cardIdList[cardIndex + 1] || '';
};
