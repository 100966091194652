import {
    Button,
    useColorMode,
    Text,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalCloseButton,
    HStack,
    InputGroup,
    InputLeftAddon,
} from '@chakra-ui/react';
import { VscAdd as IconAdd, VscError as IconError } from 'react-icons/vsc';
import { useRef, memo } from 'react';

import { useSurveyCreateButton } from './use-survey-create-button';
import { TrimmedInput } from '@dmp/shared/components';

const SurveyCreateButtonCom = () => {
    const { colorMode } = useColorMode();
    const initialRef = useRef<HTMLInputElement>(null);

    const {
        error,
        handleChange,
        handleModalClose,
        handleSubmit,
        handleKeyDown,
        isOpen,
        onClose,
        onOpen,
        qqid,
        surveyName,
    } = useSurveyCreateButton();

    return (
        <>
            <Button
                variant="usatoday_blue_full"
                size="sm"
                leftIcon={<IconAdd />}
                onClick={onOpen}
            >
                Add Survey
            </Button>

            <Modal
                isOpen={isOpen}
                onClose={handleModalClose}
                initialFocusRef={initialRef}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Create New Survey</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text fontWeight="bold">Survey name</Text>
                        <InputGroup>
                            <InputLeftAddon
                                fontWeight="bold"
                                px="2"
                                title="qqid"
                            >
                                {qqid}
                            </InputLeftAddon>
                            <TrimmedInput
                                ref={initialRef}
                                placeholder="enter name"
                                onChange={handleChange}
                                onKeyDown={handleKeyDown}
                                value={surveyName}
                                borderColor={
                                    colorMode === 'light'
                                        ? 'gray.300'
                                        : 'gray.600'
                                }
                                isInvalid={!!error}
                                errorBorderColor="red.300"
                            />
                        </InputGroup>
                        {error && (
                            <HStack color="red.300" py="1">
                                <IconError />
                                <Text>{error}</Text>
                            </HStack>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button mr="3" onClick={onClose}>
                            Cancel
                        </Button>
                        <Button
                            variant="usatoday_blue_full"
                            onClick={handleSubmit}
                        >
                            Create
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export const SurveyCreateButton = memo(SurveyCreateButtonCom);
