import { VStack } from '@chakra-ui/react';
import type { Card } from '@dmp/qqms/types';
import * as React from 'react';

import { useRecoilValue } from 'recoil';
import { CardConnectionForkedAnswer } from './card-connection-forked-answer';
import { cardAnswerIdsState } from '../../../state/card/card-atoms';

interface CardConnectionForkedProps {
    cardId: Card['id'];
}

const CardConnectionForkedCom: React.FC<CardConnectionForkedProps> = ({
    cardId,
}) => {
    const answerIds = useRecoilValue(cardAnswerIdsState(cardId));

    return (
        <VStack align="start" spacing="2">
            {answerIds.map((answerId) => (
                <CardConnectionForkedAnswer
                    key={answerId}
                    cardId={cardId}
                    answerId={answerId}
                />
            ))}
        </VStack>
    );
};

export const CardConnectionForked = React.memo(CardConnectionForkedCom);
