import { generateAnswerId } from '@dmp/qqms/survey-generators';
import type { Card } from '@dmp/qqms/types';
import { useRecoilCallback } from 'recoil';
import { cardItemState } from '../card/card-atoms';
import { isLinearQuestionItem, isQuestionItem } from '../card/card-type-guards';
import type { QuestionItemState } from '../card/card-types';
import { answerItemState } from './answer-atoms';
import type { AnswerItemState } from './answer-types';

export const useAnswerAdd = (cardId: Card['id']) => {
    const addAnswer = useRecoilCallback(
        ({ set, snapshot }) =>
            async () => {
                const card = await snapshot.getPromise(cardItemState(cardId));

                if (!isQuestionItem(card)) {
                    throw new Error('Only Question Cards can have Answers.');
                }

                const { newQuestion, newAnswer } = addAnswerUtil(card);

                // create new answer atom
                set(answerItemState(newAnswer.id), newAnswer);

                // add new answer id into card
                set(cardItemState(cardId), newQuestion);
            },
        [cardId]
    );

    return {
        addAnswer,
    };
};

/**
 * Create new answer and get modified atoms
 */
export const addAnswerUtil = <T extends QuestionItemState>(question: T) => {
    const newAnswer: AnswerItemState = isLinearQuestionItem(question)
        ? {
              type: 'linearAnswer',
              id: generateAnswerId('linearAnswer'),
              body: '',
              traxexLabel: '',
          }
        : {
              type: 'forkedAnswer',
              id: generateAnswerId('forkedAnswer'),
              body: '',
              resultCard: undefined,
              traxexLabel: '',
          };

    // add answer id into question
    const newQuestion = {
        ...question,
        answers: question.answers.concat(newAnswer.id),
    };

    return {
        newQuestion,
        newAnswer,
    };
};
