import { useSurveyState } from '../state/use-survey-state';
import { classes, isSurveyPaginated } from '../utils';
import { CardsComponent } from './cards-component';
import { FooterComponent } from './footer-component';
import { HeaderComponent } from './header-component';
import './styles/survey-component.scss';

interface SurveyComponentProps {
    // The site on which the ad unit is being served.
    site: string;
}

export function SurveyComponent({ site }: SurveyComponentProps) {
    const surveyState = useSurveyState();

    const className = classes(
        isSurveyPaginated(surveyState.survey) && 'paginated',
        surveyState.survey.sponsorInfo && 'sponsored'
    );

    return (
        <div
            id="survey-root"
            className={className}
            data-current-card-type={
                surveyState.type === 'idle'
                    ? surveyState.currentCard.type
                    : surveyState.nextCard.type
            }
        >
            <HeaderComponent domain={site} />
            <CardsComponent />
            <FooterComponent />
        </div>
    );
}
