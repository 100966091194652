import { Box, Text, VStack } from '@chakra-ui/react';
import type { SurveyItemResponse } from '@dmp/qqms/data-access';
import { fetchSurvey } from '@dmp/qqms/data-access';
import type { Survey } from '@dmp/qqms/types';
import { PageTitle } from '@dmp/shared/components';
import { useQuery } from '@tanstack/react-query';
import { memo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SURVEY } from '../../config/query-keys';
import { MOCK_QQMS_API } from '../../hooks/use-poll-result-interceptor';
import { useSurveyFetch } from '../../state/survey/use-survey-fetch';
import { LogoBar } from '../common/logo-bar';
import { SurveyPreviewBox } from './survey-preview-box';

const SurveyProofCom = () => {
    const { surveyId } = useParams() as { surveyId: string };

    const proof = useSurveyProof(surveyId);
    const survey = proof?.survey;

    const title = proof ? `${proof.survey.qqid} - ${proof.name}` : 'Loading...';

    return (
        <Box alignItems="stretch" h="100%">
            <PageTitle title={`Preview: ${title}`} />

            <VStack flex="1" spacing="0" align="stretch" overflow="hidden">
                <LogoBar>
                    <Text fontWeight="bold" lineHeight="1.1">
                        Preview: {title}
                    </Text>
                </LogoBar>

                <VStack
                    flex="1"
                    p={['0', '10']}
                    overflow="auto"
                    className="scrollbar"
                >
                    {survey && <SurveyPreviewBox survey={survey} />}
                </VStack>
            </VStack>
        </Box>
    );
};

export const SurveyProof = memo(SurveyProofCom);

export const useSurveyProof = (
    surveyId: Survey['id']
): { name: string; survey: Survey } | undefined => {
    // store survey response
    const [surveyAttributes, setSurveyAttributes] = useState<
        SurveyItemResponse['data']['attributes'] | undefined
    >(undefined);

    const { insertSurveyIntoState } = useSurveyFetch(surveyId);

    useQuery([SURVEY, surveyId], () => fetchSurvey(surveyId), {
        enabled: surveyAttributes === undefined,
        onSuccess: (resp) => {
            insertSurveyIntoState(resp);
            setSurveyAttributes(resp.data.attributes);
        },
    });

    return surveyAttributes
        ? {
              name: surveyAttributes.name,
              survey: {
                  id: surveyId,
                  qqid: surveyAttributes.qqid,
                  shortId: surveyAttributes.shortId,
                  qqmsApi: MOCK_QQMS_API,
                  ...surveyAttributes.config,
              },
          }
        : undefined;
};
