import { useContext, useEffect } from 'react';
import { PollResultContext } from '../components/survey-provider';
import type { QuestionPollResultState } from './internal/use-poll-results';
import { LOADING_STATE } from './internal/use-poll-results';

/**
 * Get the Poll Results for a Question. It will await a fetch if one is in
 * progress, and it will initiate a fetch if one has not yet been performed.
 */
export function useQuestionPollResult(
    questionId: string
): QuestionPollResultState {
    const context = useContext(PollResultContext);

    if (context === undefined) {
        throw new Error(
            'useQuestionPollResult() must be used inside a <SurveyProvider />.'
        );
    }

    const { state, fetchPollResult } = context;
    const questionState = state[questionId];

    useEffect(() => {
        if (questionState === undefined) {
            fetchPollResult(questionId);
        }
    }, [fetchPollResult, questionId, questionState]);

    return questionState || LOADING_STATE;
}
