import { useEffect, useRef, useState } from 'react';
import { useSurveyState } from '../state/use-survey-state';

export function AboutComponent() {
    const {
        survey: { aboutCopy },
    } = useSurveyState();

    const [isVisible, setIsVisible] = useState(false);
    const toggleVisible = () => setIsVisible((current) => !current);

    const arrowRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        const arrow$ = arrowRef.current;
        const button$ = buttonRef.current;

        if (!isVisible || !arrow$ || !button$) {
            return;
        }

        // Center the arrow over the "About this survey" button.
        const left = button$.offsetLeft + button$.offsetWidth / 2 - 8; // 8 === half the width of the arrow
        arrow$.style.left = `${left}px`;
    }, [isVisible]);

    return (
        <>
            <div id="about-this-survey">
                <button
                    id="about-button"
                    className={isVisible ? 'active' : undefined}
                    onClick={toggleVisible}
                    ref={buttonRef}
                >
                    <span>About this survey</span>
                    <svg width="12" height="12">
                        <path
                            d="M6 1a5 5 0 11-.001 10.001A5 5 0 016 1zm.863 3.945l-2.15.114v.591l.141.014c.071.007.152.024.242.052a.668.668 0 01.18.074c.046.03.085.07.116.124a.31.31 0 01.043.169V8.46c0 .075-.012.13-.032.164-.015.027-.047.052-.102.072a.537.537 0 01-.14.032 4.101 4.101 0 01-.2.02l-.147.011v.603h2.898v-.597l-.14-.016a1.122 1.122 0 01-.338-.094.218.218 0 01-.105-.087.298.298 0 01-.038-.16V5.163l-.046-.112-.061-.061-.12-.046zM6.153 2.6a.965.965 0 00-.677.263c-.192.176-.29.395-.29.647s.099.47.29.645c.189.173.417.26.677.26a.964.964 0 00.672-.26c.192-.175.29-.393.29-.645s-.097-.47-.287-.647a.963.963 0 00-.675-.263z"
                            fill="#626262"
                            fillRule="evenodd"
                        />
                    </svg>
                </button>
            </div>

            <div id="about-popup" className={isVisible ? 'active' : undefined}>
                <div id="about-popup-inner">
                    <div id="about-popup-text">{aboutCopy}</div>
                    <a
                        id="policy-link"
                        href="https://cm.usatoday.com/privacy"
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        Our privacy policy
                    </a>

                    <button
                        id="popup-close-button"
                        onClick={() => setIsVisible(false)}
                    >
                        <svg width="18" height="19">
                            <path
                                d="M13.125 4.423L14.5 5.798l-4.126 4.124 4.126 4.126-1.375 1.375-4.126-4.126-4.124 4.126L3.5 14.048l4.125-4.126L3.5 5.798l1.375-1.375 4.124 4.125 4.126-4.125z"
                                fill="#626262"
                                fillRule="evenodd"
                            />
                        </svg>
                    </button>
                </div>

                <div id="about-arrow" ref={arrowRef} />
            </div>
        </>
    );
}
