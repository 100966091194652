import {
    Box,
    Button,
    Center,
    HStack,
    Link,
    StackDivider,
    VStack,
} from '@chakra-ui/react';
import { Text } from '@chakra-ui/react';

import { countSeq } from '@dmp/shared/client-utils';
import {
    AlertBox,
    PageTitle,
    Pagination,
    SearchBox,
} from '@dmp/shared/components';
import { PaperCard } from '../common/paper-card';
import { SurveyCreateButton } from './survey-create-button';
import { SurveyItem, SurveyItemSkeleton } from './survey-item';
import { useSurveyList } from './use-survey-list';
import { LogoBar } from '../common/logo-bar';
import PermissionGate from '../permission-gate/permission-gate';
import { AuthGate } from '../auth-gate/auth-gate';

export const SurveyList = () => {
    const { handleGoToPage, handleSubmitKeyword, keyword, query, users } =
        useSurveyList();

    return (
        <AuthGate>
            <PageTitle title="QQMS - QuickQuestion Managing System" />

            <VStack align="stretch" spacing="0" h="100%" overflow="hidden">
                <LogoBar>
                    <HStack w="100%" spacing="5">
                        <Text fontWeight="bold">Survey List</Text>
                        <Box flex="1" px="8">
                            <SearchBox
                                initialValue={keyword}
                                onSubmit={handleSubmitKeyword}
                            />
                        </Box>
                        <SurveyCreateButton />
                        <PermissionGate
                            silent
                            permission="coreQuestions.manage"
                        >
                            <Button as={Link} href="/core-questions" size="sm">
                                Admin
                            </Button>
                        </PermissionGate>
                    </HStack>
                </LogoBar>

                <VStack flex="1" py="10" className="scrollbar" overflow="auto">
                    <PaperCard
                        w="100%"
                        maxWidth="1100px"
                        mx="auto"
                        spacing="100"
                        p="10"
                    >
                        <VStack align="stretch" divider={<StackDivider />}>
                            <HStack
                                display={{ base: 'none', lg: 'flex' }}
                                spacing="6"
                            >
                                <Box w="70px">
                                    <Text variant="gray" fontSize="sm">
                                        QQID
                                    </Text>
                                </Box>

                                <Box flex="1">
                                    <Text variant="gray" fontSize="sm">
                                        Survey
                                    </Text>
                                </Box>

                                <Box w="380px">
                                    <Text variant="gray" fontSize="sm">
                                        Actions
                                    </Text>
                                </Box>

                                <Box w="100px">
                                    <Text variant="gray" fontSize="sm">
                                        Updated
                                    </Text>
                                </Box>
                            </HStack>

                            {query.status === 'loading' ? (
                                countSeq(1, 3).map((i) => (
                                    <SurveyItemSkeleton key={i} />
                                ))
                            ) : query.status === 'success' ? (
                                query.data.data.length > 0 ? (
                                    query.data.data.map((sr) => (
                                        <SurveyItem
                                            key={sr.id}
                                            surveyResource={sr}
                                            users={users}
                                        />
                                    ))
                                ) : (
                                    <AlertBox status="info">
                                        No results found.
                                    </AlertBox>
                                )
                            ) : query.status === 'error' ? (
                                <AlertBox status="error">
                                    QQMS experienced an error while retrieving a
                                    list of Surveys.
                                </AlertBox>
                            ) : null}
                        </VStack>

                        {query.status === 'success' && (
                            <Center>
                                <Pagination
                                    isLoading={query.isFetching}
                                    pagination={query.data.meta.pagination}
                                    goTo={handleGoToPage}
                                />
                            </Center>
                        )}
                    </PaperCard>
                </VStack>
            </VStack>
        </AuthGate>
    );
};
