import { createSurvey } from '@dmp/qqms/data-access';
import { useErrorToaster, useSuccessToaster } from '@dmp/shared/components';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { routeSurvey } from '../../config/routeUrls';

export const useSurveyCreate = () => {
    const navigate = useNavigate();

    const { handleError } = useErrorToaster();
    const { handleSuccess } = useSuccessToaster();

    const createSurveyCallback = useCallback(
        (name: string, qqid: string) => {
            createSurvey({ name, qqid })
                .then((resp) => {
                    handleSuccess('Survey created successfully.');

                    navigate(routeSurvey(resp.data.id));
                })
                .catch(handleError);
        },
        [handleError, handleSuccess, navigate]
    );

    return {
        createSurvey: createSurveyCallback,
    };
};
