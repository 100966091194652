import { Checkbox, HStack, Text, useColorMode, VStack } from '@chakra-ui/react';
import * as React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import {
    answerBodyState,
    answerIsFreeFormState,
} from '../../state/answer/answer-atoms';
import { AnswerActionButtons } from './answer-action-buttons';
import { AnswerTraxex } from './answer-traxex';
import { cardFormTypeState, cardTypeState } from '../../state/card/card-atoms';
import { questionFieldStyles } from '../../theme/common-styles/question-field-styles';
import { Textarea } from '../common/textarea';

interface AnswerItemProps {
    cardId: string;
    answerId: string;
}

export const AnswerItemCom = ({ cardId, answerId }: AnswerItemProps) => {
    const { colorMode } = useColorMode();

    const [answerBody, setAnswerBody] = useRecoilState(
        answerBodyState(answerId)
    );

    const cardType = useRecoilValue(cardTypeState(cardId));
    const formType = useRecoilValue(cardFormTypeState(cardId));

    const [isFreeForm, setIsFreeForm] = useRecoilState(
        answerIsFreeFormState(answerId)
    );

    const toggleFreeForm = () =>
        setIsFreeForm((c) => (c === undefined ? true : undefined));

    const { input } = questionFieldStyles(colorMode);

    return (
        <HStack
            align="stretch"
            spacing="1"
            pb="8"
            className="answer"
            position="relative"
        >
            <VStack align="stretch" flex="1">
                <Textarea
                    placeholder="enter answer"
                    {...input}
                    rows={answerBody.length > 45 ? 2 : 1}
                    value={answerBody}
                    onChange={(e) => setAnswerBody(e.target.value)}
                />

                <VStack align="stretch" pl="8" pt="2" spacing="3">
                    {cardType === 'linearQuestion' && formType === 'radio' && (
                        <Checkbox
                            isChecked={!!isFreeForm}
                            onChange={toggleFreeForm}
                            size="md"
                            fontWeight="bold"
                        >
                            <Text fontSize="sm">Free-form answer?</Text>
                        </Checkbox>
                    )}

                    <AnswerTraxex answerId={answerId} />
                </VStack>
            </VStack>

            <AnswerActionButtons cardId={cardId} answerId={answerId} />
        </HStack>
    );
};

export const AnswerItem = React.memo(AnswerItemCom);
