import type {
    Answer,
    Card,
    DynamicCoreQuestion,
    ForkedAnswer,
    ForkedQuestion,
    LinearAnswer,
    LinearQuestion,
    Question,
    ThankYouCard,
    PollResultCard,
} from '@dmp/qqms/types';
import type { RequiredProps } from '@dmp/shared/types';

/********************************
 *
 * Cards
 *
 ********************************/

export function isQuestion(card: Card): card is Question {
    return isLinearQuestion(card) || isForkedQuestion(card);
}

export function isLinearQuestion(card: Card): card is LinearQuestion {
    return card.type === 'linearQuestion';
}

export function isLinearQuestionFromCoreQuestion(
    card: Card
): card is RequiredProps<LinearQuestion, 'isCoreQuestion'> {
    return isLinearQuestion(card) && card.isCoreQuestion === true;
}

export function isForkedQuestion(card: Card): card is ForkedQuestion {
    return card.type === 'forkedQuestion';
}

export function isDynamicCoreQuestion(card: Card): card is DynamicCoreQuestion {
    return card.type === 'dynamicCoreQuestion';
}

export function isThankYouCard(card: Card): card is ThankYouCard {
    return card.type === 'thankYouCard';
}

export function isPollResultCard(card: Card): card is PollResultCard {
    return card.type === 'pollResultCard';
}

/********************************
 *
 * Answers
 *
 ********************************/

export function isForkedAnswer(answer: Answer): answer is ForkedAnswer {
    return answer.type === 'forkedAnswer';
}

export function isLinearAnswer(answer: Answer): answer is LinearAnswer {
    return answer.type === 'linearAnswer';
}
