import { Button, IconButton, useColorMode } from '@chakra-ui/react';
import type { MouseEvent } from 'react';
import React, { memo } from 'react';

import type { RTEType } from './config';

interface CommandButtonProps extends RTEType {
    isActive: boolean;
    onToggle?: (style: string) => unknown;
}

const CommandButtonCom: React.FC<CommandButtonProps> = ({
    icon,
    isActive,
    label,
    onToggle,
    style,
}) => {
    const { colorMode } = useColorMode();
    const isDark = colorMode === 'dark';

    const handleToggle = (e: MouseEvent) => {
        e.preventDefault();
        onToggle && onToggle(style);
    };

    const activeColor = isActive ? (isDark ? 'brand.200' : 'brand.100') : '';

    if (icon) {
        return (
            <IconButton
                size="xs"
                variant="ghost"
                key={style}
                title={label}
                aria-label={label}
                icon={icon}
                color={activeColor}
                // Must not use onClick as it will lose focus highlight
                onMouseDown={handleToggle}
            />
        );
    }

    return (
        <Button
            size="xs"
            fontSize="small"
            px="1"
            variant="ghost"
            key={style}
            title={label}
            aria-label={label}
            leftIcon={icon}
            color={activeColor}
            onMouseDown={handleToggle}
        >
            {label}
        </Button>
    );
};

export const CommandButton = memo(CommandButtonCom);
