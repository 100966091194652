import { Box, VStack } from '@chakra-ui/react';
import { SurveyProvider } from '@dmp/qqms-renderer';
import { SurveyPreview } from '../../common/survey-preview/survey-preview';
import { CardPreviewStatus } from './card-preview-status';
import { useCardToSurveyPreview } from './use-card-to-survey-preview';

interface CardPreviewProps {
    cardId: string;
    width: number;
    height: number;
    previewZoom: number;
}

export function CardPreview({
    cardId,
    width,
    height,
    previewZoom,
}: CardPreviewProps) {
    const survey = useCardToSurveyPreview(cardId);

    if (survey === undefined) {
        return <Box w={width * previewZoom} h={height * previewZoom} />;
    }

    return (
        <SurveyProvider survey={survey} isNonInteractive>
            <VStack spacing="5">
                <Box w={width * previewZoom} h={height * previewZoom}>
                    <Box
                        border="1px solid rgba(0,0,0,0.1)"
                        boxSizing="content-box"
                        w={width * previewZoom}
                        h={height * previewZoom}
                    >
                        <SurveyPreview key={survey.id} />
                    </Box>
                </Box>

                <Box w={width * previewZoom} minW="300px">
                    <CardPreviewStatus cardId={cardId} />
                </Box>
            </VStack>
        </SurveyProvider>
    );
}
