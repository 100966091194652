import { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useSetQuestionsForDynamicCoreQuestion } from '../../../../hooks/use-set-questions-for-dynamic-core-question';
import { cardMaximizeResponsesState } from '../../../../state/card/card-atoms';
import { activeCoreQuestionShortIdsListSelector } from '../../../../state/core-question/core-question-list-atoms';

/**
 * Provides the current value for `maximizeResponses` as well as a callback to
 * update it. Additionally, if `maximizeResponses` is set to `true`, we will
 * ensure that the full, sorted Core Question list is always used.
 */
export function useMaximizeResponses(cardId: string) {
    const [maximizeResponses, setMaximizeResponses] = useRecoilState(
        cardMaximizeResponsesState(cardId)
    );

    const allActiveCoreQuestionShortIds = useRecoilValue(
        activeCoreQuestionShortIdsListSelector
    );

    const { setQuestionsForDynamicCoreQuestion } =
        useSetQuestionsForDynamicCoreQuestion(cardId);

    useEffect(() => {
        if (!maximizeResponses) {
            return;
        }

        setQuestionsForDynamicCoreQuestion(allActiveCoreQuestionShortIds);
    }, [
        allActiveCoreQuestionShortIds,
        maximizeResponses,
        setQuestionsForDynamicCoreQuestion,
    ]);

    return {
        maximizeResponses,
        setMaximizeResponses,
    };
}
