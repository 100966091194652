import { useRecoilCallback } from 'recoil';
import { answerItemState } from '../answer/answer-atoms';
import { cardItemState } from '../card/card-atoms';
import { isLinearQuestionItem } from '../card/card-type-guards';
import {
    editingCoreQuestionIdState,
    formCoreQuestionIsActiveState,
    isCoreQuestionFormDirtyState,
    newCoreQuestionIdState,
} from './core-question-edit-form-atoms';

/**
 * Reset all core questions
 */
export const useCoreQuestionListReset = () => {
    const resetCoreQuestionList = useRecoilCallback(
        ({ snapshot, reset }) =>
            async () => {
                const editingCoreQuestionId = await snapshot.getPromise(
                    editingCoreQuestionIdState
                );

                if (!editingCoreQuestionId) {
                    return;
                }

                const editingCard = await snapshot.getPromise(
                    cardItemState(editingCoreQuestionId)
                );

                // reset editing card
                reset(cardItemState(editingCoreQuestionId));

                // reset current editing form.
                // Note: `editingCoreQuestionIdState` is important since
                // validateCardConfigSelector depends on it to determine if it's core question route.
                reset(editingCoreQuestionIdState);
                reset(newCoreQuestionIdState);
                reset(formCoreQuestionIsActiveState);
                reset(isCoreQuestionFormDirtyState);

                if (!isLinearQuestionItem(editingCard)) {
                    return;
                }

                // reset all core question atoms
                editingCard.answers.forEach((id) => reset(answerItemState(id)));
            },
        []
    );

    return {
        resetCoreQuestionList,
    };
};
