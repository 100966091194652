import {
    Alert,
    AlertDescription,
    AlertTitle,
    HStack,
    StackDivider,
    useColorModeValue,
    VStack,
} from '@chakra-ui/react';
import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { VscDebugDisconnect as IconDisconnected } from 'react-icons/vsc';

import type { Card } from '@dmp/qqms/types';
import { CardActionBar, CardCoreQuestionActionBar } from './card-action-bar';
import { CardAdd } from './card-add';
import { CardConnection } from './card-connection';
import { CardForms } from './card-forms';
import { CardPreviewBox } from './card-preview-box';
import { CardVisualTypes } from './card-visual-types';
import { cardVisualTypeSelector } from '../../state/card/card-atoms';
import { isCardConnectedSelector } from '../../state/survey-config/survey-config-atoms';
import { PaperCard } from '../common/paper-card';
import { useIsEditingCoreQuestion } from '../../hooks/use-is-editing-core-question';

interface CardItemComProps {
    cardId: Card['id'];
}

const CardItemCom = ({ cardId }: CardItemComProps) => {
    const visualCardType = useRecoilValue(cardVisualTypeSelector(cardId));
    const isCoreQuestion = useIsEditingCoreQuestion();
    const isConnected = useRecoilValue(isCardConnectedSelector(cardId));
    const disabledCoverBg = useColorModeValue(
        'whiteAlpha.800',
        'blackAlpha.700'
    );

    return (
        <VStack id={`card-${cardId}`}>
            <VStack
                align="stretch"
                className={!isConnected ? 'card-disabled' : ''}
                sx={{
                    '&.card-disabled *': {
                        pointerEvents: 'none',
                    },
                }}
            >
                <PaperCard
                    align="stretch"
                    spacing="0"
                    shadow="lg"
                    divider={<StackDivider />}
                    className="card"
                    position="relative"
                >
                    <HStack align="stretch" p="1">
                        {isCoreQuestion ? (
                            <CardCoreQuestionActionBar cardId={cardId} />
                        ) : (
                            <CardActionBar cardId={cardId} />
                        )}
                    </HStack>

                    <HStack
                        align="stretch"
                        spacing="0"
                        divider={<StackDivider />}
                    >
                        <VStack align="stretch" py="4">
                            <CardVisualTypes cardId={cardId} />
                        </VStack>

                        <CardForms
                            cardId={cardId}
                            visualCardType={visualCardType}
                        />

                        <CardPreviewBox cardId={cardId} />
                    </HStack>

                    {!isConnected && (
                        <HStack
                            position="absolute"
                            bg={disabledCoverBg}
                            top="0"
                            bottom="0"
                            left="0"
                            right="0"
                            justify="center"
                            rounded="md"
                            zIndex="1"
                        >
                            <Alert
                                status="info"
                                variant="subtle"
                                flexDirection="column"
                                alignItems="center"
                                justifyContent="center"
                                textAlign="center"
                            >
                                <IconDisconnected size="40px" />
                                <AlertTitle mt="2">
                                    This card is disconnected.
                                </AlertTitle>
                                <AlertDescription>
                                    It will not be seen by the reader in the
                                    survey.
                                </AlertDescription>
                            </Alert>
                        </HStack>
                    )}
                </PaperCard>

                {!isCoreQuestion && <CardConnection cardId={cardId} />}
            </VStack>

            {!isCoreQuestion && <CardAdd cardId={cardId} />}
        </VStack>
    );
};

export const CardItem = React.memo(CardItemCom);
