import { IconButton, Tooltip, VStack } from '@chakra-ui/react';
import {
    VscArrowLeft as IconBack,
    VscDebugRestart as IconReplay,
} from 'react-icons/vsc';
import { useSurveyPreviewControls } from '../../survey-proof/use-survey-preview-controls';

export function SurveyPreviewControls() {
    const { atStart, goBack, startOver } = useSurveyPreviewControls();

    return (
        <VStack align="start">
            <Tooltip label="back" placement="right">
                <IconButton
                    icon={<IconBack />}
                    aria-label="back"
                    size="sm"
                    disabled={atStart}
                    onClick={goBack}
                />
            </Tooltip>
            <Tooltip label="start over" placement="right">
                <IconButton
                    icon={<IconReplay />}
                    aria-label="start over"
                    size="sm"
                    disabled={atStart}
                    onClick={startOver}
                />
            </Tooltip>
        </VStack>
    );
}
