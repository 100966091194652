import { isLinearAnswer } from '@dmp/qqms/survey-utils';
import type { Answer, Question } from '@dmp/qqms/types';
import { useDispatch } from '../events/hooks';
import { classes } from '../utils';
import { ButtonComponent } from './button-component';
import { FreeFormComponent } from './free-form-component';

interface ButtonGroupComponentProps {
    question: Question;
}

export function ButtonGroupComponent({ question }: ButtonGroupComponentProps) {
    const dispatch = useDispatch();

    const answerCount = question.answers.length;
    const className = classes('button-group', `button-group-${answerCount}`);

    const onSingleAnswer = (answer: Answer) => {
        dispatch('answersSelected', { answers: [answer], question });
    };

    return (
        <div className={className}>
            {question.answers.map((a) => (
                <ButtonGroupSwitch
                    key={a.id}
                    answer={a}
                    onClick={() => onSingleAnswer(a)}
                />
            ))}
        </div>
    );
}

interface ButtonGroupSwitchProps {
    answer: Answer;
    onClick: () => void;
}

function ButtonGroupSwitch({ answer, onClick }: ButtonGroupSwitchProps) {
    if (isLinearAnswer(answer) && answer.isFreeForm) {
        return <FreeFormComponent label={answer.body} onAnswer={onClick} />;
    }

    return <ButtonComponent label={answer.body} onClick={onClick} />;
}
