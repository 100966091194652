import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
} from '@chakra-ui/react';
import * as React from 'react';
import { AiOutlineCloudDownload as IconDownload } from 'react-icons/ai';

import type { Survey } from '@dmp/qqms/types';
import { PublishSettings } from '../../survey-view/survey-publish-button/publish-settings';

interface SurveyPublishProps {
    surveyId: Survey['id'];
    surveyName: string;
    surveyQQID: string;
}

const SurveyPublishButtonCom: React.FC<SurveyPublishProps> = ({
    surveyId,
    surveyName,
    surveyQQID,
}) => {
    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const onClose = () => setIsOpen(false);
    const onOpen = () => setIsOpen(true);

    return (
        <>
            <Button
                leftIcon={<IconDownload size="18px" />}
                size="sm"
                fontWeight="normal"
                variant="usatoday_outline"
                onClick={onOpen}
            >
                Settings
            </Button>

            {isOpen && (
                <Modal isOpen={isOpen} onClose={onClose} isCentered>
                    <ModalOverlay />
                    <ModalContent w="90%" minW="650px" maxW="90%">
                        <ModalHeader>Survey Settings Download</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <PublishSettings
                                surveyId={surveyId}
                                surveyName={surveyName}
                                surveyQQID={surveyQQID}
                                saveFirst={false}
                            />
                        </ModalBody>
                    </ModalContent>
                </Modal>
            )}
        </>
    );
};

export const SurveyPublishButton = React.memo(SurveyPublishButtonCom);
