import { RequestError } from './fatch';

/**
 * Initiate a user download.
 * https://gist.github.com/dreamyguy/6b4ab77d2f118adb8a63c4a03fba349d
 *
 * Usage:
 * - downloadFile(new Blob([response.data]), 'fileName');
 */
export const downloadFile = (blob: Blob, filename: string) => {
    const blobURL = window.URL.createObjectURL(blob);
    const tempLink = document.createElement('a');

    tempLink.style.display = 'none';
    tempLink.href = blobURL;
    tempLink.setAttribute('download', filename);

    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof tempLink.download === 'undefined') {
        tempLink.setAttribute('target', '_blank');
    }

    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
    window.URL.revokeObjectURL(blobURL);
};

/**
 * Download a URL. This exists primarily to support POST and PATCH
 * http methods, which cannot be downloaded using a typical link.
 */
export async function downloadUrl(url: string, requestInit?: RequestInit) {
    const response = await fetch(url, requestInit);

    if (!response.ok) {
        const responseType = response.headers.get('content-type') || '';
        const data = responseType.includes('application/json')
            ? await response.json()
            : await response.text();

        throw new RequestError(data, response);
    }

    const fileName =
        fileNameFromResponse(response) || fileNameFromUrl(url) || 'download';

    downloadFile(new Blob([await response.text()]), fileName);
}

/**
 * Extracts filename from `content-disposition` header similar to:
 *  `attachment;filename=FirstProofTimes20180729-20180802.csv`
 */
function fileNameFromResponse(response: Response) {
    const contentDispositionHeader =
        response.headers.get('content-disposition') || '';

    const fileNameMatches = contentDispositionHeader.match(
        /filename[^;=\n]*=['"]?([^'"]*)['"]?/
    );

    return fileNameMatches && fileNameMatches[1];
}

/**
 * Generates a file name from a URL.
 */
function fileNameFromUrl(url: string): string | undefined {
    const withoutQueryString = url.split('?')[0].replace(/\/$/, '');
    return withoutQueryString.split('/').pop();
}
