import * as React from 'react';
import {
    HStack,
    useColorMode,
    Tooltip,
    Select,
    Divider,
} from '@chakra-ui/react';
import { useRecoilState, useRecoilValue } from 'recoil';

import {
    editingCoreQuestionIdState,
    formCoreQuestionIsActiveState,
    newCoreQuestionIdState,
} from '../../../state/core-question/core-question-edit-form-atoms';
import { EditBar } from './edit-bar';
import { NewQuestionBar } from './new-question-bar';

const CoreQuestionActionBarCom: React.FC = () => {
    const { colorMode } = useColorMode();

    const editingId = useRecoilValue(editingCoreQuestionIdState);
    const newCoreQuestionId = useRecoilValue(newCoreQuestionIdState);
    const [isActive, setIsActive] = useRecoilState(
        formCoreQuestionIsActiveState
    );

    const bg = colorMode === 'dark' ? 'gray.900' : 'white';
    const borderColor = colorMode === 'dark' ? 'gray.700' : 'gray.200';

    // is editing a brand new core question.
    const isNew = newCoreQuestionId === editingId;

    const switchActive = (e: React.ChangeEvent<HTMLSelectElement>) =>
        setIsActive(e.target.value === '1' ? true : false);

    return (
        <HStack
            w="100%"
            justifyContent="flex-end"
            bg={bg}
            p="2"
            borderBottom="1px solid"
            borderColor={borderColor}
        >
            {isNew ? <NewQuestionBar /> : <EditBar />}

            <Divider orientation="vertical" h="10px" />

            <Tooltip
                label="activate/deactivate this core question"
                placement="top"
            >
                <Select
                    w="120px"
                    size="sm"
                    rounded="md"
                    value={isActive ? '1' : '0'}
                    color={isActive ? 'green.300' : 'orange.300'}
                    onChange={switchActive}
                >
                    <option value="1">Active</option>
                    <option value="0">Disabled</option>
                </Select>
            </Tooltip>
        </HStack>
    );
};

export const CoreQuestionActionBar = React.memo(CoreQuestionActionBarCom);
