import type { DraftInlineStyleType } from 'draft-js';
import {
    MdFormatBold as IconBold,
    MdFormatItalic as IconItalic,
    MdStrikethroughS as IconST,
    MdFormatUnderlined as IconUnderline,
    MdFormatListBulleted as IconUL,
    MdFormatListNumbered as IconOL,
} from 'react-icons/md';

export interface RTEType<T = string> {
    label: string;
    style: T;
    icon?: JSX.Element;
}

/* *********************************
 * Block style types
 * *********************************/
export type RTEBlockType = RTEType<string>;

export const H1: RTEBlockType = { label: 'H1', style: 'header-one' };
export const H2: RTEBlockType = { label: 'H2', style: 'header-two' };
export const H3: RTEBlockType = { label: 'H3', style: 'header-three' };
export const UL: RTEBlockType = {
    label: 'Unordered list',
    style: 'unordered-list-item',
    icon: <IconUL size="18px" />,
};
export const OL: RTEBlockType = {
    label: 'Ordered list',
    style: 'ordered-list-item',
    icon: <IconOL size="18px" />,
};
export const BLOCKQUOTE: RTEBlockType = {
    label: 'Quote',
    style: 'blockquote',
};

/* *********************************
 * Inline style types
 * *********************************/
export type RTEInlineType = RTEType<DraftInlineStyleType>;

export const BOLD: RTEInlineType = {
    label: 'Bold',
    style: 'BOLD',
    icon: <IconBold size="18px" />,
};

export const ITALIC: RTEInlineType = {
    label: 'Italic',
    style: 'ITALIC',
    icon: <IconItalic size="18px" />,
};

export const STRIKETHROUGH: RTEInlineType = {
    label: 'Strike-through',
    style: 'STRIKETHROUGH',
    icon: <IconST size="18px" />,
};

export const UNDERLINE: RTEInlineType = {
    label: 'Underline',
    style: 'UNDERLINE',
    icon: <IconUnderline size="18px" />,
};
