import { Route, Routes } from 'react-router-dom';
import { CoreQuestionList } from './components/core-question-list';
import { SurveyList } from './components/survey-list';
import { SurveyProof } from './components/survey-proof';
import { SurveyView } from './components/survey-view';
import { AuthGroupView } from './components/auth-group-view';

export function App() {
    return (
        <Routes>
            <Route path="/" element={<SurveyList />} />
            <Route path="/surveys/:surveyId" element={<SurveyView />} />
            <Route
                path="/surveys/:surveyId/preview"
                element={<SurveyProof />}
            />
            <Route path="/core-questions" element={<CoreQuestionList />} />
            <Route path="/auth-groups" element={<AuthGroupView />} />
        </Routes>
    );
}

export default App;
