import { FiSettings as IconSettings } from 'react-icons/fi';
import {
    Box,
    FormControl,
    FormLabel,
    HStack,
    IconButton,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverHeader,
    PopoverTrigger,
    Switch,
    Tooltip,
    useColorMode,
} from '@chakra-ui/react';
import * as React from 'react';
import { useRecoilState } from 'recoil';
import { devToolState } from '../../../state/ui/dev-tool-atoms';

const SettingsCom: React.FC = () => {
    const { colorMode, toggleColorMode } = useColorMode();
    const [devTool, setDevTool] = useRecoilState(devToolState);

    return (
        <Popover>
            <PopoverTrigger>
                <Box>
                    <Tooltip label="app settings">
                        <IconButton
                            opacity="0.7"
                            icon={<IconSettings />}
                            aria-label="settings"
                            variant="ghost"
                            size="md"
                        />
                    </Tooltip>
                </Box>
            </PopoverTrigger>
            <PopoverContent>
                <PopoverArrow />
                <PopoverHeader>App Settings</PopoverHeader>
                <PopoverCloseButton />
                <PopoverBody>
                    <FormControl>
                        <HStack>
                            <FormLabel flex="1" htmlFor="theme">
                                Dark Theme
                            </FormLabel>
                            <Switch
                                id="theme"
                                isChecked={colorMode === 'dark'}
                                onChange={toggleColorMode}
                            />
                        </HStack>
                        <HStack>
                            <FormLabel flex="1" htmlFor="dev-tool">
                                Show Dev Tool
                            </FormLabel>
                            <Switch
                                id="dev-tool"
                                isChecked={devTool}
                                onChange={() => setDevTool(!devTool)}
                            />
                        </HStack>
                    </FormControl>
                </PopoverBody>
            </PopoverContent>
        </Popover>
    );
};

export const Settings = React.memo(SettingsCom);
