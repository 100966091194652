import type { CoreQuestionResource } from '@dmp/qqms/data-access';
import type { CoreQuestion } from '@dmp/qqms/types';

export const coreQuestionResourceToCoreQuestion = (
    resource: CoreQuestionResource
): CoreQuestion => {
    return {
        ...resource.attributes.config,
        id: resource.attributes.shortId,
        type: 'coreQuestion',
    };
};

export const getActiveCoreQuestionsFromResources = (
    resources: CoreQuestionResource[]
) =>
    resources
        .filter((res) => res.attributes.isActive)
        .map(coreQuestionResourceToCoreQuestion);
