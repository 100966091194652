import { IconButton, Tooltip } from '@chakra-ui/react';
import {
    VscArrowLeft as IconBack,
    VscDebugRestart as IconReplay,
} from 'react-icons/vsc';
import { useSurveyPreviewButtonProps } from './use-survey-preview-button-props';
import { useSurveyPreviewControls } from './use-survey-preview-controls';

export function SurveyPreviewBoxControls() {
    const buttonProps = useSurveyPreviewButtonProps();
    const { goBack, atStart, startOver } = useSurveyPreviewControls();

    return (
        <>
            <Tooltip label="back" placement="top">
                <IconButton
                    {...buttonProps}
                    icon={<IconBack />}
                    aria-label="back"
                    disabled={atStart}
                    onClick={goBack}
                />
            </Tooltip>

            <Tooltip label="start over" placement="top">
                <IconButton
                    {...buttonProps}
                    icon={<IconReplay />}
                    aria-label="start over"
                    disabled={atStart}
                    onClick={startOver}
                />
            </Tooltip>
        </>
    );
}
