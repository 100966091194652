import { Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { useRecoilValue } from 'recoil';
import { VscChevronDown as IconSelect } from 'react-icons/vsc';
import * as React from 'react';

import type { Card } from '@dmp/qqms/types';
import {
    cardFormTypeState,
    cardTypeState,
} from '../../../state/card/card-atoms';
import { IconForkedQuestion } from '../../card-type-icons/icon-forked-question';
import { IconLinearQuestion } from '../../card-type-icons/icon-linear-question';
import { useQuestionTypeSwitch } from '../../../hooks/use-question-type-switch';

interface QuestionTypeSelectProps {
    cardId: Card['id'];
}

const QuestionTypeSelectCom: React.FC<QuestionTypeSelectProps> = ({
    cardId,
}) => {
    const cardType = useRecoilValue(cardTypeState(cardId));
    const cardFormType = useRecoilValue(cardFormTypeState(cardId));

    const { switchQuestionType } = useQuestionTypeSwitch(cardId);

    const isLinear = cardType === 'linearQuestion';
    const isMultiSelect = cardFormType === 'multi-select';
    const ShowIcon = isLinear ? IconLinearQuestion : IconForkedQuestion;

    return (
        <Menu isLazy>
            <MenuButton
                size="sm"
                as={Button}
                rightIcon={<IconSelect />}
                leftIcon={<ShowIcon w="24px" h="18px" />}
            >
                {isLinear ? 'Linear' : 'Forked'}
            </MenuButton>

            <MenuList>
                <MenuItem
                    onClick={() => switchQuestionType('linearQuestion')}
                    isDisabled={isLinear}
                >
                    <IconLinearQuestion w="40px" h="30px" mr="2" />
                    <span>Linear Question</span>
                </MenuItem>
                <MenuItem
                    onClick={() => switchQuestionType('forkedQuestion')}
                    isDisabled={!isLinear || isMultiSelect}
                >
                    <IconForkedQuestion w="40px" h="30px" mr="2" />
                    <span>Forked Question</span>
                </MenuItem>
            </MenuList>
        </Menu>
    );
};

export const QuestionTypeSelect = React.memo(QuestionTypeSelectCom);
