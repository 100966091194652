import type { CardItemState } from './card-types';

export const typeIsDynamicCoreQuestion = (
    cardType: CardItemState['type']
): boolean => cardType === 'dynamicCoreQuestion';

export const typeIsForkedQuestion = (
    cardType: CardItemState['type']
): boolean => cardType === 'forkedQuestion';

export const typeIsLinearQuestion = (
    cardType: CardItemState['type']
): boolean => cardType === 'linearQuestion';

export const typeIsQuestion = (cardType: CardItemState['type']): boolean =>
    ['forkedQuestion', 'linearQuestion'].includes(cardType);

export const typeIsThankYouCard = (cardType: CardItemState['type']): boolean =>
    cardType === 'thankYouCard';

export const typeIsPollResultCard = (
    cardType: CardItemState['type']
): boolean => cardType === 'pollResultCard';
