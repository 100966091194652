import { isQuestion } from '@dmp/qqms/survey-utils';
import type {
    PollConfig,
    PollResponse,
    Question,
    Survey,
} from '@dmp/qqms/types';
import { useCallback, useEffect, useState } from 'react';
import { useEventListener } from '../../events/hooks';
import { questionHasPollResult } from '../../utils';

export type PollResultsState = Record<
    string,
    QuestionPollResultState | undefined
>;

export type QuestionPollResultState =
    | { type: 'LOADING' }
    | { type: 'LOADED'; config: PollConfig }
    | { type: 'ERROR' };

export const LOADING_STATE: QuestionPollResultState = { type: 'LOADING' };
export const ERROR_STATE: QuestionPollResultState = { type: 'ERROR' };

interface UsePollResultsInternalReturn {
    state: PollResultsState;
    fetchPollResult: (
        questionId: string,
        answerIds?: string[]
    ) => Promise<QuestionPollResultState>;
}

/**
 * Manage fetching Poll Results from the QQMS API.
 *
 * NOTE: This function should only be used by the <SurveyProvider />. All other
 * Components should access and manage state via non-internal hooks.
 */
export function usePollResults_INTERNAL(
    survey: Survey
): UsePollResultsInternalReturn {
    const [state, setState] = useState<PollResultsState>({});

    const setQuestionState = useCallback(
        (questionId: string, questionState: QuestionPollResultState) => {
            setState((current) => ({
                ...current,
                [questionId]: questionState,
            }));

            return questionState;
        },
        []
    );

    /**
     * Fetch the Poll Results for a Question.
     */
    const fetchPollResult = useCallback(
        async (questionId: string, answerIds: string[] = []) => {
            const existingResults = state[questionId];

            if (existingResults) {
                return existingResults;
            }

            if (survey.qqmsApi === undefined) {
                console.error('Poll results API not configured');
                return setQuestionState(questionId, ERROR_STATE);
            }

            // Get the source Question.
            const question = survey.cards
                .filter(isQuestion)
                .find((c) => c.id === questionId);

            if (!question || question.pollResultsKey === undefined) {
                console.error(
                    'Unable to find source Question for Poll Results.'
                );

                return setQuestionState(questionId, ERROR_STATE);
            }

            const url = new URL(survey.qqmsApi);

            url.pathname = `/api/poll-results/${encodeURIComponent(
                question.pollResultsKey
            )}`;

            setQuestionState(questionId, LOADING_STATE);

            fetch(url.toString())
                .then((resp) => resp.json())
                .then((response) => {
                    setQuestionState(questionId, {
                        type: 'LOADED',
                        config: calcPollConfig(question, response, answerIds),
                    });
                })
                .catch((e) => {
                    console.error(e);
                    setQuestionState(questionId, ERROR_STATE);
                });

            return LOADING_STATE;
        },
        [setQuestionState, state, survey.cards, survey.qqmsApi]
    );

    /**
     * If a Question is answered, and it has an associated
     * Poll Result Card, fetch the results from the QQMS API.
     */
    useEventListener('answersSelected', (e) => {
        const { question, answers } = e.detail;

        if (questionHasPollResult(survey, question.id)) {
            fetchPollResult(
                question.id,
                answers.map((a) => a.id)
            );
        }
    });

    /**
     * Reset state when the `survey` changes.
     * This should only happen in the QQMS Client.
     */
    useEffect(() => {
        setState({});
    }, [survey]);

    return { state, fetchPollResult };
}

/**
 * Calculate PollConfig
 * - total = api total + # of user's answered answers
 * - Calculate percent based on above total
 */
export function calcPollConfig(
    question: Question,
    response: PollResponse,
    answeredAnswerIds: string[]
): PollConfig {
    const adjustedTotal = response.total + answeredAnswerIds.length;

    const results = question.answers.map((answer) => {
        const isAnswered = answeredAnswerIds.includes(answer.id);
        const result = response.results.find((r) => r.answerId === answer.id);
        let votes = result ? result.votes : 0;

        if (isAnswered) {
            votes = votes + 1;
        }

        const percent = (votes * 100) / adjustedTotal;

        return {
            label: answer.body,
            answerId: answer.id,
            votes,
            percent,
            isAnswered,
        };
    });

    return {
        title: question.body,
        total: adjustedTotal,
        results,
    };
}
