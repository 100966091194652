import { Box, HStack, Text } from '@chakra-ui/react';
import { Link as ReactRouterLink, useMatch } from 'react-router-dom';
import { memo } from 'react';
import { Users } from '@phosphor-icons/react';
import { IconCoreQuestion } from '../card-type-icons/icon-core-question';

interface Tab {
    title: string;
    path: string;
    icon: JSX.Element;
}

const tabs: Tab[] = [
    {
        title: 'Manage Core Questions',
        path: '/core-questions',
        icon: <IconCoreQuestion viewBox="12 2 55 60" w="100%" h="100%" />,
    },
    {
        title: 'Manage Authentication Groups',
        path: '/auth-groups',
        icon: <Users />,
    },
];

const AdminNavCom = () => (
    <HStack spacing="4" px="4">
        {tabs.map((tab) => (
            <LinkTab key={tab.path} {...tab} />
        ))}
    </HStack>
);

export const AdminNav = memo(AdminNavCom);

const LinkTab = (tab: Tab) => {
    const isMatch = useMatch({ path: tab.path, end: true }) !== null;

    return (
        <HStack
            as={ReactRouterLink}
            to={tab.path}
            px="5"
            py="2"
            rounded="md"
            color={isMatch ? 'brand.100' : ''}
            opacity={isMatch ? '1' : '0.6'}
            _hover={{ opacity: '1' }}
        >
            <Box w="18px">{tab.icon}</Box>
            <Text fontWeight="bold">{tab.title}</Text>
        </HStack>
    );
};
