import type { CoreQuestionResource } from '@dmp/qqms/data-access';
import { atom } from 'recoil';

/**
 * Atom state to record current editing core question.
 */
export const editingCoreQuestionIdState = atom<CoreQuestionResource['id']>({
    key: 'ui_editingCoreQuestionIdState',
    default: '',
});

/**
 * Atom state to record editing core question form dirty
 */
export const isCoreQuestionFormDirtyState = atom<boolean>({
    key: 'ui_isCoreQuestionFormDirtyState',
    default: false,
});

/**
 * Atom state to record the new core question id
 */
export const newCoreQuestionIdState = atom<CoreQuestionResource['id']>({
    key: 'ui_newCoreQuestionIdState',
    default: '',
});

/**
 * Atom state to record the new core question isActive
 * Note: this will affect dirty state, do not prefix `ui_`
 */
export const formCoreQuestionIsActiveState = atom<boolean>({
    key: 'formCoreQuestionIsActiveState',
    default: false,
});
