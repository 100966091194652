import type { ThemeOverride } from '@chakra-ui/react';
import { backgroundColor } from './foundations/colors';

const styles: ThemeOverride['styles'] = {
    global: (props) => {
        const isDark = props.colorMode === 'dark';

        const scrollbarColor = isDark
            ? 'rgba(255, 255, 255, 0.2)'
            : 'rgba(0, 0, 0, 0.2)';
        const scrollbarBg = isDark ? '#1A202C' : 'white';

        return {
            'html, #root': {
                width: '100%',
                height: '100%',
                overflow: 'hidden',
            },
            body: {
                width: '100%',
                height: '100%',
                background: backgroundColor(props.colorMode),
                fontFamily:
                    '-apple-system, BlinkMacSystemFont, “Segoe UI”, “Roboto”, “Oxygen”, “Ubuntu”, “Cantarell”, “Fira Sans”, “Droid Sans”, “Helvetica Neue”, sans-serif',
            },
            '.scrollbar': {
                scrollbarWidth: 'thin',
                scrollbarColor: `${scrollbarColor} ${scrollbarBg}`,
                '&::-webkit-scrollbar': {
                    width: '9px',
                    height: '9px',
                    display: 'block',
                    backgroundColor: scrollbarBg,
                },
                '&::-webkit-scrollbar-thumb': {
                    borderRadius: '4px',
                    backgroundColor: scrollbarColor,
                    border: `1px solid ${scrollbarBg}`,
                },
                '&::-webkit-scrollbar-thumb:hover': {
                    backgroundColor: scrollbarColor,
                },
            },
        };
    },
};

export default styles;
