import {
    Box,
    Button,
    Heading,
    HStack,
    Link,
    Skeleton,
    Stack,
    Text,
    VStack,
} from '@chakra-ui/react';
import type { SurveyListResource, UserResource } from '@dmp/qqms/data-access';
import { dateToMDY } from '@dmp/shared/client-utils';
import type { ResourceObject } from '@dmp/shared/types';
import * as React from 'react';
import {
    VscPlay as IconPreview,
    VscBrowser as IconLiveProof,
} from 'react-icons/vsc';
import { routeSurvey, routeSurveyPreview } from '../../config/routeUrls';
import { useLiveProof } from '../../hooks/use-live-proof';
import { SurveyPublishButton } from './survey-publish-button';
import { SurveyDuplicateButton } from './survey-duplicate-button/survey-duplicate-button';

interface SurveyItemProps {
    surveyResource: SurveyListResource;
    users: UserResource[];
}

export const SurveyItem: React.FC<SurveyItemProps> = React.memo(
    ({ surveyResource, users }) => {
        const { name, qqid } = surveyResource.attributes;

        const { id, attributes, relationships } = surveyResource;
        const { updatedAt } = attributes;

        const userId = (relationships?.updatedBy?.data as ResourceObject)?.id;
        const user = users.find((user) => user.id === userId);
        const userName = user
            ? `${user.attributes.firstName} ${user.attributes.lastName}`
            : '';

        const liveProof = useLiveProof(id);

        return (
            <Stack
                direction={{ base: 'column', lg: 'row' }}
                flexWrap="wrap"
                spacing={{ base: 3, lg: 4 }}
                py={{ base: 2, lg: 2 }}
            >
                <HStack w="80px">
                    <Text fontSize="sm" variant="gray">
                        {qqid}
                    </Text>
                </HStack>

                <HStack flex={['0 0 100%', '1']} minW="320px">
                    <Heading
                        as="h1"
                        variant="usatoday_blue_bold"
                        fontSize="l"
                        _hover={{
                            textDecoration: 'underline',
                        }}
                    >
                        <Link href={routeSurvey(id)}>{name}</Link>
                    </Heading>
                </HStack>

                <HStack w="380px">
                    <SurveyPublishButton
                        surveyId={id}
                        surveyName={name}
                        surveyQQID={qqid}
                    />

                    <Button
                        as={Link}
                        leftIcon={<IconPreview size="17px" />}
                        size="sm"
                        fontWeight="normal"
                        variant="usatoday_outline"
                        href={routeSurveyPreview(id)}
                    >
                        Preview
                    </Button>

                    <Button
                        leftIcon={<IconLiveProof size="17px" />}
                        size="sm"
                        fontWeight="normal"
                        variant="usatoday_outline"
                        onClick={liveProof.initiate}
                        isLoading={liveProof.isProofing}
                        loadingText="Proofing"
                    >
                        Live Proof
                    </Button>

                    <SurveyDuplicateButton surveyId={id} />
                </HStack>

                <VStack align="start" w="100px" fontSize="xs">
                    <Text variant="gray">{dateToMDY(`${updatedAt}`)}</Text>
                    {userName && <Text variant="gray">by {userName}</Text>}
                </VStack>
            </Stack>
        );
    }
);

export const SurveyItemSkeleton = () => {
    return (
        <HStack flexWrap="wrap">
            <Box w="100px" py="4">
                <Skeleton height="20px" />
            </Box>
            <Box flex={['0 0 100%', '1']} minW="320px" py="4">
                <Skeleton height="20px" />
            </Box>
            <Box w="200px" py="4">
                <Skeleton height="20px" />
            </Box>
            <Box w="100px" py="4" fontSize="sm">
                <Skeleton height="20px" />
            </Box>
        </HStack>
    );
};
