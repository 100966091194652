import { memo } from 'react';
import type { IconProps } from '@chakra-ui/react';
import { Icon } from '@chakra-ui/react';

const IconButtonCom = (props: IconProps) => {
    return (
        <Icon viewBox="0 0 80 60" {...props}>
            <path
                fill="currentColor"
                d="M60.1 16.4H19.9c-3.8 0-6.9 3.1-6.9 6.9v7.9c0 3.8 3.1 6.9 6.9 6.9h21.4v-2.9H19.9c-2.1 0-4-1.8-4-4v-7.9c0-2.1 1.8-4 4-4h40.3c2.1 0 4 1.8 4 4v7.9c0 2.1-1.8 4-4 4H59l3.1 2.6c2.8-.8 4.9-3.5 4.9-6.6v-7.9c0-3.8-3.1-6.9-6.9-6.9z"
            />
            <path
                fill="currentColor"
                d="M51.8 47.5c-.5.2-1.1 0-1.3-.5l-2.1-4.6-2.4 2c-.2.1-.4.2-.6.2-.5 0-1-.4-1-1V29.2c0-.5.4-1 1-1 .2 0 .5.1.6.2l11.1 9.3c.4.3.5 1 .1 1.4-.1.2-.3.3-.6.3l-2.9.6 2.1 4.6c.3.5 0 1.1-.5 1.3l-3.5 1.6z"
            />
        </Icon>
    );
};

export const IconButton = memo(IconButtonCom);
