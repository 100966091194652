import { Group } from '@visx/group';
import { hierarchy, Tree as TreeRoot } from '@visx/hierarchy';
import { LinkHorizontal } from '@visx/shape';
import { useRecoilValue } from 'recoil';
import * as React from 'react';

import { convertCardsToTree } from '../../../utils/convert-cards-to-tree';
import { surveyConfigSelector } from '../../../state/survey-config/survey-config-atoms';
import { TreeNode } from './tree-node';

const TreeCom = () => {
    const survey = useRecoilValue(surveyConfigSelector);

    if (!survey.id) {
        return null;
    }

    const card = survey.cards[0];
    const treeInfo = convertCardsToTree(card, survey.cards);

    return (
        <TreeRoot
            root={hierarchy(treeInfo, (node) => node.children)}
            // tree is reversed, so it's [height, width]
            nodeSize={[75, 150]}
            // based on node size
            separation={() => 1}
        >
            {(tree) => (
                <Group top={0} left={0}>
                    {tree.links().map((link, i) => (
                        <LinkHorizontal
                            key={i}
                            data={link}
                            stroke="rgb(254,110,158,0.6)"
                            strokeWidth="1"
                            fill="none"
                            style={{ pointerEvents: 'none' }}
                        />
                    ))}

                    {tree.descendants().map((node, key) => (
                        <TreeNode key={key} node={node} />
                    ))}
                </Group>
            )}
        </TreeRoot>
    );
};

export const Tree = React.memo(TreeCom);
