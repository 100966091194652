import { Box, Heading, VStack } from '@chakra-ui/react';
import * as React from 'react';

import type { Card } from '@dmp/qqms/types';
import { cardTypeState } from '../../../state/card/card-atoms';
import { useRecoilValue } from 'recoil';
import CardBody from './fields/card-body';
import { CardTraxexLabel } from './fields/card-traxex-label';

interface CardAsThankYouComProps {
    cardId: Card['id'];
}

const CardAsThankYouCom: React.FC<CardAsThankYouComProps> = ({ cardId }) => {
    const cardType = useRecoilValue(cardTypeState(cardId));

    if (cardType !== 'thankYouCard') {
        return null;
    }

    return (
        <VStack align="start">
            <Heading as="h2" fontSize="sm">
                Thank You Message
            </Heading>
            <CardBody
                cardId={cardId}
                isRichTextEditor={true}
                fontSize="xl"
                size="lg"
                rows={5}
                placeholder="enter Thank You note"
            />

            <Box pt="4" pl="8" w="100%">
                <CardTraxexLabel
                    label="Tracking Label: User viewed the ThankYou Card"
                    cardId={cardId}
                />
            </Box>
        </VStack>
    );
};

export const CardAsThankYou = React.memo(CardAsThankYouCom);
