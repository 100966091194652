import { Accordion, VStack } from '@chakra-ui/react';
import { useRecoilValue } from 'recoil';

import { surveyState } from '../../../state/survey/survey-atoms';
import { countSeq } from '@dmp/shared/client-utils';
import { SOCard } from './so-card';
import { StateObserverItem } from './state-observer-item';
import { devToolState } from '../../../state/ui/dev-tool-atoms';

export function StateObserver() {
    const devToolOn = useRecoilValue(devToolState);
    const survey = useRecoilValue(surveyState);
    const cardIds = survey.cards;

    if (!devToolOn) {
        return null;
    }

    return (
        <VStack
            w="250px"
            overflowY="auto"
            bg="blue.800"
            fontSize="xs"
            color="white"
        >
            <Accordion w="100%" defaultIndex={[0, 1, 2]} allowMultiple>
                <StateObserverItem name="Survey" item={survey} />

                <Accordion
                    bg="gray.700"
                    w="100%"
                    defaultIndex={countSeq(0, 5)}
                    allowMultiple
                >
                    {cardIds.map((cardId) => (
                        <SOCard key={cardId} cardId={cardId} />
                    ))}
                </Accordion>
            </Accordion>
        </VStack>
    );
}
