import type { UserResource } from '@dmp/qqms/data-access';
import { fetchSurveys } from '@dmp/qqms/data-access';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';
import { SURVEYS } from '../../config/query-keys';
import { useQueryParams } from '../../hooks/use-query-params';

export const useSurveyList = () => {
    /********************************
     *
     * Pagination and filtering.
     *
     ********************************/

    const [queryParams, setQueryParams] = useQueryParams();
    const pageNumber = queryParams?.page?.number || 1;
    const pageSize = queryParams?.page?.size || 20;
    const keyword = queryParams?.filters?.combined || '';

    const handleSubmitKeyword = (text: string) => {
        setQueryParams({
            filters: {
                combined: text,
            },
            page: {
                number: 1, // reset page
                size: pageSize,
            },
        });
    };

    const handleGoToPage = (page: number) => {
        setQueryParams({
            page: {
                number: page,
                size: pageSize,
            },
        });
    };

    /********************************
     *
     * API query.
     *
     ********************************/

    const query = useQuery(
        [SURVEYS, { pageNumber, pageSize, keyword }],
        () => fetchSurveys(keyword, pageNumber, pageSize),
        { keepPreviousData: true }
    );

    const users = useMemo(() => {
        const data =
            query.data?.included?.filter((i) => i.type === 'users') || [];

        return data as UserResource[];
    }, [query.data]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [query.data]);

    return {
        handleGoToPage,
        handleSubmitKeyword,
        keyword,
        page: pageNumber,
        query,
        users,
    } as const;
};
