import { Button, HStack, Text, VStack } from '@chakra-ui/react';
import { TrimmedInput } from '@dmp/shared/components';
import { zodResolver } from '@hookform/resolvers/zod';
import { memo } from 'react';
import type { FieldErrors } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { useAuthGroupCreate } from '../../state/auth-group/use-auth-group-create';
import { AuthGroupSelect } from './auth-group-select';

const CreateCom = () => {
    const { createAuthGroup } = useAuthGroupCreate();

    const form = useForm<AuthGroupValues>({
        mode: 'onChange',
        resolver: zodResolver(validator),
        defaultValues: {
            oktaAuthGroup: '',
            permissionGroup: 'users',
        },
    });

    const { isDirty, isValid, isSubmitting, errors } = form.formState;
    const canSubmit = isDirty && isValid && !isSubmitting;

    const onSubmit = form.handleSubmit(createAuthGroup);

    return (
        <FormProvider {...form}>
            <VStack align="stretch" py="2">
                <HStack
                    as="form"
                    onSubmit={onSubmit}
                    align="stretch"
                    spacing="4"
                    maxW="600px"
                >
                    <TrimmedInput
                        autoComplete="off"
                        fontSize="md"
                        size="sm"
                        rounded="md"
                        placeholder="Enter group name"
                        {...form.register('oktaAuthGroup')}
                    />
                    <AuthGroupSelect {...form.register('permissionGroup')} />

                    <Button
                        type="submit"
                        w="250px"
                        size="sm"
                        rounded="md"
                        bg={canSubmit ? 'brand.100' : 'blackAlpha.200'}
                        border="1px solid"
                        borderColor={canSubmit ? 'brand.100' : 'whiteAlpha.200'}
                        color={canSubmit ? 'white' : 'gray.500'}
                        isDisabled={!canSubmit}
                    >
                        Add
                    </Button>
                </HStack>

                <AuthGroupCreateErrors errors={errors} />
            </VStack>
        </FormProvider>
    );
};

type AuthGroupCreateErrorsProps = {
    errors: FieldErrors<AuthGroupValues>;
};

const AuthGroupCreateErrors = ({ errors }: AuthGroupCreateErrorsProps) => {
    if (Object.keys(errors).length === 0) {
        return null;
    }

    return (
        <VStack align="start" opacity="0.7" spacing="0">
            {Object.values(errors).map((err) => (
                <Text fontSize="sm">{err.message}</Text>
            ))}
        </VStack>
    );
};

export const AuthGroupCreate = memo(CreateCom);

const validator = z.object({
    permissionGroup: z.union([z.literal('users'), z.literal('managers')]),
    oktaAuthGroup: z.string().min(2),
});
type AuthGroupValues = z.infer<typeof validator>;
