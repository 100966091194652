import * as React from 'react';
import { Button, HStack, Text, Box } from '@chakra-ui/react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
    VscSave as IconSave,
    VscDiscard as IconDiscard,
} from 'react-icons/vsc';

import {
    editingCoreQuestionIdState,
    isCoreQuestionFormDirtyState,
} from '../../../state/core-question/core-question-edit-form-atoms';
import { switchEditingCoreQuestionSelector } from '../../../state/core-question/switch-editing-core-question-selector';
import { useCoreQuestionPatch } from '../../../state/core-question/use-core-question-patch';
import { FormShortcutListeners } from './form-shortcut-listeners';

const EditBarCom: React.FC = () => {
    const editingId = useRecoilValue(editingCoreQuestionIdState);
    const isFormDirty = useRecoilValue(isCoreQuestionFormDirtyState);

    const switchCoreQuestion = useSetRecoilState(
        switchEditingCoreQuestionSelector
    );

    const { patchCoreQuestion } = useCoreQuestionPatch();

    const save = () => patchCoreQuestion(editingId);

    // switch the same id will reset the form
    const discard = () => switchCoreQuestion(editingId);

    return (
        <>
            <Box flex="1" px="5">
                <Text fontSize="sm" opacity="0.6" fontStyle="italic">
                    {isFormDirty && '(Unsaved)'}
                </Text>
            </Box>
            <HStack spacing="5">
                <Button
                    leftIcon={<IconDiscard />}
                    size="sm"
                    variant="usatoday_blue"
                    onClick={discard}
                    isDisabled={!isFormDirty}
                >
                    Discard
                </Button>
                <Button
                    leftIcon={<IconSave />}
                    size="sm"
                    variant="usatoday_blue"
                    onClick={save}
                    isDisabled={!isFormDirty}
                >
                    <u>S</u>ave
                </Button>
            </HStack>
            <FormShortcutListeners />
        </>
    );
};

export const EditBar = React.memo(EditBarCom);
