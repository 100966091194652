import { generateQuestionId } from '@dmp/qqms/survey-generators';
import { useRecoilCallback } from 'recoil';
import { insertNewCard } from '../../utils/insertNewCard';
import { answerItemState } from '../answer/answer-atoms';
import { addAnswerUtil } from '../answer/use-answer-add';
import { surveyState } from '../survey/survey-atoms';
import type { SurveyState } from '../survey/survey-types';
import {
    cardItemState,
    defaultQuestion,
    surveyCardsSelector,
} from './card-atoms';
import type { CardItemState, LinearQuestionItemState } from './card-types';

/**
 * Add a standard question card
 * cardId given, new card will be add after that card
 */
export const useCardAddQuestion = (cardId?: CardItemState['id']) => {
    const addQuestionCard = useRecoilCallback(
        ({ set, snapshot }) =>
            async () => {
                const survey = await snapshot.getPromise(surveyState);
                const cardsInSurvey = await snapshot.getPromise(
                    surveyCardsSelector
                );

                const { newSurvey, newCards, newAnswer } = addCardQuestionUtil(
                    survey,
                    cardsInSurvey
                )(cardId);

                // update all cards, & create new card atom
                newCards.forEach((c) => set(cardItemState(c.id), c));

                // create new answer atom
                set(answerItemState(newAnswer.id), newAnswer);

                // add new card id into survey
                set(surveyState, newSurvey);
            },
        [cardId]
    );

    return {
        addQuestionCard,
    };
};

/**
 * Create new card and get modified atoms
 */
export const addCardQuestionUtil =
    (survey: SurveyState, cardsInSurvey: CardItemState[]) =>
    (cardId?: CardItemState['id']) => {
        const newQuestion: LinearQuestionItemState = {
            ...defaultQuestion,
            id: generateQuestionId(),
        };

        // add answer
        const { newQuestion: newQuestionWithAnswer, newAnswer } =
            addAnswerUtil(newQuestion);

        // Insert new card and reconnects `resultCard` in the collection
        const { newSurvey, newCards } = insertNewCard(survey, cardsInSurvey)(
            newQuestionWithAnswer,
            cardId
        );

        return {
            newSurvey,
            newCards,
            newAnswer,
        };
    };
