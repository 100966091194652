import { IconButton, StackDivider, Tooltip, VStack } from '@chakra-ui/react';
import {
    VscTrash as IconTrash,
    VscTriangleUp as IconUp,
    VscTriangleDown as IconDown,
} from 'react-icons/vsc';
import * as React from 'react';

import type { AnswerItemState } from '../../state/answer/answer-types';
import type { Card } from '@dmp/qqms/types';
import { useAnswerActionButtons } from './use-answer-action-buttons';

interface AnswerActionButtonsComProps {
    cardId: Card['id'];
    answerId: AnswerItemState['id'];
}

const AnswerActionButtonsCom: React.FC<AnswerActionButtonsComProps> = ({
    cardId,
    answerId,
}) => {
    const { canMoveDown, canMoveUp, moveDown, moveUp, remove } =
        useAnswerActionButtons(cardId, answerId);

    return (
        <VStack
            align="start"
            divider={<StackDivider w="5" alignSelf="center" />}
            opacity="0.2"
            sx={{
                '.answer:hover &': {
                    opacity: 1,
                },
            }}
        >
            <Tooltip label="remove answer" placement="right">
                <IconButton
                    icon={<IconTrash />}
                    onClick={remove}
                    aria-label="remove answer"
                    variant="ghost"
                    size="sm"
                />
            </Tooltip>

            <VStack spacing="0">
                <Tooltip label="move up" placement="right">
                    <IconButton
                        icon={<IconUp />}
                        onClick={moveUp}
                        isDisabled={!canMoveUp}
                        aria-label="move up"
                        variant="ghost"
                        size="sm"
                    />
                </Tooltip>

                <Tooltip label="move down" placement="right">
                    <IconButton
                        icon={<IconDown />}
                        onClick={moveDown}
                        isDisabled={!canMoveDown}
                        aria-label="move down"
                        variant="ghost"
                        size="sm"
                    />
                </Tooltip>
            </VStack>
        </VStack>
    );
};

export const AnswerActionButtons = React.memo(AnswerActionButtonsCom);
