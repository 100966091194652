import { useSetRecoilState } from 'recoil';
import KeyboardJS from 'keyboardjs';
import * as React from 'react';

import {
    previewShortcutState,
    publishShortcutState,
} from '../../state/ui/shortcut-atoms';
import { useSurveyPatch } from '../../state/survey/use-survey-patch';

export const ShortcutListeners: React.FC = () => {
    const setPreviewOpen = useSetRecoilState(previewShortcutState);
    const setPublishOpen = useSetRecoilState(publishShortcutState);
    const { patchSurvey } = useSurveyPatch();

    React.useEffect(() => {
        // save
        KeyboardJS.bind(['ctrl+s', 'command+s'], (e) => {
            e?.preventDefault();
            patchSurvey();
        });

        // preview
        KeyboardJS.bind(
            ['ctrl+p', 'command+p', 'ctrl+enter', 'command+enter'],
            (e) => {
                e?.preventDefault();
                setPreviewOpen(true);
            }
        );

        // publish
        KeyboardJS.bind(['ctrl+d', 'command+d'], (e) => {
            e?.preventDefault();
            setPublishOpen(true);
        });

        return () => {
            KeyboardJS.reset();
        };
    }, [patchSurvey, setPreviewOpen, setPublishOpen]);

    return null;
};
