import { Box, HStack, Icon, IconButton, useColorMode } from '@chakra-ui/react';
import { CSS } from '@dnd-kit/utilities';
import { IoIosRemoveCircleOutline as IconRemove } from 'react-icons/io';
import { useRecoilValue } from 'recoil';
import { useSortable } from '@dnd-kit/sortable';
import { VscGripper as IconDrag } from 'react-icons/vsc';
import * as React from 'react';

import { coreQuestionShortIdBodySelector } from '../../../../state/core-question/core-question-list-atoms';
import { CoreQuestionBody } from './core-question-body';
import type { ListName } from './use-sortable-core-questions';

interface SortableBarProps {
    listName: ListName;
    id: string;
    onRemove?: (id: string) => void;
    onSelect?: (
        e: React.MouseEvent<HTMLElement>,
        listName: ListName,
        id: string
    ) => void;
    isDragging?: boolean;
    isLib?: boolean;
    isSelected?: boolean;
    isDisabled?: boolean;
    keyword: string;
}

const SortableBarCom: React.FC<SortableBarProps> = ({
    listName,
    isDragging = false,
    isLib = false,
    id,
    onSelect,
    isSelected,
    isDisabled,
    onRemove,
    keyword,
}) => {
    const { colorMode } = useColorMode();

    const body = useRecoilValue(coreQuestionShortIdBodySelector(id));

    const { attributes, listeners, setNodeRef, transform, transition } =
        useSortable({ id });

    const isDark = colorMode === 'dark';

    const style = {
        transform: isDisabled ? '' : CSS.Transform.toString(transform) || '',
        transition: transition || '',
    };

    const attrs = { ...listeners, ...attributes };

    const remove = () => onRemove && onRemove(id);

    // visible based on search keyword
    const visible =
        keyword && body ? new RegExp(keyword, 'i').test(body) : true;

    return (
        <HStack
            w="100%"
            minW="200px"
            h="8"
            rounded="md"
            bg={isSelected ? 'green.500' : isDark ? 'gray.800' : 'white'}
            color={isSelected ? 'gray.800' : isDark ? 'inherit' : 'gray.800'}
            spacing="0"
            style={style}
            opacity={isDragging ? '0' : '1'}
            height={visible ? 'unset' : '0'}
            overflow={visible ? 'unset' : 'hidden'}
            ref={setNodeRef}
        >
            <Box {...attrs} px="2">
                <Icon as={IconDrag} opacity="0.2" cursor="grab" />
            </Box>

            <HStack
                flex="1"
                p="2"
                overflow="hidden"
                onClick={(e) => onSelect && onSelect(e, listName, id)}
            >
                <CoreQuestionBody shortId={id} />
            </HStack>

            {!isLib && (
                <IconButton
                    icon={<IconRemove />}
                    aria-label="remove"
                    size="sm"
                    variant="ghost"
                    onClick={remove}
                />
            )}
        </HStack>
    );
};

export const SortableBar = React.memo(SortableBarCom);

interface SortableBarShadowProps {
    ids: string[];
}

// Shadow: cloned active-dragging render of the current element
export const SortableBarShadowCom: React.FC<SortableBarShadowProps> = ({
    ids,
}) => {
    const { colorMode } = useColorMode();

    const isDark = colorMode === 'dark';

    return (
        <Box position="relative">
            {ids.map((id, idx) => (
                <HStack
                    key={id}
                    w="100%"
                    h="8"
                    minW="200px"
                    rounded="md"
                    bg={isDark ? 'gray.800' : 'white'}
                    spacing="0"
                    p="1"
                    position="absolute"
                    top={idx * 5}
                    left={idx * 5}
                    border="1px solid"
                    borderColor="green.500"
                >
                    <Icon as={IconDrag} opacity="0.2" cursor="grab" />
                    <HStack flex="1" p="2" overflow="hidden">
                        <CoreQuestionBody shortId={id} />
                    </HStack>
                </HStack>
            ))}
        </Box>
    );
};

export const SortableBarShadow = React.memo(SortableBarShadowCom);
