import * as React from 'react';
import { HStack, VStack } from '@chakra-ui/react';

import { swatches, swatchesGannett } from './color-picker-config';
import { SwatchColor } from './swatch-color';
import { isEqualHEXorRGB } from './colorUtils';

interface SwatchesProps {
    value: string;
    onChange: (value: string) => void;
}

export const Swatches: React.FC<SwatchesProps> = ({ value, onChange }) => {
    return (
        <VStack w="100%" bg="gray.700">
            <HStack spacing="0" wrap="wrap">
                {swatchesGannett.map((swatch) => (
                    <React.Fragment key={swatch.name}>
                        {swatch.colors.map((color) => (
                            <SwatchColor
                                key={color}
                                name={swatch.name}
                                value={color}
                                isActive={isEqualHEXorRGB(value, color)}
                                onChange={() => onChange(color)}
                            />
                        ))}
                    </React.Fragment>
                ))}
            </HStack>

            <HStack spacing="0" wrap="wrap">
                {swatches.map((swatch) => (
                    <React.Fragment key={swatch.name}>
                        {swatch.colors.map((color) => (
                            <SwatchColor
                                key={color}
                                name={swatch.name}
                                value={color}
                                isActive={value === color}
                                onChange={() => onChange(color)}
                            />
                        ))}
                    </React.Fragment>
                ))}
            </HStack>
        </VStack>
    );
};
