import {
    Divider,
    HStack,
    IconButton,
    Tooltip,
    useColorMode,
    VStack,
} from '@chakra-ui/react';
import { patchCoreQuestions } from '@dmp/qqms/data-access';
import { SearchBox } from '@dmp/shared/components';
import type { DragEndEvent } from '@dnd-kit/core';
import {
    closestCenter,
    DndContext,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { memo, useEffect, useState } from 'react';
import { VscAdd as IconAdd } from 'react-icons/vsc';
import { useRecoilValue } from 'recoil';
import { coreQuestionIdsListSelector } from '../../state/core-question/core-question-list-atoms';
import { useCoreQuestionAdd } from '../../state/core-question/use-core-question-add';
import { useCoreQuestionListFetch } from '../../state/core-question/use-core-question-list-fetch';
import { useCoreQuestionListReset } from '../../state/core-question/use-core-question-list-reset';
import { AuthGate } from '../auth-gate/auth-gate';
import { LogoBar } from '../common/logo-bar';
import PermissionGate from '../permission-gate/permission-gate';
import { CoreQuestionEditForm } from './core-question-edit-form';
import { CoreQuestionItem } from './core-question-item';
import { AdminNav } from '../common/admin-nav';

const CoreQuestionListCom = () => {
    const { colorMode } = useColorMode();

    const {
        addCoreQuestion,
        coreQuestionIds,
        keyword,
        setKeyword,
        sensors,
        handleDragEnd,
    } = useCoreQuestionList();

    const bg = colorMode === 'dark' ? 'gray.900' : 'white';

    return (
        <AuthGate>
            <PermissionGate permission="coreQuestions.manage">
                <VStack
                    align="stretch"
                    h="100%"
                    spacing="0"
                    divider={<Divider />}
                >
                    <LogoBar>
                        <AdminNav />
                    </LogoBar>
                    <HStack
                        flex="1"
                        align="stretch"
                        overflow="hidden"
                        spacing="0"
                    >
                        <VStack
                            bg={bg}
                            minW="300px"
                            maxW="400px"
                            spacing="0"
                            overflowY="auto"
                            p="5"
                        >
                            <HStack w="100%" spacing="4" pb="5">
                                <SearchBox
                                    boxHeight="10"
                                    initialValue={keyword}
                                    placeholder="search"
                                    isInstant
                                    onSubmit={setKeyword}
                                />
                                <Tooltip label="add new core question">
                                    <IconButton
                                        aria-label="add question"
                                        icon={<IconAdd />}
                                        onClick={addCoreQuestion}
                                    />
                                </Tooltip>
                            </HStack>

                            <DndContext
                                sensors={sensors}
                                collisionDetection={closestCenter}
                                onDragEnd={handleDragEnd}
                            >
                                <SortableContext
                                    items={coreQuestionIds}
                                    strategy={verticalListSortingStrategy}
                                >
                                    {coreQuestionIds.map((id) => (
                                        <CoreQuestionItem
                                            key={id}
                                            coreQuestionId={id}
                                            filterKeyword={keyword}
                                        />
                                    ))}
                                </SortableContext>
                            </DndContext>
                        </VStack>

                        <Divider orientation="horizontal" w="1px" h="100%" />

                        <VStack flex="1" align="stretch" overflow="hidden">
                            <CoreQuestionEditForm />
                        </VStack>
                    </HStack>
                </VStack>
            </PermissionGate>
        </AuthGate>
    );
};

export const CoreQuestionList = memo(CoreQuestionListCom);

export const useCoreQuestionList = () => {
    const coreQuestionIds = useRecoilValue(coreQuestionIdsListSelector);

    const { fetchCoreQuestionList } = useCoreQuestionListFetch();
    const { resetCoreQuestionList } = useCoreQuestionListReset();
    const { addCoreQuestion } = useCoreQuestionAdd();

    const [keyword, setKeyword] = useState<string>('');

    useEffect(() => {
        fetchCoreQuestionList();

        return () => {
            // Prevent Memory Leak - clean up core question atoms after exit
            resetCoreQuestionList();
        };
        // no dependency for it performs once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Sorting --------------------------------

    const [localIds, setLocalIds] = useState(coreQuestionIds);

    useEffect(() => {
        setLocalIds(coreQuestionIds);
    }, [coreQuestionIds]);

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const handleDragEnd = async (event: DragEndEvent) => {
        const { active, over } = event;

        if (over && active.id !== over.id) {
            const oldIndex = localIds.indexOf(active.id.toString());
            const newIndex = localIds.indexOf(over.id.toString());
            const newIds = arrayMove(localIds, oldIndex, newIndex);

            setLocalIds(newIds);

            const payload = newIds.map((id, idx) => ({
                id,
                priority: newIds.length - idx,
            }));

            await patchCoreQuestions(payload);
        }
    };

    return {
        addCoreQuestion,
        coreQuestionIds: localIds,
        keyword,
        setKeyword,

        sensors,
        handleDragEnd,
    };
};
