import { selector } from 'recoil';

import type { CoreQuestionResource } from '@dmp/qqms/data-access';
import {
    answerItemState,
    surveyAllAnswersSelector,
} from '../answer/answer-atoms';
import type { AnswerItemState } from '../answer/answer-types';
import { cardItemState, surveyCardsSelector } from '../card/card-atoms';
import type { CardItemState } from '../card/card-types';
import { allCoreQuestionList } from '../core-question/core-question-list-atoms';
import { surveyCardIdsState, surveyState } from '../survey/survey-atoms';
import type { SurveyState } from '../survey/survey-types';

export interface CollectiveState {
    survey: SurveyState;
    cards: CardItemState[];
    answers: AnswerItemState[];
    allCoreQuestions: CoreQuestionResource[];
}

/**
 * Retrieve the whole app state info
 *
 * NOTE: Since this selector relates to all the state/atoms, it should be used cautiously
 * - Inside useRecoilCallback
 * - Or, in debounced render components
 */
export const collectiveStateSelector = selector<CollectiveState>({
    key: 'collectiveStateSelector',
    get: ({ get }) => {
        const survey = get(surveyState);
        const cards = get(surveyCardsSelector);
        const answers = get(surveyAllAnswersSelector);
        const allCoreQuestions = get(allCoreQuestionList);

        return {
            survey,
            cards,
            answers,
            allCoreQuestions,
        };
    },
    // RESET ALL SURVEY RELATED STAGES
    set: ({ get, reset }) => {
        const surveyCardIds = get(surveyCardIdsState);
        const surveyAnswers = get(surveyAllAnswersSelector);

        // reset survey state
        reset(surveyState);

        // reset all card states
        surveyCardIds.forEach((id) => reset(cardItemState(id)));

        // reset all answer states
        surveyAnswers.forEach((a) => reset(answerItemState(a.id)));
    },
});
