import { z } from 'zod';

export const traxexLabel = () =>
    z
        .string()
        .min(1)
        .max(32)
        .regex(
            /^[A-Za-z0-9-]+$/,
            'Traxex labels must contain letters, numbers, and dashes only'
        );
