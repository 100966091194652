import { useRecoilCallback } from 'recoil';

import type { AnswerItemState } from './answer-types';
import type { Card } from '@dmp/qqms/types';
import { cardItemState } from '../card/card-atoms';
import { isQuestionItem } from '../card/card-type-guards';
import type { QuestionItemState } from '../card/card-types';
import { move } from '@dmp/shared/helpers';

export const useAnswerSort = (
    cardId: Card['id'],
    answerId: AnswerItemState['id']
) => {
    const sortAnswer = useRecoilCallback(
        ({ set, snapshot }) =>
            async (newIndex: number) => {
                const card = await snapshot.getPromise(cardItemState(cardId));

                if (!isQuestionItem(card)) {
                    throw new Error('Only Question Cards can sort Answers.');
                }

                const oldIndex = card.answers.findIndex(
                    (aId) => aId === answerId
                );
                const { newQuestion } = sortAnswerUtil(card)(
                    oldIndex,
                    newIndex
                );

                // add new answer id into card
                set(cardItemState(cardId), newQuestion);
            },
        [cardId, answerId]
    );

    return {
        sortAnswer,
    };
};

/**
 * sort answers in card
 */
export const sortAnswerUtil =
    <T extends QuestionItemState>(question: T) =>
    (oldIndex: number, newIndex: number) => {
        const answerIds = question.answers;

        // invalid when index < 0 or > array length
        const invalid =
            oldIndex < 0 ||
            newIndex < 0 ||
            oldIndex > answerIds.length ||
            newIndex > answerIds.length;

        const newQuestion = {
            ...question,
            // no sort if any index is invalid
            answers: invalid ? answerIds : move(answerIds, oldIndex, newIndex),
        };

        return {
            newQuestion,
        };
    };
