import { memo } from 'react';
import type { IconProps } from '@chakra-ui/react';
import { Icon } from '@chakra-ui/react';

const IconForkedQuestionCom = (props: IconProps) => {
    return (
        <Icon viewBox="0 0 80 60" {...props}>
            <path
                fill="currentColor"
                d="M48.6 27H65c1.2 0 2.2-1 2.2-2.2V14.2c0-1.2-1-2.2-2.2-2.2H48.6c-1.2 0-2.2 1-2.2 2.2v4.3h-13v-4.3c0-1.2-1-2.2-2.2-2.2H15c-1.2 0-2.2 1-2.2 2.2v10.6c0 1.2 1 2.2 2.2 2.2h16.4c1.2 0 2.2-1 2.2-2.2v-4.1h5.2V41h7.7v4.7c0 1.2 1 2.2 2.2 2.2H65c1.2 0 2.2-1 2.2-2.2V35.1c0-1.2-1-2.2-2.2-2.2H48.6c-1.2 0-2.2 1-2.2 2.2v3.8h-5.6V20.7h5.6v4.1c.1 1.3 1.1 2.2 2.2 2.2z"
            />
        </Icon>
    );
};

export const IconForkedQuestion = memo(IconForkedQuestionCom);
