import type { Question } from '@dmp/qqms/types';
import type { CardItemState } from '../state/card/card-types';

export type VisualCardType =
    | Question['formType']
    | 'thankYouCard'
    | 'pollResultCard'
    | 'dynamicCoreQuestion';

export const getVisualCardType = ({
    type,
    formType,
}: {
    type: CardItemState['type'];
    formType: Question['formType'];
}): VisualCardType => {
    switch (type) {
        case 'forkedQuestion':
        case 'linearQuestion':
            return formType;

        // includes: 'thankYouCard' and 'dynamicCoreQuestion'
        default:
            return type;
    }
};
