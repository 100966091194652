import { memo } from 'react';
import type { IconProps } from '@chakra-ui/react';
import { Icon } from '@chakra-ui/react';

const IconButtonClickCom = (props: IconProps) => {
    return (
        <Icon viewBox="0 0 80 60" {...props}>
            <path
                fill="currentColor"
                d="M70.9,38c-0.1-1-0.4-2-0.9-2.8l-2.7-4.9c-1.1-2-3.6-2.7-5.5-1.6c-0.1,0.1-0.2,0.1-0.3,0.2l-1,0.7c-0.2-0.1-0.4-0.1-0.6-0.2
		c-0.6-0.2-1.5-0.4-2.3-0.2c-0.8,0.2-1.6,0.8-2.1,1.2l-0.2,0.2c-0.5-0.3-1.1-0.5-1.7-0.4c-0.5,0-1.1,0.2-1.6,0.4
		c-0.6,0.3-1.3,0.8-1.8,1.1l-0.1,0.1l-3.2-4.5c-1.1-1.6-3.4-2-5-0.9c-1.6,1.1-2,3.4-0.9,5l7.6,10.9l-4.4-0.8
		c-1.7-0.3-3.3,0.8-3.6,2.5c-0.2,1.4,0.5,2.8,1.8,3.3l9,4l4.9,2.6c1,0.6,2.3,0.5,3.2-0.2l10.5-7.4c0.9-0.6,1.4-1.7,1.3-2.8L70.9,38z
		 M68.9,44.8l-10.5,7.4c-0.3,0.2-0.7,0.2-1.1,0.1l-4.9-2.7l-9.1-4.1c-0.5-0.2-0.8-0.9-0.5-1.4c0.2-0.4,0.7-0.7,1.1-0.6l6.8,1.2
		c0.6,0.1,1.1-0.3,1.2-0.8c0-0.3,0-0.5-0.2-0.8l-9-12.9c-0.5-0.7-0.3-1.6,0.4-2.1c0.7-0.5,1.6-0.3,2.1,0.4l7.3,10.4
		c0.3,0.5,1,0.6,1.4,0.3c0.5-0.3,0.6-1,0.3-1.4l-3-4.2l0.1-0.1c0.5-0.4,1.1-0.8,1.4-0.9c0.3-0.1,0.6-0.2,1-0.2
		c0.2,0,0.4,0.1,0.6,0.3l2.4,3.4c0.3,0.5,1,0.6,1.4,0.3c0.5-0.3,0.6-1,0.3-1.4l-1.8-2.6l0.2-0.1c0.5-0.4,1-0.7,1.2-0.8
		c0.3-0.1,0.7,0,1.3,0.2c0.2,0.1,0.5,0.1,0.6,0.2l1.6,2.2c0.3,0.5,1,0.6,1.4,0.3c0.5-0.3,0.6-1,0.3-1.4L62,31.1l0.6-0.4
		c0.9-0.6,2.2-0.4,2.8,0.5c0,0.1,0.1,0.1,0.1,0.2l2.7,4.9c0.3,0.6,0.6,1.3,0.6,2l0.5,5.7C69.4,44.3,69.2,44.6,68.9,44.8z"
            />
            <path
                fill="currentColor"
                d="M64,23.3c0-3.8-3.1-6.9-6.9-6.9H16.9c-3.8,0-6.9,3.1-6.9,6.9v7.9c0,3.8,3.1,6.9,6.9,6.9h19.7v-2.9H16.9c-2.1,0-4-1.8-4-4
		v-7.9c0-2.1,1.8-4,4-4h40.3c2.1,0,4,1.8,4,4v0.1L64,23.3L64,23.3z"
            />
        </Icon>
    );
};

export const IconButtonClick = memo(IconButtonClickCom);
