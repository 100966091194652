import * as React from 'react';
import { Button, HStack, Text } from '@chakra-ui/react';
import { useRecoilValue } from 'recoil';
import { VscDiscard as IconDiscard } from 'react-icons/vsc';

import {
    formCoreQuestionIsActiveState,
    isCoreQuestionFormDirtyState,
} from '../../../state/core-question/core-question-edit-form-atoms';
import { useCoreQuestionAdd } from '../../../state/core-question/use-core-question-add';
import { useCoreQuestionPost } from '../../../state/core-question/use-core-question-post';

const NewQuestionBarCom: React.FC = () => {
    const isFormDirty = useRecoilValue(isCoreQuestionFormDirtyState);
    const isActive = useRecoilValue(formCoreQuestionIsActiveState);

    const { addCoreQuestion } = useCoreQuestionAdd();
    const { postCoreQuestion } = useCoreQuestionPost();

    const activateAndSave = () => postCoreQuestion(true);
    const create = () => postCoreQuestion();

    // discard by creating an another new empty core question
    const discard = () => addCoreQuestion();

    return (
        <>
            <HStack flex="1" px="2">
                <Text variant="usatoday_blue">Create New Question</Text>
                <Text fontSize="sm" opacity="0.6" fontStyle="italic">
                    {isFormDirty && '(Unsaved)'}
                </Text>
            </HStack>
            <HStack spacing="5">
                <Button
                    leftIcon={<IconDiscard />}
                    size="sm"
                    variant="usatoday_blue"
                    onClick={discard}
                    isDisabled={!isFormDirty}
                >
                    Discard
                </Button>
                {!isActive && (
                    <Button
                        size="sm"
                        variant="usatoday_blue"
                        onClick={activateAndSave}
                        isDisabled={!isFormDirty}
                    >
                        Create & Activate
                    </Button>
                )}
                <Button
                    size="sm"
                    variant="usatoday_blue_full"
                    onClick={create}
                    isDisabled={!isFormDirty}
                >
                    Create
                </Button>
            </HStack>
        </>
    );
};

export const NewQuestionBar = React.memo(NewQuestionBarCom);
