import { HStack, Text } from '@chakra-ui/react';
import * as React from 'react';

import type { Card } from '@dmp/qqms/types';

interface CardCoreQuestionActionBarProps {
    cardId: Card['id'];
}

const CardCoreQuestionActionBarCom: React.FC<
    CardCoreQuestionActionBarProps
> = () => {
    return (
        <HStack flex="1" px="3">
            <Text variant="usatoday_blue_bold">Core Question</Text>
        </HStack>
    );
};

export const CardCoreQuestionActionBar = React.memo(
    CardCoreQuestionActionBarCom
);
