import type { CoreQuestionResource } from '@dmp/qqms/data-access';
import { replaceItem } from '@dmp/shared/helpers';
import { atom, DefaultValue, selector, selectorFamily } from 'recoil';

/**
 * Store original core question config list
 */
export const allCoreQuestionList = atom<CoreQuestionResource[]>({
    key: 'allCoreQuestionList',
    default: [],
});

/**
 * Get the list of all core question ids
 */
export const coreQuestionIdsListSelector = selector<
    Array<CoreQuestionResource['id']>
>({
    key: 'coreQuestionIdsListSelector',
    get: ({ get }) =>
        [...get(allCoreQuestionList)].sort(byPriority).map((cq) => cq.id),
});

/**
 * Get the list of only active core question ids
 */
export const activeCoreQuestionShortIdsListSelector = selector<
    Array<CoreQuestionResource['id']>
>({
    key: 'activeCoreQuestionIdsListSelector',
    get: ({ get }) =>
        get(allCoreQuestionList)
            .filter((cq) => cq.attributes.isActive)
            .sort(byPriority)
            .map((cq) => cq.attributes.shortId),
});

/**
 * Sort function that sorts Core Question Resources by priority (highest-to-lowest).
 */
function byPriority(a: CoreQuestionResource, b: CoreQuestionResource): number {
    return b.attributes.priority - a.attributes.priority;
}

/**
 * Get/Set core question config by id
 * - Getter: get core question config
 * - Setter: set core question config by id. allCoreQuestionList will be automatically updated.
 */
export const coreQuestionResourceSelector = selectorFamily<
    CoreQuestionResource | undefined,
    CoreQuestionResource['id']
>({
    key: 'coreQuestionConfigSelector',
    get:
        (id) =>
        ({ get }) =>
            get(allCoreQuestionList).find((cq) => cq.id === id),
    set:
        (id) =>
        ({ get, set }, newValue) => {
            if (newValue instanceof DefaultValue) {
                return;
            }

            if (!newValue) {
                return;
            }

            const allCoreQuestions = get(allCoreQuestionList);
            const index = allCoreQuestions.findIndex((cq) => cq.id === id);

            // exists, update the config
            if (index !== -1) {
                const newCoreQuestionList = replaceItem(
                    allCoreQuestions,
                    index,
                    newValue
                );

                set(allCoreQuestionList, newCoreQuestionList);
                return;
            }

            // not found, add on top of the list
            set(allCoreQuestionList, [newValue].concat(allCoreQuestions));
        },
});

/**
 * Get/Set core question resource isActive
 */
export const coreQuestionShortIdIsActiveSelector = selectorFamily<
    boolean,
    CoreQuestionResource['attributes']['shortId']
>({
    key: 'coreQuestionIsActiveSelector',
    get:
        (shortId) =>
        ({ get }) =>
            !!get(allCoreQuestionList).find(
                (cq) => cq.attributes.shortId === shortId
            )?.attributes.config.body,
    set:
        (shortId) =>
        ({ get, set }, newValue) => {
            if (newValue instanceof DefaultValue) {
                return;
            }

            const prev = get(allCoreQuestionList).find(
                (cq) => cq.attributes.shortId === shortId
            );

            // update the current core question resource isActive
            if (prev) {
                set(coreQuestionResourceSelector(prev.id), {
                    ...prev,
                    attributes: {
                        ...prev.attributes,
                        isActive: newValue,
                    },
                });
            }
        },
});

/**
 * Get core question body by id
 */
export const coreQuestionShortIdBodySelector = selectorFamily<
    string | undefined,
    CoreQuestionResource['id']
>({
    key: 'coreQuestionConfigSelector',
    get:
        (shortId) =>
        ({ get }) =>
            get(allCoreQuestionList).find(
                (cq) => cq.attributes.shortId === shortId
            )?.attributes.config.body,
});
