import type { BoxProps, CenterProps } from '@chakra-ui/react';
import {
    Center,
    Icon,
    Text,
    VStack,
    Spinner,
    Box,
    IconButton,
    Tooltip,
    useColorModeValue,
} from '@chakra-ui/react';
import type { ReactNode } from 'react';
import { memo, useCallback, useState } from 'react';
import type { DropzoneProps } from 'react-dropzone';
import { useDropzone } from 'react-dropzone';
import {
    UploadSimple as IconUpload,
    X as IconClear,
} from '@phosphor-icons/react';
import type { Icon as PhosphorIconType } from '@phosphor-icons/react';
import type { IconType } from 'react-icons/lib';

export interface UploadDropzoneProps {
    /** URL string of the image, if any */
    value?: string;

    /** Process onChange event */
    onChange: (value?: File, files?: File[]) => unknown;
    /** Disable the dropzone */

    disabled?: boolean;

    /** Display text for the centered upload button */
    dropzoneText?: ReactNode;

    /**
     * https://react-dropzone.js.org/#section-accepting-specific-file-types
     * ex:
     * - { 'image/*': ['.jpeg', '.png', '.gif', '.jpg'] }
     */
    accept?: DropzoneProps['accept'];

    /** is the dropping item invalid? */
    isInvalid?: boolean;

    /** custom Upload Icon */
    uploadIcon?: IconType | PhosphorIconType;

    /** Container border color */
    borderColor?: CenterProps['borderColor'];

    /** Container roundness */
    rounded?: CenterProps['rounded'];

    /** Icon size for the upload icon */
    iconSize?: string;

    /** Allow multiple files to be uploaded. Defaults to `true`. */
    multiple?: boolean;
}

export const IMAGE_EXTENSIONS = ['.jpeg', '.png', '.gif', '.jpg', '.svg'];

const UploadDropzoneCom = ({
    onChange,
    value = '',
    disabled = false,
    dropzoneText = 'Drop/Upload File',
    accept = { 'image/*': IMAGE_EXTENSIONS },
    isInvalid,
    borderColor: propBorderColor,
    multiple = true,
    rounded = 'md',
    uploadIcon = IconUpload,
    iconSize = '50px',
}: UploadDropzoneProps) => {
    const baseBorderColor = useColorModeValue(
        'blackAlpha.100',
        'whiteAlpha.200'
    );
    const textBg = useColorModeValue('whiteAlpha.500', 'blackAlpha.500');

    const [isBusy, setIsBusy] = useState<boolean>(false);

    const onDrop = useCallback(
        async (files: File[]) => {
            setIsBusy(true);
            await onChange(files[0], files);
            setIsBusy(false);
        },
        [onChange]
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept,
        onDrop,
        multiple,
        disabled,
    });

    const clear: React.MouseEventHandler = (e) => {
        e.stopPropagation();
        onChange();
    };

    const borderColor = isDragActive
        ? 'blue.400'
        : isInvalid
        ? 'red.300'
        : propBorderColor ?? baseBorderColor;

    return (
        <Center
            position="relative"
            h="100%"
            w="100%"
            p="4"
            border="1px solid"
            cursor={disabled ? 'not-allowed' : 'pointer'}
            overflow="hidden"
            borderColor={borderColor}
            rounded={rounded}
            sx={{
                '&:hover .upload-thumb': {
                    opacity: '1',
                },
            }}
            {...(getRootProps() as CenterProps)}
        >
            {value && <ThumbVisual imgSrc={value} />}
            <VStack
                position="relative"
                spacing="0"
                justifyContent="center"
                alignContent="center"
                marginTop="-4"
                opacity={disabled ? '0.5' : '1'}
            >
                <input {...getInputProps()} />
                {isBusy && <Spinner size="lg" />}

                {!isBusy && (
                    <>
                        <Icon
                            as={uploadIcon}
                            w={iconSize}
                            h={iconSize}
                            mb="2"
                        />
                        {dropzoneText && (
                            <Text
                                textAlign="center"
                                fontSize="sm"
                                px="1"
                                rounded="md"
                                backdropFilter="blur(10px)"
                                bg={textBg}
                            >
                                {dropzoneText}
                            </Text>
                        )}
                    </>
                )}
            </VStack>
            {value && (
                <Tooltip label="Clear">
                    <IconButton
                        icon={<IconClear />}
                        onClick={clear}
                        size="sm"
                        variant="ghost"
                        backdropFilter="blur(10px)"
                        rounded="full"
                        aria-label="clear"
                        position="absolute"
                        top="1"
                        right="1"
                        background="transparent"
                        border="none"
                    />
                </Tooltip>
            )}
        </Center>
    );
};

export const UploadDropzone = memo(UploadDropzoneCom);

const ThumbVisual = memo(({ imgSrc }: { imgSrc: string }) => {
    const styles: BoxProps = {
        position: 'absolute',
        w: '100%',
        h: '100%',
        left: '0',
        top: '0',
        bgRepeat: 'no-repeat',
        bgPosition: 'center',
    };

    const bgImage = `url(${imgSrc})`;

    return (
        <Box
            {...styles}
            overflow="hidden"
            className="upload-thumb"
            opacity="0.9"
        >
            <Box
                {...styles}
                style={{ backgroundImage: bgImage }}
                bgSize="cover"
                filter="blur(50px) opacity(0.3)"
            />
            <Box
                {...styles}
                style={{ backgroundImage: bgImage }}
                bgSize="contain"
            />
        </Box>
    );
});
